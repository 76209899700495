import React, { useContext, useCallback } from 'react';
import { EditableCard } from 'components/EditableCard';
import type { IEditableCard } from 'components/EditableCard';
import { ContactContext } from 'contexts/ContactContext';
import type { IEditableCardProps } from 'pages/Contact/interfaces';
import { PageConfig } from 'pages/page-config.service';
import { Form } from '@procore/core-react';
import i18n from 'i18n';
import type { CardConfigTranslated } from 'pages/page-config.interfaces';
import type { ISelectOption } from 'interfaces/common.interface';

export const ContactSettingsCard = (props: IEditableCardProps) => {
  const context = useContext(ContactContext);
  const config = PageConfig.get(
    i18n.t,
    'Contact.Settings.ContactSettings',
  ) as CardConfigTranslated;
  const contact = context.dataSources?.contact;

  const sendUserMessageOptions = [
    { id: 'via email', label: 'via email' },
    { id: 'not at all', label: 'not at all' },
  ];

  const formatPersonaOptions = useCallback(() => {
    const options: ISelectOption[] = contact.data?.persona_options.map(
      (option) => {
        return {
          id: option,
          label: option,
        };
      },
    );

    return options;
  }, [contact.data?.country_language_options]);

  const formatLanguageOptions = useCallback(() => {
    const options: ISelectOption[] = contact.data?.country_language_options.map(
      (option: any) => {
        return {
          id: option[1],
          label: option[0],
        };
      },
    );

    return options;
  }, [contact.data?.country_language_options]);

  const getLanguageLabel = useCallback(
    (languageCode) => {
      if (contact.data?.country_language_options) {
        const languageOption = contact.data?.country_language_options.find(
          (option: any) => option[1] === languageCode,
        );

        return languageOption?.[0] ?? '';
      } else {
        return '';
      }
    },
    [contact.data?.country_language_options],
  );

  const card: IEditableCard = {
    id: props.id,
    i18n: config.translate,
    label: config.label,
    canEdit: context.hasPermission(config.permissions.edit),
    save: props.save,
    fields: [
      {
        source: contact.id,
        value: {
          id: contact.data?.send_user_messages,
          label: contact.data?.send_user_messages,
        },
        name: 'send_user_messages',
        element: <React.Fragment />,
        elementFactory: (field) => (
          <Form.Select
            name={field.name}
            label={field.label}
            options={sendUserMessageOptions}
          />
        ),
        ...config.fields.send_user_messages,
      },
      {
        source: contact.id,
        value: contact.data?.is_employee,
        name: 'is_employee',
        dataType: 'boolean',
        element: Form.Checkbox,
        ...config.fields.is_employee,
      },
      {
        source: contact.id,
        value: contact.data?.is_insurance_manager,
        name: 'is_insurance_manager',
        dataType: 'boolean',
        element: Form.Checkbox,
        ...config.fields.is_insurance_manager,
      },
      {
        source: contact.id,
        value: {
          id: contact.data?.country_language,
          label: getLanguageLabel(contact.data?.country_language),
        },
        name: 'country_language',
        element: <React.Fragment />,
        elementFactory: (field) => (
          <Form.Select
            name={field.name}
            label={field.label}
            options={formatLanguageOptions()}
          />
        ),
        ...config.fields.country_language,
      },
      {
        source: contact.id,
        value: {
          id: contact.data?.persona,
          label: contact.data?.persona,
        },
        name: 'persona',
        element: <React.Fragment />,
        elementFactory: (field) => (
          <Form.Select
            name={field.name}
            label={field.label}
            options={formatPersonaOptions()}
          />
        ),
        ...config.fields.persona,
      },
      {
        source: contact.id,
        value: contact.data?.can_run_extracts,
        name: 'can_run_extracts',
        dataType: 'boolean',
        element: Form.Checkbox,
        ...config.fields.can_run_extracts,
      },
    ],
  };

  return <EditableCard card={card} />;
};

export default ContactSettingsCard;
