import type { PageConfig } from 'pages/page-config.interfaces';

const AdvancedPage: PageConfig = {
  label: 'TAB_ADVANCED',
  route: {
    page: 'advanced',
  },
  i18n: {
    namespace: 'company',
  },
  permissions: {
    edit: ['company_tab_edit'],
  },
  endpoints: {
    view: ['companyAdvancedSettingsControllerGetCompanyAdvancedSettings'],
  },

  cards: {
    CustomFields: {
      i18n: {
        keyPrefix: 'ADVANCED.CUSTOM_FIELDS',
      },
      label: 'TITLE',
      permissions: {
        edit: ['edit_custom_fields'],
      },
      fields: {
        // These are dynamically generated
      },
      endpoints: {
        view: ['companyAdvancedSettingsControllerGetCustomFieldsSettings'],
      },
    },

    EnableSettings: {
      i18n: {
        keyPrefix: 'ADVANCED.ENABLE_SETTINGS',
      },
      label: 'TITLE',
      permissions: {
        edit: ['edit_advanced_settings'],
      },
      fields: {
        erp_integrated: {
          label: 'ENABLE_ERP_INTEGRATION',
        },
        skilljar_enabled: {
          label: 'ENABLE_SKILLJAR_TRAINING_LINK',
          tooltip: 'ENABLE_SKILLJAR_TRAINING_LINK_TOOLTIP',
        },
        contextual_help_enabled: {
          label: 'ENABLE_CONTEXTUAL_HELP',
          tooltip: 'ENABLE_CONTEXTUAL_HELP_TOOLTIP',
        },
        daily_construction_report_log_enabled: {
          label: 'ENABLE_DAILY_CONSTRUCTION_SUPPORT_LOG',
          tooltip: 'ENABLE_DAILY_CONSTRUCTION_SUPPORT_LOG_TOOLTIP',
        },
        box_dot_net_integration_enabled: {
          label: 'ENABLE_BOX_INTEGRATION',
          tooltip: 'ENABLE_BOX_INTEGRATION_TOOLTIP',
        },
        allow_quick_add_user: {
          label: 'ENABLE_QUICK_ADD_NEW_CONTACTS',
          tooltip: 'ENABLE_QUICK_ADD_NEW_CONTACTS_TOOLTIP',
        },
        allow_quick_permission_change: {
          label: 'ENABLE_PERMISSION_UPGRADE_EXISTING_CONTRACTS',
          tooltip: 'ENABLE_PERMISSION_UPGRADE_EXISTING_CONTRACTS_TOOLTIP',
        },
        enable_company_my_open_items_view: {
          label: 'ENABLE_MY_OPEN_ITEMS_COMPANY_HOME',
        },
        enable_folder_checkout: {
          label: 'ENABLE_CHECKIN_CHECKOUT_DOCS',
        },
        enable_proest_import: {
          label: 'ENABLE_PROEST_IMPORT',
        },
        enable_timberline_vendor_id: {
          label: 'ENABLE_TIMBERLINE_VENDOR_ID',
          tooltip: 'ENABLE_TIMBERLINE_VENDOR_ID_TOOLTIP',
        },
        enable_timberline_integration: {
          label: 'ENABLE_TIMBERLINE_INTEGRATION',
        },
        enable_company_level_open_submittals_report: {
          label: 'ENABLE_COMPANY_LEVEL_OPEN_SUBMITTALS_REPORT',
        },
        enable_change_reason_select_for_new_projects: {
          label: 'ENABLE_CHANGE_ORDER_CHANGE_REASON_SELECT_NEW_PROJECTS',
          tooltip:
            'ENABLE_CHANGE_ORDER_CHANGE_REASON_SELECT_NEW_PROJECTS_TOOLTIP',
        },
        show_bcc: {
          label: 'ENABLE_BCC',
        },
        enable_standards_can_create_cors_and_cops: {
          label: 'ENABLE_CONFIG_ALLOW_STANDARDS_CREATE_COR_PCCO_CCO',
          tooltip: 'ENABLE_CONFIG_ALLOW_STANDARDS_CREATE_COR_PCCO_CCO_TOOLTIP',
        },
      },
      endpoints: {
        edit: ['companyAdvancedSettingsControllerUpdateAdvancedSettings'],
      },
    },

    SalesforceDemoSettings: {
      i18n: {
        keyPrefix: 'ADVANCED.SALESFORCE_DEMO_ACCOUNTS',
      },
      label: 'TITLE',
      permissions: {
        edit: ['edit_salesforce_settings'],
      },
      fields: {
        is_demo: {
          label: 'DEMO_COMPANY',
        },
        sales_force_account_id: {
          label: 'SALESFORCE_ACCOUNT_ID',
          tooltip: 'SALESFORCE_ACCOUNT_ID_TOOLTIP',
        },
      },
      endpoints: {
        edit: [
          'companyAdvancedSettingsControllerUpdateSalesforceAccountSettings',
        ],
      },
    },

    AccountAdminSettings: {
      i18n: {
        keyPrefix: 'ADVANCED.ACCOUNT_ADMIN_SETTINGS',
      },
      label: 'TITLE',
      permissions: {
        edit: ['update_account_admins'],
      },
      fields: {
        account_administrator_email: {
          label: 'ACCOUNT_ADMINISTRATOR',
        },
      },
      endpoints: {
        view: ['companyControllerGetCompanyAdmins'],
        edit: ['companyAdvancedSettingsControllerUpdateAccountAdmin'],
      },
    },

    MiscSettings: {
      i18n: {
        keyPrefix: 'ADVANCED.MISC_SETTINGS',
      },
      label: 'TITLE',
      permissions: {
        edit: ['edit_advanced_settings'],
      },
      fields: {},
      endpoints: {
        edit: ['companyAdvancedSettingsControllerUpdateAdvancedSettings'],
      },
    },
  },
};

export default AdvancedPage;
