import React, { useState, useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Title,
  H2,
  Flex,
  Button,
  InfoBanner,
  Banner,
  Link,
  Typography,
} from '@procore/core-react';
import { getBaseUrl, getCompanyUrl } from 'utils/utils';
import { getApi } from 'utils/api';
import {
  CompaniesApi,
  JITApi,
  type JitSessionDto,
} from '@procore/ipa-nt-api-client-ts';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import EnvironmentSwitcher from 'components/EnvironmentSwitcher';
import { DataDisplayModalComponent } from 'components/DataDisplayModal';
import { TableComponent } from 'components/Table';
import type { TableHeader } from 'components/Table/table.interface';
import JitEscalateModal, {
  type JitEscalateFormValues,
} from 'components/JitEscalateModal';
import { AnalyticEvents } from 'pages/analytic-events.service';
import { getCompanyLink, expiresCell } from 'pages/JitHome/JitSessionList';
import { can } from 'utils/permissions.helper';
import { AppContext } from 'contexts/AppContext';

type Environment = 'PROD' | 'PROD_MONTHLY';

const JIT = () => {
  const { t } = useTranslation('homepage');
  const { t: tJit } = useTranslation(['jit']);
  const { t: tCommon } = useTranslation(['common']);
  const [env, setEnv] = useState<Environment>('PROD');
  const { userConfig } = useContext(AppContext);
  const alert = useContext(IpaAlertContext);
  const [openEscalateModal, setOpenEscalateModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [jitSessions, setJitSessions] = useState<JitSessionDto[]>([]);
  const [openSessionsModal, setOpenSessionsModal] = useState<boolean>(false);
  const [deescalateAction, setDeescalateAction] = useState<boolean>(false);
  const [extendAction, setExtendAction] = useState<boolean>(false);
  const [selectedSession, setSelectedSession] = useState<JitSessionDto>(null);

  const isAdmin = can({ user: userConfig }, ['view_admin']);

  const companyCell = (session: JitSessionDto) => {
    return (
      <div>
        {getCompanyLink(session, env)}

        {session.accessLevel === 'impersonate' && (
          <span>
            {' '}
            (as <u>{session.impersonateEmail}</u>)
          </span>
        )}
      </div>
    );
  };

  const actionButtons = (session: JitSessionDto) => {
    return (
      <Box>
        {!deescalateAction && !extendAction && (
          <Flex gap="sm">
            <Button
              onClick={() => {
                setDeescalateAction(true);
                setSelectedSession(session);
              }}
              size="sm"
              data-qa="homepage-jit-deeescalate"
            >
              {tJit('LABEL_DEESCALATE')}
            </Button>
            <Button
              onClick={() => {
                setExtendAction(true);
                setSelectedSession(session);
              }}
              size="sm"
              variant="secondary"
              data-qa="homepage-jit-extend"
            >
              {tJit('LABEL_EXTEND')}
            </Button>
          </Flex>
        )}
        {deescalateAction && (
          <Flex gap="sm">
            <Button
              size="sm"
              onClick={deescalateConfirm}
              data-qa="homepage-confirm-deescalate"
            >
              {t('CONFIRM_DEESCALATE')}
            </Button>
            <Button
              onClick={() => setDeescalateAction(false)}
              size="sm"
              variant="secondary"
              data-qa="homepage-cancel-deescalate"
            >
              {tCommon('CANCEL')}
            </Button>
          </Flex>
        )}
        {extendAction && (
          <Flex gap="sm">
            <Button
              size="sm"
              onClick={extendConfirm}
              data-qa="homepage-confirm-extend"
            >
              {t('CONFIRM_EXTEND')}
            </Button>
            <Button
              onClick={() => setExtendAction(false)}
              size="sm"
              variant="secondary"
              data-qa="homepage-cancel-extend"
            >
              {tCommon('CANCEL')}
            </Button>
          </Flex>
        )}
      </Box>
    );
  };

  const tableHeaders: TableHeader[] = [
    {
      title: tCommon('COMPANY_NAME'),
      node: 'companyName',
      display_func: companyCell,
      is_sortable: false,
      column_width: 35,
    },
    {
      title: tJit('LABEL_ACCESS_LEVEL'),
      node: 'accessLevel',
      is_sortable: false,
      is_text: true,
      column_width: 20,
    },
    {
      title: tJit('LABEL_EXPIRES'),
      node: 'expiresAt',
      display_func: (session) => expiresCell(session, tJit),
      is_sortable: false,
      column_width: 20,
    },
    {
      title: '',
      node: 'id',
      is_sortable: false,
      display_func: actionButtons,
      column_width: 25,
    },
  ];

  const changeEnvironment = (newEnv: Environment) => {
    setEnv(newEnv);
  };

  const escalate = useCallback(async (values: JitEscalateFormValues) => {
    try {
      setIsLoading(true);

      const result = await getApi(JITApi).jitControllerCreateSessionV10({
        env: env,
        companyId: Number(values.company_id),
        accessLevel: values.access_level!.id as any,
        category: values.category!.id,
        reason: values.detail,
        impersonateEmail: values.email,
        pin: values.pin,
        salesForceCaseNumber: values.salesforce_case_number,
        escalationLength: Number(values.escalation_length.id),
        projectId: Number(values.project_id),
        pdmSupport: values.pdm_support,
        escalationConfirmationCheck: values.sa_escalation_confirmation_check,
      });

      if (result.data) {
        alert.success(
          tJit('ESCALATE_SUCCESS', {
            accessLevel: result.data.accessLevel,
            companyName: result.data.companyName,
          }),
        );
        if (values.open_new_window) {
          if (values.project_id !== '') {
            window.open(
              `${getBaseUrl(env, 'procore', +values.company_id)}/${
                values.project_id
              }/project/home`,
              '_blank',
            );
          } else {
            // Use the utility function to get the company URL
            const url = await getCompanyUrl(
              +values.company_id,
              env,
              alert.error,
            );
            window.open(url, '_blank');
          }
        } else {
          loadSessionList();
        }
      }
    } catch (error: any) {
      alert.error(
        tJit('ESCALATE_FAILED', {
          companyId: values.company_id,
          reason: error.response?.data?.message || error.message,
        }),
      );
    } finally {
      setIsLoading(false);
      setOpenEscalateModal(false);
      await AnalyticEvents.postProcoreEvent({
        key: 'ipa.ipa_jit.escalate.escalated',
        eventProps: {
          user_company_id: values.company_id,
          env: env,
        },
        env: env,
      });
    }
  }, []);

  const deescalateConfirm = useCallback(async () => {
    if (!selectedSession) {
      return;
    }

    try {
      await getApi(JITApi).jitControllerTerminateSessionV10(selectedSession.id);
      alert.success(
        tJit('DEESCALATE_SUCCESS', {
          accessLevel: selectedSession.accessLevel,
          companyName: selectedSession.companyName,
        }),
      );
      loadSessionList();
      setDeescalateAction(false);
    } catch (error: any) {
      alert.error(
        tJit('DEESCALATE_ERROR', {
          ns: 'jit',
          reason: error.response?.data?.message || error.message,
        }),
      );
    }
  }, [selectedSession]);

  const extendConfirm = useCallback(async () => {
    if (!selectedSession) {
      return;
    }

    try {
      await getApi(JITApi).jitControllerExtendSessionV10(selectedSession.id);
      alert.success(
        tJit('EXTEND_SUCCESS', {
          accessLevel: selectedSession.accessLevel,
          companyName: selectedSession.companyName,
        }),
      );
      loadSessionList();
      setExtendAction(false);
    } catch (error: any) {
      alert.error(
        tJit('EXTEND_ERROR', {
          ns: 'jit',
          reason: error.response?.data?.message || error.message,
        }),
      );
    }
  }, [selectedSession]);

  const loadSessionList = useCallback(async () => {
    try {
      const result = await getApi(JITApi).jitControllerGetActiveSessionsForUserV10(
        env,
        {
          headers: {
            'Cache-Control': 'no-cache',
          },
        },
      );
      if (result.data) {
        setJitSessions(result.data);
      }
    } catch (error: any) {
      alert.error(
        tJit('LOAD_SESSIONS_ERROR', {
          ns: 'jit',
          env: env,
          reason: error.response?.data?.message || error.message,
        }),
      );
    }
  }, [env]);

  useEffect(() => {
    loadSessionList();
  }, [loadSessionList]);

  return (
    <Box data-qa="homepage-jit">
      <Title>
        <Title.Text>
          <H2>{t('MODULES.JIT')}</H2>
        </Title.Text>
      </Title>
      <Box marginTop="lg">
        <Flex gap="lg">
          <EnvironmentSwitcher
            initialValue={env}
            onChange={changeEnvironment}
          />
          <Button
            loading={isLoading}
            onClick={() => setOpenEscalateModal(!openEscalateModal)}
            data-qa="homepage-jit-escalate"
          >
            {tJit('ESCALATE')}
          </Button>
        </Flex>
        {jitSessions.length > 0 && (
          <Box marginTop="lg" data-qa="homepage-jit-sessions">
            <InfoBanner>
              <Banner.Content>
                <Banner.Body>
                  {t('JIT_ESCALATIONS', { num: jitSessions.length, env: env })}
                </Banner.Body>
              </Banner.Content>
              <Banner.Action>
                <Button
                  onClick={() => setOpenSessionsModal(!openSessionsModal)}
                  data-qa="homepage-jit-manage-sessions"
                >
                  {t('MANAGE')}
                </Button>
              </Banner.Action>
            </InfoBanner>
          </Box>
        )}
        {isAdmin && (
          <Box marginTop="lg">
            <Typography>
              <Link href="/v2/jit-audit-log">{t('JIT_AUDIT_LOG')}</Link>
            </Typography>
          </Box>
        )}
      </Box>
      <JitEscalateModal
        env={env}
        isOpen={openEscalateModal}
        closeModal={() => setOpenEscalateModal(false)}
        re_escalate={false}
        customer_success={false}
        confirmModal={escalate}
        canSelectEscalationLength={true}
        selected_company={null}
        selected_access_level={undefined}
        selected_reason={''}
        escalation_length={null}
        pdmSupport={false}
        // Showing the strategic account checkbox if redirectUrl is provided (eg Polaris Enhanced Reporting)
        isStrategicAccount={false}
      />
      <DataDisplayModalComponent
        show={openSessionsModal}
        modalTitle={t('JIT_SESSIONS_MODAL_TITLE', { env: env })}
        closeModal={() => setOpenSessionsModal(false)}
        modalWidth="lg"
        id="homepage-jit-sessions-modal"
        modalContent={
          <TableComponent
            headers={tableHeaders}
            data={jitSessions}
            default_sort_column="company_name"
            default_sort_direction="asc"
            hide_pagination={true}
            fixed_table={true}
            show_no_data_message={true}
            no_data_message={t('NO_JIT_SESSIONS')}
            data_qa="jit-sessions-table"
          />
        }
      />
    </Box>
  );
};

export default JIT;
