import styled from 'styled-components';
import { Card, colors } from '@procore/core-react';

const gridPadding = '5px';

export const ApiRequestCard = styled(Card)`
  margin-bottom: 2px;
  padding: 3px;

  .heading-open {
    font-weight: bold;
    bottom-border: 1px solid ${colors.gray20};
  }

  .request-detail {
    padding: 15px;
  }

  .request-detail h4 {
    border-bottom: 1px solid ${colors.gray20};
  }

  .request-detail ul {
    list-style-type: none;
    padding: 0 0 0 20px;
  }
`;
