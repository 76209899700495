import React from 'react';
import { Button, Link, Tooltip } from '@procore/core-react';
import { useTranslation } from 'react-i18next';
import { JitSessionList } from './JitSessionList';
import { ExternalLink, Help } from '@procore/core-icons';
import { getBaseUrl } from 'utils/utils';

interface JitEnvironmentProps {
  env: string;
  openModal: (env: string) => void;
  latestEscalation: Date;
}

export const JitEnvironment = (props: JitEnvironmentProps) => {
  const { t } = useTranslation(['jit']);

  const envDescriptionKey = `ENV_DESCRIPTION_${props.env}`;
  const envDescription =
    t(envDescriptionKey) !== envDescriptionKey
      ? t(envDescriptionKey)
      : undefined;

  return (
    <div>
      <h4>
        <Link
          href={`${getBaseUrl(props.env, 'login')}/staff`}
          target={'_blank'}
        >
          {props.env} <ExternalLink size="sm" />
        </Link>
        &nbsp;&nbsp;
        {envDescription && (
          <Tooltip overlay={envDescription} trigger="hover">
            <Help size="sm" />
          </Tooltip>
        )}
        &nbsp;&nbsp;
        <Button
          size="sm"
          onClick={() => props.openModal(props.env)}
          data-qa={`${props.env}-escalate-btn`}
        >
          {t('ESCALATE')}
        </Button>
      </h4>
      <JitSessionList
        env={props.env}
        latestEscalation={props.latestEscalation}
      />
    </div>
  );
};
