import { Button, Title, H2, Page } from '@procore/core-react';
import ConfirmModal from 'components/ConfirmModal';
import React, { useState, useContext } from 'react';
import { ipaAppStyles } from 'styles';
import { ProjectInfoContext } from '..';
import { ProjectsApi } from '@procore/ipa-nt-api-client-ts';
import { getApi } from 'utils/api';
import type { ProjectAdvancedSettingsProps } from '../interfaces';
import { ProjectContext } from 'contexts/ProjectContext';
import { IpaAlertContext } from 'contexts/IpaAlertContext';

export const ProjectSpecificationSettings = (
  props: ProjectAdvancedSettingsProps,
) => {
  const alert = useContext(IpaAlertContext);
  const context = useContext(ProjectContext);
  const config = props.cardConfig;
  const t = config.translate;

  const projectName =
    useContext(ProjectInfoContext).dataSources.projectSettings.data?.name;
  const [showConfirm, setShowConfirm] = useState(false);

  const confirmIndexProjectSpecification = async () => {
    try {
      const api = getApi(ProjectsApi);
      const res = await api.projectControllerIndexProjectSpecificationV10(
        context.env,
        context.companyId,
        context.projectId,
      );
      if (res) {
        alert.success(t('SUCCESS'));
      }
    } catch (e: any) {
      alert.error(e);
    } finally {
      setShowConfirm(false);
    }
  };

  return (
    <React.Fragment>
      {context.hasPermission(config.permissions.edit) && (
        <React.Fragment>
          <Title style={ipaAppStyles.titleMargin}>
            <Title.Text>
              <H2>{config.label}</H2>
            </Title.Text>
          </Title>
          <Page.Row>
            <Button onClick={() => setShowConfirm(true)}>
              {config.fields.index_project_specification.label}
            </Button>
          </Page.Row>
          <ConfirmModal
            isOpen={showConfirm}
            closeModal={() => setShowConfirm(false)}
            modalTitle={config.fields.index_project_specification.label}
            modalCopy={t('MODAL', { projectName: projectName })}
            confirmModal={() => confirmIndexProjectSpecification()}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ProjectSpecificationSettings;
