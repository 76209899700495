import { CompaniesApi } from '@procore/ipa-nt-api-client-ts';
import { getApi } from 'utils/api';
import type { ZONE_OPTION } from 'common/constants';

export type ServiceType = 'procore' | 'login';

export const capitalizeFirstLetter = (str: string) => {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
};

export const getPublicConfig = (): any => {
  if (window.hasOwnProperty('config')) {
    return (window as any).config as any;
  }
};

export const getBaseUrl = (
  env: string = 'PROD',
  service: ServiceType,
  company_id?: number,
): string => {
  const config = getPublicConfig();
  if (!config) {
    throw new Error('Cannot generate base URI: public config missing.');
  }

  const environment = config.environments[env];
  if (!environment) {
    throw new Error(
      `Cannot generate base URL: environment ${env} not found in config.`,
    );
  }

  if (service === 'login') {
    return environment.login_api;
  } else if (service === 'procore') {
    const zoneId = company_id ? getCompanyZone(company_id) : 1;
    const zone = getZoneConfig(zoneId, env);

    if (zone) {
      return zone.url;
    } else {
      throw new Error(
        `Cannot generate base URI: zone ${zone} not found in env_url config.`,
      );
    }
  } else {
    throw new Error('Unknown service type');
  }
};

export const getCompanyZone = (company_id: number): number => {
  const id = parseInt(
    company_id.toString(16).padStart(16, '0').slice(0, 4),
    16,
  );
  if (id > 3) {
    throw new Error(`Invalid company ID ${company_id} for zone lookup.`);
  }
  return id === 0 ? 1 : id;
};

export const getEnvironmentConfig = (env: string) => {
  const config = getPublicConfig();
  if ('environments' in config && env in config.environments) {
    return config.environments[env];
  } else {
    throw new Error(`Cannot get zone config: environment ${env} not found.`);
  }
};

export const getZoneConfig = (zoneId: number, env: string = 'PROD') => {
  const config = getEnvironmentConfig(env);
  const zoneConfig = config.procore_zones.find(
    (z: any) => z.zone_id === zoneId,
  );
  if (!zoneConfig) {
    throw new Error(
      `Cannot get zone config: zone ${zoneId} not found in environment ${env}.`,
    );
  }
  return zoneConfig;
};

export const getZoneOptions = (env: string = 'PROD'): ZONE_OPTION[] => {
  return getEnvironmentConfig(env).procore_zones.map((zone: any) => ({
    id: zone.name,
    label: zone.name,
  }));
};

export const getUserSetting = (key: string, defaultValue: unknown) => {
  const userSettingsJson = localStorage.getItem('user-settings');
  if (!userSettingsJson) {
    return defaultValue;
  }

  let userSettings;
  try {
    userSettings = JSON.parse(userSettingsJson);
    if (!userSettings.settings) {
      return defaultValue;
    }
  } catch (error: any) {
    console.log('Error parsing user settings', error.stack);
    return defaultValue;
  }

  return userSettings.settings[key] ?? defaultValue;
};

/**
 * Extract specific keys from object
 * @param obj
 * @param keys
 * @returns
 */
export const pickObject = <T>(obj: T, keys: Array<keyof T>): Partial<T> => {
  return keys.reduce((acc, key) => ({ ...acc, [key]: obj[key] }), {});
};

/**
 * Split array into chunks
 * @param arr Input array
 * @param n Chunk size
 * @returns Generator
 */
export const chunks = function* <T>(arr: Array<T>, n: number) {
  for (let i = 0; i < arr.length; i += n) {
    yield arr.slice(i, i + n);
  }
};

export const getSettingValue = (v: any, isNumber = false) => {
  if (v === null) {
    return '';
  }
  if (isNumber && !v) {
    return 'N/A';
  }
  if (isNumber || typeof v === 'string') {
    return v;
  }
  if (typeof v === 'undefined') {
    return v;
  }
  if (typeof v === 'object' && v.label !== undefined) {
    return v.label ?? '';
  }
  if (typeof v === 'object' && v.name !== undefined) {
    return v.name ?? '';
  }
  if (Array.isArray(v)) {
    return v
      .map(function (item) {
        return item['label'];
      })
      .toString();
  }
  return v.toString().toUpperCase();
};

export const getChangedKeys = (obj1: any, obj2: any) => {
  let keys = Object.keys(obj1);
  let keys2 = Object.keys(obj2);
  keys = keys.concat(keys2).filter((v, i, a) => a.indexOf(v) === i);

  const changedKeys = [];
  for (const key of keys) {
    if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
      changedKeys.push(key);
    }
  }
  return changedKeys;
};

export const longWordFilter = (str: string, wordBreak: number = 20) => {
  if (str !== null) {
    return str
      .split(' ')
      .map((s) =>
        s.length >= wordBreak ? `${s.substring(0, wordBreak - 4)}....` : s,
      )
      .join(' ');
  } else {
    return '';
  }
};

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

/**
 * Get the URL for a company using the companyControllerGetCompanyProcoreAppUrl endpoint
 * with a fallback to the company/home/list URL
 * @param companyId The company ID
 * @param env The environment
 * @param alertErrorFn Optional function to call to display errors
 * @returns The company URL
 */
export const getCompanyUrl = async (
  companyId: number,
  env: string,
  alertErrorFn?: (message: string) => void,
): Promise<string> => {
  const api = getApi(CompaniesApi);
  const fallbackUrl = `${getBaseUrl(
    env,
    'procore',
    companyId,
  )}/${companyId}/company/home/list`;

  try {
    const res = await api.companyControllerGetCompanyProcoreAppUrlV10(
      companyId,
      env,
    );

    if (!res.data?.procore_app_url) {
      if (alertErrorFn) {
        alertErrorFn('Could not get Procore App URL for company');
      }
      return fallbackUrl;
    }

    return res.data.procore_app_url;
  } catch (error: any) {
    if (alertErrorFn) {
      alertErrorFn(error.response?.data?.message || error.message);
    }
    return fallbackUrl;
  }
};
