import React, { useContext, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import axios from 'axios';
import { AppContext } from 'contexts/AppContext';

export const AxiosConfigurator: React.FC = () => {
  const appContext = useContext(AppContext);
  const { oktaAuth, authState } = useOktaAuth();

  useEffect(() => {
    setTokenFromJwt();
  }, [oktaAuth, !!authState, authState?.isAuthenticated]);

  const setTokenFromJwt = () => {
    const token = oktaAuth.getOrRenewAccessToken();
    token.then((accessToken) => {
      if (accessToken !== null) {
        setTokenHeader(accessToken);
        oktaAuth.tokenManager.on('renewed', function (key, newToken) {
          if (key === 'accessToken' && 'accessToken' in newToken) {
            setTokenHeader(newToken.accessToken);
          }
        });
      }
    });
  };

  const setTokenHeader = (token: string) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    appContext.updateAuthHeaderPresent(true);
  };

  return <React.Fragment />;
};
