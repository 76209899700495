import React, { useContext, useEffect, useState } from 'react';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import type { IEditableCard } from 'components/EditableCard';
import { getApi } from 'utils/api';
import { AppContext } from 'contexts/AppContext';
import { useTranslation } from 'react-i18next';
import { ContactContext } from 'contexts/ContactContext';

import { Box, Card, Flex, Spinner } from '@procore/core-react';
import { AxiosError } from 'axios';
import { CompanyContactsApi } from '@procore/ipa-nt-api-client-ts';
import { can } from 'utils/permissions.helper';
import i18n from 'i18next';
import { PageConfig } from 'pages/page-config.service';
import ContactSettingsCard from './ContactSettings';
import OutboundEmailSettingsCard from './OutboundEmailSettings';
import OverdueDigestSettingsCard from './OverdueDigestCard';
import EsticomSettingsCard from './EsticomSettings';
import AdvancedSecuritySettingsCard from './AdvancedSecuritySettings';
import CorrespondenceSettingsCard from './CorrespondenceSettings';
import WorkflowAdminOverrideSettingsCard from './WorkflowAdminOverrideSettings';
import NewProjectSettingsCard from './NewProjectSettings';

const pageConfig = PageConfig.get(i18n.t, 'Contact.Settings');

const SettingsTab = () => {
  const { userConfig } = useContext(AppContext);
  const alert = useContext(IpaAlertContext);
  const context = useContext(ContactContext);
  const { t } = useTranslation(['contact'], {
    keyPrefix: 'CONTACT_INFO_PAGE',
  });
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(true);
  const [cardLoading, setCardLoading] = useState<{ [key: string]: boolean }>(
    {},
  );

  const {
    dataSources: { contact },
  } = useContext(ContactContext);

  const canEdit = can({ user: userConfig }, pageConfig.permissions.edit);

  const saveContactSettings = async (
    card: IEditableCard,
    values: any,
    reason?: string,
    changedKeys?: string[],
  ) => {
    alert.closeAlert();
    setCardLoading({
      ...cardLoading,
      [card.id]: true,
    });

    try {
      await getApi(
        CompanyContactsApi,
      ).companyContactsControllerUpdateCompanyUserContactV10(
        context.env,
        context.companyId,
        contact.data?.id ?? -1,
        {
          update: {
            send_user_messages: values.contact.send_user_messages?.id,
            is_employee: values.contact.is_employee,
            is_insurance_manager: values.contact.is_insurance_manager,
            country_language: values.contact.country_language?.id,
            persona: values.contact.persona?.id,
            can_run_extracts: values.contact.can_run_extracts,
          },
          reason: reason ?? '',
        },
      );
      // TODO: set response data to contact context
      context.loadContactInfo();
      alert.success(t('CARD_UPDATED', { CARD: card.label }));
    } catch (e: any) {
      alert.error(e);
    } finally {
      setCardLoading({
        ...cardLoading,
        [card.id]: false,
      });
    }
  };

  const cards = [
    {
      id: 'ContactSettingsCard',
      component: ContactSettingsCard,
      canEdit: canEdit,
      save: saveContactSettings,
    },
    {
      id: 'OutboundEmailSettingsCard',
      component: OutboundEmailSettingsCard,
    },
    {
      id: 'OverdueDigestSettingsCard',
      component: OverdueDigestSettingsCard,
    },
    {
      id: 'EsticomSettingsCard',
      component: EsticomSettingsCard,
    },
    {
      id: 'AdvancedSecuritySettingsCard',
      component: AdvancedSecuritySettingsCard,
    },
    {
      id: 'CorrespondenceSettingsCard',
      component: CorrespondenceSettingsCard,
    },
    {
      id: 'WorkflowAdminOverrideSettingsCard',
      component: WorkflowAdminOverrideSettingsCard,
    },
    {
      id: 'NewProjectSettingsCard',
      component: NewProjectSettingsCard,
    },
  ];

  const initPage = async () => {
    const errors: AxiosError[] = [];
    setIsInit(false);
    setLoading(true);

    const promises = await Promise.all([]);
    promises.forEach((promise: any) => {
      if (promise instanceof AxiosError) {
        errors.push(promise);
      }
    });
    if (errors.length > 0) {
      alert.error(errors);
    }
    setLoading(false);
    setIsInit(true);
  };

  useEffect(() => {
    initPage();
  }, [context.env, context.companyId, context.contactId, context.loginId]);

  return (
    <React.Fragment>
      {cards.map((card, idx) => (
        <Box
          marginBottom="lg"
          key={idx}
          data-qa={`card-${card.component.name}`}
        >
          {(isLoading || cardLoading[card.component.name]) && (
            <Flex justifyContent="center">
              <Spinner loading={isLoading} />
            </Flex>
          )}

          {isInit && !isLoading && !cardLoading[card.component.name] && (
            <Card>
              <Box padding="lg">
                {React.createElement(card.component, {
                  key: `card-${idx}`,
                  id: card.id,
                  save: card.save,
                })}
              </Box>
            </Card>
          )}
        </Box>
      ))}
      {(!isInit || isLoading || !contact) && <Spinner />}
    </React.Fragment>
  );
};

export default SettingsTab;
