import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Flex,
  Spinner,
  Box,
  EmptyState,
  Checkbox,
  H3,
  spacing,
} from '@procore/core-react';
import { getApi } from 'utils/api';
import { ProjectsApi } from '@procore/ipa-nt-api-client-ts';
import type { ProjectSubmittalWorkflowTemplatesDto } from '@procore/ipa-nt-api-client-ts';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { ProjectContext } from 'contexts/ProjectContext';
import TableComponent from 'components/Table';
import type { TableHeader } from 'components/Table/table.interface';

const checkboxStyle = {
  display: 'inline-block',
  verticalAlign: 'middle',
  marginRight: `${spacing.lg}px`,
};

interface TemplateApprover {
  step: number;
  approvers: any[];
  approver_type: string;
  days_to_respond: number;
}

interface WorkflowTemplate extends ProjectSubmittalWorkflowTemplatesDto {
  approvers: TemplateApprover[];
}

const SubmittalWorkflowTemplatesPage = () => {
  const alert = useContext(IpaAlertContext);
  const context = useContext(ProjectContext);
  const config = context.pageConfig.card('SubmittalWorkflowTemplates');

  const t = config.translate;
  const { t: tCommon } = useTranslation(['common']);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(true);
  const [workflowTemplates, setWorkflowTemplates] = useState<
    WorkflowTemplate[]
  >([]);

  const displayApprovers = (item: any, node: string) => {
    if (item[node] && item[node].length) {
      return (
        <div>
          {item[node].map((approver: any, i: number) => (
            <div key={i}>
              <Checkbox
                disabled={true}
                checked={approver.response_required}
                style={checkboxStyle}
              />
              {approver.approver_name}
            </div>
          ))}
        </div>
      );
    } else {
      return <span />;
    }
  };

  const tableHeaders: TableHeader[] = [
    {
      title: config.fields.step.label,
      node: 'step',
      is_text: true,
      is_sortable: false,
      column_width: 10,
    },
    {
      title: config.fields.approvers.label,
      node: 'approvers',
      display_func: displayApprovers,
      column_width: 50,
    },
    {
      title: config.fields.approver_type.label,
      node: 'approver_type',
      is_text: true,
      is_sortable: false,
      column_width: 20,
    },
    {
      title: config.fields.days_to_respond.label,
      node: 'days_to_respond',
      is_text: true,
      is_sortable: false,
      column_width: 20,
    },
  ];

  const getProjectSubmittalWorkflowTemplates = useCallback(async () => {
    setIsInit(false);
    setIsLoading(true);
    try {
      const api = getApi(ProjectsApi);
      const result =
        await api.projectControllerGetProjectSubmittalWorkflowTemplatesV10(
          context.env,
          context.companyId,
          context.projectId,
        );
      setWorkflowTemplates(
        result.data.map((t) => {
          const approvers = t.template_approvers.map((ta: any, i) => {
            return {
              step: i + 1,
              approvers: ta,
              approver_type: ta[0].approver_type,
              days_to_respond: ta[0].days_to_respond,
            };
          });
          return { ...t, approvers: approvers };
        }),
      );
    } catch (e: any) {
      alert.error(e);
    } finally {
      setIsLoading(false);
      setIsInit(true);
    }
  }, [context.projectId, context.companyId, context.env]);

  useEffect(() => {
    getProjectSubmittalWorkflowTemplates();
  }, [getProjectSubmittalWorkflowTemplates]);

  return (
    <React.Fragment>
      <Card>
        <Box padding="lg">
          {(!isInit || isLoading) && (
            <Box marginBottom="lg">
              <Card>
                <Box padding="lg">
                  {!isInit && !isLoading && (
                    <EmptyState>
                      <EmptyState.NoItems />
                      <EmptyState.Title>{tCommon('NO_DATA')}</EmptyState.Title>
                    </EmptyState>
                  )}
                  {isLoading && (
                    <Flex justifyContent="center">
                      <Spinner loading={isLoading} />
                    </Flex>
                  )}
                </Box>
              </Card>
            </Box>
          )}
          {!isLoading && workflowTemplates && workflowTemplates.length > 0
            ? workflowTemplates.map((template, i) => {
                return (
                  <Box
                    key={i}
                    data-qa="submittal-workflow-templates"
                    marginBottom="lg"
                  >
                    <H3>
                      {t('SUBMITTAL_WORKFLOW_TEMPLATES_PAGE.TEMPLATE_NAME', {
                        templateName: template.name,
                      })}
                    </H3>
                    <TableComponent
                      data={template.approvers}
                      headers={tableHeaders}
                      default_sort_column="step"
                      default_sort_direction="asc"
                      fixed_table={true}
                    />
                  </Box>
                );
              })
            : !isLoading && (
                <Box margin="lg">
                  <H3>
                    {t('SUBMITTAL_WORKFLOW_TEMPLATES_PAGE.NO_TEMPLATES_FOUND')}
                  </H3>
                </Box>
              )}
        </Box>
      </Card>
    </React.Fragment>
  );
};

export default SubmittalWorkflowTemplatesPage;
