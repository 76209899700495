import React from 'react';
import DefaultStagesCard from './DefaultStages';
import ProjectsCard from './Projects';

const ProjectListPage = () => {
  return (
    <React.Fragment>
      <ProjectsCard />
      <br />
      <DefaultStagesCard />
    </React.Fragment>
  );
};
export default ProjectListPage;
