import React, { useEffect, useState, useContext, useCallback } from 'react';
import {
  Box,
  Flex,
  Page,
  Card,
  Spinner,
  Tabs,
  ToolHeader,
} from '@procore/core-react';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'contexts/AppContext';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CompanyInfoApi,
  ConfigurableFieldSetsApi,
} from '@procore/ipa-nt-api-client-ts';
import type {
  CompanyAccountStatusDto,
  ConfigurableFieldSetApiDto,
} from '@procore/ipa-nt-api-client-ts';
import { getApi, getErrorMessage } from 'utils/api';
import { FieldSet } from './FieldSet';
import CompanyHeader from 'components/CompanyHeader';
import { CompanyContext } from 'contexts/CompanyContext';
import CompanyBreadcrumbs from 'pages/Company/CompanyBreadcrumbs';

export type FieldSetsTabType = {
  id: string;
  name: string;
  class_name: string[];
  tag: string;
  fieldsets: ConfigurableFieldSetApiDto[][];
};

export const ConfigurableFieldSetsPage = () => {
  const { t } = useTranslation('configurable-field-sets');
  const { env } = useContext(AppContext);
  const context = useContext(CompanyContext);

  const navigate = useNavigate();

  const alert = useContext(IpaAlertContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(true);
  const [companyStatus, setCompanyStatus] =
    useState<CompanyAccountStatusDto>(undefined);
  const [firstValidIndex, setFirstValidIndex] = useState(0);

  const { company_id: _companyId } = useParams();
  const companyId = Number(_companyId);

  const [tabs, setTabs] = useState<FieldSetsTabType[]>([
    {
      id: 'project',
      name: 'TAB_PROJECT_FIELD_SETS',
      class_name: ['Project'],
      tag: 'Fieldsets-tab',
      fieldsets: [],
    },
    {
      id: 'contracts',
      name: 'TAB_CONTRACTS',
      class_name: [
        'PrimeContract',
        'PurchaseOrderContract',
        'WorkOrderContract',
      ],
      tag: 'Contracts-tab',
      fieldsets: [],
    },
    {
      id: 'coordination-issues',
      name: 'TAB_COORDINATION_ISSUES',
      class_name: ['CoordinationIssue'],
      tag: 'Coordination-Issues-tab',
      fieldsets: [],
    },
    {
      id: 'directory',
      name: 'TAB_DIRECTORY',
      class_name: ['Contact', 'Vendor'],
      tag: 'Directory-tab',
      fieldsets: [],
    },
    {
      id: 'dailylog',
      name: 'TAB_DAILY_LOG',
      class_name: [
        'ManpowerLog',
        'NotesLog',
        'DailyConstructionReportLog',
        'WeatherLog',
        'VisitorLog',
      ],
      tag: 'Daily-Log-tab',
      fieldsets: [],
    },
    {
      id: 'project-documents',
      name: 'TAB_PROJECT_DOCUMENTS',
      class_name: ['Folder'],
      tag: 'Project-Documents-tab',
      fieldsets: [],
    },
    {
      id: 'drawings',
      name: 'TAB_DRAWINGS',
      class_name: ['DrawingRevision'],
      tag: 'Drawings-tab',
      fieldsets: [],
    },
    {
      id: 'incidents',
      name: 'TAB_INCIDENTS',
      class_name: [
        'Incident',
        'Incident::Detail::Injury',
        'Incident::Detail::PropertyDamage',
        'Incident::Detail::Environmental',
        'Incident::Detail::NearMiss',
        'Incident::WitnessStatement',
        'Incident::Action',
      ],
      tag: 'Incidents-tab',
      fieldsets: [],
    },
    {
      id: 'inspections',
      name: 'TAB_INSPECTIONS',
      class_name: ['Checklist::List'],
      tag: 'Inspections-tab',
      fieldsets: [],
    },
    {
      id: 'mytime',
      name: 'TAB_MY_TIME',
      class_name: ['MyTimeTimecardEntry'],
      tag: 'My-Time-tab',
      fieldsets: [],
    },
    {
      id: 'observations',
      name: 'TAB_OBSERVATIONS',
      class_name: ['Observations::Item'],
      tag: 'Observations-tab',
      fieldsets: [],
    },
    {
      id: 'punch-list',
      name: 'TAB_PUNCH_LIST',
      class_name: ['PunchItem'],
      tag: 'Punch-List-tab',
      fieldsets: [],
    },
    {
      id: 'rfis',
      name: 'TAB_RFIS',
      class_name: ['Rfi::Header'],
      tag: 'RFIs-tab',
      fieldsets: [],
    },
    {
      id: 'specifications',
      name: 'TAB_SPECIFICATIONS',
      class_name: ['SpecificationSectionRevision'],
      tag: 'Specifications-tab',
      fieldsets: [],
    },
    {
      id: 'submittal',
      name: 'TAB_SUBMITTALS',
      class_name: ['SubmittalLog'],
      tag: 'Submittals-tab',
      fieldsets: [],
    },
    {
      id: 'timesheets',
      name: 'TAB_TIMESHEETS',
      class_name: ['ProjectTimesheetTimecardEntry'],
      tag: 'Timesheets-tab',
      fieldsets: [],
    },
  ]);
  const [activeTab, setActiveTab] = useState<FieldSetsTabType>(undefined);

  const changeEnvironment = (newEnv: string) => {
    navigate(
      `${context.pageConfig.route.path.replace(
        ':company_id',
        companyId.toString(),
      )}?env=${newEnv}`,
    );
  };

  const isTabActive = (tabId: string) => {
    return tabId === activeTab.id;
  };

  const changeTab = (tab: FieldSetsTabType) => {
    if (tab.id !== activeTab.id) {
      setActiveTab(tab);
      window.location.hash = '#' + tab.id;
    }
  };

  const loadCompanyStatus = useCallback(async () => {
    try {
      const api = getApi(CompanyInfoApi);
      const res = await api.companyCompanyInfoControllerGetCompanyAccountStatusV10(
        env,
        companyId,
      );
      if (res.data) {
        setCompanyStatus(res.data);
      }
    } catch (e: any) {
      return e;
    }
  }, [companyId, env]);

  const loadConfigurableFieldSets = useCallback(async () => {
    const api = getApi(ConfigurableFieldSetsApi);
    const result =
      await api.companyConfigurableFieldSetsControllerGetConfigurableFieldSetsV10(
        companyId,
        env,
      );

    if (result.data) {
      const newTabs = [...tabs];
      newTabs.forEach((tab) => {
        tab.fieldsets = [];
        result.data.forEach((fieldset) => {
          if (tab.class_name.includes(fieldset.class_name)) {
            tab.fieldsets.push(fieldset.fieldsets);
          }
        });
      });
      setFirstValidIndex(newTabs.findIndex((t) => t.fieldsets.length > 0));
      setTabs(newTabs);
    }
  }, [companyId, env, tabs]);

  const initPage = useCallback(async () => {
    setIsInit(false);
    setIsLoading(true);
    const results = await Promise.allSettled([
      loadCompanyStatus(),
      loadConfigurableFieldSets(),
    ]);

    const errors = results.flatMap((result, index: number): string | string[] =>
      result.status === 'rejected'
        ? getErrorMessage(result.reason) ||
          context.pageConfig.translate('THERE_WAS_ERROR', { ns: 'common' })
        : [],
    );

    if (errors.length > 0) {
      alert.error(errors.join('\n'));
    }

    setIsInit(true);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (window.location.hash !== '') {
      const index = tabs.findIndex(
        (t) => t.id === window.location.hash.substring(1),
      );
      if (index !== -1) {
        setActiveTab(tabs[index]);
      } else {
        setActiveTab(tabs[firstValidIndex]);
      }
    } else {
      setActiveTab(tabs[firstValidIndex]);
    }
  }, [tabs, firstValidIndex]);

  useEffect(() => {
    initPage();
  }, [companyId, env]);

  return (
    <Page>
      <Page.Main>
        <Page.Header>
          <CompanyBreadcrumbs pageLabel={t('TITLE')} />
          <ToolHeader>
            <ToolHeader.Title>{t('TITLE')} </ToolHeader.Title>
          </ToolHeader>
        </Page.Header>
        <Page.Body>
          <CompanyHeader
            env={context.env}
            companyStatus={companyStatus}
            onChangeEnv={changeEnvironment}
          />
          <Box marginBottom="lg">
            {isLoading && (
              <Flex justifyContent="center">
                <Spinner loading={isLoading} />
              </Flex>
            )}
            {!isLoading && firstValidIndex === -1 && (
              <Flex justifyContent="center">{t('NO_FIELDSETS')}</Flex>
            )}
            {!isLoading && isInit && firstValidIndex >= 0 && (
              <Card>
                <Box padding="lg">
                  <Page.Tabs>
                    <Tabs>
                      {tabs.map((tab, i) => {
                        if (tab.fieldsets.length > 0) {
                          return (
                            <Tabs.Tab
                              active={isTabActive(tab.id)}
                              onClick={() => changeTab(tab)}
                              data-qa={tab.tag}
                              key={tab.id}
                            >
                              <Tabs.Link>{t(tab.name)}</Tabs.Link>
                            </Tabs.Tab>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </Tabs>
                  </Page.Tabs>
                  <FieldSet data={activeTab} pageConfig={context.pageConfig} />
                </Box>
              </Card>
            )}
          </Box>
        </Page.Body>
      </Page.Main>
    </Page>
  );
};

export default ConfigurableFieldSetsPage;
