import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Card, Spinner, Box, FlexList, Button, H2 } from '@procore/core-react';
import { getApi } from 'utils/api';
import { CompanyCostCodesApi } from '@procore/ipa-nt-api-client-ts';
import type {
  CompanyCostCodesDto,
  CompanyRootCostCodesDto,
  CompanyCostCodesResponseDto,
} from '@procore/ipa-nt-api-client-ts';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { useTranslation } from 'react-i18next';
import { ContactContext } from 'contexts/ContactContext';
import { PageConfig } from 'pages/page-config.service';
import type { CardConfigTranslated } from 'pages/page-config.interfaces';
import TreeList from 'components/TreeList';
import ImportCompanyCostCodesModal from './ImportCompanyCostCodesModal';
import type { ImportForm } from './ImportCompanyCostCodesModal';
let csvToJson = require('csvjson-csv2json');

interface RootCostCode extends CompanyRootCostCodesDto {
  isLoaded: boolean;
  isOpen: boolean;
  codes: CompanyCostCodesResponseDto[];
}

interface CostCode {
  id: number;
  name: string;
  costCodesList?: RootCostCode[];
}

const CompanyCostCodesPage = () => {
  const { t } = useTranslation();
  const { companyId, env } = useContext(ContactContext);
  const alert = useContext(IpaAlertContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isInit, setIsInit] = useState<boolean>(false);
  const [costCodesList, setCostCodesList] = useState<CompanyCostCodesDto[]>([]);
  const [companyCostCodes, setCompanyCostCodes] = useState<CostCode[]>([]);
  const [showImportModal, setShowImportModal] = useState<boolean>(false);
  const [importIndex, setImportIndex] = useState<number>(0);
  const config = PageConfig.get(
    t,
    'Contact.CompanyCostCodes',
  ) as CardConfigTranslated;

  const getCompanyCostCodes = useCallback(async () => {
    alert.closeAlert();
    try {
      setIsLoading(true);
      const api = getApi(CompanyCostCodesApi);
      const result =
        await api.companyCostCodesControllerGetCompanyCostCodeListsV10(
          env,
          companyId,
        );
      setCostCodesList(result.data);
      const temp: CostCode[] = [];
      for (const costCode of result.data) {
        const costCodeList =
          await api.companyCostCodesControllerGetCompanyRootCostCodesV10(
            env,
            companyId,
            Number(costCode.id),
          );
        temp.push({
          id: Number(costCode.id),
          name: costCode.name,
          costCodesList: costCodeList.data
            .map((item) => ({
              ...item,
              isLoaded: false,
              isOpen: false,
              codes: [] as CompanyCostCodesResponseDto[],
            }))
            .sort((a, b) => (a.code > b.code ? 1 : -1)),
        });
        setCompanyCostCodes(temp);
      }
    } catch (e: any) {
      alert.error(e);
    } finally {
      setIsInit(true);
      setIsLoading(false);
    }
  }, [companyId]);

  const loadTreeBranch = async (
    node: any,
    onlyOne: boolean,
    index: number,
    treeIndex: number,
  ) => {
    try {
      const tempCostCodes = [...companyCostCodes];
      const temp = [...companyCostCodes[treeIndex].costCodesList];
      if (!node.isLoaded) {
        const api = getApi(CompanyCostCodesApi);
        const result = await api.companyCostCodesControllerGetCompanyCostCodeV10(
          env,
          companyId,
          node.standard_cost_code_list_id,
          node.id,
        );
        const childrenCodes = result.data.children.sort((a, b) =>
          +a.code > +b.code ? 1 : -1,
        );
        temp[index] = {
          ...temp[index],
          codes: childrenCodes,
          isLoaded: true,
          isOpen: true,
        };
      } else {
        temp[index] = { ...temp[index], isOpen: !temp[index].isOpen };
      }
      setCompanyCostCodes(
        tempCostCodes.map((item, i) => {
          if (i === treeIndex) {
            return { ...item, costCodesList: temp };
          }
          return item;
        }),
      );
    } catch (e: any) {
      alert.error(e);
    }
  };

  const importFile = async (values: ImportForm) => {
    alert.closeAlert();
    if (values.file) {
      const reader = new FileReader();
      reader.readAsText(values.file, 'UTF-8');
      reader.onload = async (e) => {
        if (e.target) {
          let json = csvToJson(e.target.result, { parseNumbers: true });
          json = json.map((o: any) => {
            return {
              code: o.code || o.Code,
              name: o.name || o.Name,
            };
          });

          try {
            const api = getApi(CompanyCostCodesApi);
            const result =
              await api.companyCostCodesControllerImportCompanyCostCodesV10(
                env,
                companyId,
                +values.standard_cost_code_list_id.id,
                { cost_codes: json },
              );
            const tempCostCodes = [...companyCostCodes];
            tempCostCodes[importIndex].costCodesList = result.data
              .map((item) => ({
                ...item,
                isLoaded: false,
                isOpen: false,
                codes: [],
              }))
              .sort((a, b) => (a.code > b.code ? 1 : -1));
            setCompanyCostCodes(tempCostCodes);
            alert.success(config.translate('IMPORT_SUCCESS'));
          } catch (e: any) {
            alert.error(e);
          }
        }
      };
      reader.onerror = (e: any) => {
        alert.error(e);
      };
    }
    setShowImportModal(false);
  };

  useEffect(() => {
    if (!isInit) {
      getCompanyCostCodes();
    }
  }, [getCompanyCostCodes, isInit]);

  return (
    <Box>
      {isLoading && (
        <Box>
          <FlexList justifyContent="center">
            <Spinner loading={isLoading} />
          </FlexList>
        </Box>
      )}
      {!isLoading && isInit && companyCostCodes.length > 0 && (
        <Box marginBottom="lg">
          {companyCostCodes.map((costCode, i) => (
            <Card key={`cost-codes-list-${costCode.id}`}>
              <Box padding="lg">
                <FlexList space="xxl">
                  <H2>{costCode.name}</H2>
                  <Button
                    data-qa="import-cost-codes"
                    size="sm"
                    onClick={() => {
                      setShowImportModal(true);
                      setImportIndex(i);
                    }}
                  >
                    {config.translate('IMPORT_CSV')}
                  </Button>
                </FlexList>
                <TreeList
                  data={costCode.costCodesList}
                  loadBranch={loadTreeBranch}
                  childrenNode="codes"
                  treeIndex={i}
                />
              </Box>
            </Card>
          ))}
        </Box>
      )}
      {!isLoading && isInit && companyCostCodes.length === 0 && (
        <Card>
          <Box padding="lg">
            <FlexList justifyContent="center">
              <Box>{config.translate('NO_COST_CODES')}</Box>
            </FlexList>
          </Box>
        </Card>
      )}
      <ImportCompanyCostCodesModal
        show={showImportModal}
        closeModal={() => setShowImportModal(false)}
        cost_codes_list={costCodesList}
        importFile={importFile}
      />
    </Box>
  );
};

export default CompanyCostCodesPage;
