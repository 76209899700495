import React, { useState, useContext } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Form,
  Button,
  Box,
  H3,
  Page,
  Flex,
  Spinner,
} from '@procore/core-react';
import { getChangedData, validateFormBeforeSubmit } from 'utils/form';
import { PaymentsBusinessEntityContext } from 'contexts/PaymentsBusinessEntityContext';
import { PaymentsBusinessEntityGeneralTabContext } from '.';
import FeePaySettingsForm from './FeePaySettingsForm';
import {
  getFeePaySettingsOptions,
  transformDataForForm,
  getDisplayValueForComparisonGrid,
} from './FeePaySettingsConstants';
import { ComparisonGridWrapper, IpaModalWrapper } from 'styles';
import type { FormikProps } from 'formik';

export interface FeePaySettingsEditModalProps {
  isOpen: boolean;
  closeModal: () => void;
  save: (values: any, reason?: string, changedKeys?: string[]) => void;
  isLoading?: boolean;
}

export const FeePaySettingsEditModal = (
  props: FeePaySettingsEditModalProps,
) => {
  const context = useContext(PaymentsBusinessEntityContext);
  const config = context.pageConfig.card('FeePaySettings');
  const t = config.translate;
  const { t: tCommon } = useTranslation(['common']);

  const [modalStep, setModalStep] = useState<number>(1);
  const [changedData, setChangedData] = useState<any>([]);

  const {
    dataSources: { generalInformation },
  } = useContext(PaymentsBusinessEntityGeneralTabContext);

  const options = getFeePaySettingsOptions(t);
  const initialFormValues = {
    ...transformDataForForm(generalInformation.data, options),
    reason: '',
  };

  const closeModal = () => {
    setModalStep(1);
    if (document.getElementById('fee-pay-settings-modal-form')) {
      const form = document.getElementById(
        'fee-pay-settings-modal-form',
      ) as HTMLFormElement;
      form.reset();
    }
    props.closeModal();
  };

  const formForward = (n: number, values: any) => {
    // Transform select option values back to raw values
    const transformedValues = {
      ...values,
      applyFeesOnAgreements: values.applyFeesOnAgreements.id === 'feeEnabled',
      payorPaysFeesFeatureEnabled:
        values.payorPaysFeesFeatureEnabled.id === 'GC_PAYS',
      payeeFeeCollectionStage: values.payeeFeeCollectionStage.id,
      requiresPayAgreementForInvoiceSubmission:
        values.requiresPayAgreementForInvoiceSubmission.id === 'ENABLED',
    };
    setChangedData(getChangedData(generalInformation.data, transformedValues));
    setModalStep(n);
  };

  const getSettingDisplayName = (name: string): string => {
    const setting = options.dataMap.find((s: any) => s.name === name);
    return setting ? setting.display_name : '';
  };

  const changeModalView = (n: number) => {
    setModalStep(n);
  };

  // Helper function to get form friendly display value for the comparison
  const getFormattedCurrentValue = (key: string) => {
    return getDisplayValueForComparisonGrid(
      key,
      generalInformation.data,
      options,
    );
  };

  // Helper function to get form friendly display value for the new values
  const getFormattedNewValue = (key: string, values: any) => {
    if (key === 'applyFeesOnAgreements') {
      return values[key]?.label || '';
    } else if (key === 'payorPaysFeesFeatureEnabled') {
      return values[key]?.label || '';
    } else if (key === 'payeeFeeCollectionStage') {
      return values[key]?.label || '';
    } else if (key === 'requiresPayAgreementForInvoiceSubmission') {
      return values[key]?.label || '';
    }
    return values[key]?.toString() || '';
  };

  if (props.isLoading) {
    return (
      <Modal
        width="md"
        open={props.isOpen}
        onClickOverlay={closeModal}
        onClose={closeModal}
      >
        <Flex justifyContent="center" padding="lg">
          <Spinner loading={true} />
        </Flex>
      </Modal>
    );
  }

  return (
    <Modal
      width="md"
      open={props.isOpen}
      onClickOverlay={closeModal}
      onClose={closeModal}
    >
      <IpaModalWrapper>
        <Modal.Header onClose={closeModal}>
          {t('EDIT_MODAL_TITLE')}
        </Modal.Header>
        <Form
          enableReinitialize={true}
          view="update"
          initialValues={initialFormValues}
          validationSchema={yup.object().shape({
            reason: yup.string().nullable().required(tCommon('REQUIRED')),
          })}
          onSubmit={(values) => {
            props.save(values, values.reason, changedData);
            closeModal();
          }}
        >
          {(formProps: FormikProps<any>) => {
            const { dirty, values, setFieldValue } = formProps;
            return (
              <Form.Form id="fee-pay-settings-modal-form">
                {modalStep === 1 && (
                  <React.Fragment>
                    <Modal.Body>
                      <FeePaySettingsForm
                        values={values}
                        disabled={false}
                        setFieldValue={setFieldValue}
                      />
                    </Modal.Body>
                    <Modal.Footer>
                      <Modal.FooterButtons>
                        <React.Fragment>
                          <Button
                            variant="tertiary"
                            onClick={closeModal}
                            data-qa="cancel-btn"
                          >
                            {tCommon('CANCEL')}
                          </Button>
                          <Button
                            variant="primary"
                            type="button"
                            data-qa="next-btn"
                            disabled={!dirty}
                            onClick={() =>
                              validateFormBeforeSubmit(formProps, () =>
                                formForward(2, values),
                              )
                            }
                          >
                            {tCommon('NEXT')}
                          </Button>
                        </React.Fragment>
                      </Modal.FooterButtons>
                    </Modal.Footer>
                  </React.Fragment>
                )}
                {modalStep === 2 && (
                  <React.Fragment>
                    <Modal.Body>
                      <Modal.Section>
                        <p>{tCommon('CHANGES_MADE')}</p>
                        <ComparisonGridWrapper>
                          <Page.Row>
                            <Page.Column className="ipa-col">
                              <Box>
                                <H3>{tCommon('CURRENT_VALUES')}</H3>
                                <Box>
                                  <ul>
                                    {changedData?.map((key: string) => (
                                      <li key={key}>{`${getSettingDisplayName(
                                        key,
                                      )}: ${getFormattedCurrentValue(
                                        key,
                                      )}`}</li>
                                    ))}
                                  </ul>
                                </Box>
                              </Box>
                            </Page.Column>
                            <Page.Column className="ipa-col">
                              <Box>
                                <H3>{tCommon('NEW_VALUES')}</H3>
                                <Box>
                                  <ul>
                                    {changedData?.map((key: string) => (
                                      <li key={key}>{`${getSettingDisplayName(
                                        key,
                                      )}: ${getFormattedNewValue(
                                        key,
                                        values,
                                      )}`}</li>
                                    ))}
                                  </ul>
                                </Box>
                              </Box>
                            </Page.Column>
                          </Page.Row>
                        </ComparisonGridWrapper>
                        <Box className="ipa-modal-desc form">
                          <p>{tCommon('PROVIDE_REASON')}</p>
                        </Box>
                        <Form.TextArea
                          name="reason"
                          label={tCommon('REASON')}
                          data-qa="data-edit-modal-reason"
                        />
                      </Modal.Section>
                    </Modal.Body>
                    <Modal.Footer>
                      <Modal.FooterButtons>
                        <Button
                          variant="tertiary"
                          onClick={() => changeModalView(1)}
                        >
                          {tCommon('BACK')}
                        </Button>
                        <Button
                          variant="primary"
                          type="submit"
                          disabled={values.reason === ''}
                          data-qa="data-change-modal-save"
                        >
                          {tCommon('SAVE')}
                        </Button>
                      </Modal.FooterButtons>
                    </Modal.Footer>
                  </React.Fragment>
                )}
              </Form.Form>
            );
          }}
        </Form>
      </IpaModalWrapper>
    </Modal>
  );
};

export default FeePaySettingsEditModal;
