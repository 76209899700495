import React, { useState, useEffect } from 'react';
import { Modal, Grid, Checkbox, Search } from '@procore/core-react';
import type {
  ProjectPermissionTemplatesDto,
  AssignablePermissionTemplatesDto,
} from '@procore/ipa-nt-api-client-ts';
import { useTranslation } from 'react-i18next';

interface AssignableTemplatesModalProps {
  show: boolean;
  template: any;
  assignableTemplates: any;
  closeModal: () => void;
}

export const AssignableTemplatesModal = (
  props: AssignableTemplatesModalProps,
) => {
  const { t } = useTranslation('contact', {
    keyPrefix: 'PROJECT_PERMISSION_TEMPLATES',
  });
  const [showModal, setShowModal] = useState(false);
  const [template, setTemplate] =
    useState<ProjectPermissionTemplatesDto | null>(null);
  const [assignableTemplates, setAssignableTemplates] = useState<
    AssignablePermissionTemplatesDto[] | null
  >(null);
  const [searchValue, setSearchValue] = useState('');
  const [filteredTemplates, setFilteredTemplates] = useState<
    AssignablePermissionTemplatesDto[]
  >([]);

  const closeModal = () => {
    setSearchValue('');
    setTemplate(null);
    setAssignableTemplates(null);
    setFilteredTemplates([]);
    props.closeModal();
  };

  const doSearch = (v: string) => {
    const searchStr = v.trim().toLowerCase();
    if (searchStr.length > 1) {
      setSearchValue(searchStr);
      setFilteredTemplates(
        assignableTemplates.filter((template) => {
          return template.name.toLowerCase().indexOf(searchStr) !== -1;
        }),
      );
    }
    if (searchStr.length === 0) {
      setFilteredTemplates(assignableTemplates);
      setSearchValue('');
    }
  };

  const clearSearch = () => {
    setFilteredTemplates(assignableTemplates);
  };

  useEffect(() => {
    setShowModal(props.show);
    if (props.template) {
      setTemplate(props.template);
    }
  }, [props]);

  useEffect(() => {
    const sortedAssignableTemplates = props.assignableTemplates
      ? props.assignableTemplates.sort(
          (
            a: AssignablePermissionTemplatesDto,
            b: AssignablePermissionTemplatesDto,
          ) => {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
          },
        )
      : null;

    setAssignableTemplates(sortedAssignableTemplates);
    setFilteredTemplates(sortedAssignableTemplates);
  }, [props.assignableTemplates]);

  return (
    <Modal
      open={showModal}
      onClickOverlay={closeModal}
      id="view-assignable-templates"
      data-qa="view-assignable-templates"
      onClose={closeModal}
    >
      {template && (
        <React.Fragment>
          <Modal.Header onClose={closeModal}>
            {t('ASSIGNABLE_TEMPLATES_MODAL_TITLE', {
              templateName: template?.name,
            })}
          </Modal.Header>
          {assignableTemplates && filteredTemplates && (
            <Modal.Body>
              <Grid gutterY="md">
                <Grid.Row>
                  <Search
                    placeholder="Filter..."
                    className="tool-filter"
                    value={searchValue}
                    onSubmit={doSearch}
                    onClear={clearSearch}
                  />
                </Grid.Row>
                <Grid.Row>
                  {filteredTemplates.map((t, i) => {
                    return (
                      <Grid.Col
                        colWidth={{ tabletSm: 6 }}
                        key={`assignable-template-${t.id}`}
                      >
                        <Checkbox
                          disabled={true}
                          checked={t.assigned}
                          onChange={() => {}}
                          data-qa="template-option"
                        >
                          {t.name}
                        </Checkbox>
                      </Grid.Col>
                    );
                  })}
                </Grid.Row>
              </Grid>
            </Modal.Body>
          )}
        </React.Fragment>
      )}
    </Modal>
  );
};

export default AssignableTemplatesModal;
