import type { PageConfig } from 'pages/page-config.interfaces';

const SettingsPage: PageConfig = {
  route: {
    page: 'settings',
  },
  i18n: {
    namespace: 'company',
  },
  label: 'TAB_SETTINGS',
  permissions: {
    edit: ['company_tab_edit'],
  },
  endpoints: {
    view: [
      'companySettingsControllerGetCompanySettings',
      'companyAdvancedSettingsControllerGetCompanyAdvancedSettings',
    ],
    edit: [
      'companySettingsControllerUpdateSettings',
      'companyAdvancedSettingsControllerUpdateAdvancedSettings',
    ],
  },
  cards: {
    OutboundEmailSettings: {
      i18n: {
        keyPrefix: 'SETTINGS.OUTBOUND_EMAIL',
      },
      label: 'TITLE',
      permissions: {
        edit: ['outbound_email_edit'],
      },
      fields: {
        email_from_name: {
          label: 'EMAIL_FROM_NAME',
        },
        from_email_address_prefix: {
          label: 'FROM_EMAIL_ADDRESS_PREFIX',
        },
      },
      endpoints: {
        edit: ['companySettingsControllerUpdateOutboundEmailSettings'],
      },
    },

    BiddingSettings: {
      i18n: {
        keyPrefix: 'SETTINGS.BIDDING',
      },
      label: 'TITLE',
      permissions: {
        edit: ['edit_bidding_settings'],
      },
      fields: {
        bid_forms_enabled: {
          label: 'BID_FORMS_ENABLED',
        },
      },
      endpoints: {
        edit: ['companyAdvancedSettingsControllerUpdateBiddingSettings'],
      },
    },

    ProjectSettings: {
      i18n: {
        keyPrefix: 'SETTINGS.PROJECT',
      },
      label: 'TITLE',
      fields: {
        enable_templates: { label: 'ENABLE_TEMPLATES' },
        exclude_bidding_stage_projects: {
          label: 'EXCLUDE_BIDDING_STAGE_PROJECTS',
        },
        procore_max_active_projects: {
          label: 'MAXIMUM_NUMBER_OF_ACTIVE_PROJECTS',
          tooltip: 'MAXIMUM_NUMBER_OF_ACTIVE_PROJECTS_TOOLTIP',
        },
        active_projects_count: {
          label: 'PROJECT_CAP',
          tooltip: 'PROJECT_CAP_TOOLTIP',
        },
        disallow_project_creation: { label: 'DISALLOW_PROJECT_CREATION' },
      },
    },

    CorrespondenceSettings: {
      i18n: {
        keyPrefix: 'SETTINGS.CORRESPONDENCE',
      },
      label: 'TITLE',
      permissions: {
        edit: ['edit_correspondence_settings'],
      },
      fields: {
        correspondence_types_limit: {
          label: 'CORRESPONDENCE_TYPES_LIMIT',
          tooltip: 'CORRESPONDENCE_TYPES_LIMIT_TOOLTIP',
        },
        allow_admin_view_private_communication_type: {
          label: 'ALLOW_ADMIN_VIEW_PRIVATE_COMMUNICATION_TYPE',
          tooltip: 'ALLOW_ADMIN_VIEW_PRIVATE_COMMUNICATION_TYPE_TOOLTIP',
        },
        allow_admin_view_private_communication: {
          label: 'ALLOW_ADMIN_VIEW_PRIVATE_COMMUNICATION',
          tooltip: 'ALLOW_ADMIN_VIEW_PRIVATE_COMMUNICATION_TOOLTIP',
        },
      },
    },

    OverdueDigestSettings: {
      i18n: {
        keyPrefix: 'SETTINGS.OVERDUE_DIGEST',
      },
      label: 'TITLE',
      fields: {
        overdue_rfi_email_hour_of_day: {
          label: 'OVERDUE_RFI_EMAIL_HOUR_OF_DAY',
        },
      },
    },

    WorkflowSettings: {
      i18n: {
        keyPrefix: 'SETTINGS.WORKFLOW',
      },
      label: 'TITLE',
      fields: {
        company_admin: { label: 'COMPANY_ADMIN' },
        project_admin: { label: 'PROJECT_ADMIN' },
        tool_admin: { label: 'TOOL_ADMIN' },
      },
      endpoints: {
        view: ['companySettingsControllerGetCompanyWorkflowSettings'],
        edit: ['companySettingsControllerUpdateWorkflowSettings'],
      },
    },

    SecuritySettings: {
      i18n: {
        keyPrefix: 'SETTINGS.SECURITY_SETTINGS',
      },
      label: 'TITLE',
      fields: {
        use_strict_passwords: { label: 'USE_STRICT_PASSWORDS' },
        strict_file_uploading: { label: 'STRICT_FILE_UPLOADING' },
      },
    },

    AdvancedSecuritySettings: {
      i18n: {
        keyPrefix: 'SETTINGS.ADVANCED_SECURITY_SETTINGS',
      },
      label: 'TITLE',
      permissions: {
        edit: ['edit_advanced_settings'],
      },
      fields: {
        enable_login_lockout: {
          label: 'ENABLE_LOGIN_LOCKOUT',
        },
        session_timeout: {
          label: 'SESSION_TIMEOUT',
        },
        password_reset_after: {
          label: 'PASSWORD_RESET_AFTER',
        },
        enforce_password_reset_by_email: {
          label: 'ENFORCE_PASSWORD_BY_RESET_EMAIL',
        },
        require_mfa_for_members: {
          label: 'REQUIRE_MFA_FOR_MEMBERS',
        },
        require_mfa_enforcement_date: {
          label: 'REQUIRE_MFA_ENFORCEMENT_DATE',
          tooltip: 'REQUIRE_MFA_ENFORCEMENT_DATE_TOOLTIP',
        },
      },
      endpoints: {
        view: ['companySettingsControllerGetCompanyAdvancedSecuritySettings'],
        edit: ['companySettingsControllerUpdateAdvancedSecuritySettings'],
      },
    },

    JitPinSettings: {
      i18n: {
        keyPrefix: 'SETTINGS.JIT_PIN_SETTINGS',
      },
      label: 'TITLE',
      permissions: {
        edit: ['edit_jit_pin_settings'],
      },
      fields: {
        is_pin_required_for_jit: {
          label: 'REQUIRE_JIT_PIN',
        },
        authorized_users_to_bypass_pin: {
          label: 'AUTHORIZED_USERS_TO_BYPASS_PIN',
        },
      },
      endpoints: {
        view: [
          // 'oktaControllerGetOktaUsers', // Fails! Endpoint requires `view`
          'jitControllerGetBypassPinUsers',
        ],
        edit: [
          'jitControllerAddBypassPinUser',
          'jitControllerRemoveBypassPinUser',
          'companyAccessControlControllerUpdateJitPinSettings',
        ],
      },
    },

    StrategicAccountSettings: {
      i18n: {
        keyPrefix: 'SETTINGS.STRATEGIC_ACCOUNT',
      },
      label: 'TITLE',
      permissions: {
        edit: ['edit_strategic_account_settings'],
      },
      fields: {
        is_strategic_account: {
          label: 'IS_STRATEGIC_ACCOUNT',
        },
      },
      endpoints: {
        view: ['companySettingsControllerCheckStrategicAccount'],
        edit: ['companySettingsControllerUpdateStrategicAccountSettings'],
      },
    },

    EsticomSettings: {
      i18n: {
        keyPrefix: 'SETTINGS.ESTICOM_SETTINGS',
      },
      label: 'TITLE',
      fields: {
        esticom_enabled: {
          label: 'ESTICOM_LICENSE',
        },
      },
      endpoints: {
        view: ['companyControllerHasEsticomLicense'],
        edit: ['companyControllerUpdateEsticomLicense'],
      },
    },

    ErpConnectionSettings: {
      i18n: {
        keyPrefix: 'SETTINGS.ERP_CONNECTION',
      },
      label: 'TITLE',
      fields: {
        default_tax_group_enabled: {
          label: 'DEFAULT_TAX_GROUP_ENABLED',
        },
        custom_commitment_change_order_title: {
          label: 'CUSTOM_COMMITMENT_CHANGE_ORDER_TITLE',
          tooltip: 'CUSTOM_COMMITMENT_CHANGE_ORDER_TITLE_TOOLTIP',
        },
        job_id_format: {
          label: 'JOB_ID_FORMAT',
          tooltip: 'JOB_ID_FORMAT_TOOLTIP',
        },
      },
      endpoints: {
        view: ['companyControllerGetCompanyErpConnections'],
        edit: ['companyControllerUpdateCompanyErpConnections'],
      },
    },
  },
};

export default SettingsPage;
