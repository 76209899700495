import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Card, Flex, Spinner, Box, Button, spacing } from '@procore/core-react';
import { getApi } from 'utils/api';
import { ProjectsApi } from '@procore/ipa-nt-api-client-ts';
import type { ProjectCostCodesDto } from '@procore/ipa-nt-api-client-ts';
import TreeList from 'components/TreeList';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { ProjectContext } from 'contexts/ProjectContext';
import { ImportProjectCostCodesModal } from './ImportProjectCostCodesModal';
import type { IImportProjectCostCodesForm } from './ImportProjectCostCodesModal';
let csvToJson = require('csvjson-csv2json');

interface ProjectCostCode extends ProjectCostCodesDto {
  codes?: any[];
  isLoaded?: boolean;
  isOpen?: boolean;
}

const CostCodesPage = () => {
  const alert = useContext(IpaAlertContext);
  const context = useContext(ProjectContext);
  const config = context.pageConfig;
  const t = config.translate;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(true);
  const [isImportModalOpen, setIsImportModalOpen] = useState<boolean>(false);
  const [costCodes, setCostCodes] = useState<ProjectCostCode[]>([]);

  const processCostCodes = (data: ProjectCostCode[]) => {
    const temp = data
      .map((costCode) => {
        return {
          ...costCode,
          codes: [],
          isLoaded: false,
          isOpen: false,
        };
      })
      .sort((a, b) => (+a.code > +b.code ? 1 : -1));
    setCostCodes(temp);
  };

  const importFile = (values: IImportProjectCostCodesForm) => {
    alert.closeAlert();
    if (values.file) {
      const reader = new FileReader();
      reader.readAsText(values.file, 'UTF-8');
      reader.onload = async (e) => {
        if (e.target) {
          let json = csvToJson(e.target.result, { parseNumbers: true });
          json = json.map((o: any) => {
            return {
              code: o.code || o.Code,
              name: o.name || o.Name,
            };
          });
          try {
            const api = getApi(ProjectsApi);
            const result = await api.projectControllerImportProjectCostCodesV10(
              context.env,
              context.companyId,
              context.projectId,
              { cost_codes: json },
            );
            processCostCodes(result.data);
            alert.success(t('IMPORT_SUCCESS'));
          } catch (e: any) {
            alert.error(e);
          }
        }
      };
      reader.onerror = (e) => {
        alert.error(e.toString());
      };
    }
    setIsImportModalOpen(false);
  };

  const loadTreeBranch = async (node: any, onlyOne: boolean, index: number) => {
    try {
      const temp = [...costCodes];
      if (!node.isLoaded) {
        const api = getApi(ProjectsApi);
        const result = await api.projectControllerGetProjectCostCodeDetailsV10(
          context.env,
          context.companyId,
          context.projectId,
          node.id,
        );
        const childrenCodes = result.data.children.sort((a, b) =>
          +a.code > +b.code ? 1 : -1,
        );
        temp[index] = {
          ...temp[index],
          codes: childrenCodes,
          isLoaded: true,
          isOpen: true,
        };
      } else {
        temp[index] = { ...temp[index], isOpen: !temp[index].isOpen };
      }
      setCostCodes(temp);
    } catch (e: any) {
      alert.error(e);
    }
  };

  const getCostCodes = useCallback(async () => {
    setIsInit(false);
    setIsLoading(true);
    try {
      const api = getApi(ProjectsApi);
      const result = await api.projectControllerGetProjectCostCodesV10(
        context.env,
        context.companyId,
        context.projectId,
      );
      processCostCodes(result.data);
    } catch (e: any) {
      alert.error(e);
    } finally {
      setIsLoading(false);
      setIsInit(true);
    }
  }, [context.projectId, context.companyId, context.env]);

  useEffect(() => {
    getCostCodes();
  }, [getCostCodes]);

  return (
    <React.Fragment>
      <Card>
        <Box padding="lg" id="testing">
          {(!isInit || isLoading) && (
            <Box>
              {isLoading && (
                <Flex justifyContent="center">
                  <Spinner loading={isLoading} />
                </Flex>
              )}
            </Box>
          )}
          {isInit && !isLoading && (
            <Flex>
              <div data-qa="cost-codes-tree-list">
                <TreeList
                  data={costCodes}
                  loadBranch={loadTreeBranch}
                  childrenNode="codes"
                />
              </div>
              <Button
                style={{ marginLeft: 'auto', marginTop: `${spacing.lg}px` }}
                onClick={() => setIsImportModalOpen(true)}
              >
                {t('IMPORT_CSV')}
              </Button>
            </Flex>
          )}
        </Box>
      </Card>
      <ImportProjectCostCodesModal
        isOpen={isImportModalOpen}
        close={() => setIsImportModalOpen(false)}
        importFile={importFile}
      />
    </React.Fragment>
  );
};

export default CostCodesPage;
