import React, { useEffect, useCallback, useContext, useState } from 'react';
import { Button, FlexList, Typography, Tooltip } from '@procore/core-react';
import { JITApi } from '@procore/ipa-nt-api-client-ts';
import type {
  JitCompanySettingsDto,
  JitSessionDto,
} from '@procore/ipa-nt-api-client-ts';
import Countdown from 'components/Countdown';
import { JitDeescalateModal } from 'components/JitDeescalateModal';
import JitEscalateModal from 'components/JitEscalateModal';
import type { JitEscalateFormValues } from 'components/JitEscalateModal';
import { JitExtendModal } from 'components/JitExtendModal';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { useTranslation } from 'react-i18next';
import { getApi } from 'utils/api';
import { JitWidgetWrapper } from './styles';
import { getBaseUrl, getCompanyUrl } from 'utils/utils';

interface JitWidgetProps {
  env: string;
  companyId: number;
  companyName: string;
  impersonateEmail?: string;
  isPinRequired: boolean;
  redirectUrl?: string;
}

const JitWidget = (props: JitWidgetProps) => {
  const { t } = useTranslation(['jit']);
  const [loading, setLoading] = useState<boolean>(false);
  const alert = useContext(IpaAlertContext);
  const [jitSession, setJitSession] = useState<JitSessionDto>();
  const [escalateModalOpen, setEscalateModalOpen] = useState<boolean>(false);
  const [deescalateModalOpen, setDeescalateModalOpen] =
    useState<boolean>(false);
  const [extendModalOpen, setExtendModalOpen] = useState<boolean>(false);

  const getActiveSession = async (useCache: boolean = true) => {
    try {
      setLoading(true);
      const result = await getApi(JITApi).jitControllerGetActiveSessionsForUserV10(
        props.env,
        !useCache && {
          headers: {
            'Cache-Control': 'no-cache',
          },
        },
      );
      setLoading(false);
      if (result.data) {
        const session = result.data.find(
          (session) => +session.companyId === props.companyId,
        );
        setJitSession(session);
      }
    } catch (error: any) {
      const msg = t('LOAD_SESSIONS_ERROR', {
        ns: 'jit',
        env: props.env,
        reason: error.response?.data?.message || error.message,
      });
      alert.error(msg);
    }
  };

  const escalate = async (values: JitEscalateFormValues) => {
    try {
      setLoading(true);
      const result = await getApi(JITApi).jitControllerCreateSessionV10({
        env: props.env,
        companyId: Number(values.company_id),
        accessLevel: values.access_level!.id as any,
        category: values.category!.id,
        reason: values.detail,
        impersonateEmail: values.email,
        pin: values.pin,
        salesForceCaseNumber: values.salesforce_case_number,
        escalationLength: Number(values.escalation_length.id),
        projectId: Number(values.project_id),
        pdmSupport: values.pdm_support,
      });
      setLoading(false);

      if (result.data) {
        alert.success(
          t('ESCALATE_SUCCESS', {
            accessLevel: result.data.accessLevel,
            companyName: result.data.companyName,
          }),
        );
        if (values.open_new_window) {
          if (props.redirectUrl) {
            window.open(props.redirectUrl, '_blank');
          } else if (values.project_id !== '') {
            window.open(
              `${getBaseUrl(props.env, 'procore', +values.company_id)}/${
                values.project_id
              }/project/home`,
              '_blank',
            );
          } else {
            // Use the utility function to get the company URL
            const url = await getCompanyUrl(
              +values.company_id,
              props.env,
              alert.error,
            );
            window.open(url, '_blank');
          }
        }
      }
    } catch (error: any) {
      const msg = t('ESCALATE_FAILED', {
        companyId: values.company_id,
        reason: error.response?.data?.message || error.message,
      });
      alert.error(msg);
    } finally {
      setEscalateModalOpen(false);
      getActiveSession(false);
    }
  };

  const expiresCell = (session: JitSessionDto) => {
    const diff = Math.round(
      (+new Date(session.expiresAt) - +new Date()) / 1000,
    );
    const deescalatingText = t('DEESCALATING', { ns: 'jit' });
    return (
      <span>
        {diff > 0 ? (
          <Countdown time_left={diff} expired_msg={deescalatingText} />
        ) : (
          deescalatingText
        )}
      </span>
    );
  };

  const deescalateFinished = useCallback(
    (success: boolean, session: JitSessionDto) => {
      setDeescalateModalOpen(false);
      getActiveSession(false);
      success &&
        alert.success(
          t('DEESCALATE_SUCCESS', {
            accessLevel: session.accessLevel,
            companyName: session.companyName,
          }),
        );
    },
    [],
  );

  const extendFinished = useCallback(
    (success: boolean, session: JitSessionDto) => {
      setExtendModalOpen(false);
      getActiveSession(false);
      success &&
        alert.success(
          t('EXTEND_SUCCESS', {
            accessLevel: session.accessLevel,
            companyName: session.companyName,
          }),
        );
    },
    [],
  );

  useEffect(() => {
    getActiveSession(true);
  }, [props.env, props.companyId]);

  return (
    <JitWidgetWrapper>
      {jitSession && (
        <div className="jit-status" data-qa="jit-status">
          <Typography intent="body">
            <strong>{t('LABEL_ACCESS_LEVEL')}:</strong> {jitSession.accessLevel}
            {jitSession.accessLevel === 'impersonate' && (
              <span>
                {' '}
                (as <u>{jitSession.impersonateEmail}</u>)
              </span>
            )}
            <br />
            <strong>{t('LABEL_EXPIRES')}:</strong> {expiresCell(jitSession)}
          </Typography>
          <FlexList size="xs">
            <Button
              onClick={() => setDeescalateModalOpen(true)}
              size="sm"
              data-qa="jit-widget-deescalate"
            >
              {t('LABEL_DEESCALATE')}
            </Button>
            <Tooltip overlay={t('JIT_EXTENSION_LIMIT')}>
              <span>
                <Button
                  onClick={() => setExtendModalOpen(true)}
                  size="sm"
                  variant="secondary"
                  data-qa="jit-widget-extend"
                  disabled={jitSession.extensionCount > 1}
                >
                  {t('LABEL_EXTEND')}
                </Button>
              </span>
            </Tooltip>
          </FlexList>

          <JitExtendModal
            session={jitSession}
            isOpen={extendModalOpen}
            closeModal={() => setExtendModalOpen(false)}
            setParentErrorMessage={alert.error}
            doneCallback={extendFinished}
          />
          <JitDeescalateModal
            session={jitSession}
            isOpen={deescalateModalOpen}
            closeModal={() => setDeescalateModalOpen(false)}
            setParentErrorMessage={alert.error}
            doneCallback={deescalateFinished}
          />
        </div>
      )}
      {!jitSession && (
        <div>
          <Button
            onClick={() => setEscalateModalOpen(true)}
            data-qa="jit-widget-escalate"
          >
            {t('ESCALATE')}
          </Button>
          <JitEscalateModal
            env={props.env}
            selected_company={
              {
                companyId: props.companyId,
                companyName: props.companyName,
                isPinRequired: props.isPinRequired,
              } as unknown as JitCompanySettingsDto
            }
            isOpen={escalateModalOpen}
            closeModal={() => setEscalateModalOpen(false)}
            confirmModal={escalate}
            canSelectEscalationLength={true}
            selected_email={props.impersonateEmail}
            selected_access_level={
              jitSession?.accessLevel
                ? {
                    id: jitSession.accessLevel,
                    label: jitSession.accessLevel,
                    disabled: false,
                  }
                : undefined
            }
          />
        </div>
      )}
    </JitWidgetWrapper>
  );
};

export default JitWidget;
