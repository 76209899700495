import React, { useState, useEffect, useContext } from 'react';
import * as yup from 'yup';
import type { FormikProps } from 'formik';
import { useTranslation, Trans } from 'react-i18next';
import {
  Modal,
  Form,
  SegmentedController,
  Button,
  Box,
  Grid,
  Link,
  ZIndexProvider,
  Spinner,
  Tooltip,
  Pill,
} from '@procore/core-react';
import { Check, Error, ExternalLink, Help } from '@procore/core-icons';
import { MessageBanner } from 'components/MessageBanner';
import EnvironmentSwitcher from 'components/EnvironmentSwitcher';
import { urlIdParser } from 'utils/form';
import { getApi } from 'utils/api';
import {
  CompaniesApi,
  ProjectsApi,
  JITApi,
  CompanyDirectoryApi,
  CompanySettingsApi,
  UserSettingsApi,
} from '@procore/ipa-nt-api-client-ts';
import type {
  JitCompanySettingsDto,
  JitCompanyPinDto,
  CompanyAdvancedSecuritySettingsDto,
  UserSettingDto,
} from '@procore/ipa-nt-api-client-ts';
import { AppContext } from 'contexts/AppContext';
import { JIT_ACCESS_LEVELS, REASON_CATEGORIES } from 'common/constants';
import { ipaAppStyles, StyledFormSelect, StyledFormTextArea } from 'styles';
import {
  SearchWrapper,
  SelectedCompany,
  FormDescription,
  TooltipColumn,
  styles,
} from './styles';
import { getCompanyZone, getZoneConfig } from 'utils/utils';
import { can } from 'utils/permissions.helper';

type SelectOption = {
  id: number | string;
  label: string;
};

type AccessLevel = {
  id: string;
  label: string;
  disabled: boolean;
};

type ReasonCategory = {
  id: string;
  name: string;
  salesforceCaseNumberRequired: boolean;
};

interface IJitProject {
  id: number;
  isActive: boolean;
  name: string;
  projectNumber: string;
}

export interface JitEscalateFormValues {
  env: string;
  company_id: string;
  project_id?: string;
  pin?: string;
  category: ReasonCategory | null;
  is_pin_required: boolean | null;
  detail: string;
  access_level: AccessLevel | null;
  email?: string;
  pdm_support: boolean;
  escalation_length: SelectOption;
  salesforce_case_number?: string;
  open_new_window: boolean;
  sa_escalation_confirmation_check?: boolean;
}

interface JitEscalateModalProps {
  env: string;
  isOpen: boolean;
  closeModal(): void;
  re_escalate?: boolean;
  customer_success?: boolean;
  selected_company?: JitCompanySettingsDto;
  canSelectEscalationLength?: boolean;
  selected_access_level?: AccessLevel | null;
  selected_reason?: string | null;
  escalation_length?: string | null;
  selected_email?: string | null;
  isStrategicAccount?: boolean;
  pdmSupport?: boolean;
  salesforce_case_number?: string | null;
  detail?: string | null;
  confirmModal(values: JitEscalateFormValues): void;
  default_new_window?: boolean;
  projectId?: string;
}

export const JitEscalateModal = (props: JitEscalateModalProps) => {
  const { t } = useTranslation('jit');
  const { userConfig, userSettings, updateUserSettings } =
    useContext(AppContext);
  const {
    re_escalate,
    customer_success,
    canSelectEscalationLength,
    confirmModal,
    salesforce_case_number,
    detail,
  } = props;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [searchType, setSearchType] = useState<string>('company');
  const [selectedCompany, setSelectedCompany] =
    useState<JitCompanySettingsDto | null>(null);
  const [advancedSecuritySettings, setAdvancedSecuritySettings] =
    useState<CompanyAdvancedSecuritySettingsDto>();
  const [selectedProject, setSelectedProject] = useState<IJitProject | null>(
    null,
  );
  const [selectedEmail, setSelectedEmail] = useState<string | null>(null);
  const [companyError, setCompanyError] = useState<string>('');
  const [projectError, setProjectError] = useState<string>('');
  const [companyPin, setCompanyPin] = useState<JitCompanyPinDto | null>(null);
  const [companyPinError, setCompanyPinError] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [jitCategories, setJitCategories] = useState<any[]>([]);
  const [isCurrentUserInDirectory, setIsCurrentUserInDirectory] =
    useState<boolean>(false);
  const [isStrategicAccount, setIsStrategicAccount] = useState<boolean>(false);
  const [pdmSupportEnabled, setPdmSupportEnabled] = useState<boolean>(false);
  const [companyValidationInProgress, setCompanyValidationInProgress] =
    useState<boolean>(false);
  const [pinValidationInProgress, setPinValidationInProgress] =
    useState<boolean>(false);
  const [emailValidationInProgress, setEmailValidationInProgress] =
    useState<boolean>(false);
  const [projectValidationInProgress, setProjectValidationInProgress] =
    useState<boolean>(false);
  const [selectedAccessLevel, setSelectedAccessLevel] =
    useState<AccessLevel | null>(null);
  const [selectedCategory, setSelectedCategory] =
    useState<ReasonCategory | null>();
  const [selectedEscalationLength, setSelectedEscalationLength] =
    useState<SelectOption>();
  const [env, setEnv] = useState('PROD');
  const [loading, setLoading] = useState<boolean>(false);
  const [numberOfCompanyProjects, setNumberOfCompanyProjects] =
    useState<number>(null);

  const accessLevels = JIT_ACCESS_LEVELS.map((level) => {
    return {
      ...level,
      label: t(level.label, { ns: 'jit' }),
    };
  });

  const hasPdmSupprtPermission = can(
    { user: userConfig },
    'can_use_pdm_support',
  );

  const escalationLengthOptions: SelectOption[] = [
    {
      id: 2,
      label: t('LABEL_2_HOURS'),
    },
    {
      id: 4,
      label: t('LABEL_4_HOURS'),
    },
  ];

  const getEscalationLengthOptions = () => {
    if (can({ user: userConfig }, 'process_extract_escalation_length')) {
      return [
        ...escalationLengthOptions,
        {
          id: 8,
          label: t('LABEL_8_HOURS'),
        },
      ];
    } else {
      return escalationLengthOptions;
    }
  };

  const getCategory = (reason: string) => {
    return REASON_CATEGORIES.find(
      (category) => category.key === reason || category.id === reason,
    );
  };

  const closeModal = () => {
    setCompanyError('');
    setShowModal(false);
    props.closeModal();
  };

  const getAdvancedSecuritySettings = async (companyId: string) => {
    setAdvancedSecuritySettings(undefined);
    try {
      const api = getApi(CompanySettingsApi);
      const res =
        await api.companySettingsControllerGetCompanyAdvancedSecuritySettingsV10(
          env,
          Number(companyId),
        );
      if (res.data) {
        setAdvancedSecuritySettings(res.data);
      }
    } catch (e: any) {
      return e;
    }
  };

  const getProjects = async (companyId: number) => {
    const companyApi = getApi(CompaniesApi);
    const res = await companyApi.companyControllerGetProjectsV10(
      env,
      companyId,
      501,
    );

    setNumberOfCompanyProjects(res?.data.length);
  };

  const searchCompany = async (id: string, setFunc: Function) => {
    setCompanyValidationInProgress(true);
    setCompanyError('');
    setCompanyData(null);
    setSelectedAccessLevel(accessLevels[0]);
    setPdmSupportEnabled(false);
    debugger;
    try {
      if (searchType === 'company') {
        getAdvancedSecuritySettings(id);
        const company = await getApi(JITApi).jitControllerGetCompanySettingsV10(
          +id,
          env,
        );
        if (company.data.canUserEscalate) {
          setSelectedProject(null);
          setFunc('project_id', '');
          setCompanyData(company.data);
        } else if (company.data.cannotEscalateReason) {
          setCompanyError(company.data.cannotEscalateReason);
        }
        setCompanyValidationInProgress(false);
      } else if (searchType === 'project') {
        const companyId = getZoneConfig(getCompanyZone(+id))?.company_id || +id;
        const res = await getApi(
          ProjectsApi,
        ).projectControllerGetProjectInfoV10(env, companyId, +id);
        setFunc('project_id', id);
        setSelectedProject({
          id: res.data.id,
          isActive: res.data.active,
          name: res.data.name,
          projectNumber: res.data.project_number,
        });
        if (res.data.company) {
          getAdvancedSecuritySettings(`${res.data.company.id}`);
          setFunc('company_id', res.data.company.id);
          setSearchType('company');
        }
        if (res.data.company.is_active) {
          const company = await getApi(
            JITApi,
          ).jitControllerGetCompanySettingsV10(+res.data.company.id, env);
          if (company.data.canUserEscalate) {
            setCompanyData(company.data);
          } else if (company.data.cannotEscalateReason) {
            setCompanyError(company.data.cannotEscalateReason);
          }
        } else {
          setCompanyError(t('COMPANY_INACTIVE'));
        }
        setCompanyValidationInProgress(false);
      }
    } catch (error: any) {
      if (error.request.status === 404) {
        setCompanyError(t('COMPANY_NOT_FOUND'));
      } else {
        setCompanyError(error.request.statusText);
      }
      setCompanyValidationInProgress(false);
    }
  };

  const searchProject = async (id: string, setFunc?: Function) => {
    setSelectedProject(null);
    setProjectValidationInProgress(true);
    try {
      const res = await getApi(ProjectsApi).projectControllerGetProjectInfoV10(
        env,
        +id,
        +id,
      );
      setProjectValidationInProgress(false);

      const company: JitCompanySettingsDto =
        selectedCompany || props.selected_company;

      if (Number(res.data.company.id) !== Number(company?.companyId)) {
        setProjectError(
          t('PROJECT_DOES_NOT_BELONG', {
            companyName: company?.companyName,
          }),
        );
      } else {
        setSelectedProject({
          id: res.data.id,
          isActive: res.data.active,
          name: res.data.name,
          projectNumber: res.data.project_number,
        });
      }
    } catch (error: any) {
      setProjectValidationInProgress(false);
      if (error.request.status === 404) {
        setProjectError(t('PROJECT_NOT_FOUND'));
      }
    }
  };

  const setCompanyData = async (company?: JitCompanySettingsDto) => {
    if (company) {
      getProjects(+company.companyId);
      setSelectedCompany(company);
      setIsCurrentUserInDirectory(await checkDirectoryUser(+company.companyId));
      setIsStrategicAccount(await checkStrategicAccount(+company.companyId));
    } else {
      setSelectedCompany(null);
      setIsCurrentUserInDirectory(false);
      setIsStrategicAccount(false);
    }
  };

  const validateCompany = (v: any) => {
    if (!v) {
      return t('REQUIRED', { ns: 'common' });
    } else if (!selectedCompany) {
      return t('VALIDATE_COMPANY_OR_PROJECT');
    }
  };

  const validateProject = (v: any) => {
    if (!v) {
      return t('REQUIRED', { ns: 'common' });
    } else if (!selectedProject) {
      return t('VALIDATE_PROJECT');
    }
  };

  const validatePin = (v: string) => {
    if (!v) {
      return t('REQUIRED', { ns: 'common' });
    } else if (!companyPin) {
      return t('VALIDATE_PIN');
    }
  };

  const validateEmail = (v: string) => {
    if (!v) {
      return t('REQUIRED', { ns: 'common' });
    } else if (!selectedEmail) {
      return t('VALIDATE_EMAIL');
    }
  };

  const checkDirectoryUser = async (companyId: number) => {
    const res = await getApi(
      CompanyDirectoryApi,
    ).companyDirectoryControllerCheckCompanyContactV10(
      env,
      companyId,
      userConfig.user?.username as string,
    );

    return res.data;
  };

  const checkStrategicAccount = async (companyId: number) => {
    if (props.isStrategicAccount === undefined) {
      const res = await getApi(
        CompanySettingsApi,
      ).companySettingsControllerCheckStrategicAccountV10(companyId);

      return res.data;
    } else {
      return props.isStrategicAccount;
    }
  };

  const getStrategicAccountLabel = () => {
    return t('STRATEGIC_ACCOUNT_WARNING', {
      companyName: selectedCompany?.companyName,
    });
  };

  const getDefaultCategory = () => {
    if (selectedCategory) {
      return selectedCategory;
    } else if (localStorage.getItem('ipa-jit-last-reason')) {
      return JSON.parse(localStorage.getItem('ipa-jit-last-reason'));
    } else if (userConfig.user.roles.indexOf('erp_support') !== -1) {
      return getCategory('REASON_ERP_SUPPORT');
    } else if (userConfig.user.roles.indexOf('customer_success') !== -1) {
      return getCategory('REASON_CUSTOMER_SUPPORT');
    } else {
      return null;
    }
  };

  const getDefaultAccessLevel = () => {
    if (selectedAccessLevel) {
      return selectedAccessLevel;
    } else if (localStorage.getItem('ipa-jit-last-access-level')) {
      return JSON.parse(localStorage.getItem('ipa-jit-last-access-level'));
    } else {
      return accessLevels[0];
    }
  };

  const JitPinNotice = () => {
    const awsCompanyIds = [562949953433542, 562949953434613];

    return selectedCompany &&
      awsCompanyIds.includes(+selectedCompany.companyId) ? (
      <div style={{ marginBottom: '15px' }}>
        <MessageBanner
          banner_type="info"
          show_title={true}
          banner_title={t('NOTICE')}
          banner_message={t('AWS_PIN_NOTICE')}
        />
      </div>
    ) : (
      <span></span>
    );
  };

  const lookupCompanyPin = async (pin: string) => {
    setCompanyPinError('');
    try {
      if (pin !== '' && selectedCompany) {
        setPinValidationInProgress(true);
        const res = await getApi(JITApi).jitControllerGetCompanyPinV10(
          env,
          +selectedCompany.companyId,
          pin,
        );
        setCompanyPin(res.data);
        setPinValidationInProgress(false);
      }
    } catch (error: any) {
      if (error.request.status === 404) {
        setCompanyPinError(t('PIN_NOT_FOUND'));
      }
      setPinValidationInProgress(false);
    }
  };

  const lookupEmail = async (
    email: string,
    lookupCompany?: JitCompanySettingsDto,
  ) => {
    setSelectedEmail('');
    setEmailError('');
    try {
      if (lookupCompany || selectedCompany) {
        setEmailValidationInProgress(true);
        const company = lookupCompany || selectedCompany;
        const res = await getApi(CompaniesApi).companyControllerUserLookupV10(
          props.env,
          +company.companyId,
          email,
        );
        setEmailValidationInProgress(false);
        if (res.data.length) {
          setSelectedEmail(email);
        } else {
          setEmailError(t('USER_NOT_FOUND'));
        }
      } else {
        setEmailError(t('VALIDATE_COMPANY'));
        return false;
      }
    } catch (error: any) {
      if (error.request.status === 404) {
        setEmailError(t('USER_NOT_FOUND'));
      }
      setEmailValidationInProgress(false);
    }
  };

  const sanitizeValues = (values: JitEscalateFormValues) => {
    if (values.pin) {
      values.pin = values.pin.trim();
    }
    if (values.email) {
      values.email = values.email.trim();
    }
    return values;
  };

  const updateFavorites = async (id: string) => {
    const favorites = userSettings.favorite_accounts;
    if (favorites) {
      const index = favorites.findIndex((f) => f.id === +id);
      if (index > -1) {
        const temp = favorites[index];
        favorites[index] = { ...temp, updated_at: new Date().toISOString() };
        const updatedSettings: UserSettingDto = {
          ...userSettings,
          favorite_accounts: favorites,
        };
        try {
          const api = getApi(UserSettingsApi);
          const res = await api.settingsControllerUpdateV10(updatedSettings);

          updateUserSettings(res.data);
        } catch (error: any) {}
      }
    }
  };

  useEffect(() => {
    setShowModal(props.isOpen);
    setJitCategories(
      REASON_CATEGORIES.map((category) => ({
        id: category.id,
        label: t(category.key, { ns: 'common' }),
        salesforceCaseNumberRequired: category.salesforceCaseNumberRequired,
      })),
    );
    if (props.env) {
      setEnv(props.env);
    } else {
      setEnv('PROD');
    }
    if (props.selected_company) {
      setSelectedCompany(props.selected_company);
      setIsStrategicAccount(props.isStrategicAccount ?? false);
      if (
        props.selected_email &&
        props.selected_access_level &&
        props.selected_access_level.id === 'impersonate'
      ) {
        lookupEmail(props.selected_email, props.selected_company);
        if (props.pdmSupport && props.projectId) {
          searchProject(props.projectId);
        }
      }
    }
    if (props.selected_access_level) {
      setSelectedAccessLevel(
        accessLevels.find(
          (level) => level.id === props.selected_access_level?.id,
        ) as AccessLevel,
      );
    }
    if (props.selected_reason) {
      const reason = getCategory(props.selected_reason);

      if (reason) {
        setSelectedCategory({
          ...reason,
          name: t(reason?.id, { ns: 'common' }),
        });
      } else {
        setSelectedCategory(null);
      }
    }
    if (props.escalation_length) {
      const lengthOption = escalationLengthOptions.find(
        (option) => option.id === +props.escalation_length,
      );
      if (lengthOption) {
        setSelectedEscalationLength(lengthOption);
      }
    }
    if (props.pdmSupport) {
      setPdmSupportEnabled(props.pdmSupport);
    }

    return () => {
      setEnv('');
      setSelectedCompany(null);
      setSelectedAccessLevel(null);
      setSelectedEmail(null);
      setCompanyPin(null);
      setIsStrategicAccount(false);
      setPdmSupportEnabled(false);
      setSelectedCategory(null);
      setLoading(false);
    };
  }, [props]);

  useEffect(() => {
    const onKeyDownHandler = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 'j') {
        !showModal ? setShowModal(true) : setShowModal(false);
      }
    };

    window.addEventListener('keydown', onKeyDownHandler);
    return () => {
      window.removeEventListener('keydown', onKeyDownHandler);
    };
  }, [showModal]);

  return (
    <ZIndexProvider>
      <Modal
        open={showModal}
        onClickOverlay={closeModal}
        width="md"
        data-qa="escalate-modal"
        onClose={closeModal}
        style={{ maxWidth: '800px' }}
      >
        <Modal.Header onClose={closeModal}>{t('MODAL_TITLE')}</Modal.Header>
        <Form
          view="create"
          enableReinitialize={true}
          initialValues={{
            env: env,
            company_id: selectedCompany
              ? selectedCompany.companyId.toString()
              : '',
            project_id: selectedProject ? selectedProject.id.toString() : '',
            is_pin_required: selectedCompany && selectedCompany.isPinRequired,
            open_new_window: props.default_new_window || true,
            access_level: getDefaultAccessLevel(),
            category: getDefaultCategory(),
            pdm_support: props.pdmSupport ?? false,
            escalation_length:
              selectedEscalationLength ?? escalationLengthOptions[0],
            pin: '',
            email: selectedEmail ?? '',
            detail: detail || '',
            salesforce_case_number: salesforce_case_number || '',
          }}
          validationSchema={yup.object().shape({
            company_id: yup.number().required(t('REQUIRED', { ns: 'common' })),
            detail: yup.string().when('salesforce_case_number', {
              is: (salesforce_case_number: string) =>
                !salesforce_case_number || salesforce_case_number?.length === 0,
              then: yup
                .string()
                .required(t('REQUIRED', { ns: 'common' }))
                .max(500, t('CHARACTER_LIMIT')),
            }),
            access_level: yup
              .object()
              .shape({ id: yup.string(), label: yup.string() })
              .nullable()
              .required(t('REQUIRED', { ns: 'common' })),
            category: yup
              .object()
              .shape({ id: yup.string(), label: yup.string() })
              .nullable()
              .required(t('REQUIRED', { ns: 'common' })),
            salesforce_case_number: yup
              .string()
              .test(
                'salesforce_case_number',
                '',
                (value, validationContext) => {
                  const {
                    createError,
                    parent: { category },
                  } = validationContext;

                  if (
                    category &&
                    category.salesforceCaseNumberRequired &&
                    (!value || value === '')
                  ) {
                    return createError({
                      message: t('SALESFORCE_REQUIRED'),
                    });
                  }

                  if (
                    selectedCompany &&
                    selectedCompany.isPinRequired &&
                    (!value || value === '')
                  ) {
                    return createError({
                      message: t('SALESFORCE_REQUIRED_PIN'),
                    });
                  }

                  return true;
                },
              ),
            project_id: yup.number().when('pdm_support', {
              is: (pdm_support: boolean) => {
                return pdm_support;
              },
              then: yup.number().required(t('PROJECT_ID_PDM_REQUIRED')),
            }),
            sa_escalation_confirmation_check: yup.boolean().when([], {
              is: () => isStrategicAccount || props.isStrategicAccount,
              then: yup
                .boolean()
                .oneOf([true], t('REQUIRED', { ns: 'common' }))
                .required(t('REQUIRED', { ns: 'common' })),
            }),
          })}
          onSubmit={(values: JitEscalateFormValues) => {
            if (values.salesforce_case_number !== '' && values.detail === '') {
              values.detail = 'Salesforce';
            }
            values.pdm_support = pdmSupportEnabled;
            values = sanitizeValues(values);
            localStorage.setItem(
              'ipa-jit-last-reason',
              JSON.stringify(values.category),
            );
            localStorage.setItem(
              'ipa-jit-last-access-level',
              JSON.stringify(values.access_level),
            );
            if (!hasPdmSupprtPermission) {
              values.pdm_support = false;
            }
            if (typeof confirmModal === 'function') {
              setLoading(true);
              confirmModal(values);
              updateFavorites(values.company_id);
            }
          }}
        >
          {(form: FormikProps<JitEscalateFormValues>) => (
            <Form.Form style={ipaAppStyles.ipaModalForm}>
              <Modal.Body data-qa="jit-modal-body">
                {!props.env && (
                  <Box marginBottom="lg">
                    <EnvironmentSwitcher
                      initialValue={env || 'PROD'}
                      onChange={(e) => setEnv(e)}
                    />
                  </Box>
                )}
                <Grid gutterX="md">
                  <Grid.Row>
                    <Grid.Col colWidth={4}>
                      <Box marginTop="xl">
                        <SegmentedController
                          disabled={re_escalate || customer_success}
                          block={true}
                          onChange={(n) => {
                            setSearchType(n ? 'project' : 'company');
                          }}
                          aria-label={t('SEARCH_SELECTOR')}
                        >
                          <SegmentedController.Segment
                            active={searchType === 'company'}
                            tooltip={t('TOOLTIP_LOOKUP_COMPANY_ID')}
                            data-qa="company-lookup"
                          >
                            {t('COMPANY_ID', { ns: 'common' })}
                          </SegmentedController.Segment>
                          <SegmentedController.Segment
                            active={searchType === 'project'}
                            tooltip={t('TOOLTIP_LOOKUP_PROJECT_ID')}
                            data-qa="project-lookup"
                          >
                            {t('PROJECT_ID', { ns: 'common' })}
                          </SegmentedController.Segment>
                        </SegmentedController>
                      </Box>
                    </Grid.Col>
                    <Grid.Col colWidth={4}>
                      <SearchWrapper>
                        <Form.Text
                          data-qa="jit-company-id"
                          name="company_id"
                          id="company_id"
                          type="number"
                          label={t('LABEL_ID')}
                          validate={validateCompany}
                          disabled={re_escalate || customer_success}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              searchCompany(
                                form.values.company_id,
                                form.setFieldValue,
                              );
                            }
                          }}
                          onPaste={(e) => {
                            let id = urlIdParser(
                              e,
                              form.setFieldValue,
                              'company_id',
                            );
                            searchCompany(id, form.setFieldValue);
                          }}
                          onBlur={() => {
                            if (
                              !selectedCompany ||
                              selectedCompany.companyId !==
                                +form.values.company_id
                            ) {
                              searchCompany(
                                form.values.company_id,
                                form.setFieldValue,
                              );
                            }
                          }}
                        >
                          <Form.Text.Create required />
                        </Form.Text>
                      </SearchWrapper>
                    </Grid.Col>
                    <Grid.Col colWidth={3}>
                      {companyValidationInProgress && (
                        <SelectedCompany>
                          <Spinner size="md" />
                        </SelectedCompany>
                      )}
                      {selectedCompany && (
                        <SelectedCompany>
                          <Check className="greenIcon" />
                          <span data-qa="validated-company-name">
                            {selectedCompany.companyName}
                          </span>
                        </SelectedCompany>
                      )}
                      {!selectedCompany && companyError !== '' && (
                        <SelectedCompany>
                          <Error
                            className="redIcon"
                            data-qa="inactive-company"
                          />
                          {companyError}
                        </SelectedCompany>
                      )}
                    </Grid.Col>
                  </Grid.Row>
                  {selectedCompany && numberOfCompanyProjects > 500 && (
                    <Grid.Row>
                      <Grid.Col colWidth={12} style={{ marginBottom: '15px' }}>
                        <Pill color="red">{t('LARGE_NUMBER_OF_PROJECTS')}</Pill>
                      </Grid.Col>
                    </Grid.Row>
                  )}
                  <Grid.Row>
                    <Grid.Col>
                      <Form.Checkbox
                        data-qa="jit-open-new-window"
                        name="open_new_window"
                        id="open_new_window"
                        label={t('OPEN_NEW_WINDOW_COMPANY')}
                        tooltip={t('TOOLTIP_OPEN_NEW_WINDOW')}
                      />
                    </Grid.Col>
                  </Grid.Row>
                  {selectedCompany &&
                    advancedSecuritySettings?.require_mfa_for_members && (
                      <Grid.Row>
                        <Grid.Col>
                          <span style={styles.jitMfaNotice}>
                            {t('JIT_MFA_REQUIRED_NOTICE')}
                          </span>
                        </Grid.Col>
                      </Grid.Row>
                    )}
                  {selectedCompany && isStrategicAccount && (
                    <Grid.Row>
                      <Grid.Col>
                        <Form.Checkbox
                          data-qa="sa-escalation-confirmation-check"
                          name="sa_escalation_confirmation_check"
                          id="sa_escalation_confirmation_check"
                          label={getStrategicAccountLabel()}
                        />
                      </Grid.Col>
                    </Grid.Row>
                  )}

                  {selectedCompany && selectedCompany.isPinRequired && (
                    <Grid.Row>
                      <JitPinNotice />

                      <Grid.Col>
                        <SearchWrapper>
                          <Form.Text
                            data-qa="jit-pin"
                            name="pin"
                            id="pin"
                            type="string"
                            label={t('LABEL_PIN')}
                            validate={validatePin}
                            className="text-area"
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                lookupCompanyPin(form.values.pin as string);
                              }
                            }}
                            onPaste={(e) => {
                              e.preventDefault();
                              form.setFieldValue(
                                'pin',
                                e.clipboardData.getData('text').trim(),
                              );
                            }}
                            required
                          ></Form.Text>
                          <Button
                            data-qa="validate-pin-btn"
                            onClick={() =>
                              lookupCompanyPin(form.values.pin as string)
                            }
                            disabled={pinValidationInProgress}
                          >
                            {t('VALIDATE', { ns: 'common' })}
                          </Button>
                        </SearchWrapper>
                      </Grid.Col>
                      <Grid.Col data-qa="company-pin-validation-response">
                        {pinValidationInProgress && (
                          <SelectedCompany>
                            <Spinner size="md" />
                          </SelectedCompany>
                        )}
                        {companyPin && (
                          <SelectedCompany>
                            <Check
                              style={styles.greenCheckMark}
                              data-qa="valid-pin"
                            />
                            {t('COMPANY_PIN_GENERATED_BY', {
                              author: companyPin.createdBy,
                            })}
                          </SelectedCompany>
                        )}
                        {!companyPin && companyPinError !== '' && (
                          <SelectedCompany>
                            <Error className="redIcon" data-qa="invalid-pin" />
                            <span>{companyPinError}</span>
                          </SelectedCompany>
                        )}
                      </Grid.Col>
                    </Grid.Row>
                  )}
                  <Grid.Row>
                    {canSelectEscalationLength && (
                      <Grid.Col>
                        <StyledFormSelect
                          onSearch={false}
                          name="escalation_length"
                          id="escalation_length"
                          label={t('LABEL_ESCALATION_LENGTH')}
                          options={getEscalationLengthOptions()}
                          data-qa="escalation-length-select"
                        />
                      </Grid.Col>
                    )}
                    <TooltipColumn>
                      <StyledFormSelect
                        onSearch={false}
                        name="access_level"
                        id="access_level"
                        label={t('LABEL_ACCESS_LEVEL')}
                        options={accessLevels}
                        placeholder={t('PLACEHOLDER_ACCESS_LEVEL')}
                        onSelect={(level) => {
                          setSelectedAccessLevel({
                            id: level.item.id,
                            label: level.item.label,
                            disabled: level.item.disabled,
                          } as AccessLevel);
                        }}
                        disabled={re_escalate}
                        data-qa="access-level"
                      />
                      {form.values.access_level?.id === 'impersonate' && (
                        <Tooltip
                          overlay={t('IMPERSONATE_USER_EXPLANATION')}
                          trigger="hover"
                          placement="top"
                        >
                          <Help size="sm" />
                        </Tooltip>
                      )}
                    </TooltipColumn>
                  </Grid.Row>
                  {form.values.access_level &&
                    form.values.access_level.id === 'impersonate' && (
                      <Grid.Row>
                        <Grid.Col>
                          <SearchWrapper>
                            <Form.Text
                              data-qa="jit-email"
                              name="email"
                              id="email"
                              label={t('LABEL_EMAIL')}
                              type="text"
                              validate={validateEmail}
                              disabled={re_escalate || customer_success}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  lookupEmail(form.values.email as string);
                                }
                              }}
                            >
                              <Form.Text.Create required />
                            </Form.Text>
                            {!re_escalate && !customer_success && (
                              <Button
                                data-qa="validate-email-btn"
                                onClick={() =>
                                  lookupEmail(form.values.email as string)
                                }
                                disabled={emailValidationInProgress}
                              >
                                {t('VALIDATE', { ns: 'common' })}
                              </Button>
                            )}
                          </SearchWrapper>
                        </Grid.Col>
                        <Grid.Col data-qa="email-validation-response">
                          {emailValidationInProgress && (
                            <SelectedCompany>
                              <Spinner size="md" />
                            </SelectedCompany>
                          )}
                          {selectedEmail && (
                            <SelectedCompany>
                              <Check
                                className="greenIcon"
                                data-qa="valid-email"
                              />
                            </SelectedCompany>
                          )}
                          {!selectedEmail && emailError !== '' && (
                            <SelectedCompany>
                              <Error
                                className="redIcon"
                                data-qa="invalid-email"
                              />
                              {emailError}
                            </SelectedCompany>
                          )}
                        </Grid.Col>
                      </Grid.Row>
                    )}
                  {(form.values.access_level?.id === 'impersonate' ||
                    selectedAccessLevel?.id === 'impersonate') &&
                    hasPdmSupprtPermission && (
                      <Grid.Row>
                        <Grid.Col>
                          <Form.Checkbox
                            data-qa="pdm-support"
                            name="pdm_support"
                            id="pdm_support"
                            label={t('LABEL_PDM_SUPPORT')}
                            tooltip={t('TOOLTIP_PDM')}
                            checked={pdmSupportEnabled}
                            onClick={() => {
                              setProjectError('');
                              setPdmSupportEnabled(!pdmSupportEnabled);
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>
                    )}
                  {hasPdmSupprtPermission && pdmSupportEnabled && (
                    <Grid.Row>
                      <Grid.Col>
                        <SearchWrapper>
                          <Form.Text
                            data-qa="jit-project-id"
                            name="project_id"
                            id="project_id"
                            type="number"
                            label={t('PROJECT_ID', { ns: 'common' })}
                            validate={validateProject}
                            disabled={customer_success}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                searchProject(
                                  form.values.project_id as string,
                                  form.setFieldValue,
                                );
                              }
                            }}
                            onPaste={(e) =>
                              urlIdParser(e, form.setFieldValue, 'project_id')
                            }
                          >
                            <Form.Text.Create required />
                          </Form.Text>
                          <Button
                            data-qa="validate-project-btn"
                            onClick={() =>
                              searchProject(
                                form.values.project_id as string,
                                form.setFieldValue,
                              )
                            }
                            disabled={projectValidationInProgress}
                          >
                            {t('VALIDATE', { ns: 'common' })}
                          </Button>
                        </SearchWrapper>
                      </Grid.Col>
                      <Grid.Col>
                        {selectedProject && (
                          <SelectedCompany>
                            <Check className="greenIcon" />
                            <span>{selectedProject.name}</span>
                          </SelectedCompany>
                        )}
                        {!selectedProject && projectError !== '' && (
                          <SelectedCompany>
                            <Error
                              className="redIcon"
                              data-qa="invalid-project"
                            />
                            {projectError}
                          </SelectedCompany>
                        )}
                      </Grid.Col>
                    </Grid.Row>
                  )}
                  <Grid.Row>
                    <Grid.Col>
                      <StyledFormSelect
                        data-qa="jit-category"
                        onSearch={false}
                        name="category"
                        id="category"
                        label={t('LABEL_CATEGORY')}
                        options={jitCategories}
                        placeholder={t('PLACEHOLDER_CATEGORY')}
                      />
                    </Grid.Col>
                    <Grid.Col>
                      <Form.Text
                        name="salesforce_case_number"
                        label={t('LABEL_SALESFORCE')}
                        required={
                          form.values.category?.salesforceCaseNumberRequired
                        }
                      />
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col>
                      <StyledFormTextArea
                        data-qa="jit-detail"
                        name="detail"
                        label={t('LABEL_DETAIL')}
                        id="detail"
                      />
                      <Box textAlign="right">
                        {t('CHARACTER_COUNT', {
                          count: form.values.detail.length,
                          limit: 500,
                        })}
                      </Box>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col colWidth={{ tabletSm: 12 }}>
                      <FormDescription>
                        <Trans>{t('PROHIBITED_ACTIVITIES')}</Trans>
                        <p>
                          {t('NEED_HELP')}{' '}
                          <Link href="slack://channel?team=T02AS2EJA&amp;id=C02MJ40US5U">
                            #pam-questions
                            <ExternalLink
                              size="sm"
                              style={{ verticalAlign: 'text-bottom' }}
                            />
                          </Link>
                        </p>
                      </FormDescription>
                    </Grid.Col>
                  </Grid.Row>
                </Grid>
              </Modal.Body>
              {isCurrentUserInDirectory === true && (
                <MessageBanner
                  banner_title={t('WARNING')}
                  banner_type="error"
                  show_title={true}
                  banner_message={t('COMPANY_MEMBER_WARNING')}
                />
              )}
              <Modal.Footer>
                <Modal.FooterButtons>
                  <Button
                    variant="secondary"
                    onClick={closeModal}
                    data-qa="cancel-btn"
                  >
                    {t('CANCEL', { ns: 'common' })}
                  </Button>
                  <Button
                    type="submit"
                    loading={loading}
                    data-qa="escalate-btn"
                  >
                    {t('ESCALATE')}
                  </Button>
                </Modal.FooterButtons>
              </Modal.Footer>
            </Form.Form>
          )}
        </Form>
      </Modal>
    </ZIndexProvider>
  );
};

export default JitEscalateModal;
