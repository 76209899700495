import React, { useState, useContext } from 'react';
import { Modal, Button } from '@procore/core-react';
import { Info } from '@procore/core-icons';
import { useTranslation } from 'react-i18next';
import { IpaModalWrapper } from 'styles';
import { AppContext } from 'contexts/AppContext';

export const FEDRAMP_LOCALSTORAGE_KEY = 'fedramp-notice-shown-timestamp';

export const FedrampNoticeComponent = () => {
  const { t } = useTranslation('fedramp');

  const appContext = useContext(AppContext);

  const lastShownTime =
    parseInt(localStorage.getItem(FEDRAMP_LOCALSTORAGE_KEY)) || 0;
  const fedrampNoticeInterval = 24 * 60 * 60 * 1000; // Display every 24 hours

  const [shouldDisplayNotice, setShouldDisplayNotice] = useState(
    appContext.publicConfig.fed_ramp &&
      Date.now() - fedrampNoticeInterval > lastShownTime,
  );

  const closeModal = () => {
    localStorage.setItem(FEDRAMP_LOCALSTORAGE_KEY, Date.now().toString());
    setShouldDisplayNotice(false);
  };

  return (
    shouldDisplayNotice && (
      <Modal
        open={true}
        id={'fedramp-notice-modal'}
        data-qa={'fedramp-notice-modal'}
        width={'md'}
        onClose={closeModal}
      >
        <IpaModalWrapper>
          <Modal.Header onClose={closeModal}>
            <Info size="md" />
            <span data-qa="modal-title">{t('NOTICE_TITLE')}</span>
          </Modal.Header>
          <Modal.Body dangerouslySetInnerHTML={{ __html: t('NOTICE_TEXT') }} />
          <Modal.Footer>
            <Modal.FooterButtons>
              <Button
                variant="primary"
                onClick={closeModal}
                data-qa="ok-button"
              >
                {t('OK', { ns: 'common' })}
              </Button>
            </Modal.FooterButtons>
          </Modal.Footer>
        </IpaModalWrapper>
      </Modal>
    )
  );
};

export default FedrampNoticeComponent;
