import React from 'react';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import type { IDatasource, IEditableCard } from 'components/EditableCard';
import { getApi, getErrorMessage } from 'utils/api';
import { AppContext } from 'contexts/AppContext';
import { useTranslation } from 'react-i18next';
import { ContactContext } from 'contexts/ContactContext';
import PersonalInfoCard from './PersonalInfo';
import OfficeInfoCard from './OfficeInfo';
import { Box, Card, Flex, Spinner } from '@procore/core-react';
import { AxiosError } from 'axios';
import AvailableCompanyToolsCard from './AvailableCompanyTools';
import CompanyToolPermissionsCard from './CompanyToolPermissions';
import {
  CompaniesApi,
  CompanyContactsApi,
  EntitlementsApi,
} from '@procore/ipa-nt-api-client-ts';
import type {
  CompanyErpConnectionsDto,
  CompanyUserErpPermissionsDto,
  CompanyUserToolPermissionsDto,
} from '@procore/ipa-nt-api-client-ts';
import type {
  AccountFeaturesProps,
  ToolProps,
} from 'pages/Company/Tools/interfaces';
import { ErpSettingsCard } from 'pages/Company/ErpSettings/ErpSettingsCard';
import type { IErpSettingsEdit } from 'pages/Company/ErpSettings/erp.settings.interface';
import { can } from 'utils/permissions.helper';
import i18n from 'i18next';
import { PageConfig } from 'pages/page-config.service';
import { ErpPermissions } from './ErpPermissions';
import { ErpIntegrations } from './ErpIntegrations';

interface InfoTabContextProps {
  dataSources: {
    companyTools: IDatasource<ToolProps[]>;
    companyUserToolPermissions: IDatasource<CompanyUserToolPermissionsDto>;
    erpPermissions: IDatasource<CompanyUserErpPermissionsDto>;
    erpConnections: IDatasource<CompanyErpConnectionsDto>;
  };
}

const pageConfig = PageConfig.get(i18n.t, 'Contact.ContactInfo');

export const InfoTabContext = createContext({} as InfoTabContextProps);

const InfoTab = () => {
  const { userConfig } = useContext(AppContext);
  const alert = useContext(IpaAlertContext);
  const context = useContext(ContactContext);
  const { t } = useTranslation(['contact'], {
    keyPrefix: 'CONTACT_INFO_PAGE',
  });
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(true);
  const [cardLoading, setCardLoading] = useState<{ [key: string]: boolean }>(
    {},
  );
  const [companyTools, setCompanyTools] = useState<ToolProps[]>();
  const [erpPermissions, setErpPermissions] =
    useState<CompanyUserErpPermissionsDto>();
  const [erpConnections, setErpConnections] =
    useState<CompanyErpConnectionsDto>();
  const [companyUserToolPermissions, setCompanyUserToolPermissions] =
    useState<CompanyUserToolPermissionsDto>(null);
  const [erpIntegratedError, setErpIntegratedError] = useState<boolean>(false);
  const [erpPermissionsError, setErpPermissionsError] =
    useState<boolean>(false);

  const {
    dataSources: { contact },
  } = useContext(ContactContext);

  const dataSources: InfoTabContextProps['dataSources'] = {
    companyTools: {
      id: 'companyTools',
      data: companyTools!,
    },
    companyUserToolPermissions: {
      id: 'companyUserToolPermissions',
      data: companyUserToolPermissions,
    },
    erpPermissions: {
      id: 'erpPermissions',
      data: erpPermissions!,
    },
    erpConnections: {
      id: 'erpConnections',
      data: erpConnections!,
    },
  };

  const canEdit = can({ user: userConfig }, pageConfig.permissions.edit);

  const cards = [
    {
      id: 'PersonalInfo',
      component: PersonalInfoCard,
      canEdit: false,
    },
    {
      id: 'CompanyToolPermissions',
      component: CompanyToolPermissionsCard,
      canEdit: false,
    },
    {
      id: 'OfficeInfo',
      component: OfficeInfoCard,
      canEdit: false,
    },
    {
      id: 'company-tools-card',
      component: AvailableCompanyToolsCard,
      canEdit: false,
    },
    {
      id: 'ErpPermissions',
      component: ErpPermissions,
      canEdit: false,
    },
    {
      id: 'ErpIntegrations',
      component: ErpIntegrations,
      canEdit: false,
    },
  ];

  const getTools = useCallback(async () => {
    setCompanyTools(undefined);
    try {
      const api = getApi(EntitlementsApi);
      const res = await api.toolEntitlementControllerGetEntitlementsV10(
        context.companyId,
        context.env,
      );

      if (res.data) {
        const accountFeatures = (res.data as AccountFeaturesProps).entitlements;
        const tools = accountFeatures.filter(
          (tool: ToolProps) => tool.feature_level === 'company',
        );
        setCompanyTools(tools);
      }
    } catch (e: any) {
      return e;
    }
  }, [context.companyId, context.env]);

  const loadCompanyUserToolPermissions = useCallback(async () => {
    try {
      const api = getApi(CompanyContactsApi);
      const res =
        await api.companyContactsControllerGetContactCompanyToolPermissionsV10(
          context.env,
          context.companyId,
          context.loginId,
        );

      if (res.data) {
        setCompanyUserToolPermissions(res.data);
        const ftp = res.data?.rows.filter((p) => {
          if (
            !p.label.toLowerCase().includes('generic') &&
            !p.name.toLowerCase().includes('generic')
          ) {
            return p;
          }
        });
        context.updatePowerUser(ftp.every((d) => d.user_access_level.id === 4));
      }
    } catch (e: any) {
      return e;
    }
  }, [context]);

  const loadErpPermissions = useCallback(async () => {
    setErpPermissions(undefined);
    try {
      const api = getApi(CompanyContactsApi);
      const res = await api.companyContactsControllerGetContactErpPermissionsV10(
        context.env,
        context.companyId,
        context.loginId,
      );

      if (res.data) {
        let arrayData = Object.entries(res.data).map(([key, value]) => {
          return { key: key, value: value };
        });

        setErpPermissions(res.data);
      } else {
        setErpPermissions(undefined);
      }
    } catch (e: any) {
      setErpPermissionsError(true);
    }
  }, [context.env, context.companyId, context.loginId]);

  const loadErpConnections = useCallback(async () => {
    try {
      const api = await getApi(CompaniesApi);
      const res = await api.companyControllerGetCompanyErpConnectionsV10(
        context.env,
        context.companyId,
      );

      if (res.data) {
        setErpConnections(res.data);
      } else {
        setErpConnections(undefined);
      }
    } catch (e: any) {
      setErpIntegratedError(true);
    }
  }, [context.env, context.companyId]);

  const saveErpSettings = async (values: IErpSettingsEdit) => {
    try {
      const res = await getApi(
        CompanyContactsApi,
      ).companyContactsControllerUpdateCompanyUserContactV10(
        context.env,
        context.companyId,
        contact.data.id,
        { update: values, reason: values.reason ?? '' },
      );
      if (res.data) {
        context.loadContactInfo();
        alert.success(
          pageConfig.translate('ERP_SETTINGS_CARD.UPDATED_SETTINGS_SUCCESS', {
            NAME: contact.data.email,
          }),
        );
      }
    } catch (e: any) {
      alert.error(e);
    }
  };

  const initPage = async () => {
    const errors: AxiosError[] = [];
    setIsInit(false);
    setLoading(true);
    const promises = await Promise.all([
      getTools(),
      loadCompanyUserToolPermissions(),
      loadErpConnections(),
      loadErpPermissions(),
    ]);
    promises.forEach((promise: any) => {
      if (promise instanceof AxiosError) {
        errors.push(promise);
      }
    });
    if (errors.length > 0) {
      alert.error(errors);
    }
    setLoading(false);
    setIsInit(true);
  };

  const saveChanges = async (
    card: IEditableCard,
    values: any,
    reason?: string,
  ) => {
    alert.closeAlert();
    setCardLoading({
      ...cardLoading,
      [card.id]: true,
    });
    const operations = [];
    operations.push(() => {});

    const results = await Promise.allSettled(operations);
    setCardLoading({
      ...cardLoading,
      [card.id]: false,
    });

    const errors = results.flatMap((result, index: number): string | string[] =>
      result.status === 'rejected'
        ? getErrorMessage(result.reason) ||
          t('THERE_WAS_ERROR', { ns: 'common' })
        : [],
    );

    if (errors.length > 0) {
      alert.error(errors.join('\n'));
    } else {
      alert.success(t('CARD_UPDATED', { CARD: card.label }));
    }
  };

  useEffect(() => {
    initPage();
  }, [context.env, context.companyId, context.contactId, context.loginId]);

  return (
    <InfoTabContext.Provider value={{ dataSources }}>
      {cards.map((card, idx) => (
        <Box
          marginBottom="lg"
          key={idx}
          data-qa={`card-${card.component.name}`}
        >
          {(isLoading || cardLoading[card.component.name]) && (
            <Flex justifyContent="center">
              <Spinner loading={isLoading} />
            </Flex>
          )}

          {isInit && !isLoading && !cardLoading[card.component.name] && (
            <Card>
              <Box padding="lg">
                {card.component.name !== 'ErpIntegrations' &&
                  card.component.name !== 'ErpPermissions' &&
                  React.createElement(card.component, {
                    key: `card-${card.id}`,
                    id: card.id,
                    save: saveChanges,
                  })}
                {card.component.name === 'ErpPermissions' &&
                  React.createElement(card.component, {
                    key: `card-${card.id}`,
                    id: card.id,
                    save: saveChanges,
                    error: erpPermissionsError,
                  })}
                {card.component.name === 'ErpIntegrations' &&
                  React.createElement(card.component, {
                    key: `card-${card.id}`,
                    id: card.id,
                    save: saveChanges,
                    error: erpIntegratedError,
                  })}
              </Box>
            </Card>
          )}
        </Box>
      ))}
      {(!isInit || isLoading || !contact) && <Spinner />}
      {isInit && !isLoading && contact && (
        <ErpSettingsCard
          email={contact?.data?.email ?? ''}
          canEdit={false}
          erpSettings={contact.data}
          saveErpSettings={saveErpSettings}
          isCollapsible={true}
        />
      )}
    </InfoTabContext.Provider>
  );
};
export default InfoTab;
