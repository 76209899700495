import React, { useState, useEffect, useContext, useCallback } from 'react';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { useTranslation } from 'react-i18next';
import { getApi } from 'utils/api';
import { ProjectsApi } from '@procore/ipa-nt-api-client-ts';
import type { CustomFormsDto } from '@procore/ipa-nt-api-client-ts';
import {
  Card,
  Spinner,
  Box,
  FlexList,
  EmptyState,
  Grid,
  Panel,
} from '@procore/core-react';
import { ContactContext } from 'contexts/ContactContext';
import { ipaAppStyles } from 'styles';

const CustomFormsPage = () => {
  const { t } = useTranslation('contact', {
    keyPrefix: 'CUSTOM_FORMS',
  });
  const context = useContext(ContactContext);
  const alert = useContext(IpaAlertContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isInit, setIsInit] = useState<boolean>(false);
  const [customForms, setCustomForms] = useState<CustomFormsDto>({});

  const getProjectCustomForms = useCallback(async () => {
    try {
      setIsLoading(true);
      const api = getApi(ProjectsApi);
      const result = await api.projectControllerGetProjectCustomFormsV10(
        context.env,
        context.companyId,
        context.projectId,
      );
      setCustomForms(result.data);
    } catch (e: any) {
      alert.error(e);
    } finally {
      setIsInit(true);
      setIsLoading(false);
    }
  }, [context]);

  useEffect(() => {
    if (!isInit) {
      getProjectCustomForms();
    }
  }, [getProjectCustomForms, isInit]);

  return (
    <Card>
      <Box padding="lg">
        {isLoading && (
          <Box>
            <FlexList justifyContent="center">
              <Spinner loading={isLoading} />
            </FlexList>
          </Box>
        )}
        {!isLoading &&
          isInit &&
          customForms.custom_form_sections.length === 0 && (
            <EmptyState>
              <EmptyState.NoResults />
              <EmptyState.Title>{t('NO_CUSTOM_FORMS')}</EmptyState.Title>
            </EmptyState>
          )}
        {!isLoading &&
          isInit &&
          customForms.custom_form_sections.length > 0 && (
            <Box data-qa="project-custom-forms">
              {customForms.custom_form_sections.map((section, i) => (
                <Panel key={i} data-qa="custom-form-section">
                  <Panel.Body>
                    <Panel.Section heading={section.name}>
                      <Grid>
                        <Grid.Row>
                          {section.fields.map((field, j) => (
                            <Grid.Col key={j} colWidth={6}>
                              <div style={ipaAppStyles.ipaCustomField}>
                                {`
                                ${field.field_title} [${field.field_type}]
                              `}
                              </div>
                            </Grid.Col>
                          ))}
                        </Grid.Row>
                      </Grid>
                    </Panel.Section>
                  </Panel.Body>
                </Panel>
              ))}
            </Box>
          )}
      </Box>
    </Card>
  );
};

export default CustomFormsPage;
