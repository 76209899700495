import type { SectionConfig } from 'pages/page-config.interfaces';

export default {
  pages: {},
  id: 'ContactUpdateLogs',
  route: {
    path: '/v2/contact-update-logs',
  },
  i18n: {
    namespace: 'contact-update-logs',
  },
  permissions: {
    view: ['company_tab_view', 'cs_view'],
  },
} as SectionConfig;
