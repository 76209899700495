import React, { useCallback, useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Flex,
  Spinner,
  Box,
  Title,
  H2,
  EmptyState,
} from '@procore/core-react';
import { getApi } from 'utils/api';
import { CompaniesApi } from '@procore/ipa-nt-api-client-ts';
import type { CompanySubmittalsDto } from '@procore/ipa-nt-api-client-ts';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { ProjectManagementContext } from '..';
import { ipaAppStyles } from 'styles';
import TableComponent from 'components/Table';
import type { TableHeader } from 'components/Table/table.interface';

const SubmittalsPage = () => {
  const { t } = useTranslation(['project-management']);
  const { t: tCommon } = useTranslation(['common']);
  const { env, companyId } = useContext(ProjectManagementContext);
  const alert = useContext(IpaAlertContext);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(true);
  const [companySubmittials, setCompanySubmittals] =
    useState<CompanySubmittalsDto>({} as CompanySubmittalsDto);

  const submittalTypeTableHeaders: TableHeader[] = [
    {
      title: t('CUSTOM_SUBMITTAL_TYPES.NAME'),
      node: 'name',
      is_sortable: true,
      is_text: true,
      column_width: 50,
    },
    {
      title: t('CUSTOM_SUBMITTAL_TYPES.ACTIVE'),
      node: 'active',
      is_sortable: false,
      is_boolean: true,
      column_width: 25,
    },
    {
      title: t('CUSTOM_SUBMITTAL_TYPES.GLOBAL'),
      node: 'global',
      is_sortable: false,
      is_boolean: true,
      column_width: 25,
    },
  ];

  const submittalStatusTableHeaders: TableHeader[] = [
    {
      title: t('CUSTOM_SUBMITTAL_LOG_STATUSES.NAME'),
      node: 'name',
      is_sortable: true,
      is_text: true,
      column_width: 50,
    },
    {
      title: t('CUSTOM_SUBMITTAL_LOG_STATUSES.GLOBAL'),
      node: 'global',
      is_sortable: false,
      is_boolean: true,
      column_width: 50,
    },
  ];

  const getCompanySubmittals = useCallback(async () => {
    setIsInit(false);
    setLoading(true);

    try {
      const api = getApi(CompaniesApi);
      const result = await api.companyControllerGetCompanySubmittalsV10(
        env,
        companyId,
      );
      setCompanySubmittals(result.data);
    } catch (e: any) {
      alert.error(e);
    } finally {
      setLoading(false);
      setIsInit(true);
    }
  }, [companyId, env]);

  useEffect(() => {
    getCompanySubmittals();
  }, [getCompanySubmittals]);

  return (
    <React.Fragment>
      <Card>
        <Box padding="lg">
          <Title style={ipaAppStyles.titleMargin}>
            <Title.Text>
              <H2>{t('CUSTOM_SUBMITTAL_TYPES.TITLE')}</H2>
            </Title.Text>
          </Title>
          <div>
            {(!isInit || isLoading) && (
              <Box marginBottom="lg">
                <Card>
                  <Box padding="lg">
                    {!isInit && !isLoading && (
                      <EmptyState>
                        <EmptyState.NoItems />
                        <EmptyState.Title>
                          {tCommon('NO_DATA')}
                        </EmptyState.Title>
                      </EmptyState>
                    )}
                    {isLoading && (
                      <Flex justifyContent="center">
                        <Spinner loading={isLoading} />
                      </Flex>
                    )}
                  </Box>
                </Card>
              </Box>
            )}
            {isInit && !isLoading && (
              <TableComponent
                data_qa="pm-project-stages-table"
                data={companySubmittials?.submittal_log_types}
                headers={submittalTypeTableHeaders}
                default_sort_column="name"
                show_no_data_message={true}
                no_data_message={tCommon('NO_DATA')}
                fixed_table={true}
                default_sort_direction={'asc'}
              />
            )}
          </div>
        </Box>
      </Card>
      <Box marginTop="lg">
        <Card>
          <Box padding="lg">
            <Title style={ipaAppStyles.titleMargin}>
              <Title.Text>
                <H2>{t('CUSTOM_SUBMITTAL_LOG_STATUSES.TITLE')}</H2>
              </Title.Text>
            </Title>
            <div>
              {(!isInit || isLoading) && (
                <Box marginBottom="lg">
                  <Card>
                    <Box padding="lg">
                      {!isInit && !isLoading && (
                        <EmptyState>
                          <EmptyState.NoItems />
                          <EmptyState.Title>
                            {tCommon('NO_DATA')}
                          </EmptyState.Title>
                        </EmptyState>
                      )}
                      {isLoading && (
                        <Flex justifyContent="center">
                          <Spinner loading={isLoading} />
                        </Flex>
                      )}
                    </Box>
                  </Card>
                </Box>
              )}
              {isInit && !isLoading && (
                <TableComponent
                  data_qa="pm-project-stages-table"
                  data={companySubmittials?.submittal_log_statuses}
                  headers={submittalStatusTableHeaders}
                  default_sort_column="name"
                  show_no_data_message={true}
                  no_data_message={tCommon('NO_DATA')}
                  fixed_table={true}
                  default_sort_direction={'asc'}
                />
              )}
            </div>
          </Box>
        </Card>
      </Box>
    </React.Fragment>
  );
};

export default SubmittalsPage;
