import type { SectionConfig } from 'pages/page-config.interfaces';

const correspondenceTypesSectionConfig: SectionConfig = {
  pages: {},
  id: 'CorrespondenceTypes',
  route: {
    path: '/v2/company/:company_id/correspondence-types',
  },
  i18n: {
    namespace: 'correspondence-types',
  },
  permissions: {
    view: ['company_tab_view', 'cs_view'],
    edit: ['cs_edit'],
  },
};

export default correspondenceTypesSectionConfig;
