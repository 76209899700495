import React, { useState, useCallback, useContext } from 'react';
import {
  Box,
  Button,
  Card,
  EmptyState,
  Flex,
  FlexList,
  Page,
  Spinner,
  ToolHeader,
} from '@procore/core-react';
import { useTranslation } from 'react-i18next';
import { getApi } from 'utils/api';
import { SuperUserApi } from '@procore/ipa-nt-api-client-ts';
import type { ProcoreSuDto, SuSessionDto } from '@procore/ipa-nt-api-client-ts';
import type { TableHeader } from 'components/Table/table.interface';
import { PageConfig } from 'pages/page-config.service';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { useNavigate } from 'react-router-dom';
import TableComponent from 'components/Table';
import SuSyncReportForm from './SuSyncReportForm';
import { TableCellButtons } from 'styles';

interface IProcoreSuUser extends ProcoreSuDto {
  siteList: string;
}

export const SuSyncPage = () => {
  const { t } = useTranslation(['su-sync', 'common']);
  const pageConfig = PageConfig.getPage(t, 'Admin', 'SuSync');
  const alert = useContext(IpaAlertContext);
  const navigate = useNavigate();
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [env, setEnv] = useState<string>('PROD');
  const [procoreSus, setProcoreSus] = useState<IProcoreSuUser[]>([]);
  const [ipaSus, setIpaSus] = useState<SuSessionDto[]>([]);
  const [reportDateTime, setReportDateTime] = useState<string>('');

  const procoreSuTableHeaders: TableHeader[] = [
    {
      title: pageConfig.translate('EMAIL'),
      node: 'email',
      is_sortable: true,
      is_text: true,
      column_width: 30,
    },
    {
      title: pageConfig.translate('SUPER_USER_ON'),
      node: 'siteList',
      is_sortable: true,
      is_text: true,
      column_width: 30,
    },
    {
      title: pageConfig.translate('LAST_PROMOTED'),
      node: 'lastPromoted',
      is_sortable: true,
      is_datetime: true,
      column_width: 20,
    },
    {
      title: t('ACTION', { ns: 'common' }),
      node: '',
      is_sortable: false,
      column_width: 20,
      display_func: (item: ProcoreSuDto) => {
        return (
          <TableCellButtons>
            <Button
              variant="secondary"
              data-qa="demote-btn"
              onClick={() => demoteUser(item)}
            >
              {t('DEMOTE_USER')}
            </Button>
            <Button
              variant="secondary"
              data-qa="history-btn"
              onClick={() => navigate(`/v2/events/users?email=${item.email}`)}
            >
              {t('HISTORY_BTN')}
            </Button>
          </TableCellButtons>
        );
      },
    },
  ];

  const ipaSuTableHeaders: TableHeader[] = [
    {
      title: pageConfig.translate('EMAIL'),
      node: 'email',
      is_sortable: true,
      is_text: true,
      column_width: 50,
    },
    {
      title: pageConfig.translate('USER_UUID'),
      node: 'uuid',
      is_sortable: true,
      is_text: true,
      column_width: 50,
    },
    {
      title: t('ACTION', { ns: 'common' }),
      node: '',
      is_sortable: false,
      column_width: 20,
      display_func: (item: SuSessionDto) => {
        return (
          <TableCellButtons>
            <Button
              variant="secondary"
              data-qa="demote-btn"
              onClick={() => demoteSessionUser(item)}
            >
              {t('DEMOTE_USER')}
            </Button>
            <Button
              variant="secondary"
              data-qa="history-btn"
              onClick={() => navigate(`/v2/events/users?email=${item.email}`)}
            >
              {t('HISTORY_BTN')}
            </Button>
          </TableCellButtons>
        );
      },
    },
  ];

  const demoteUser = useCallback(async (item: ProcoreSuDto) => {
    alert.closeAlert();
    try {
      await getApi(SuperUserApi).superUserControllerDemoteV10({
        email: item.email,
        env: item.env,
      });
      await getReport(item.env);
      alert.success(t('DEMOTE_USER_SUCCESS', { email: item.email }));
    } catch (error: any) {
      alert.error(error);
    }
  }, []);

  const demoteSessionUser = useCallback(async (item: SuSessionDto) => {
    alert.closeAlert();
    try {
      await getApi(SuperUserApi).superUserControllerDemoteV10({
        email: item.email,
        env: env,
      });
      await getReport(env);
      alert.success(t('DEMOTE_USER_SUCCESS', { email: item.email }));
    } catch (error: any) {
      alert.error(error);
    }
  }, []);

  const getReport = useCallback(async (env: string) => {
    setProcoreSus(null);
    setIpaSus(null);
    setLoading(true);

    setEnv(env);
    try {
      const result = await getApi(
        SuperUserApi,
      ).superUserControllerGetSyncReportV10(env);
      const procoreUsers: IProcoreSuUser[] = result.data.procoreOnlySus.map(
        (item) => {
          return {
            ...item,
            siteList: item.superUserOn.join(', '),
          };
        },
      );
      console.log(result.data.ipaOnlySus);
      setProcoreSus(procoreUsers);
      setIpaSus(result.data.ipaOnlySus);
      setReportDateTime(result.data.reportDateTime);

      setIsInitialized(true);
    } catch (error: any) {
      setIsInitialized(false);
      alert.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <Page>
      <Page.Main style={{ flex: 'auto' }}>
        <Page.Header>
          <ToolHeader>
            <ToolHeader.Title data-qa={'jit-sync-title'}>
              {t('TITLE')}
            </ToolHeader.Title>
          </ToolHeader>
        </Page.Header>
        <Page.Body>
          <Card>
            <Box padding={'lg'} marginBottom="lg">
              <Card>
                <FlexList>
                  <SuSyncReportForm getReport={getReport} />
                </FlexList>
              </Card>
            </Box>
          </Card>
          <Card>
            <Box padding="lg">
              {!isInitialized && !loading && (
                <EmptyState>
                  <EmptyState.NoItems />
                  <EmptyState.Title>
                    {t('SU_SYNC_REPORT_EMPTY_STATE')}
                  </EmptyState.Title>
                </EmptyState>
              )}
              {loading && (
                <Flex justifyContent="center">
                  <Spinner loading={loading} />
                </Flex>
              )}
              {isInitialized && !loading && procoreSus && (
                <React.Fragment>
                  <h3>
                    {t('PROCORE_ONLY_SU_TITLE', {
                      date: reportDateTime,
                      env: env,
                    })}
                  </h3>
                  <TableComponent
                    headers={procoreSuTableHeaders}
                    data={procoreSus}
                    default_sort_column="email"
                    default_sort_direction="asc"
                    show_no_data_message={true}
                    show_page_size_select={true}
                    show_search={true}
                    search_placeholder={t('SEARCH_PROCORE_USER_PLACEHODER')}
                    search_columns={['email']}
                    fixed_table={true}
                  />
                  <h3>
                    {t('IPA_ONLY_SU_TITLE', { date: reportDateTime, env: env })}
                  </h3>
                  <TableComponent
                    headers={ipaSuTableHeaders}
                    data={ipaSus}
                    default_sort_column="email"
                    default_sort_direction="asc"
                    show_no_data_message={true}
                    show_page_size_select={true}
                    fixed_table={true}
                  />
                </React.Fragment>
              )}
            </Box>
          </Card>
        </Page.Body>
      </Page.Main>
    </Page>
  );
};

export default SuSyncPage;
