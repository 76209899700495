import React, { useContext, useEffect, useState } from 'react';
import {
  Page,
  Card,
  Box,
  Flex,
  Spinner,
  Title,
  H1,
  Button,
} from '@procore/core-react';
import { useTranslation } from 'react-i18next';
import { getApi } from 'utils/api';
import { PageConfig } from 'pages/page-config.service';
import type { PageConfigTranslated } from 'pages/page-config.interfaces';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { RequestLoggingApi } from '@procore/ipa-nt-api-client-ts';
import { ApiRequestsSearchForm } from './ApiRequestsSearchForm';
import { ApiRequestsList } from './ApiRequestsList';
import { useSearchParams } from 'react-router-dom';
import { RotateClockwise } from '@procore/core-icons';

export interface ApiRequestsSearchOptions {
  path?: string;
  apiName?: string;
  durationMin?: number;
  responseCodeClass?: string;
  pastMinutes?: number;
  timedOut?: boolean;
}

// let refreshTimer: NodeJS.Timeout;

export const ApiRequestsPage = () => {
  const { t: tCommon } = useTranslation(['common']);
  const { t } = useTranslation(['api-requests']);
  const pageConfig = PageConfig.get(
    t,
    'Admin.ApiRequests',
  ) as PageConfigTranslated;
  const alert = useContext(IpaAlertContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const [requestsList, setRequestsList] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(25);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isReloading, setIsReloading] = useState<boolean>(false);

  const searchOptions: ApiRequestsSearchOptions = {
    path: searchParams.get('path') ?? undefined,
    apiName: searchParams.get('apiName') ?? undefined,
    durationMin: searchParams.get('durationMin')
      ? parseInt(searchParams.get('durationMin'))
      : undefined,
    responseCodeClass: searchParams.get('responseCodeClass') ?? undefined,
    pastMinutes: searchParams.get('pastMinutes')
      ? parseInt(searchParams.get('pastMinutes'))
      : undefined,
    timedOut: searchParams.get('timedOut') === 'true' ? true : undefined,
  };

  const listRequests = async (refresh: boolean = false) => {
    if (refresh) {
      setIsReloading(true);
    } else {
      setRequestsList([]);
      setIsLoading(true);
      alert.closeAlert();
    }

    try {
      const api = getApi(RequestLoggingApi);
      const res = await api.requestLoggingControllerListV10({
        limit: 100,
        ...searchOptions,
      });

      if (res.data) {
        setRequestsList(res.data.results);
        setTotal(res.data.count);
      }
    } catch (e: any) {
      alert.error(e);
    } finally {
      setIsLoading(false);
      setIsReloading(false);
    }
  };

  const formSubmit = async (values: ApiRequestsSearchOptions) => {
    setSearchParams((prev) => {
      for (const [k, v] of Object.entries(values)) {
        if (v !== undefined && v !== null) {
          if (k === 'responseCodeClass') {
            prev.set(k, v.id);
          } else {
            prev.set(k, v);
          }
        } else {
          prev.delete(k);
        }
      }
      return prev;
    });
  };

  const refresh = () => {
    listRequests(true);
  };

  useEffect(() => {
    listRequests();
  }, [searchParams]);

  // useEffect(() => {
  //   if (!refreshTimer) {
  //     refreshTimer = setInterval(() => {
  //       listRequests(true);
  //     }, 5000);
  //   }
  // }, []);

  return (
    <Page>
      <Page.Main style={{ flex: 'auto' }}>
        <Page.Header>
          <Title>
            <Title.Text data-qa={'api-requests-header'}>
              <H1>{pageConfig.translate('TITLE')}</H1>
            </Title.Text>
          </Title>
        </Page.Header>
        <Page.Body>
          <Card>
            <Box padding="lg">
              <ApiRequestsSearchForm
                isLoading={isLoading}
                submit={formSubmit}
                pageConfig={pageConfig}
                searchOptions={searchOptions}
              />
            </Box>
          </Card>
          <Box padding="lg">
            {isLoading && (
              <Flex justifyContent="center">
                <Spinner loading={isLoading} />
              </Flex>
            )}
          </Box>
          <div style={{ textAlign: 'right', marginBottom: '1rem' }}>
            <Button onClick={refresh} loading={isReloading}>
              <RotateClockwise size="sm" />
              Refresh
            </Button>
          </div>

          <ApiRequestsList data={requestsList} />
        </Page.Body>
      </Page.Main>
    </Page>
  );
};
