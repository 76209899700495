import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Page, Card, Box, Flex, Spinner, Title, H1 } from '@procore/core-react';
import { useTranslation } from 'react-i18next';
import AuditLogsForm from './AuditLogsForm';
import type { AuditLogsSearchProps } from './AuditLogsForm';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { getApi } from 'utils/api';
import { AuditLogsApi } from '@procore/ipa-nt-api-client-ts';
import TableBackendComponent from 'components/TableBackend';
import type { TableHeader } from 'components/TableBackend/table.interface';
import { PageConfig } from '../page-config.service';
import type { PageConfigTranslated } from '../page-config.interfaces';

export const AuditLogsPage = () => {
  const { t: tCommon } = useTranslation(['common']);
  const { t } = useTranslation(['audit-logs']);
  const pageConfig = PageConfig.get(
    t,
    'Admin.AuditLogs',
  ) as PageConfigTranslated;
  const alert = useContext(IpaAlertContext);
  const [logData, setLogData] = useState<any[]>([]);
  const [eventCategory, setEventCategory] = useState<string>();
  const [hideViewEvents, setHideViewEvents] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [companyId, setCompanyId] = useState<string>('');
  const [dateRange, setDateRange] = useState<number>(7);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(25);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const tableHeaders: TableHeader[] = [
    {
      title: pageConfig.translate('Date'),
      node: 'inserted_at',
      is_datetime: true,
      is_sortable: false,
      column_width: 10,
    },
    {
      title: pageConfig.translate('EVENT_TYPE_LABEL'),
      node: 'event_type',
      is_text: true,
      is_sortable: false,
      column_width: 10,
    },
    {
      title: pageConfig.translate('USER_LABEL'),
      node: 'user_id',
      is_text: true,
      is_sortable: false,
      column_width: 10,
    },
    {
      title: pageConfig.translate('COMPANY_ID_LABEL'),
      node: 'company_id',
      is_text: true,
      is_sortable: false,
      column_width: 10,
    },
    {
      title: pageConfig.translate('RESOURCE_NAME_LABEL'),
      node: 'resource_name',
      is_text: true,
      is_sortable: false,
      column_width: 10,
    },
    {
      title: pageConfig.translate('RESOURCE_ID_LABEL'),
      node: 'resource_id',
      is_text: true,
      is_sortable: false,
      column_width: 10,
    },
    {
      title: pageConfig.translate('EVENT_PROPS_LABEL'),
      node: 'event_props',
      is_text: true,
      is_sortable: false,
      column_width: 40,
    },
  ];

  const changePage = async (_page: number) => {
    setPage(_page);
  };

  const changePageSize = async (_pageSize: number) => {
    setPageSize(_pageSize);
    setPage(1);
  };

  const searchLogs = useCallback(async () => {
    setLogData([]);
    setIsLoading(true);
    alert.closeAlert();
    try {
      const toDate = new Date().getTime();
      const fromDate = new Date().setDate(new Date().getDate() - dateRange);
      const api = getApi(AuditLogsApi);

      const res = await api.auditLogControllerListV10('PROD', {
        companyId: companyId ? Number(companyId) : undefined,
        category: eventCategory as any,
        hideViewEvents,
        page,
        pageSize,
        sortDirection: 'desc',
        fromDate,
        toDate,
        userId: email ? email : undefined,
      });

      if (res.data) {
        setLogData(res.data.items);
        setTotal(res.data.total);
      }
    } catch (e: any) {
      alert.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [
    page,
    pageSize,
    hideViewEvents,
    eventCategory,
    email,
    dateRange,
    companyId,
  ]);

  const formSubmit = async (values: AuditLogsSearchProps) => {
    setDateRange(values.dateRange);
    setEventCategory(values.eventCategory);
    setEmail(values.email);
    setCompanyId(values.companyId);
    setPage(1);
    setHideViewEvents(values.hideViewEvents);
  };

  useEffect(() => {
    if (eventCategory && dateRange) {
      searchLogs();
    }
  }, [
    page,
    pageSize,
    hideViewEvents,
    eventCategory,
    email,
    dateRange,
    companyId,
  ]);

  return (
    <Page>
      <Page.Main style={{ flex: 'auto' }}>
        <Page.Header>
          <Title>
            <Title.Text data-qa={'audit-logs-header'}>
              <H1>{pageConfig.translate('TITLE')}</H1>
            </Title.Text>
          </Title>
        </Page.Header>
        <Page.Body>
          <Card>
            <Box padding="lg">
              <AuditLogsForm submit={formSubmit} pageConfig={pageConfig} />
            </Box>
          </Card>
          <Card>
            <Box padding="lg">
              {isLoading && (
                <Flex justifyContent="center">
                  <Spinner loading={isLoading} />
                </Flex>
              )}
              <TableBackendComponent
                data={logData}
                data_total={total}
                current_page={page}
                show_page_size_select={true}
                paginator={changePage}
                page_size={pageSize}
                page_size_action={changePageSize}
                default_sort_column="name"
                default_sort_direction="asc"
                headers={tableHeaders}
                show_no_data_message={true}
                no_data_message={tCommon('NO_DATA')}
                sorter={() => {}}
                fixed_table={true}
              />
            </Box>
          </Card>
        </Page.Body>
      </Page.Main>
    </Page>
  );
};

export default AuditLogsPage;
