import styled from 'styled-components';
import { colors, spacing, FlexList } from '@procore/core-react';

export const styles = {
  popover: {
    minWidth: '350px',
  },
};

export const QuickHistoryWrapper = styled.div`
  padding: ${spacing.sm}px;

  ul {
    padding: 0;
    list-style-type: none;

    > li {
      padding: ${spacing.xs}px;
      border: none;
      margin-bottom: ${spacing.xs}px;
      border-radius: ${spacing.xs}px;
      font-size: 14px;
    }
  }
`;

export const SquareIcon = styled.div`
  width: ${spacing.md}px;
  height: ${spacing.md}px;
  display: inline-block;
  position: relative;
  top: ${spacing.xxs}px;
  margin-right: ${spacing.xs}px;
  background-color: ${colors.black};

  &.company {
    background-color: ${colors.blue50};
  }

  &.project {
    background-color: ${colors.yellow50};
  }

  &.contact {
    background-color: ${colors.green50};
  }

  &.legend {
    margin-right: 0px;
  }
`;

export const Legend = styled(FlexList)`
  padding: ${spacing.xs}px;
  position: relative;

  &:before {
    content: '';
    height: 1px;
    position: absolute;
    left: ${spacing.xs}px;
    right: ${spacing.xs}px;
    top: 0;
    border-top: 1px solid ${colors.gray70};
  }
`;
