import { IpaAlertContext } from 'contexts/IpaAlertContext';
import React, { useContext, useState, useCallback, useEffect } from 'react';
import { EditableCard } from 'components/EditableCard';
import type { IEditableCard } from 'components/EditableCard';
import { getApi } from 'utils/api';
import { ProjectContext } from 'contexts/ProjectContext';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Flex,
  Spinner,
  Box,
  EmptyState,
  Form,
} from '@procore/core-react';
import { ProjectsApi } from '@procore/ipa-nt-api-client-ts';
import type {
  ProjectCommitmentSettingsDto,
  ProjectCommitmentSettingsUpdateDto,
} from '@procore/ipa-nt-api-client-ts';

const CommitmentSettingsPage = () => {
  const context = useContext(ProjectContext);
  const alert = useContext(IpaAlertContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(true);
  const [commitmentSettings, setCommitmentSettings] =
    useState<ProjectCommitmentSettingsDto>();

  const config = context.pageConfig.card('CommitmentSettings');
  const t = config.translate;
  const [cardLoading, setCardLoading] = useState<{ [key: string]: boolean }>(
    {},
  );
  const { t: tCommon } = useTranslation(['common']);
  const getCommitmentSettings = useCallback(async () => {
    setIsInit(false);
    setIsLoading(true);

    try {
      const api = getApi(ProjectsApi);
      const result = await api.projectControllerGetProjectCommitmentSettingsV10(
        context.env,
        context.companyId,
        context.projectId,
      );
      setCommitmentSettings(result.data);
    } catch (e: any) {
      alert.error(e);
    } finally {
      setIsLoading(false);
      setIsInit(true);
    }
  }, [context.projectId, context.companyId, context.env]);

  useEffect(() => {
    getCommitmentSettings();
  }, [getCommitmentSettings]);

  const updateCommitmentSettingsRequest = async (
    values: any,
    reason?: string,
  ) => {
    const api = getApi(ProjectsApi);
    return api.projectControllerUpdateProjectCommitmentSettingsV10(
      context.env,
      context.companyId,
      context.projectId,
      {
        reason: reason ?? '',
        update:
          values?.commitmentSettings as ProjectCommitmentSettingsUpdateDto,
      },
    );
  };

  const saveChanges = async (
    card: IEditableCard,
    values: any,
    reason?: string,
  ) => {
    alert.closeAlert();
    setCardLoading({
      ...cardLoading,
      [card.id]: true,
    });

    try {
      const res = await updateCommitmentSettingsRequest(values, reason);
      if (res.data) {
        setCommitmentSettings(res.data);
      }
      alert.success(t('CARD_UPDATED'));
    } catch (e: any) {
      alert.error(e);
    } finally {
      setCardLoading({
        ...cardLoading,
        [card.id]: false,
      });
    }
  };

  const sourceId = 'commitmentSettings';
  const card: IEditableCard = {
    id: 'commitment-settings',
    i18n: t,
    label: config.label,
    canEdit: context.hasPermission(config.permissions.edit),
    save: saveChanges,
    fields: [
      {
        source: sourceId,
        value: commitmentSettings?.change_order_statuses_cascade,
        name: 'change_order_statuses_cascade',
        dataType: 'text',
        editable: true,
        element: Form.Checkbox,
        ...config.fields.change_order_statuses_cascade,
      },
      {
        source: sourceId,
        value: commitmentSettings?.enable_field_orders,
        name: 'enable_field_orders',
        dataType: 'text',
        editable: true,
        element: Form.Checkbox,
        ...config.fields.enable_field_orders,
      },
      {
        source: sourceId,
        value: commitmentSettings?.enable_rfq,
        name: 'enable_rfq',
        dataType: 'text',
        editable: true,
        element: Form.Checkbox,
        ...config.fields.enable_rfq,
      },
      {
        source: sourceId,
        value: commitmentSettings?.rfq_overdue_emails_enabled,
        name: 'rfq_overdue_emails_enabled',
        dataType: 'text',
        editable: true,
        element: Form.Checkbox,
        ...config.fields.rfq_overdue_emails_enabled,
      },
      {
        source: sourceId,
        value: `${commitmentSettings?.rfq_due_days_default} working day(s) by default`,
        name: 'rfq_due_days_default',
        dataType: 'text',
        editable: false,
        element: Form.Text,
        ...config.fields.rfq_due_days_default,
      },
      {
        source: sourceId,
        value: commitmentSettings?.number_of_commitment_change_order_tiers,
        name: 'number_of_commitment_change_order_tiers',
        dataType: 'number',
        editable: true,
        element: Form.Text,
        ...config.fields.number_of_commitment_change_order_tiers,
      },
    ],
  };

  return (
    <React.Fragment>
      <Card>
        <Box padding="lg">
          {(!isInit || isLoading) && (
            <Box marginBottom="lg">
              <Card>
                <Box padding="lg">
                  {!isInit && !isLoading && (
                    <EmptyState>
                      <EmptyState.NoItems />
                      <EmptyState.Title>{tCommon('NO_DATA')}</EmptyState.Title>
                    </EmptyState>
                  )}
                  {isLoading && (
                    <Flex justifyContent="center">
                      <Spinner loading={isLoading} />
                    </Flex>
                  )}
                </Box>
              </Card>
            </Box>
          )}
          {isInit && !isLoading && commitmentSettings && (
            <EditableCard card={card} />
          )}
        </Box>
      </Card>
    </React.Fragment>
  );
};

export default CommitmentSettingsPage;
