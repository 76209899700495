import { Box, Flex } from '@procore/core-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CompanyPills from '../CompanyPills';
import EnvironmentSwitcher from '../EnvironmentSwitcher';
import type { CompanyAccountStatusDto } from '@procore/ipa-nt-api-client-ts';
import CompanyLink from '../CompanyLink';
import ContextInfoPopup from 'components/ContextInfoPopup';

interface CompanyHeaderProps {
  env: string;
  companyStatus: CompanyAccountStatusDto;
  onChangeEnv: (value: string) => void;
}

export const CompanyHeader = (props: CompanyHeaderProps) => {
  const { t } = useTranslation(['common']);

  const companyStatus = props.companyStatus;

  return (
    <Box paddingBottom="lg">
      {companyStatus && (
        <Flex>
          <Box data-qa="company-name-div" marginRight="xl">
            <Flex>
              <CompanyLink
                companyId={companyStatus.id}
                companyName={companyStatus.name}
                env={props.env}
              />
              <ContextInfoPopup />
            </Flex>
            <CompanyPills companyId={companyStatus.id} env={props.env} />
          </Box>
          <Box marginLeft="auto">
            <EnvironmentSwitcher
              initialValue={props.env}
              onChange={props.onChangeEnv}
            />
          </Box>
        </Flex>
      )}
    </Box>
  );
};

export default CompanyHeader;
