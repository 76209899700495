import React from 'react';
import { Button, Form } from '@procore/core-react';
import type { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  FormButtonWrapper,
  FormWrapper,
  StyledFormCheckbox,
  StyledFormSelect,
  StyledFormText,
} from 'styles';
import type { ApiRequestsSearchOptions } from '.';

interface ApiRequestsSearchFormProps {
  submit: (values: object) => void;
  isLoading: boolean;
  pageConfig: any;
  searchOptions: ApiRequestsSearchOptions;
}

const responseCodeClassOptions = [
  {
    id: '2xx',
    label: '2xx',
  },
  {
    id: '3xx',
    label: '3xx',
  },
  {
    id: '4xx',
    label: '4xx',
  },
  {
    id: '5xx',
    label: '5xx',
  },
];

export const ApiRequestsSearchForm = (props: ApiRequestsSearchFormProps) => {
  const { t } = useTranslation('api-requests');

  return (
    <FormWrapper>
      <Form
        view="create"
        enableReinitialize={true}
        initialValues={{
          ...props.searchOptions,
          responseCodeClass: props.searchOptions.responseCodeClass
            ? {
                id: props.searchOptions.responseCodeClass,
                label: props.searchOptions.responseCodeClass,
              }
            : undefined,
        }}
        onSubmit={(values) => props.submit(values)}
      >
        {(formProps: FormikProps<any>) => {
          const { dirty, values, handleSubmit } = formProps;
          return (
            <Form.Form
              data-qa="api-requests-search-form"
              onKeyDown={(e) => {
                if (e.key === 'Enter' && dirty) {
                  handleSubmit();
                }
              }}
            >
              <Form.Row>
                <StyledFormText
                  name="path"
                  label={t('SEARCH.PATH')}
                  data-qa="search-path"
                  type="text"
                  placeholder="/api/endpoint"
                  colStart={1}
                  colWidth={3}
                />
                <StyledFormText
                  name="apiName"
                  label={t('SEARCH.API_NAME')}
                  data-qa="search-apiName"
                  type="text"
                  placeholder=""
                  colStart={4}
                  colWidth={3}
                />
                <StyledFormSelect
                  name="responseCodeClass"
                  label={t('SEARCH.RESPONSE_CODE_CLASS')}
                  data-qa="search-response-code"
                  options={responseCodeClassOptions}
                  colStart={7}
                  colWidth={2}
                />

                <StyledFormText
                  name="durationMin"
                  label={t('SEARCH.DURATION_MIN')}
                  data-qa="search-duration-min"
                  type="number"
                  placeholder="ms"
                  colStart={9}
                  colWidth={1}
                />
                <StyledFormText
                  name="pastMinutes"
                  label={t('SEARCH.PAST_MINUTES')}
                  data-qa="search-past-minutes"
                  type="number"
                  placeholder="minutes"
                  defaultValue={15}
                  colStart={10}
                  colWidth={1}
                />
                <StyledFormCheckbox
                  name="timedOut"
                  label={t('SEARCH.TIMED_OUT')}
                  data-qa="search-timed-out"
                  type="checkbox"
                  placeholder="0"
                  colStart={11}
                  colWidth={1}
                />

                <FormButtonWrapper>
                  <Button
                    type="submit"
                    children={t('SEARCH.SUBMIT')}
                    data-qa="api-requests-search-button"
                    disabled={!dirty || values.email === ''}
                    loading={props.isLoading}
                  ></Button>
                </FormButtonWrapper>
              </Form.Row>
            </Form.Form>
          );
        }}
      </Form>
    </FormWrapper>
  );
};
