import type {
  BaseConfig,
  BaseConfigWithRequired,
  CardConfig,
  CardConfigTranslated,
  CardConfigWithRequired,
  PageConfigTranslated,
  PageConfigWithRequired,
  SectionConfig,
  SectionConfigTranslated,
  SectionConfigWithRequired,
} from './page-config.interfaces';
import type { TFunction } from 'i18next';
import _ from 'lodash';

import Admin from './admin.section.config';
import Company from './Company/section.config';
import CompanyDirectory from './CompanyDirectory/section.config';
import Contact from './Contact/section.config';
import DevPortal from './DevPortal/section.config';
import EmailManagement from './EmailManagement/section.config';
import ErpDeactivations from './ErpDeactivations/section.config';
import ErpSupport from './ErpSupport/section.config';
import JIT from './JitHome/section.config';
import Payments from './Payments/section.config';
import Permissions from './Permissions/section.config';
import Project from './Project/section.config';
import ProjectManagement from './ProjectManagement/section.config';
import Search from './search.section.config';
import UserHistory from './UserHistory/section.config';
import CorrespondenceTypes from './CorrespondenceTypes/section.config';
import Insights from './Insights/section.config';
import SuperUser from './SuperUser/section.config';
import ConfigurableFieldSets from './ConfigurableFieldSets/section.config';
import ContactUpdateLogs from './ContactUpdateLogs/section.config';
import Reports from './reports.section.config';
import WorkflowStateTool from './WorkflowStateTool/section.config';
import SuperArea from './Super/section.config';

export class PageConfigService {
  Sections: { [key: string]: SectionConfigWithRequired };

  constructor(sections: { [key: string]: SectionConfig }) {
    this.Sections = _.mapValues(sections, (section) =>
      this.buildSection(section),
    );
  }

  /**
   * Return route object with values from `parent` if not present in `child`
   */
  createRouteWithDefaults = (
    parent: BaseConfig,
    child: BaseConfig,
  ): BaseConfigWithRequired['route'] => {
    return {
      path: (parent.route?.path ?? '') + (child.route?.path ?? ''),
      page: child.route?.page || parent.route?.page,
      tab: child.route?.tab || parent.route?.tab,
    };
  };

  /**
   * Return i18n object with values from `parent` if not present in `child`
   * @param parent
   * @param child
   * @returns
   */
  createI18nWithDefaults = (
    parent: BaseConfig,
    child: BaseConfig,
  ): BaseConfigWithRequired['i18n'] => {
    if (child.i18n?.namespace) {
      // If child has a namespace defined, don't inherit keyPrefix
      return {
        namespace: child.i18n.namespace,
        keyPrefix: child.i18n.keyPrefix,
      };
    } else {
      // Inherit namespace from parent, and keyPrefix if not defined in child
      return {
        namespace: parent.i18n?.namespace ?? 'common',
        keyPrefix: child.i18n?.keyPrefix || parent.i18n?.keyPrefix,
      };
    }
  };

  /**
   * Return permissions object with values from `parent` if not present in `child`
   * @param parent
   * @param child
   * @returns
   */
  createPermissionsWithDefaults = (parent: BaseConfig, child: BaseConfig) => {
    if (parent.permissions) {
      const perms = { ...child.permissions };
      Object.entries(parent.permissions).map(([key, value]) => {
        if (!perms[key]) {
          perms[key] = value ?? [];
        }
      });
      return perms;
    } else {
      return child.permissions ?? { view: [], edit: [] };
    }
  };

  /**
   * Translate `key` using provided i18n instance prefixed with `keyPrefix` and namespaced to `namespace`
   * @param t
   * @param config
   * @param key
   * @returns
   */
  translate = (
    t: TFunction,
    config: BaseConfigWithRequired,
    key?: string,
    options?: any,
  ): string => {
    const _key = config.i18n.keyPrefix
      ? `${config.i18n.keyPrefix}.${key}`
      : `${key}`;
    const result = t(_key, {
      ns: [config.i18n.namespace, 'common'],
      ...(typeof options == 'object' ? options : {}),
    });

    // We need the type check below check because `options` passed to i18next could possibly
    // configure it to return an object.  Because of this, the return type from `t` is changed
    // from `string` to `TFunctionDetailedResult<object>` by logic in their type definitions.
    // However, this is not how we use it and we always expect a string to be returned.
    return typeof result == 'string' ? result : '';
  };

  /**
   * Build Section object with defaults applied to pages and cards
   * @param sectionConfig
   * @returns
   */
  buildSection = (sectionConfig: SectionConfig) => {
    const _sectionConfig = {
      ...sectionConfig,
      id: sectionConfig.id ?? sectionConfig.label ?? '',
      route: {
        path: sectionConfig.route?.path,
      },
      i18n: this.createI18nWithDefaults(sectionConfig, sectionConfig),
      permissions: this.createPermissionsWithDefaults(
        sectionConfig,
        sectionConfig,
      ),
    };
    return {
      ..._sectionConfig,
      pages: Object.entries(sectionConfig.pages).map(([page, pageConfig]) => {
        const _pageConfig = {
          ...pageConfig,
          id: page,
          route: this.createRouteWithDefaults(_sectionConfig, pageConfig),
          i18n: this.createI18nWithDefaults(_sectionConfig, pageConfig),
          permissions: this.createPermissionsWithDefaults(
            _sectionConfig,
            pageConfig,
          ),
        };

        const cards = Object.entries(pageConfig.cards).map(
          ([card, cardConfig]) => {
            return {
              ...cardConfig,
              id: card,
              route: this.createRouteWithDefaults(_pageConfig, cardConfig),
              i18n: this.createI18nWithDefaults(_pageConfig, cardConfig),
              permissions: this.createPermissionsWithDefaults(
                _pageConfig,
                cardConfig,
              ),
            };
          },
        );

        return {
          ..._pageConfig,
          cards,
          /**
           * Find card by id. Throws if not found.
           * @param id
           */
          card: (id: string): CardConfigWithRequired => {
            const _card = cards.find((c) => c.id === id);
            if (!_card) {
              throw new Error(`Card ${id} does not exist in page ${page}`);
            } else {
              return _card;
            }
          },
        };
      }),
    };
  };

  _getSection = (section: string): SectionConfigWithRequired => {
    if (!this.Sections[section]) {
      throw new Error(`Section ${section} does not exist`);
    }
    return this.Sections[section];
  };

  getSection = (
    t: TFunction,
    section: string,
    sectionConfig?: SectionConfigWithRequired,
  ): SectionConfigTranslated => {
    const _sectionConfig = sectionConfig || this._getSection(section);
    return {
      ...this.translateSection(t, _sectionConfig),
      pages: _sectionConfig.pages.map((pageConfig) =>
        this.getPage(t, section, pageConfig.id, pageConfig),
      ),
      translate: (key?: string, options?: any) =>
        this.translate(t, _sectionConfig, key, options),
    };
  };

  translateSection = (
    t: TFunction,
    sectionConfig: SectionConfigWithRequired,
  ): SectionConfigWithRequired => {
    return {
      ...sectionConfig,
      label: sectionConfig.label
        ? this.translate(t, sectionConfig, sectionConfig.label)
        : sectionConfig.label,
    };
  };

  getSections = (t: TFunction) => {
    return Object.keys(this.Sections).map((section) => {
      return this.getSection(t, section);
    });
  };

  /**
   * Get page config for a given section and page (non-translated)
   * @param section
   * @param page
   * @returns
   */
  _getPage = (section: string, page: string): PageConfigWithRequired => {
    const sectionConfig = this.Sections[section];
    const pageConfig = sectionConfig.pages.find((p) => p.id === page);
    if (!pageConfig) {
      throw new Error(`Page ${page} does not exist in section ${section}`);
    }
    return pageConfig;
  };

  /**
   * Get page config for a given section and page.  Apply translations to label and provide
   *  a translate function scoped to page namespace and keyPrefix.
   * @param t
   * @param section
   * @param page
   * @param pageConfig
   * @returns
   */
  getPage = (
    t: TFunction,
    section: string,
    page: string,
    pageConfig?: PageConfigWithRequired,
  ): PageConfigTranslated => {
    const _pageConfig = pageConfig || this._getPage(section, page);
    return {
      ...this.translatePage(t, _pageConfig),
      card: (id: string) => this.getCard(t, section, page, id),
      cards: _pageConfig.cards.map((cardConfig) =>
        this.getCard(t, section, page, cardConfig.id, cardConfig),
      ),
      translate: (key?: string, options?: any) =>
        this.translate(t, _pageConfig, key, options),
    };
  };

  translatePage = (
    t: TFunction,
    pageConfig: PageConfigWithRequired,
  ): PageConfigWithRequired => {
    return {
      ...pageConfig,
      label: this.translate(t, pageConfig, pageConfig.label),
    };
  };

  /**
   * Get card config for a given section, page and card (non-translated)
   * @param section
   * @param page
   * @param card
   * @returns
   */
  _getCard = (
    section: string,
    page: string,
    card: string,
  ): CardConfig & BaseConfigWithRequired => {
    const pageConfig = this._getPage(section, page);
    const cardConfig = pageConfig.cards.find((c) => c.id === card);
    if (!cardConfig) {
      throw new Error(
        `Card ${card} does not exist in page ${page} of section ${section}`,
      );
    }

    return cardConfig;
  };

  /**
   * Get card config for a given section, page and card.  Apply translations to label and fields,
   *  and provide a translate function scoped to card namespace and keyPrefix
   * @param t
   * @param section
   * @param page
   * @param card
   * @returns
   */
  getCard = (
    t: TFunction,
    section: string,
    page: string,
    card: string,
    cardConfig?: CardConfig & BaseConfigWithRequired,
  ): CardConfigTranslated => {
    const _cardConfig = cardConfig || this._getCard(section, page, card);
    return {
      ...this.translateCard(t, _cardConfig),
      translate: (key?: string, options?: any) =>
        this.translate(t, _cardConfig, key, options),
    };
  };

  translateCard = (
    t: TFunction,
    cardConfig: CardConfig & BaseConfigWithRequired,
  ) => {
    return {
      ...cardConfig,
      label: cardConfig.label
        ? this.translate(t, cardConfig, cardConfig.label)
        : cardConfig.label,
      fields: _.mapValues(cardConfig.fields, (fieldConfig) => {
        return {
          ...fieldConfig,
          label: fieldConfig.label
            ? this.translate(t, cardConfig, fieldConfig.label)
            : fieldConfig.label,
          tooltip: fieldConfig.tooltip
            ? this.translate(t, cardConfig, fieldConfig.tooltip)
            : fieldConfig.tooltip,
        };
      }),
    };
  };

  /**
   * Find the section, page, or card config for a given path separated by `.`
   * @param t
   * @param path
   * @returns
   */
  get = (t: TFunction, path: string) => {
    if (!path) {
      throw new Error('Page Config path cannot be empty');
    }
    const [section, page, card] = path.split('.');
    if (card) {
      return this.getCard(t, section, page, card);
    }
    if (page) {
      return this.getPage(t, section, page);
    }
    return this.getSection(t, section);
  };
}

export const PageConfig = new PageConfigService({
  Admin,
  Company,
  'Company Directory': CompanyDirectory,
  Contact,
  ContactUpdateLogs,
  DevPortal,
  'Email Management': EmailManagement,
  'ERP Deactivations': ErpDeactivations,
  'ERP Support': ErpSupport,
  JIT,
  Payments,
  Permissions,
  Project,
  'Project Management': ProjectManagement,
  Search,
  'User History': UserHistory,
  CorrespondenceTypes,
  Insights,
  SuperUser,
  ConfigurableFieldSets,
  Reports,
  WorkflowStateTool,
  SuperArea,
});
