import React, { useContext, useState } from 'react';
import {
  Box,
  Card,
  Flex,
  Form,
  Spinner,
  Button,
  Title,
  H2,
} from '@procore/core-react';
import { PaymentsBusinessEntityContext } from 'contexts/PaymentsBusinessEntityContext';
import { PaymentsBusinessEntityGeneralTabContext } from '.';
import { ipaAppStyles } from 'styles';
import FeePaySettingsEditModal from './FeePaySettingsEditModal';
import FeePaySettingsForm from './FeePaySettingsForm';
import { useTranslation } from 'react-i18next';
import {
  getFeePaySettingsOptions,
  transformDataForForm,
  transformFormValuesToRaw,
} from './FeePaySettingsConstants';

export interface FeePaySettingsCardProps {
  id: string;
  isCardLoading: boolean;
  save: (
    card: any,
    updatedValues: any,
    reason?: string,
    changedKeys?: string[],
  ) => void;
}

export const FeePaySettingsCard = (props: FeePaySettingsCardProps) => {
  const context = useContext(PaymentsBusinessEntityContext);
  const config = context.pageConfig.card('FeePaySettings');
  const t = config.translate;
  const { t: tCommon } = useTranslation(['common']);

  const [modalOpen, setModalOpen] = useState(false);

  const {
    dataSources: { generalInformation },
  } = useContext(PaymentsBusinessEntityGeneralTabContext);

  const options = getFeePaySettingsOptions(t);
  const initialFormValues = transformDataForForm(
    generalInformation.data,
    options,
  );

  const handleSave = (values: any, reason?: string, changedKeys?: string[]) => {
    // Transform select option values back to raw values
    const transformedValues = transformFormValuesToRaw(values);

    const card: any = {
      id: props.id,
      i18n: t,
      label: t('FEE_PAY_SETTINGS'),
      canEdit: context.hasPermission(config.permissions.edit || []),
      fields: [],
      save: props.save,
    };

    return props.save(card, transformedValues, reason, changedKeys);
  };

  return (
    <Box marginBottom="lg">
      <Card>
        {props.isCardLoading ? (
          <Flex justifyContent="center" padding="lg">
            <Spinner loading={props.isCardLoading} />
          </Flex>
        ) : (
          <Box padding="lg">
            <Title style={ipaAppStyles.titleMargin}>
              <Title.Text>
                <H2>{t('TITLE')}</H2>
              </Title.Text>
              <Title.Actions>
                {context.hasPermission(config.permissions.edit || []) && (
                  <Button
                    variant="secondary"
                    onClick={() => setModalOpen(true)}
                    data-qa={`edit-btn-${props.id}`}
                  >
                    {tCommon('EDIT')}
                  </Button>
                )}
              </Title.Actions>
            </Title>

            <Form
              enableReinitialize={true}
              view="read"
              initialValues={initialFormValues}
            >
              {(formProps: any) => (
                <Form.Form data-qa={`form-view-${props.id}`}>
                  <FeePaySettingsForm
                    values={formProps.values}
                    disabled={true}
                  />
                </Form.Form>
              )}
            </Form>

            <FeePaySettingsEditModal
              isOpen={modalOpen}
              closeModal={() => setModalOpen(false)}
              save={handleSave}
              isLoading={props.isCardLoading}
            />
          </Box>
        )}
      </Card>
    </Box>
  );
};

export default FeePaySettingsCard;
