import { AnalyticEventsApi } from '@procore/ipa-nt-api-client-ts';
import type { AnalyticEvent } from '@procore/ipa-nt-api-client-ts';
import { getApi } from 'utils/api';

export interface IPAEvent {
  event: string;
  props: object;
}

export class AnalyticEventsService {
  postProcoreEvent = async (eventProps: AnalyticEvent) => {
    const api = getApi(AnalyticEventsApi);
    try {
      await api.analyticEventsControllerSendCoreAppEventV10(eventProps);
    } catch (e: any) {
      // we don't want to fail the app if the analytic event fails
      console.log(`Failed to send analytic event to core app`, e);
    }
  };

  postIpaEvent = async (event: IPAEvent) => {
    const api = getApi(AnalyticEventsApi);
    try {
      await api.analyticEventsControllerCreateIpaEventV10({
        event_type: event.event,
        props: event.props,
      });
    } catch (e: any) {
      // we don't want to fail the app if the analytic event fails
      console.log(`Failed to send IPA analytic event`, e);
    }
  };
}

export const AnalyticEvents = new AnalyticEventsService();
