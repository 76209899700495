import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Card,
  EmptyState,
  Flex,
  Spinner,
  Button,
  Title,
  H2,
} from '@procore/core-react';
import { Link } from 'components/Link';
import TableBackend from 'components/TableBackend';
import { getApi } from 'utils/api';
import { CompaniesApi } from '@procore/ipa-nt-api-client-ts';
import type {
  ProjectDto,
  CompanyControllerSearchProjectsV10200Response,
} from '@procore/ipa-nt-api-client-ts';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { AppContext } from 'contexts/AppContext';
import { ProjectManagementContext } from '../index';
import ProjectToolsDropdown from 'components/ProjectToolsDropdown';
import { generateUrl as projectUrlGenerator } from 'pages/Project/urlGenerator';
import fileDownload from 'js-file-download';
const { Parser } = require('json2csv');

export interface ProjectsProps
  extends CompanyControllerSearchProjectsV10200Response {}

interface ProjectDownload {
  company_id: number;
  company_name: string;
  project_id: number;
  project_name: string;
  project_number: string;
  is_active: boolean;
}

const ProjectsCard = () => {
  const { t } = useTranslation(['project-management'], {
    keyPrefix: 'PROJECTS',
  });
  const { t: tCommon } = useTranslation(['common']);
  const alert = useContext(IpaAlertContext);
  const { userSettings } = useContext(AppContext);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(
    (userSettings.default_page_size as number) || 25,
  );
  const [searchString, setSearchString] = useState<string>('');
  const [projects, setProjects] = useState<ProjectsProps>();
  const { companyId, env } = useContext(ProjectManagementContext);
  const context = useContext(ProjectManagementContext);

  const projectLink = (project: ProjectDto, node: keyof ProjectDto) => {
    return (
      <Link
        href={projectUrlGenerator('project-info', {
          ...context,
          projectId: project.id,
        })}
      >
        {project[node]}
      </Link>
    );
  };

  const tableHeaders = [
    {
      title: t('PROJECT_NUMBER'),
      node: 'project_number',
      is_sortable: false,
      is_text: true,
      column_width: 10,
    },
    {
      title: t('PROJECT_ID'),
      node: 'id',
      is_sortable: false,
      is_link: true,
      column_width: 10,
      display_func: projectLink,
    },
    {
      title: t('PROJECT_NAME'),
      node: 'project_name',
      is_sortable: false,
      is_link: true,
      display_func: projectLink,
      column_width: 45,
    },
    {
      title: t('PROJECT_ACTIVE'),
      node: 'is_active',
      is_sortable: false,
      is_boolean: true,
      column_width: 10,
    },
    {
      title: t('IS_ERP_INTEGRATED'),
      node: 'is_erp_integrated',
      is_sortable: false,
      is_boolean: true,
      column_width: 10,
    },
    {
      title: t('IS_ERP_SYNCED'),
      node: 'is_erp_synced',
      is_sortable: false,
      is_boolean: true,
      column_width: 10,
    },
    {
      title: t('TOOL_SETTINGS'),
      node: '',
      is_sortable: false,
      column_width: 5,
      display_func: (project: ProjectDto) => (
        <ProjectToolsDropdown {...{ ...context, projectId: project.id }} />
      ),
    },
  ];

  const getProjects = useCallback(async () => {
    setIsInit(false);
    setLoading(true);

    try {
      const api = getApi(CompaniesApi);

      const result = await api.companyControllerSearchProjectsV10(
        env,
        companyId,
        currentPage,
        pageSize,
        searchString,
      );

      setProjects(result.data);
    } catch (e: any) {
      alert.error(e);
    } finally {
      setLoading(false);
      setIsInit(true);
    }
  }, [env, currentPage, pageSize, searchString, companyId, alert]);

  const downloadProjects = async () => {
    try {
      const api = getApi(CompaniesApi);
      const result = await api.companyControllerSearchProjectsV10(
        env,
        companyId,
        1,
        projects.total || pageSize,
      );

      let temp: ProjectDownload[] = [];
      temp = result.data.items.map((project) => {
        return {
          company_id: context.companyId,
          company_name: context.companyName,
          project_id: project.id,
          project_name: project.project_name,
          project_number: project.project_number,
          is_active: project.is_active,
          is_erp_integrated: project.is_erp_integrated,
          is_erp_synced: project.is_erp_synced,
        };
      });

      const json2csvParser = new Parser({
        quote: '',
        fields: [
          { label: tCommon('COMPANY_ID'), value: 'company_id' },
          { label: tCommon('COMPANY_NAME'), value: 'company_name' },
          { label: t('PROJECT_NUMBER'), value: 'project_number' },
          { label: t('PROJECT_ID'), value: 'project_id' },
          { label: t('PROJECT_NAME'), value: 'project_name' },
          { label: t('PROJECT_ACTIVE'), value: 'is_active' },
        ],
      });
      const csv = json2csvParser.parse(temp);
      fileDownload(csv, `company_${context.companyId}_projects.csv`);
    } catch (e: any) {
      alert.error(e);
    }
  };

  const changePage = (newPage: number, newPageSize: number) => {
    setCurrentPage(newPage);
  };

  const changePageSize = (newPageSize: number) => {
    setPageSize(newPageSize);
    setCurrentPage(1);
  };

  const searchProjects = (searchString: string) => {
    setSearchString(searchString);
  };

  const sortProjects = (sortColumn: string, sortDirection: string) => {};

  useEffect(() => {
    getProjects();
  }, [getProjects]);

  return (
    <Card>
      <Box padding="lg">
        <Title>
          <Title.Text>
            <H2>{t('TITLE')}</H2>
          </Title.Text>
          <Title.Actions>
            <Button
              variant="secondary"
              onClick={downloadProjects}
              data-qa="download-projects"
            >
              {t('DOWNLOAD_PROJECTS')}
            </Button>
          </Title.Actions>
        </Title>
        <div>
          {!isInit ||
            (isLoading && (
              <Box marginBottom="lg">
                <Card>
                  <Box padding="lg">
                    {!isInit && !isLoading && (
                      <EmptyState>
                        <EmptyState.NoItems />
                        <EmptyState.Title>
                          {tCommon('NO_DATA')}
                        </EmptyState.Title>
                      </EmptyState>
                    )}
                    {isLoading && (
                      <Flex justifyContent="center">
                        <Spinner loading={isLoading} />
                      </Flex>
                    )}
                  </Box>
                </Card>
              </Box>
            ))}
          {isInit && !isLoading && (
            <TableBackend
              data_qa="pm-projects-table"
              data={projects?.items ?? []}
              data_total={projects?.total || 0}
              current_page={currentPage}
              headers={tableHeaders}
              default_sort_column=""
              show_search={true}
              search_placeholder={t('SEARCH_PLACE_HOLDER')}
              search_action={searchProjects}
              // initial_search={initialSearch}
              show_no_data_message={true}
              no_data_message={tCommon('NO_DATA')}
              paginator={changePage}
              page_size={pageSize}
              show_page_size_select={true}
              page_size_action={changePageSize}
              fixed_table={true}
              default_sort_direction={''}
              sorter={sortProjects}
            />
          )}
        </div>
      </Box>
    </Card>
  );
};
export default ProjectsCard;
