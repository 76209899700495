import React, { useCallback, useContext, useEffect, useState } from 'react';
import { type ApiRequestMetadataDto } from '@procore/ipa-nt-api-client-ts';
import { ApiRequestDetail } from './ApiRequestDetail';
import { Grid } from '@procore/core-react';
import { useLocation } from 'react-router-dom';
import { ApiRequestCard } from './styles';

interface ApiRequestsListProps {
  data: ApiRequestMetadataDto[];
}

export const ApiRequestsList = (props: ApiRequestsListProps) => {
  const { hash } = useLocation();

  const [openItems, setOpenItems] = useState(hash.replace('#', '').split(','));

  const toggleOpenItem = (requestId: string) => {
    if (openItems.includes(requestId)) {
      setOpenItems((prev) => prev.filter((item) => item !== requestId));
    } else {
      setOpenItems((prev) => [...prev, requestId]);
    }
  };

  useEffect(() => {
    window.location.hash = openItems.join(',');
  }, [openItems]);

  return (
    <div>
      {props.data.map((item: ApiRequestMetadataDto) => (
        <ApiRequestListItem
          key={item.request_id}
          item={item}
          isOpen={openItems.includes(item.request_id)}
          toggleOpenItem={toggleOpenItem.bind(null, item.request_id)}
        />
      ))}
    </div>
  );
};

const ApiRequestListItem = (props: {
  isOpen: boolean;
  toggleOpenItem: () => void;
  item: ApiRequestMetadataDto;
}) => {
  const isError = props.item.response_code && props.item.response_code >= 400;
  return (
    <ApiRequestCard>
      <Grid>
        <Grid.Row
          style={{ backgroundColor: isError ? '#f8d7da' : 'white' }}
          className={props.isOpen ? 'heading-open' : ''}
          onClick={props.toggleOpenItem}
        >
          <Grid.Col colWidth={{ tabletMd: 8 }}>{props.item.path}</Grid.Col>
          <Grid.Col colWidth={{ tabletMd: 1 }}>
            {props.item.method.toUpperCase()}
          </Grid.Col>
          <Grid.Col colWidth={{ tabletMd: 1 }}>
            {props.item.response_code ? props.item.response_code : 'N/A'}
          </Grid.Col>
          <Grid.Col colWidth={{ tabletMd: 1 }}>
            {props.item.duration && (
              <span>&nbsp;{props.item.duration.toFixed(0)}ms</span>
            )}
          </Grid.Col>
          <Grid.Col colWidth={{ tabletMd: 1 }}>
            {props.item.timed_out === true ? (
              <strong>TIMED OUT</strong>
            ) : (
              <span>{props.item.size} bytes</span>
            )}
          </Grid.Col>
        </Grid.Row>
      </Grid>

      {props.isOpen && <ApiRequestDetail requestId={props.item.id} />}
    </ApiRequestCard>
  );
};
