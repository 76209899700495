import React, { useContext } from 'react';
import * as yup from 'yup';
import type { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { Modal, Form, Button } from '@procore/core-react';
import { ProjectContext } from 'contexts/ProjectContext';

interface ImportProjectCostCodesModalProps {
  isOpen: boolean;
  close(): void;
  importFile(values: any): void;
}

export interface IImportProjectCostCodesForm {
  file: Blob;
}

export const ImportProjectCostCodesModal = (
  props: ImportProjectCostCodesModalProps,
) => {
  const context = useContext(ProjectContext);
  const config = context.pageConfig.card('ImportCostCodes');
  // const t = config.translate;
  const { t: tCommon } = useTranslation(['common']);

  const { isOpen } = props;

  return (
    <Modal
      open={isOpen}
      onClickOverlay={props.close}
      width="md"
      onClose={props.close}
    >
      <Form
        view="create"
        enableReinitialize={true}
        initialValues={{ file: '' }}
        validationSchema={yup.object().shape({
          file: yup.string().required('Required'),
        })}
        onSubmit={(values) => props.importFile(values)}
      >
        {(formProps: FormikProps<IImportProjectCostCodesForm>) => {
          const { values, setFieldValue } = formProps;
          return (
            <Form.Form>
              <Modal.Header>{config.label}</Modal.Header>
              <Modal.Body>
                <input
                  role="input"
                  name="file"
                  type="file"
                  onChange={(e) => {
                    setFieldValue(
                      'file',
                      e.currentTarget.files ? e.currentTarget.files[0] : '',
                    );
                  }}
                />
              </Modal.Body>
              <Modal.Footer>
                <Modal.FooterButtons>
                  <Button variant="tertiary" onClick={props.close}>
                    {tCommon('CANCEL')}
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={values.file.toString() === ''}
                  >
                    {tCommon('IMPORT')}
                  </Button>
                </Modal.FooterButtons>
              </Modal.Footer>
            </Form.Form>
          );
        }}
      </Form>
    </Modal>
  );
};

export default ImportProjectCostCodesModal;
