import React, { useContext } from 'react';
import { EditableCard } from 'components/EditableCard';
import type { IEditableCard } from 'components/EditableCard';
import { ContactContext } from 'contexts/ContactContext';
import type { IEditableCardProps } from 'pages/Contact/interfaces';
import { PageConfig } from 'pages/page-config.service';
import { Form } from '@procore/core-react';
import i18n from 'i18n';
import type { CardConfigTranslated } from 'pages/page-config.interfaces';

export const NewProjectSettingsCard = (props: IEditableCardProps) => {
  const context = useContext(ContactContext);
  const config = PageConfig.get(
    i18n.t,
    'Contact.Settings.NewProjectSettings',
  ) as CardConfigTranslated;
  const contact = context.dataSources?.contact;

  const card: IEditableCard = {
    id: props.id,
    i18n: config.translate,
    label: config.label ?? '',
    canEdit: false,
    save: props.save,
    fields: [
      {
        source: contact.id,
        value: contact.data?.add_to_new_projects,
        name: 'add_to_new_projects',
        dataType: 'boolean',
        element: Form.Checkbox,
        ...config.fields.add_to_new_projects,
      },
      {
        source: contact.id,
        value: contact.data?.can_add_new_projects,
        name: 'can_add_new_projects',
        dataType: 'boolean',
        element: Form.Checkbox,
        ...config.fields.can_add_new_projects,
      },
    ],
  };

  return <EditableCard card={card} />;
};

export default NewProjectSettingsCard;
