import type { PageConfig } from 'pages/page-config.interfaces';

const pageConfig: PageConfig = {
  label: 'TITLE',
  route: {
    page: 'project-custom-tool',
  },
  i18n: {
    namespace: 'insights',
    keyPrefix: 'PROJECT_CUSTOM_TOOL_REPORT',
  },
  endpoints: {
    view: ['insightsControllerGetProjectCustomToolsReport'],
  },
  cards: {},
};

export default pageConfig;
