import { Box, Card, Flex, Spinner, Tabs } from '@procore/core-react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import ProjectInfoTab from './ProjectInfo';
import { ProjectsApi } from '@procore/ipa-nt-api-client-ts';
import type {
  ProjectDrawingLogSettingsDto,
  ProjectSettingsDto,
} from '@procore/ipa-nt-api-client-ts';
import { getApi } from 'utils/api';
import { ProjectContext } from 'contexts/ProjectContext';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import ProjectLocationTab from './ProjectLocation';
import { useTranslation } from 'react-i18next';
import AdvancedTab from './ProjectAdvancedTab';
import type { ProjectInfoContextProps } from './interfaces';
import React from 'react';

export const ProjectInfoContext = createContext({} as ProjectInfoContextProps);

const ProjectInfoPage = () => {
  const { t } = useTranslation(['project'], {
    keyPrefix: 'INFO_TAB',
  });
  const alert = useContext(IpaAlertContext);
  const [isInit, setIsInit] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('info');
  const [projectSettings, setProjectSettings] = useState<ProjectSettingsDto>();
  const [drawingLogSettings, setDrawingLogSettings] =
    useState<ProjectDrawingLogSettingsDto>();
  const [cardLoading, setCardLoading] = useState<{ [key: string]: boolean }>(
    {},
  );
  const {
    env,
    companyId,
    projectId,
    dataSources: { companyStatus },
  } = useContext(ProjectContext);

  const dataSources: ProjectInfoContextProps['dataSources'] = {
    projectSettings: {
      id: 'projectSettings',
      data: projectSettings!,
    },
    drawingLogSettings: {
      id: 'drawingLogSettings',
      data: drawingLogSettings,
    },
    company: companyStatus,
  };

  const tabs = [
    {
      id: 'info',
      name: t('PROJECT_INFO'),
      component: ProjectInfoTab,
    },
    {
      id: 'location',
      name: t('PROJECT_LOCATION'),
      component: ProjectLocationTab,
    },
    {
      id: 'advanced',
      name: t('ADVANCED'),
      component: AdvancedTab,
    },
  ];

  const isTabActive = (tab: string) => {
    return tab === activeTab ? true : false;
  };

  const changeCardLoading = (cardId: string, loading: boolean) => {
    setCardLoading({
      ...cardLoading,
      [cardId]: loading,
    });
  };

  const changeTab = (tab: string) => {
    if (tab !== activeTab) {
      window.history.pushState(null, '', `#${tab}`);
      setActiveTab(tab);
    }
  };

  const updateDrawingLogSettings = async (values: any, reason?: string) => {
    const api = getApi(ProjectsApi);
    return api.projectControllerUpdateDrawingLogSettingsV10(
      env,
      companyId,
      projectId.toString(),
      {
        reason: reason ?? '',
        enable_rfi_real_time_as_builts: values.enable_rfi_real_time_as_builts,
      },
    );
  };

  const updatePhotoImportConfigs = async (values: any, reason?: string) => {
    debugger;
    const api = getApi(ProjectsApi);
    return api.projectControllerUpdatePhotoImportConfigsV10(
      env,
      companyId,
      projectId,
      {
        email: values.email_upload_address,
        reason: reason ?? '',
      },
    );
  };

  const updateProjectSettingsRequest = async (values: any, reason?: string) => {
    const api = getApi(ProjectsApi);

    const update = {
      inbound_email_address: values.inbound_email_address,
      is_demo: values.is_demo,
      enable_project_as_template: values.enable_project_as_template,
    } as any;

    if (typeof values.enable_change_reason_select !== 'undefined') {
      update.project_configuration = {
        enable_change_reason_select: values.enable_change_reason_select,
      };
    }

    return api.projectControllerUpdateProjectSettingsV10(
      env,
      companyId,
      projectId,
      {
        reason: reason ?? '',
        update: update,
      },
    );
  };

  const getDrawingLogSettings = useCallback(async () => {
    setDrawingLogSettings(undefined);
    try {
      const api = getApi(ProjectsApi);
      const res = await api.projectControllerGetProjectDrawingLogSettingsV10(
        env,
        companyId,
        projectId.toString(),
      );
      if (res.data) {
        setDrawingLogSettings(res.data);
      }
    } catch (e: any) {
      alert.error(e);
    }
  }, [env, companyId, projectId]);

  const getProjectSettings = useCallback(async () => {
    setProjectSettings(undefined);
    alert.closeAlert();
    setIsInit(false);
    setLoading(true);
    try {
      const api = getApi(ProjectsApi);
      const res = await api.projectControllerGetProjectSettingsV10(
        env,
        companyId,
        projectId,
      );
      if (res.data) {
        setProjectSettings(res.data);
      }
      setIsInit(true);
    } catch (e: any) {
      alert.error(e);
    } finally {
      setLoading(false);
    }
  }, [env, companyId, projectId]);

  useEffect(() => {
    if (window.location.hash !== '') {
      const hash = window.location.hash.substring(1);
      if (tabs.findIndex((t) => t.id === hash) !== -1) {
        changeTab(hash);
      }
    }
  }, []);

  useEffect(() => {
    setIsInit(false);
    setLoading(true);
    getProjectSettings();
    getDrawingLogSettings();
    setIsInit(true);
  }, [getProjectSettings, getDrawingLogSettings]);

  return (
    <ProjectInfoContext.Provider
      value={{
        dataSources: dataSources,
        projectId: projectId,
        companyId: companyId,
        env: env,
      }}
    >
      <Card>
        <Box padding="lg">
          <Tabs>
            {tabs.map((tab, i) => (
              <Tabs.Tab
                active={isTabActive(tab.id)}
                onClick={() => changeTab(tab.id)}
                key={i}
                data-qa={`project-info-${tab.id}-tab`}
              >
                <Tabs.Link>{tab.name}</Tabs.Link>
              </Tabs.Tab>
            ))}
          </Tabs>

          <React.Fragment>
            {tabs
              .filter((tab) => isTabActive(tab.id))
              .map((tab, i) => (
                <React.Fragment key={i}>
                  {(isLoading || cardLoading[tab.component.name]) && (
                    <Flex justifyContent="center">
                      <Spinner loading={isLoading} />
                    </Flex>
                  )}

                  {isInit &&
                    !isLoading &&
                    !cardLoading[tab.component.name] &&
                    projectSettings && (
                      <tab.component
                        getProjectSettings={getProjectSettings}
                        getDrawingLogSettings={getDrawingLogSettings}
                        updateProjectSettingsRequest={
                          updateProjectSettingsRequest
                        }
                        updatePhotoImportConfigs={updatePhotoImportConfigs}
                        updateDrawingLogSettings={updateDrawingLogSettings}
                        setCardLoading={changeCardLoading}
                      />
                    )}
                </React.Fragment>
              ))}
          </React.Fragment>
        </Box>
      </Card>
    </ProjectInfoContext.Provider>
  );
};
export default ProjectInfoPage;
