import React, { useState, useEffect, useContext } from 'react';
import { IpaModalWrapper } from 'styles';
import { Modal, Grid } from '@procore/core-react';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'contexts/AppContext';
import { Link } from 'components/Link';

interface modalProps {
  isOpen: boolean;
  modalTitle: string;
  closeModal(): void;
}

export const FeatureAndBugModal = (props: modalProps) => {
  const [showFeatureModal, setShowFeatureModal] = useState<boolean>(false);
  const [featureModalTitle, setFeatureModalTitle] = useState<string>('');
  const { t } = useTranslation('header');
  const { userConfig } = useContext(AppContext);

  const closeModal = () => {
    props.closeModal();
  };

  useEffect(() => {
    setShowFeatureModal(props.isOpen);
    setFeatureModalTitle(props.modalTitle);
  }, [props.isOpen]);

  return (
    <Modal
      width="md"
      open={showFeatureModal}
      onClickOverlay={closeModal}
      data-qa={'feature-and-bug-modal'}
      onClose={closeModal}
    >
      <IpaModalWrapper>
        <Modal.Header onClose={closeModal}>{featureModalTitle}</Modal.Header>
        <Modal.Body>
          <Modal.Section>
            <Grid>
              <Grid.Row>
                <Grid.Col colWidth={10}>
                  <Link
                    href="https://procoretech.atlassian.net/jira/secure/CreateIssueDetails!init.jspa?pid=20724&issuetype=1&summary=[GR]%20Bug%20generated%20from%20user%20feedback&priority=10001&description=Please%20provide%20as%20much%20detail%20and%20screenshots%20as%20possible"
                    target="_blank"
                    data-qa="report-bug-link"
                  >
                    {t('USER_REPORT_BUG')}
                  </Link>
                </Grid.Col>
              </Grid.Row>
            </Grid>
          </Modal.Section>
          <Modal.Section>
            <Grid>
              <Grid.Row>
                <Grid.Col colWidth={10}>
                  <Link
                    href={`https://procoretech.atlassian.net/jira/secure/CreateIssueDetails!init.jspa?pid=20724&issuetype=7&priority=10001&summary=New feature suggestion from: ${userConfig?.user?.username}&description=Please provide as much detail, URLs and screenshots as possible`}
                    target="_blank"
                    data-qa="request-feature-link"
                  >
                    {t('USER_REQUEST_FEATURE_TITLE')}
                  </Link>
                </Grid.Col>
              </Grid.Row>
            </Grid>
          </Modal.Section>
        </Modal.Body>
      </IpaModalWrapper>
    </Modal>
  );
};
