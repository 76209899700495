import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
  Card,
  Spinner,
  Box,
  FlexList,
  H2,
  Link,
  Flex,
} from '@procore/core-react';
import { getApi } from 'utils/api';
import { ProjectsApi } from '@procore/ipa-nt-api-client-ts';
import type {
  DefaultDistributionSettingsDto,
  ProjectUserPermissionsDto,
} from '@procore/ipa-nt-api-client-ts';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { useTranslation } from 'react-i18next';
import { ContactContext } from 'contexts/ContactContext';
import { AxiosError } from 'axios';
import DefaultEmailNotificationsCard from './DefaultEmailNotifications';
import ProjectPermissionsTemplatesCard from './ProjectPermissionsTemplates';

export interface ProjectSettingsProps {
  id: string;
  data: DefaultDistributionSettingsDto[] | ProjectUserPermissionsDto;
  reloadData?: () => void;
}

const ProjectSettingsPage = () => {
  const { t } = useTranslation('contact', {
    keyPrefix: 'PROJECT_SETTINGS',
  });
  const { companyId, env, projectId, loginId, contactId } =
    useContext(ContactContext);
  const alert = useContext(IpaAlertContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(true);
  const [defaultDistribution, setDefaultDistribution] =
    useState<DefaultDistributionSettingsDto[]>();
  const [scheduleNotifications, setScheduleNotifications] =
    useState<DefaultDistributionSettingsDto[]>();
  const [projectPermissionsTemplates, setProjectPermissionTemplates] =
    useState<ProjectUserPermissionsDto>();
  const [cardLoading, setCardLoading] = useState<{ [key: string]: boolean }>(
    {},
  );

  const getEmailNotificationSettings = useCallback(async () => {
    setDefaultDistribution(undefined);
    setScheduleNotifications(undefined);
    alert.closeAlert();
    setCardLoading({
      ...cardLoading,
      DefaultDistribution: true,
      ScheduleNotifications: true,
    });
    try {
      const api = getApi(ProjectsApi);
      const res =
        await api.projectControllerGetContactEmailNotificationSettingsV10(
          env,
          companyId,
          projectId,
          contactId,
        );

      if (res.data) {
        setDefaultDistribution(res.data.default_distribution_settings.options);
        setScheduleNotifications(
          res.data.schedule_notification_settings.options,
        );
      }
    } catch (e: any) {
      return e;
    } finally {
      setCardLoading({
        ...cardLoading,
        DefaultDistribution: false,
        ScheduleNotifications: false,
      });
    }
  }, [companyId, env, projectId, contactId]);

  const getProjectPermissionTemplates = useCallback(async () => {
    alert.closeAlert();
    setCardLoading({
      ...cardLoading,
      ProjectPermissions: true,
    });
    try {
      const api = getApi(ProjectsApi);
      const res = await api.projectControllerGetProjectUserPermissionsV10(
        env,
        companyId,
        projectId,
        loginId,
      );

      if (res.data) {
        setProjectPermissionTemplates(res.data);
      }
    } catch (e: any) {
      return e;
    } finally {
      setCardLoading({
        ...cardLoading,
        ProjectPermissions: false,
      });
    }
  }, [companyId, env, projectId, loginId]);

  const cards = [
    {
      id: 'ProjectPermissions',
      component: ProjectPermissionsTemplatesCard,
      data: projectPermissionsTemplates,
      reloadData: getProjectPermissionTemplates,
    },
    {
      id: 'DefaultDistribution',
      component: DefaultEmailNotificationsCard,
      data: defaultDistribution,
    },
    {
      id: 'ScheduleNotifications',
      component: DefaultEmailNotificationsCard,
      data: scheduleNotifications,
    },
  ];

  const initPage = async () => {
    const errors: AxiosError[] = [];
    setIsInit(false);
    setIsLoading(true);
    const promises = await Promise.all([
      getProjectPermissionTemplates(),
      getEmailNotificationSettings(),
    ]);
    promises.forEach((promise: any) => {
      if (promise instanceof AxiosError) {
        errors.push(promise);
      }
    });
    if (errors.length > 0) {
      alert.error(errors);
    }
    setIsLoading(false);
    setIsInit(true);
  };

  useEffect(() => {
    initPage();
  }, [env, companyId, contactId, projectId, loginId]);

  return (
    <React.Fragment>
      {cards.map((card, idx) => (
        <Box marginBottom="lg" key={card.id} data-qa={`card-${card.id}`}>
          <Card>
            {cardLoading[card.id] && (
              <Flex justifyContent="center">
                <Spinner loading={isLoading} />
              </Flex>
            )}
            {isInit && card.data && !cardLoading[card.id] && (
              <Box padding="lg">
                {React.createElement(card.component, {
                  key: `card-${card.id}`,
                  id: card.id,
                  data: card.data,
                  reloadData: card.reloadData,
                })}
              </Box>
            )}
          </Card>
        </Box>
      ))}
      <Box marginBottom="lg">
        <Card>
          <Box padding="lg">
            <H2>{t('MISCELLANEOUS_LINKS_CARD.TITLE')}</H2>
            <FlexList space="md">
              <strong>{t('MISCELLANEOUS_LINKS_CARD.CUSTOM_FORMS')}:</strong>
              <Link
                href={`/v2/company/${companyId}/contact/${contactId}/login/${loginId}/custom-forms?project_id=${projectId}`}
              >
                {t('MISCELLANEOUS_LINKS_CARD.CUSTOM_FORMS')}
              </Link>
            </FlexList>
          </Box>
        </Card>
      </Box>
    </React.Fragment>
  );
};

export default ProjectSettingsPage;
