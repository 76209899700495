import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';
import resourcesToBackend from 'i18next-resources-to-backend';

const languages: string[] = ['en'];

i18n
  .use(ChainedBackend)
  .use(initReactI18next)
  .init({
    backend: {
      backends: [
        resourcesToBackend(
          (lng: string, ns: string) =>
            import(
              /* webpackMode: "eager" */
              `./locales/${lng}/${ns}.json`
            ),
        ),
      ],
    },
    lng: 'en',
    fallbackLng: 'en',
    debug: false,
    supportedLngs: languages,
    initImmediate: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['span', 'ul', 'li'],
    },
    ns: [
      'admin',
      'api-requests',
      'audit-logs',
      'common',
      'company-directory',
      'company',
      'concierge',
      'contact-update-logs',
      'contact',
      'countdown',
      'currency',
      'custom-report-tabs',
      'customer-success',
      'dev-portal',
      'email-management',
      'erp-deactivations',
      'fedramp',
      'footer',
      'global-announcements',
      'header',
      'insights',
      'jit-admin',
      'jit-audit-log',
      'jit-sync',
      'jit',
      'payments',
      'permissions',
      'project-management',
      'project',
      'su-sync',
      'user-history',
      'user-my-activity',
      'user-settings',
    ],
    defaultNS: 'common',
    partialBundledLanguages: true,
  });

export default i18n;
