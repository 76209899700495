import React, { useContext, Suspense, lazy, useCallback, useMemo } from 'react';
import type { ReactNode } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { LoginCallback, useOktaAuth } from '@okta/okta-react';
import { Spinner } from '@procore/core-react';
import { AppContext } from 'contexts/AppContext';
import JitHomePage from './pages/JitHome';
import GlobalAnnouncementAdminPage from 'pages/GlobalAnnouncement';
import ProjectManagementPage from 'pages/ProjectManagement';
import ProjectPage from 'pages/Project';
import { ContactPage } from 'pages/Contact';
import { can } from 'utils/permissions.helper';
import type { IAuthzReq } from 'utils/permissions.helper';
import PaymentsPage from 'pages/Payments';
import PaymentsBusinessEntityPage from 'pages/Payments/BusinessEntity';
import PaymentsDisbursementPage from 'pages/Payments/Disbursement';
import { PageConfig } from 'pages/page-config.service';
import { useTranslation } from 'react-i18next';
import PaymentsContextLayout from 'contexts/PaymentsContext';
import { IntegratorSandboxConfigPage } from 'pages/DevPortal/IntegratorSandboxConfig';
import { MonthlySandboxPage } from 'pages/DevPortal/MonthlySandbox';
import InsightsReportsPage from 'pages/Insights';
import SuperUserPage from './pages/SuperUser';
import ConfigurableFieldSetsPage from './pages/ConfigurableFieldSets';
import { OauthApplicationsPage } from 'pages/DevPortal/OauthApplications';
import { OauthSecretRotationsPage } from 'pages/DevPortal/OauthSecretRotations';
import { OauthCachePage } from 'pages/DevPortal/OauthCache';
import { CustomReportTabsPage } from './pages/CustomReportTabs';
import CustomReportTabsDetailsPage from './pages/CustomReportTabsDetails';
import SuSyncPage from './pages/SuSync';
import AuditLogsPage from './pages/AuditLogs';
import { CompanyContextProvider } from 'contexts/CompanyContext';
import type { PageConfigTranslated } from 'pages/page-config.interfaces';
import { HomepageRedirect } from 'components/HomepageRedirect';
import HomeV2 from 'pages/HomeV2';
import {
  LegacyRouteRewriter,
  legacyRoutes,
} from 'components/LegacyRouteRewriter';
import NotFound from 'pages/NotFound';
import { ApiRequestsPage } from 'pages/ApiRequests';
import { Logout } from 'components/Logout';

const PermissionsPage = lazy(() => import('./pages/Permissions'));
const UserSettingsPage = lazy(() => import('./pages/UserSettings'));
const SuperuserPromotionReportPage = lazy(
  () => import('./pages/SuPromotionReport'),
);
const OktaUsersPage = lazy(() => import('./pages/OktaUsers'));
const OktaGroupsPage = lazy(() => import('./pages/OktaGroups'));
const GlobalDirectorySearchPage = lazy(() => import('./pages/CustomerSuccess'));
const CompanyPage = lazy(() => import('./pages/Company'));
const CompanySearchPage = lazy(() => import('./pages/CompanySearch'));
const ContactUpdateLogsPage = lazy(() => import('./pages/ContactUpdateLogs'));
const ErpDeactivationsPage = lazy(() => import('./pages/ErpDeactivations'));
const UserHistoryPage = lazy(() => import('./pages/UserHistory'));
const UserHistoryDetailsPage = lazy(() => import('./pages/UserHistory/User'));
const DashboardPage = lazy(() => import('./pages/Dashboard'));
const CompanyDirectoryPage = lazy(() => import('./pages/CompanyDirectory'));
const SystemSettingsPage = lazy(() => import('./pages/SystemSettings'));
const JitAdminPage = lazy(() => import('./pages/JitAdmin'));
const JitAuditLogPage = lazy(() => import('./pages/JitAuditLog'));
const JitHome = lazy(() => import('./pages/JitHome'));
const JitSyncPage = lazy(() => import('./pages/JitSync'));
const PdfEcrionAdminsPage = lazy(() => import('./pages/PdfEcrionAdmins'));
const ErpSupportPage = lazy(() => import('./pages/ErpSupport'));
const EmailManagementPage = lazy(() => import('./pages/EmailManagement'));
const WorkflowStateToolPage = lazy(() => import('./pages/WorkflowStateTool'));
const UserMyActivityPage = lazy(() => import('./pages/UserMyActivity'));
const SuperAreaPage = lazy(() => import('./pages/Super'));

const DirectoryChangeHistoryPage = lazy(
  () => import('./pages/CustomerSuccess/DirectoryChangeHistory'),
);
const CorrespondenceTypesPage = lazy(
  () => import('./pages/CorrespondenceTypes'),
);

const Forbidden = lazy(() => import('./pages/Forbidden'));
const UnderMaintenance = lazy(() => import('./pages/UnderMaintenance'));

const pages = [
  {
    page: 'Admin.OktaGroups',
    element: OktaGroupsPage,
  },
  {
    page: 'Admin.OktaUsers',
    element: OktaUsersPage,
  },
  {
    page: 'Admin.SuPromotionReport',
    element: SuperuserPromotionReportPage,
  },
  {
    page: 'User History.UserHistoryDetails',
    element: UserHistoryDetailsPage,
  },
  {
    page: 'User History.UserHistory',
    element: UserHistoryPage,
  },
  {
    page: 'ConfigurableFieldSets.FieldSets',
    element: ConfigurableFieldSetsPage,
    contextProvider: CompanyContextProvider,
  },
  {
    page: 'Contact',
    element: ContactPage,
  },
  {
    page: 'ContactUpdateLogs',
    element: ContactUpdateLogsPage,
  },
  {
    page: 'Company',
    element: CompanyPage,
  },
  {
    page: 'Company Directory.CompanyDirectory',
    element: CompanyDirectoryPage,
  },
  {
    page: 'Admin.ApiRequests',
    element: ApiRequestsPage,
  },
  {
    page: 'Admin.Dashboard',
    element: DashboardPage,
  },
  {
    page: 'Admin.AuditLogs',
    element: AuditLogsPage,
  },
  {
    page: 'Admin.JitAdmin',
    element: JitAdminPage,
  },
  {
    page: 'Admin.JitAuditLog',
    element: JitAuditLogPage,
  },
  {
    page: 'Admin.JitSync',
    element: JitSyncPage,
  },
  {
    page: 'Admin.GlobalAnnouncement',
    element: GlobalAnnouncementAdminPage,
  },
  {
    page: 'Admin.SystemSettings',
    element: SystemSettingsPage,
  },
  {
    page: 'DevPortal.IntegratorSandboxConfig',
    element: IntegratorSandboxConfigPage,
  },
  {
    page: 'DevPortal.MonthlySandbox',
    element: MonthlySandboxPage,
  },
  {
    page: 'DevPortal.OauthApplications',
    element: OauthApplicationsPage,
  },
  {
    page: 'DevPortal.OauthCache',
    element: OauthCachePage,
  },
  {
    page: 'DevPortal.OauthSecretRotations',
    element: OauthSecretRotationsPage,
  },
  {
    page: 'Email Management',
    element: EmailManagementPage,
  },
  {
    page: 'ERP Deactivations.ErpDeactivations',
    element: ErpDeactivationsPage,
  },
  {
    page: 'ERP Support.ErpSupportTools',
    element: ErpSupportPage,
  },
  {
    page: 'JIT.JitHome',
    element: JitHomePage,
  },
  {
    page: 'Payments',
    element: PaymentsPage,
  },
  {
    page: 'Permissions',
    element: PermissionsPage,
  },
  {
    page: 'Project',
    element: ProjectPage,
  },
  {
    page: 'Project Management',
    element: ProjectManagementPage,
  },
  {
    page: 'Search.CompanySearch',
    element: CompanySearchPage,
  },
  {
    page: 'Search.GlobalDirectorySearch',
    element: GlobalDirectorySearchPage,
  },
  {
    page: 'CorrespondenceTypes',
    element: CorrespondenceTypesPage,
    contextProvider: CompanyContextProvider,
  },
  {
    page: 'Insights',
    element: InsightsReportsPage,
  },
  {
    page: 'SuperUser',
    element: SuperUserPage,
  },
  {
    page: 'Admin.SuSync',
    element: SuSyncPage,
  },
  {
    page: 'Reports.CustomReportTabs',
    element: CustomReportTabsPage,
  },
  {
    page: 'Reports.CustomReportTabsDetails',
    element: CustomReportTabsDetailsPage,
  },
  {
    page: 'WorkflowStateTool',
    element: WorkflowStateToolPage,
  },
  {
    page: 'SuperArea',
    element: SuperAreaPage,
  },
];

export const IpaRoutes = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const { userConfig } = useContext(AppContext);
  const { t } = useTranslation();
  const authRequest: IAuthzReq = {
    user: userConfig,
  };

  const pageRoutes = useMemo(
    () =>
      pages.map((item) => {
        const config = PageConfig.get(t, item.page);

        return {
          path: config.route.path,
          requiredPermissions: config.permissions.view?.length
            ? config.permissions.view
            : 'any',
          element: item.element,
          pageConfig: config,
          contextProvider: item.contextProvider,
          ignoreMaintenance:
            can(authRequest, 'view_admin') &&
            item.page === 'Admin.SystemSettings',
        };
      }),
    [authRequest],
  );

  const customerSuccessRoutes = [
    {
      path: '/customer-success/directory-change-history/company/:company_id/contact/:contact_id/login/:login_info_id',
      component: <DirectoryChangeHistoryPage />,
      permission: 'cs_view',
    },
  ];

  const paymentsIntegratorRoutes = [
    {
      path: '/v2/payments-integrator-sandbox',
      component: <PaymentsPage />,
      permission: 'payments_operations_int_sbx',
    },
    {
      path: '/v2/payments-integrator-sandbox/business-entity/:id',
      component: <PaymentsBusinessEntityPage />,
      permission: 'payments_operations_int_sbx',
    },
    {
      path: '/v2/payments-integrator-sandbox/business-entity/:id/disbursement/:disbursementId',
      component: <PaymentsDisbursementPage />,
      permission: 'payments_operations_int_sbx',
    },
  ];

  const secureRoute = useCallback(
    (
      permission: string | string[],
      module: ReactNode,
      ignoreMaintenance?: boolean,
    ) => {
      if (!authState || !authState?.isAuthenticated || !userConfig?.user) {
        return '';
      } else if (userConfig.maintenanceMode && !ignoreMaintenance) {
        return <Navigate replace to={'/under-maintenance'} />;
      } else if (permission === 'any') {
        return <>{module}</>;
      } else {
        return can(authRequest, permission) ? (
          <>{module}</>
        ) : (
          <Navigate replace to={'/403'} />
        );
      }
    },
    [userConfig],
  );

  return (
    <Suspense fallback={<Spinner size="lg" loading={true} />}>
      <Routes>
        <Route path="/oauth2/callback" element={<LoginCallback />} />
        <Route path="/v2/logout" element={<Logout />} />
        <Route path="/" element={secureRoute('any', <HomepageRedirect />)} />

        {pageRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={secureRoute(
              route.requiredPermissions,
              route.contextProvider ? (
                <route.contextProvider
                  pageConfig={route.pageConfig as PageConfigTranslated}
                >
                  <route.element />
                </route.contextProvider>
              ) : (
                <route.element />
              ),
              route.ignoreMaintenance,
            )}
          />
        ))}
        <Route element={<PaymentsContextLayout />}>
          <Route
            path="/v2/payments/business-entity/:id"
            element={secureRoute(
              'payments_operations',
              <PaymentsBusinessEntityPage />,
            )}
          />

          <Route
            path="/v2/payments/business-entity/:id/disbursement/:disbursementId"
            element={secureRoute(
              'payments_operations',
              <PaymentsDisbursementPage />,
            )}
          />
          {paymentsIntegratorRoutes.map((route, i) => (
            <Route
              key={route.path}
              path={route.path}
              element={secureRoute(route.permission, route.component)}
            />
          ))}
        </Route>
        {customerSuccessRoutes.map((route, i) => (
          <Route
            key={route.path}
            path={route.path}
            element={secureRoute(route.permission, route.component)}
          />
        ))}
        <Route
          path="/v2/settings"
          element={secureRoute('any', <UserSettingsPage />, true)}
        />
        <Route
          path="/v2/my-activity"
          element={secureRoute('any', <UserMyActivityPage />)}
        />
        <Route
          path="/v2/custom-reports-tabs"
          element={secureRoute('any', <CustomReportTabsPage />)}
        />
        <Route
          path="/v2/pdf-ecrion-admins"
          element={secureRoute(
            'ecrion_pdf_admins',
            <PdfEcrionAdminsPage />,
            true,
          )}
        />
        <Route
          path="/under-maintenance"
          element={secureRoute('any', <UnderMaintenance />)}
        />
        <Route
          path="/v2/custom-home"
          element={secureRoute('any', <HomeV2 />)}
        />
        {legacyRoutes.map((legacyRoute) => (
          <Route
            key={legacyRoute.path}
            path={legacyRoute.path}
            element={<LegacyRouteRewriter to={legacyRoute.to} />}
          />
        ))}
        <Route path="/403" element={<Forbidden />} />
        <Route path="/404" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};
