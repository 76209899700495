import { useTranslation } from 'react-i18next';
import { Card, Box } from '@procore/core-react';
import { getApi } from 'utils/api';
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { ProjectContext } from 'contexts/ProjectContext';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { ProjectsApi } from '@procore/ipa-nt-api-client-ts';
import type {
  ProjectChangeHistory1ApiDto,
  ProjectChangeHistory1HistoryInnerApiDtoCreatedBy,
} from '@procore/ipa-nt-api-client-ts';
import type { TableHeader } from 'components/Table/table.interface';
import TableBackend from 'components/TableBackend';

const ProjectConfigurationChangeHistoryPage = () => {
  const alert = useContext(IpaAlertContext);
  const context = useContext(ProjectContext);
  const { t: tCommon } = useTranslation(['common']);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(true);
  const [data, setData] = useState<ProjectChangeHistory1ApiDto>();

  const formatCreatedBy = (item: { [key: string]: string }, node: string) => {
    const createdBy = item[
      node
    ] as unknown as ProjectChangeHistory1HistoryInnerApiDtoCreatedBy;
    if (createdBy?.name && createdBy?.name !== ' ' && createdBy?.login) {
      return (
        <span>
          {createdBy.name} ({createdBy.login})
        </span>
      );
    } else if (createdBy?.login) {
      return <span>{createdBy.login}</span>;
    } else {
      return <span>Unknown</span>;
    }
  };

  const projectConfigurationChangeHistoryHeaders: TableHeader[] = [
    {
      title: tCommon('DATE'),
      node: 'created_at',
      is_datetime: true,
      is_sortable: true,
    },
    {
      title: tCommon('ACTION_BY'),
      node: 'created_by',
      is_sortable: false,
      display_func: formatCreatedBy,
    },
    {
      title: tCommon('CHANGED'),
      node: 'column',
      is_text: true,
      is_sortable: true,
    },
    {
      title: tCommon('FROM'),
      node: 'old_value',
      is_text: true,
      is_sortable: true,
    },
    {
      title: tCommon('TO'),
      node: 'new_value',
      is_text: true,
      is_sortable: true,
    },
  ];

  const changePage = (page: number) => {
    setCurrentPage(page);
    getProjectConfigChangeHistory(page);
  };

  const getProjectConfigChangeHistory = useCallback(
    async (page: number) => {
      setIsInit(false);
      setIsLoading(true);

      try {
        const api = getApi(ProjectsApi);
        const result = await api.projectControllerGetConfigurationChangeHistoryV10(
          context.env,
          context.companyId,
          context.projectId,
          page,
        );

        setData(result.data);
      } catch (e: any) {
        alert.error(e);
      } finally {
        setIsLoading(false);
        setIsInit(true);
      }
    },
    [context.projectId, context.companyId, context.env],
  );

  useEffect(() => {
    if (context.projectId) changePage(1);
  }, [getProjectConfigChangeHistory, context.projectId]);

  return (
    <React.Fragment>
      <Card>
        <Box padding="lg">
          <TableBackend
            data={data?.history || []}
            data_total={data?.total}
            current_page={currentPage}
            page_size={data?.page_size}
            headers={projectConfigurationChangeHistoryHeaders}
            default_sort_column="created_at"
            default_sort_direction={'asc'}
            show_search={false}
            show_no_data_message={true}
            no_data_message={tCommon('NO_DATA')}
            paginator={changePage}
            sorter={() => true}
            fixed_table={true}
          />
        </Box>
      </Card>
    </React.Fragment>
  );
};
export default ProjectConfigurationChangeHistoryPage;
