import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  createContext,
} from 'react';
import { Flex, Spinner } from '@procore/core-react';
import { getApi } from 'utils/api';
import { AxiosError } from 'axios';
import { ProjectsApi } from '@procore/ipa-nt-api-client-ts';
import type { ProjectSubmittalSettingsDto } from '@procore/ipa-nt-api-client-ts';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { ProjectContext } from 'contexts/ProjectContext';
import type { SubmittalSettingsContextProps } from './interfaces';
import SubmittalSettingsCard from './SubmittalSettingsCard';
import SubmittalProjectConfigurationCard from './SubmittalProjectConfigurationCard';
import SubmittalEmailsCard from './SubmittalEmailsCard';

export const SubmittalSettingsContext = createContext(
  {} as SubmittalSettingsContextProps,
);

const SubmittalSettingsPage = () => {
  const context = useContext(ProjectContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [projectSubmittalSettings, setProjectSubmittalSettings] =
    useState<ProjectSubmittalSettingsDto>();
  const [cardLoading, setCardLoading] = useState<{ [key: string]: boolean }>(
    {},
  );

  const alert = useContext(IpaAlertContext);

  const submittalSettingsDataSources: SubmittalSettingsContextProps['submittalSettingsDataSources'] =
    {
      submittalSettings: {
        id: 'submittalSettings',
        data: projectSubmittalSettings!,
      },
    };

  const getProjectSubmittalSettings = useCallback(async () => {
    setProjectSubmittalSettings(undefined);
    try {
      const api = getApi(ProjectsApi);
      const result = await api.projectControllerGetProjectSubmittalSettingsV10(
        context.env,
        context.companyId,
        context.projectId,
      );
      setProjectSubmittalSettings(result.data);
    } catch (e: any) {
      alert.error(e);
    }
  }, [context.projectId, context.companyId, context.env, alert]);

  const initPage = useCallback(async () => {
    const errors: AxiosError[] = [];
    setIsLoading(true);

    const promises = await Promise.all([getProjectSubmittalSettings()]);

    promises.forEach((promise: any) => {
      if (promise instanceof AxiosError) {
        errors.push(promise);
      }
    });
    if (errors.length > 0) {
      alert.error(errors);
    }
    setIsLoading(false);
  }, [getProjectSubmittalSettings, alert]);

  useEffect(() => {
    initPage();
  }, [context.env, context.companyId, context.projectId]);

  const cards = [
    {
      id: 'SubmittalSettingsGeneral',
      component: SubmittalSettingsCard,
      configKey: 'SubmittalSettings',
      save: () => {},
    },
    {
      id: 'SubmittalProjectConfiguration',
      component: SubmittalProjectConfigurationCard,
      configKey: 'SubmittalProjectConfiguration',
    },
    {
      id: 'SubmittalEmails',
      component: SubmittalEmailsCard,
      configKey: 'SubmittalEmails',
      save: () => {},
    },
  ];

  return (
    <SubmittalSettingsContext.Provider
      value={{
        submittalSettingsDataSources,
      }}
    >
      {isLoading ? (
        <Flex justifyContent="center">
          <Spinner loading={isLoading} />
        </Flex>
      ) : (
        <React.Fragment>
          {cards.map((card, idx) => (
            <div data-qa={`card-${card.id}`} key={card.id}>
              <card.component
                id={card.id}
                cardConfig={context.pageConfig.card(card.configKey)}
                save={card.save}
                isCardLoading={cardLoading[card.id]}
              />
            </div>
          ))}
        </React.Fragment>
      )}
    </SubmittalSettingsContext.Provider>
  );
};

export default SubmittalSettingsPage;
