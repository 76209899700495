import { memo } from 'react';
import styled from 'styled-components';
import { spacing, colors, Typography, Card } from '@procore/core-react';

export const AsideWrapper = styled.div`
  transition: width 0.3s;
  width: 150px;
  transition-timing-function: ease-in;

  &.closed {
    width: 0px;
    transition-timing-function: ease-in;
    aside > div {
      display: none;
    }
  }

  div > div > div {
    height: calc(100% - 100px);
  }
`;

export const DraggableCard = memo(styled(Card)`
  padding: ${spacing.lg}px;
  margin: ${spacing.lg}px;
  cursor: grab;
  width: 86px;
  text-align: center;

  &.used {
    cursor: not-allowed;
    opacity: 0.3 !important;
  }

  &.dropped {
    cursor: auto;
  }
`);

export const DraggableModuleLabel = memo(styled(Typography)`
  display: inline-block;
  line-height: ${spacing.lg}px;
`);

export const DropTargetWrapper = memo(styled.div`
  border: 1px dashed ${colors.gray50};
  height: 150px;
  width: 200px;
  color: ${colors.black};
  display: flex;
  justify-content: center;
  padding: ${spacing.md}px;
  position: relative;

  &.module {
    width: 400px;
    min-height: 140px;
    height: auto;
    padding-bottom: ${spacing.lg}px;
    justify-content: left;
    border: 1px solid ${colors.gray90};
    border-radius: ${spacing.sm}px;

    &.gds {
      width: 500px;
    }
  }

  &.gds {
    width: 500px;
  }
  &.wst {
    width: 600px;
  }

  .close {
    position: absolute;
    top: ${spacing.md}px;
    right: ${spacing.md}px;
    cursor: pointer;
    border: 1px solid ${colors.gray50};
    padding: ${spacing.xxs}px;
    border-radius: ${spacing.lg}px;
    height: 20px;
    width: 20px;
    text-align: center;
  }
`);

export const EmptyStateDescription = memo(styled(Typography)`
  min-width: 450px;
  display: block;
  li {
    text-align: left;
  }
`);
