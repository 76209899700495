import React, { useContext, useState } from 'react';
import { ContactContext } from 'contexts/ContactContext';
import {
  Box,
  H2,
  Title,
  Checkbox,
  Grid,
  ErrorBanner,
  Banner,
  Button,
} from '@procore/core-react';
import { ChevronDown, ChevronRight } from '@procore/core-icons';
import { useTranslation } from 'react-i18next';
import { ipaAppStyles } from 'styles';
import type { CompanyUserErpPermissionsDto } from '@procore/ipa-nt-api-client-ts';
import { PermissionsGridWrapper, styles } from './style';
import type { IEditableCardProps } from 'pages/Contact/interfaces';
import { InfoTabContext } from './index';

export const ErpPermissions = (props: IEditableCardProps) => {
  const { pageConfig } = useContext(ContactContext);
  const config = pageConfig.card('ErpPermissions');
  const { t } = useTranslation('common');
  const {
    dataSources: { erpPermissions },
  } = useContext(InfoTabContext);
  const [collapsed, setCollapsed] = useState<boolean>(false);

  type ErpFormProps = {
    erpPermissions: CompanyUserErpPermissionsDto;
  };

  const renderErpPermissionRow = (
    props: ErpFormProps,
    label: string,
    fieldName: string,
    style?: unknown,
  ) => {
    const importFieldName = `can_import_${fieldName}`;
    const exportFieldName = `can_export_${fieldName}`;

    const importValue =
      props.erpPermissions[
        importFieldName as keyof CompanyUserErpPermissionsDto
      ];
    const exportValue =
      props.erpPermissions[
        exportFieldName as keyof CompanyUserErpPermissionsDto
      ];

    return (
      <Grid.Row className={'permissions-grid-row'} style={style}>
        <Grid.Col colWidth={5}>{label}</Grid.Col>
        <Grid.Col colWidth={3}>
          {props.erpPermissions[
            importFieldName as keyof CompanyUserErpPermissionsDto
          ] === undefined ||
          props.erpPermissions[
            importFieldName as keyof CompanyUserErpPermissionsDto
          ] === 'N/A' ? (
            'N/A'
          ) : (
            <Checkbox
              name={importFieldName}
              checked={importValue === 'true'}
              disabled={true}
            />
          )}
        </Grid.Col>
        <Grid.Col colWidth={3}>
          {props.erpPermissions[
            exportFieldName as keyof CompanyUserErpPermissionsDto
          ] === undefined ||
          props.erpPermissions[
            exportFieldName as keyof CompanyUserErpPermissionsDto
          ] === 'N/A' ? (
            'N/A'
          ) : (
            <Checkbox
              name={exportFieldName}
              checked={exportValue === 'true'}
              disabled={true}
            />
          )}
        </Grid.Col>
      </Grid.Row>
    );
  };

  const ErpForm = (props: ErpFormProps) => {
    if (props.erpPermissions) {
      return (
        <PermissionsGridWrapper>
          <Grid>
            <Grid.Row
              className="permissions-grid-header"
              data-qa={'erp-permissions-grid-header'}
            >
              <Grid.Col colWidth={5}>
                {pageConfig.translate('ERP_PERMISSIONS_CARD.SYNC_TYPE')}
              </Grid.Col>
              <Grid.Col colWidth={3}>
                {pageConfig.translate('ERP_PERMISSIONS_CARD.CAN_IMPORT')}
              </Grid.Col>
              <Grid.Col colWidth={3}>
                {pageConfig.translate('ERP_PERMISSIONS_CARD.CAN_EXPORT')}
              </Grid.Col>
            </Grid.Row>
            {renderErpPermissionRow(
              props,
              pageConfig.translate('ERP_PERMISSIONS_CARD.VENDOR'),
              'vendors',
              styles.oddRow,
            )}
            {renderErpPermissionRow(
              props,
              pageConfig.translate('ERP_PERMISSIONS_CARD.BUDGET'),
              'budgets',
            )}
            {renderErpPermissionRow(
              props,
              pageConfig.translate('ERP_PERMISSIONS_CARD.JOB'),
              'jobs',
              styles.oddRow,
            )}
            {renderErpPermissionRow(
              props,
              pageConfig.translate('ERP_PERMISSIONS_CARD.SUB_JOB'),
              'sub_jobs',
            )}
            {renderErpPermissionRow(
              props,
              pageConfig.translate('ERP_PERMISSIONS_CARD.COMMITMENT'),
              'commitments',
              styles.oddRow,
            )}
            {renderErpPermissionRow(
              props,
              pageConfig.translate('ERP_PERMISSIONS_CARD.CHANGE_ORDER'),
              'commitment_change_orders',
            )}
            {renderErpPermissionRow(
              props,
              pageConfig.translate('ERP_PERMISSIONS_CARD.STANDARD_COST_CODE'),
              'standard_cost_codes',
              styles.oddRow,
            )}
            {renderErpPermissionRow(
              props,
              pageConfig.translate('ERP_PERMISSIONS_CARD.PRIME_CONTRACT'),
              'prime_contracts',
            )}
            {renderErpPermissionRow(
              props,
              pageConfig.translate(
                'ERP_PERMISSIONS_CARD.PRIME_CONTRACT_CHANGE_ORDER',
              ),
              'prime_contract_change_orders',
              styles.oddRow,
            )}
            {renderErpPermissionRow(
              props,
              pageConfig.translate(
                'ERP_PERMISSIONS_CARD.SUBCONTRACTOR_INVOICE',
              ),
              'requisitions',
            )}
            {renderErpPermissionRow(
              props,
              pageConfig.translate('ERP_PERMISSIONS_CARD.PAYMENT_APPLICATION'),
              'payment_applications',
              styles.oddRow,
            )}
          </Grid>
        </PermissionsGridWrapper>
      );
    } else {
      return (
        <Box>
          {pageConfig.translate('ERP_PERMISSIONS_CARD.NO_ERP_PERMISSIONS')}
        </Box>
      );
    }
  };

  return (
    <Box marginBottom="lg">
      <Title style={ipaAppStyles.titleMargin}>
        <Title.Assets>
          <Button
            data-qa="erp-permissions-collapse-btn"
            variant="tertiary"
            onClick={() => setCollapsed(!collapsed)}
          >
            {!collapsed ? <ChevronDown /> : <ChevronRight />}
          </Button>
        </Title.Assets>
        <Title.Text>
          <H2 data-qa={'erp-permission-title'}>
            {pageConfig.translate('ERP_PERMISSIONS_CARD.TITLE')}
          </H2>
        </Title.Text>
      </Title>
      {!props.error && !collapsed && (
        <ErpForm erpPermissions={erpPermissions?.data ?? undefined} />
      )}
      {props.error && !collapsed && (
        <ErrorBanner>
          <Banner.Content>
            <Banner.Title>{t('ERROR')}</Banner.Title>
            <Banner.Body>{config.translate('NO_ERP_PERMISSIONS')}</Banner.Body>
          </Banner.Content>
        </ErrorBanner>
      )}
    </Box>
  );
};
