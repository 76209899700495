import type { PageConfig } from 'pages/page-config.interfaces';

const pageConfig: PageConfig = {
  label: 'LOCATIONS',
  route: {
    page: 'locations',
  },
  endpoints: {
    view: [
      'projectControllerGetProjectLocations',
      'projectControllerGetProjectLocationsConfiguration',
    ],
  },
  cards: {
    Locations: {
      label: 'LOCATIONS',
      fields: {},
    },
    LocationsConfiguration: {
      i18n: {
        keyPrefix: 'LOCATIONS_CONFIGURATION_CARD',
      },
      label: 'TITLE',
      fields: {
        disable_dynamic_location_creation: {
          label: 'DISABLE_DYNAMIC_LOCATION_CREATION',
          tooltip: 'DISABLE_DYNAMIC_LOCATION_CREATION_TOOLTIP',
        },
        include_location_code: {
          label: 'INCLUDE_LOCATION_CODE',
        },
      },
    },
  },
};

export default pageConfig;
