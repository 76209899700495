import type { PageConfig } from 'pages/page-config.interfaces';

export default {
  id: 'PayoutFailures',
  route: {
    path: '/v2/payments?tab=payout-failures',
  },
  label: 'PAYOUT_FAILURES',
  endpoints: {
    view: [],
    edit: ['retry_payout_failure'],
  },
  cards: {},
} as PageConfig;
