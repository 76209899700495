import type { PageConfig } from 'pages/page-config.interfaces';

const OauthApplications: PageConfig = {
  route: {
    path: '/oauth-applications',
  },
  label: 'TITLE',
  i18n: {
    keyPrefix: 'NAV_OAUTH_APPLICATIONS',
  },
  cards: {
    EnvInfo: {
      i18n: {
        keyPrefix: 'NAV_OAUTH_APPLICATIONS',
      },
      label: 'TITLE',
      fields: {
        app_id: {
          label: 'ENV_INFO.APP_ID',
          tooltip: 'ENV_INFO.APP_ID_TOOLTIP',
        },
        secret: {
          label: 'ENV_INFO.SECRET',
          tooltip: 'ENV_INFO.SECRET_TOOLTIP',
        },
      },
    },
  },
};

export default OauthApplications;
