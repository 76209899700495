import React, { useState, useEffect, createContext, useCallback } from 'react';
import axios from 'axios';
import type { AxiosResponse } from 'axios';
import { useOktaAuth } from '@okta/okta-react';
import type { IAuthenticatedConfig } from 'interfaces/common.interface';
import { UserSettingsApi } from '@procore/ipa-nt-api-client-ts';
import type {
  PublicConfigDto,
  UserSettingDto,
} from '@procore/ipa-nt-api-client-ts';
import { getApi } from 'utils/api';

export interface AppContextProps {
  publicConfig: PublicConfigDto;
  userConfig: IAuthenticatedConfig;
  userSettings: UserSettingDto;
  authHeaderPresent: boolean;
  updateAuthHeaderPresent(present: boolean): void;
  updateUserSettings(settings: UserSettingDto): void;
  reloadUserConfig(): Promise<void>;
  checkUserAction(action: string): boolean;
  getLoginError(): boolean;
  env: string;
}

interface ContextProps {
  children: JSX.Element;
  authHeaderPresent?: boolean;
}

interface ContextConsumerProps {
  children: any;
}

export const AppContext = createContext({} as AppContextProps);

export const AppContextProvider = (props: ContextProps) => {
  const publicConfig: PublicConfigDto = (window as any).config;
  const [userSettings, setUserSettings] = useState<UserSettingDto>(
    {} as UserSettingDto,
  );
  const { authState } = useOktaAuth();
  const [userConfig, setUserConfig] = useState<IAuthenticatedConfig>(
    {} as IAuthenticatedConfig,
  );
  const [userActions, setUserActions] = useState<string[]>([]);
  const [loginError, setLoginError] = useState<boolean>(false);
  const params = new URL(document.location.toString()).searchParams;
  const [authHeaderPresent, setAuthHeaderPresent] = useState<boolean>(
    props.authHeaderPresent ?? false,
  );
  const env = window.location.href.includes('payments-integrator-sandbox')
    ? 'INTEGRATOR_SANDBOX'
    : (params.get('env') ?? 'PROD');

  const updateAuthHeaderPresent = (present: boolean) => {
    setAuthHeaderPresent(present);
  };

  const updateUserSettings = (settings: UserSettingDto) => {
    setUserSettings(settings);
    localStorage.setItem(
      'user-settings',
      JSON.stringify({ settings: settings }),
    );
  };

  const getLoginError = () => {
    return loginError;
  };

  const fetchUserConfig = useCallback(
    async (refresh: boolean) => {
      try {
        if (authState && authHeaderPresent && (refresh || !userConfig?.user)) {
          const res: AxiosResponse = await axios.get(
            `/v1.0/config/authenticated`,
          );
          const roles: AxiosResponse = await axios.get(
            `/v1.0/permissions/roles`,
          );
          const systemSettings: AxiosResponse = await axios.get(
            `/v1.0/system-settings`,
          );
          setUserActions(res.data.user.actions);
          setUserConfig({
            ...res.data,
            bugsnag: res.data.bugsnag,
            permissionActions: res.data.user.actions,
            maintenanceMode: systemSettings.data.maintenance_mode,
          });
        }
      } catch (error: any) {
        setLoginError(true);
      }
    },
    [authState],
  );

  const reloadUserConfig = async () => {
    return await fetchUserConfig(true);
  };

  useEffect(() => {
    const fetchUserSettings = async () => {
      const api = await getApi(UserSettingsApi);
      try {
        if (authState && authHeaderPresent) {
          const settings = await api.settingsControllerGetV10();
          setUserSettings(settings.data);
          localStorage.setItem(
            'user-settings',
            JSON.stringify({ settings: settings.data }),
          );
        }
      } catch (error: any) {
        if (error.response?.status === 404 && authState) {
          // user has no settings yet, so we create a default settings for them
          const settings =
            await api.settingsControllerCreateDefaultSettingsV10();
          setUserSettings(settings.data);
        } else {
          console.log(error);
        }
      }
    };

    if (localStorage.getItem('user-settings')) {
      const temp = JSON.parse(
        localStorage.getItem('user-settings') ||
          JSON.stringify({ settings: {} }),
      ).settings;
      if (temp.enable_nestjs_urls || !temp.gds_views) {
        fetchUserSettings();
      } else {
        setUserSettings(temp);
      }
    } else if (authState && authState.isAuthenticated) {
      fetchUserSettings();
    }

    if (authState && authState.isAuthenticated) {
      fetchUserConfig(false);
    }
  }, [authState, fetchUserConfig]);

  const checkUserAction = (action: string) => {
    return userActions.includes(action);
  };

  return (
    <AppContext.Provider
      value={{
        publicConfig,
        userSettings,
        authHeaderPresent,
        updateAuthHeaderPresent,
        updateUserSettings,
        userConfig,
        reloadUserConfig,
        checkUserAction,
        getLoginError,
        env,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export const AppContextConsumer = (props: ContextConsumerProps) => {
  return <AppContext.Consumer>{props.children}</AppContext.Consumer>;
};

export default AppContextProvider;
