import type { SectionConfig } from 'pages/page-config.interfaces';
import ProjectPermissionTemplates from './ProjectPermissionTemplates/page.config';
import OutboundEmailsReport from 'pages/Contact/OutboundEmailsReport/page.config';
import ProjectSettings from './ProjectSettings/page.config';
import CompanyCostCodes from './CompanyCostCodes/page.config';
import CustomForms from './CustomForms/page.config';

const contactSectionConfig: SectionConfig = {
  id: 'Contact',
  route: {
    path: '/v2/company/:company_id/contact/:contact_id/login/:login_id/:page',
  },
  i18n: {
    namespace: 'contact',
  },
  permissions: {
    view: ['company_tab_view', 'cs_view'],
    edit: ['company_tab_edit', 'cs_edit'],
  },
  pages: {
    ContactInfo: {
      route: {
        page: 'contact-info',
        tab: 'info',
      },
      i18n: {
        keyPrefix: 'CONTACT_INFO_PAGE',
      },
      label: 'TITLE',
      cards: {
        PersonalInfo: {
          i18n: {
            keyPrefix: 'CONTACT_INFO_PAGE.PERSONAL_INFO_CARD',
          },
          label: 'TITLE',
          fields: {
            first_name: {
              label: 'FIRST_NAME',
            },
            last_name: {
              label: 'LAST_NAME',
            },
            email: {
              label: 'EMAIL',
            },
            login: {
              label: 'LOGIN',
            },
            vendor_name: {
              label: 'VENDOR_NAME',
            },
            last_login_at: {
              label: 'LAST_LOGIN_AT',
            },
          },
        },
        OfficeInfo: {
          i18n: {
            keyPrefix: 'CONTACT_INFO_PAGE.OFFICE_INFO_CARD',
          },
          label: 'TITLE',
          fields: {},
        },
        AvailableCompanyTools: {
          i18n: {
            keyPrefix: 'CONTACT_INFO_PAGE.AVAILABLE_COMPANY_TOOLS_CARD',
          },
          label: 'TITLE',
          fields: {},
        },
        CompanyToolPermissions: {
          i18n: {
            keyPrefix: 'CONTACT_INFO_PAGE.COMPANY_TOOL_PERMISSIONS_CARD',
          },
          label: 'TITLE',
          fields: {},
        },
        ErpPermissions: {
          i18n: {
            keyPrefix: 'CONTACT_INFO_PAGE.ERP_PERMISSIONS_CARD',
          },
          label: 'TITLE',
          fields: {},
        },
        ErpIntegrations: {
          i18n: {
            keyPrefix: 'CONTACT_INFO_PAGE.ERP_INTEGRATIONS_CARD',
          },
          label: 'TITLE',
          fields: {},
        },
      },
    },

    Projects: {
      route: {
        page: 'contact-info',
        tab: 'projects',
      },
      i18n: {
        keyPrefix: 'PROJECTS_PAGE',
      },
      label: 'TITLE',
      cards: {
        ProjectsBelongingTo: {
          i18n: {
            keyPrefix: 'PROJECTS_PAGE.PROJECTS_BELONGING_TO_CARD',
          },
          label: 'TITLE',
          fields: {},
        },
        ProjectsNotBelongingTo: {
          i18n: {
            keyPrefix: 'PROJECTS_PAGE.PROJECTS_NOT_BELONGING_TO_CARD',
          },
          label: 'TITLE',
          fields: {},
        },
      },
    },

    Settings: {
      route: {
        page: 'contact-info',
        tab: 'settings',
      },
      i18n: {
        keyPrefix: 'SETTINGS_PAGE',
      },
      label: 'TITLE',
      cards: {
        ContactSettings: {
          i18n: {
            keyPrefix: 'SETTINGS_PAGE.CONTACT_SETTINGS_CARD',
          },
          label: 'TITLE',
          fields: {
            send_user_messages: {
              label: 'SEND_USER_MESSAGES',
            },
            is_employee: {
              label: 'IS_EMPLOYEE',
            },
            is_insurance_manager: {
              label: 'IS_INSURANCE_MANAGER',
              tooltip: 'IS_INSURANCE_MANAGER_TOOLTIP',
            },
            country_language: {
              label: 'COUNTRY_LANGUAGE',
            },
            persona: {
              label: 'PERSONA',
            },
            can_run_extracts: {
              label: 'CAN_RUN_EXTRACTS',
            },
          },
        },
        OutboundEmailSettings: {
          i18n: {
            keyPrefix: 'SETTINGS_PAGE.OUTBOUND_EMAIL_SETTINGS_CARD',
          },
          label: 'TITLE',
          fields: {},
        },
        EsticomSettings: {
          i18n: {
            keyPrefix: 'SETTINGS_PAGE.ESTICOM_SETTINGS_CARD',
          },
          label: 'TITLE',
          fields: {},
        },
        AdvancedSecuritySettings: {
          i18n: {
            keyPrefix: 'SETTINGS_PAGE.ADVANCED_SECURITY_SETTINGS_CARD',
          },
          label: 'TITLE',
          fields: {},
        },
        CorrespondenceSettings: {
          i18n: {
            keyPrefix: 'SETTINGS_PAGE.CORRESPONDENCE_SETTINGS_CARD',
          },
          label: 'TITLE',
          fields: {},
        },
        WorkflowAdminOverrideSettings: {
          i18n: {
            keyPrefix: 'SETTINGS_PAGE.WORKFLOW_ADMIN_OVERRIDE_SETTINGS_CARD',
          },
          label: 'TITLE',
          fields: {},
        },
        NewProjectSettings: {
          i18n: {
            keyPrefix: 'SETTINGS_PAGE.NEW_PROJECT_SETTINGS_CARD',
          },
          label: 'TITLE',
          fields: {
            add_to_new_projects: {
              label: 'ADD_TO_NEW_PROJECTS',
            },
            can_add_new_projects: {
              label: 'CAN_ADD_NEW_PROJECTS',
              tooltip: 'CAN_ADD_NEW_PROJECTS_TOOLTIP',
            },
          },
        },
      },
    },

    ChangeHistory: {
      route: {
        page: 'contact-info',
        tab: 'change-history',
      },
      i18n: {
        keyPrefix: 'CHANGE_HISTORY_PAGE',
      },
      label: 'TITLE',
      cards: {
        ChangeHistoryList: {
          i18n: {
            keyPrefix: 'CHANGE_HISTORY_PAGE',
          },
          label: 'TITLE',
          fields: {},
        },
      },
    },
    ProjectPermissionTemplates,
    OutboundEmailsReport,
    ProjectSettings,
    CompanyCostCodes,
    CustomForms,
  },
};

export default contactSectionConfig;
