import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from 'contexts/AppContext';
import { ContactContext } from 'contexts/ContactContext';
import { Box, Title, H2, Spinner, Flex } from '@procore/core-react';
import TableBackendComponent from 'components/TableBackend';
import { useTranslation } from 'react-i18next';
import { ipaAppStyles } from 'styles';
import { CompanyContactsApi } from '@procore/ipa-nt-api-client-ts';
import type { ContactChangeHistoryDto } from '@procore/ipa-nt-api-client-ts';
import { getApi } from 'utils/api';
import { styles } from 'components/JitAuditLog/styles';
import { Check } from '@procore/core-icons';
import { IpaAlertContext } from 'contexts/IpaAlertContext';

export const ChangeHistoryList = () => {
  const { t } = useTranslation('contact');
  const { userSettings } = useContext(AppContext);
  const alert = useContext(IpaAlertContext);
  const { contactId, companyId, env } = useContext(ContactContext);
  const [changeHistory, setChangeHistory] = useState<ContactChangeHistoryDto[]>(
    [],
  );
  const [total, setTotal] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(
    userSettings.default_page_size ?? 25,
  );
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);

  const changePageSize = (pageSize: number) => {
    setCurrentPage(1);
    setPerPage(pageSize);
  };

  const changePage = (page: number, pageSize: number) => {
    setCurrentPage(page);
  };

  const getChangeHistory = useCallback(async () => {
    setLoading(true);
    setChangeHistory([]);
    alert.closeAlert();
    try {
      const api = getApi(CompanyContactsApi);
      const res = await api.companyContactsControllerGetChangeHistoryV10(
        env,
        companyId,
        contactId,
        currentPage,
        perPage,
      );

      if (res.data) {
        setTotal(res.data.total);
        setChangeHistory(res.data.items);
      }
    } catch (e: any) {
      alert.error(e);
    } finally {
      setLoading(false);
    }
  }, [currentPage, perPage, contactId, companyId, env]);

  useEffect(() => {
    getChangeHistory();
  }, [currentPage, perPage, contactId, companyId, env]);

  const changeHistoryHeaders = [
    {
      title: t('CHANGE_HISTORY_PAGE.DATE'),
      node: 'created_at',
      is_sortable: false,
      is_datetime: true,
    },
    {
      title: t('CHANGE_HISTORY_PAGE.ACTION_BY'),
      node: 'created_by_email',
      is_sortable: false,
      is_text: true,
    },
    {
      title: t('CHANGE_HISTORY_PAGE.CHANGED'),
      node: 'column',
      is_sortable: false,
      is_text: true,
    },
    {
      title: t('CHANGE_HISTORY_PAGE.FROM'),
      node: 'old_value',
      is_sortable: false,
      is_text: true,
    },
    {
      title: t('CHANGE_HISTORY_PAGE.TO'),
      node: 'new_value',
      is_sortable: false,
      is_text: true,
    },

    {
      title: t('CHANGE_HISTORY_PAGE.SUPERUSER_ONLY'),
      node: 'superuser_only',
      display_func: (item: ContactChangeHistoryDto) => {
        return item.superuser_only ? (
          <Check size="sm" style={styles.checkIcon} />
        ) : (
          <span />
        );
      },
      is_sortable: false,
    },
  ];

  return (
    <Box padding="lg">
      <Title style={ipaAppStyles.titleMargin}>
        <Title.Text>
          <H2 data-qa={`change-history-title`} />
        </Title.Text>
      </Title>
      {loading && (
        <Flex justifyContent="center">
          <Spinner loading={loading} />
        </Flex>
      )}
      {!loading && (
        <TableBackendComponent
          data-qa="change-history-table"
          data={changeHistory}
          data_total={total}
          current_page={currentPage}
          page_size={perPage}
          show_page_size_select={true}
          page_size_action={changePageSize}
          show_no_data_message={true}
          no_data_message={t('CHANGE_HISTORY_PAGE.NO_CHANGE_HISTORY')}
          default_sort_column=""
          default_sort_direction={'asc'}
          paginator={changePage}
          headers={changeHistoryHeaders}
          sorter={() => {}}
          fixed_table={true}
        />
      )}
    </Box>
  );
};
