import type { BusinessEntityDto } from '@procore/ipa-nt-api-client-ts';
import type { ISelectOption } from 'interfaces/common.interface';

export const getFeePaySettingsOptions = (t: (key: string) => string) => {
  const feeStatusOptions: ISelectOption[] = [
    { id: 'feeDisabled', label: t('DISABLE') },
    { id: 'feeEnabled', label: t('ENABLE') },
  ];

  const feePayTypeOptions: ISelectOption[] = [
    { id: 'SC_PAYS', label: t('GC_PAYS_DISABLED') },
    { id: 'GC_PAYS', label: t('GC_PAYS_ENABLED') },
  ];

  const feeCollectionStageOptions: ISelectOption[] = [
    { id: 'PAYOUT_COMPLETED', label: t('FEE_COLLECTION_AT_PAYOUT_COMPLETED') },
    { id: 'INVOICE_APPROVAL', label: t('FEE_COLLECTION_AT_INVOICE_APPROVAL') },
  ];

  const invoiceBlockingOptions: ISelectOption[] = [
    { id: 'ENABLED', label: t('INVOICE_BLOCKING_ENABLED') },
    { id: 'DISABLED', label: t('INVOICE_BLOCKING_DISABLED') },
  ];

  const dataMap = [
    {
      display_name: t('FEE_PAY_STATUS'),
      name: 'applyFeesOnAgreements',
    },
    {
      display_name: t('FEE_PAY_TYPE'),
      name: 'payorPaysFeesFeatureEnabled',
    },
    {
      display_name: t('FEE_COLLECTION'),
      name: 'payeeFeeCollectionStage',
    },
    {
      display_name: t('INVOICE_BLOCKING'),
      name: 'requiresPayAgreementForInvoiceSubmission',
    },
  ];

  return {
    feeStatusOptions,
    feePayTypeOptions,
    feeCollectionStageOptions,
    invoiceBlockingOptions,
    dataMap,
  };
};

export const transformDataForForm = (
  data: any,
  options: ReturnType<typeof getFeePaySettingsOptions>,
) => {
  return {
    applyFeesOnAgreements: data?.applyFeesOnAgreements
      ? options.feeStatusOptions[1]
      : options.feeStatusOptions[0],
    payorPaysFeesFeatureEnabled: data?.payorPaysFeesFeatureEnabled
      ? options.feePayTypeOptions[1]
      : options.feePayTypeOptions[0],
    payeeFeeCollectionStage:
      options.feeCollectionStageOptions.find(
        (option) => option.id === data?.payeeFeeCollectionStage,
      ) || options.feeCollectionStageOptions[0],
    requiresPayAgreementForInvoiceSubmission:
      data?.requiresPayAgreementForInvoiceSubmission
        ? options.invoiceBlockingOptions[0]
        : options.invoiceBlockingOptions[1],
  };
};

export const transformFormValuesToRaw = (values: any) => {
  return {
    applyFeesOnAgreements: values.applyFeesOnAgreements?.id === 'feeEnabled',
    payorPaysFeesFeatureEnabled:
      values.payorPaysFeesFeatureEnabled?.id === 'GC_PAYS',
    payeeFeeCollectionStage: values.payeeFeeCollectionStage?.id,
    requiresPayAgreementForInvoiceSubmission:
      values.requiresPayAgreementForInvoiceSubmission?.id === 'ENABLED',
  };
};

export const getDisplayValueForComparisonGrid = (
  key: string,
  rawValue: BusinessEntityDto,
  options: ReturnType<typeof getFeePaySettingsOptions>,
) => {
  switch (key) {
    case 'applyFeesOnAgreements':
      return rawValue[key]
        ? options.feeStatusOptions[1].label
        : options.feeStatusOptions[0].label;
    case 'payorPaysFeesFeatureEnabled':
      return rawValue[key]
        ? options.feePayTypeOptions[1].label
        : options.feePayTypeOptions[0].label;
    case 'payeeFeeCollectionStage':
      return (
        options.feeCollectionStageOptions.find(
          (option) => option.id === rawValue[key],
        )?.label || ''
      );
    case 'requiresPayAgreementForInvoiceSubmission':
      return rawValue[key]
        ? options.invoiceBlockingOptions[0].label
        : options.invoiceBlockingOptions[1].label;
    default:
      return rawValue?.toString() || '';
  }
};
