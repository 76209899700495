import React from 'react';
import { Popover, H2, H3, Box, Typography, Tooltip } from '@procore/core-react';
import { Link } from 'components/Link';
import { IconWrapper, SupportDocsWrapper, styles } from './styles';
import { useTranslation } from 'react-i18next';
import { Help } from '@procore/core-icons';

const SupportDocumentation = () => {
  const { t } = useTranslation('header', { keyPrefix: 'SUPPORT_DOCS' });

  const supportDocItems = [
    {
      title: 'PROCORE_STATUS',
      url: 'https://status.procore.com/',
    },
    {
      title: 'PROCORE_SUPPORT',
      url: 'https://support.procore.com/',
    },
  ];

  const ipaShortcuts = [
    {
      keys: 'CTRL + J',
      description: 'JIT_ESCALATION',
    },
    {
      keys: 'CTRL + P',
      description: 'PROJECT_LOOKUP',
    },
    {
      keys: 'CTRL + I',
      description: 'INFO_OVERLAY',
    },
  ];

  const supportDocsOverlay = (
    <Popover.Content
      style={styles.ipaReleasePopover}
      data-qa="support-documentation-overlay"
    >
      <SupportDocsWrapper>
        <H2>{t('TITLE')}</H2>
        <Box marginLeft="sm" marginBottom="xl">
          {supportDocItems.map((item, i) => (
            <Box key={i} marginBottom="xs">
              <Typography>
                <Link href={item.url} target="_blank">
                  {t(item.title)}
                </Link>
              </Typography>
            </Box>
          ))}
        </Box>
        <Box margin="sm">
          <H3>{t('IPA_SHORTCUTS')}</H3>
          <Box marginTop="sm">
            {ipaShortcuts.map((item, i) => (
              <Box key={i} marginBottom="xs">
                <Typography>
                  <strong>{item.keys}: </strong>
                  {t(item.description)}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </SupportDocsWrapper>
    </Popover.Content>
  );

  return (
    <Tooltip overlay={t('TITLE')} trigger="hover">
      <Popover
        aria-labelledby="documentation"
        overlay={supportDocsOverlay}
        placement="bottom-left"
      >
        {() => (
          <IconWrapper data-qa="ipa-nav-support-documentation">
            <Help />
          </IconWrapper>
        )}
      </Popover>
    </Tooltip>
  );
};

export default SupportDocumentation;
