import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, H2, Typography } from '@procore/core-react';
import { Link } from 'components/Link';
import { ContactContext } from 'contexts/ContactContext';
import type { IEditableCardProps } from 'pages/Contact/interfaces';
import { getContextQueryParams } from 'pages/Contact/urlGenerator';

export const OfficeInfoCard = (props: IEditableCardProps) => {
  const context = useContext(ContactContext);

  const { t } = useTranslation(['contact'], {
    keyPrefix: 'CONTACT_INFO_PAGE.OFFICE_INFO_CARD',
  });

  const contextQueryParams = getContextQueryParams(context);

  return (
    <Box>
      <H2>{t('TITLE')}</H2>
      <Box marginTop="sm">
        <Typography intent="body">
          <Link href={`/v2/company/${context.companyId}?${contextQueryParams}`}>
            {t('CARD_MOVED_MESSAGE')}
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default OfficeInfoCard;
