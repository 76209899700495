import React from 'react';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { useContext } from 'react';
import type { IEditableCard } from 'components/EditableCard';
import { getErrorMessage } from 'utils/api';
import ProjectInfoCard from './ProjectInfoCard';
import type { ProjectSettingsProps } from '../interfaces';
import { useTranslation } from 'react-i18next';
import { PageConfig } from 'pages/page-config.service';

const ProjectInfoTab = (props: ProjectSettingsProps) => {
  const { t } = useTranslation(['common']);
  const config = PageConfig.get(t, 'Project.ProjectInfo');

  const alert = useContext(IpaAlertContext);

  const saveChanges = async (
    card: IEditableCard,
    values: any,
    reason?: string,
  ) => {
    alert.closeAlert();
    props.setCardLoading(card.id, true);
    const operations = [];

    if (values.projectSettings) {
      const field = card.fields?.find(
        (f) => f.name === 'inbound_email_address',
      );
      if (values.projectSettings.inbound_email_address && field.noEditChunk) {
        values.projectSettings.inbound_email_address += `-${field.noEditChunk}`;
      }
      operations.push(
        props
          .updateProjectSettingsRequest(values.projectSettings, reason)
          .then(props.getProjectSettings),
      );
    }

    const results = await Promise.allSettled(operations);
    props.setCardLoading(card.id, false);

    const errors = results.flatMap((result, index: number): string | string[] =>
      result.status === 'rejected'
        ? getErrorMessage(result.reason) ||
          config.translate('THERE_WAS_ERROR', { ns: 'common' })
        : [],
    );

    if (errors.length > 0) {
      alert.error(errors.join('\n'));
    } else {
      alert.success(config.translate('CARD_UPDATED', { CARD: card.label }));
    }
  };

  return (
    <div>
      <ProjectInfoCard id="project-info-card" save={saveChanges} />
    </div>
  );
};
export default ProjectInfoTab;
