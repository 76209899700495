import type { PageConfig } from 'pages/page-config.interfaces';

const pageConfig: PageConfig = {
  label: 'SCHEDULE_SETTINGS',
  route: {
    page: 'schedule-settings',
  },
  endpoints: {
    view: ['projectControllerGetProjectScheduleSettings'],
    edit: ['projectControllerUpdateProjectScheduleSettings'],
  },
  cards: {
    ThirdPartyIntegration: {
      route: {
        tab: 'settings',
      },
      i18n: {
        keyPrefix: 'PROJECT_SCHEDULE_SETTINGS.SETTINGS_TAB',
      },
      label: '3RD_PARTY_INTEGRATION',
      fields: {
        project_integration: {
          label: 'PROJ_INT',
        },
      },
    },

    UploadProjectScheduleFiles: {
      route: {
        tab: 'settings',
      },
      i18n: {
        keyPrefix: 'PROJECT_SCHEDULE_SETTINGS.SETTINGS_TAB',
      },
      label: 'UPLOAD_PROJ',
      fields: {
        schedule_type: {
          label: 'SCHEDULE_TYPE',
        },
      },
    },

    ProjectScheduleEmail: {
      route: {
        tab: 'settings',
      },
      i18n: {
        keyPrefix: 'PROJECT_SCHEDULE_SETTINGS.SETTINGS_TAB',
      },
      label: 'PROJECT_SCHEDULE_EMAIL',
      fields: {
        sub_label: {
          label: 'PROJECT_SCHEDULE_EMAIL_SUB_LABEL',
        },
        frequency: { label: 'PROJECT_SCHEDULE_EMAIL_FREQUENCY' },
        weekly: { label: 'PROJECT_SCHEDULE_EMAIL_WEEKLY' },
        time: {
          label: 'PROJECT_SCHEDULE_EMAIL_TIME',
          tooltip: 'PROJECT_SCHEDULE_EMAIL_TIME_TOOLTIP',
        },
        coverage: {
          label: 'PROJECT_SCHEDULE_EMAIL_COVERAGE',
          tooltip: 'PROJECT_SCHEDULE_EMAIL_COVERAGE_TOOLTIP',
        },
        last_sent: { label: 'PROJECT_SCHEDULE_EMAIL_LAST_SENT' },
        next_scheduled: { label: 'PROJECT_SCHEDULE_EMAIL_NEXT_SCHEDULED' },
      },
    },

    ResourceScheduleEmail: {
      route: {
        tab: 'settings',
      },
      i18n: {
        keyPrefix: 'PROJECT_SCHEDULE_SETTINGS.SETTINGS_TAB',
      },
      label: 'RESOURCE_SCHEDULE_EMAIL',
      fields: {
        sub_label: {
          label: 'RESOURCE_SCHEDULE_EMAIL_SUB_LABEL',
        },
        frequency: { label: 'PROJECT_SCHEDULE_EMAIL_FREQUENCY' },
        weekly: { label: 'PROJECT_SCHEDULE_EMAIL_WEEKLY' },
        time: {
          label: 'PROJECT_SCHEDULE_EMAIL_TIME',
          tooltip: 'PROJECT_SCHEDULE_EMAIL_TIME_TOOLTIP',
        },
        coverage: {
          label: 'PROJECT_SCHEDULE_EMAIL_COVERAGE',
          tooltip: 'PROJECT_SCHEDULE_EMAIL_COVERAGE_TOOLTIP',
        },
        last_sent: { label: 'PROJECT_SCHEDULE_EMAIL_LAST_SENT' },
        next_scheduled: { label: 'PROJECT_SCHEDULE_EMAIL_NEXT_SCHEDULED' },
      },
    },

    LookaheadScheduleEmail: {
      route: {
        tab: 'settings',
      },
      i18n: {
        keyPrefix: 'PROJECT_SCHEDULE_SETTINGS.SETTINGS_TAB',
      },
      label: 'LOOKAHEAD_SCHEDULE_EMAIL',
      fields: {
        sub_label: {
          label: 'LOOKAHEAD_SCHEDULE_EMAIL_SUB_LABEL',
        },
        frequency: { label: 'PROJECT_SCHEDULE_EMAIL_FREQUENCY' },
        weekly: { label: 'PROJECT_SCHEDULE_EMAIL_WEEKLY' },
        time: {
          label: 'PROJECT_SCHEDULE_EMAIL_TIME',
          tooltip: 'PROJECT_SCHEDULE_EMAIL_TIME_TOOLTIP',
        },
        coverage: {
          label: 'PROJECT_SCHEDULE_EMAIL_COVERAGE',
          tooltip: 'PROJECT_SCHEDULE_EMAIL_COVERAGE_TOOLTIP',
        },
        last_sent: { label: 'PROJECT_SCHEDULE_EMAIL_LAST_SENT' },
        next_scheduled: { label: 'PROJECT_SCHEDULE_EMAIL_NEXT_SCHEDULED' },
      },
    },

    ProjectTasks: {
      route: {
        tab: 'settings',
      },
      i18n: {
        keyPrefix: 'PROJECT_SCHEDULE_SETTINGS.SETTINGS_TAB',
      },
      label: 'PROJ_TASKS',
      fields: {
        display_task_names_with_full_outline_path: {
          label: 'DISPLAY_TASKS',
        },
        schedule_show_resources_on_calendar: {
          label: 'DISPLAY_RESOURCES',
        },
        schedule_allow_task_updates: {
          label: 'ALLOW_SCHEDULE',
        },
        schedule_task_auto_formatting: {
          label: 'ENABLE_SHCEDULE',
        },
      },
    },

    ScheduleSidebar: {
      route: {
        tab: 'settings',
      },
      i18n: {
        keyPrefix: 'PROJECT_SCHEDULE_SETTINGS.SETTINGS_TAB',
      },
      label: 'SCHEDULE_SIDEBAR',
      fields: {
        create_calendar_item_enabled: {
          label: 'ENABLE_CALENDAR',
        },
        calendar_people_filters_enabled: {
          label: 'ENABLE_FILTER',
        },
      },
    },

    Permissions: {
      route: {
        tab: 'permissions',
      },
      i18n: {
        keyPrefix: 'PROJECT_SCHEDULE_SETTINGS',
      },
      label: 'PERMISSIONS',
      fields: {},
    },
  },
};

export default pageConfig;
