import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Flex, Spinner, Box, EmptyState } from '@procore/core-react';
import TableBackendComponent from 'components/TableBackend';
import type { TableHeader } from 'components/TableBackend/table.interface';
import { AppContext } from 'contexts/AppContext';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { getApi } from 'utils/api';
import { PayoutsApi } from '@procore/ipa-nt-api-client-ts';
import type { ReceivedPayoutDto } from '@procore/ipa-nt-api-client-ts';
import { PaymentsContext } from 'contexts/PaymentsContext';
import {
  formatCurrency,
  getPayoutStatus,
  getRouteForEnv,
} from 'pages/Payments/payments.helper';

const ReceivedPayoutsTab = () => {
  const { userSettings, env } = useContext(AppContext);
  const { companyId } = useContext(PaymentsContext);
  const alert = useContext(IpaAlertContext);
  const { t: tCommon } = useTranslation(['common']);
  const { t } = useTranslation(['payments'], {
    keyPrefix: 'BUSINESS_ENTITY.RECEIVED_PAYOUTS_TAB',
  });
  const { t: tStatus } = useTranslation(['payments'], {
    keyPrefix: 'STATUS',
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(
    (userSettings.default_page_size as number) || 25,
  );
  const [receivedPayouts, setReceivedPayouts] = useState<ReceivedPayoutDto[]>(
    [],
  );

  const tableHeaders: TableHeader[] = [
    {
      title: t('CREATED_ON'),
      node: 'createdAt',
      is_datetime: true,
      is_sortable: false,
      column_width: 15,
    },
    {
      title: t('INVOICE_ID'),
      node: 'invoiceId',
      is_text: true,
      is_sortable: false,
      column_width: 5,
    },
    {
      title: t('AMOUNT'),
      node: 'amount',
      display_func: (item) => {
        return (
          <span>{formatCurrency(item.currencyCode, item.amount / 100)}</span>
        );
      },
      is_sortable: false,
      column_width: 10,
    },
    {
      title: t('PROJECT'),
      node: 'metadata',
      is_sortable: false,
      display_func: (item, node) => {
        return item[node]['project_name'];
      },
      column_width: 15,
    },
    {
      title: t('STATUS'),
      node: 'status',
      is_sortable: false,
      display_func: (item: ReceivedPayoutDto, node: string) => {
        return getPayoutStatus(item.status, tStatus);
      },
      column_width: 10,
    },
    {
      title: t('DISBURSEMENT_ID'),
      node: 'disbursementId',
      is_link: true,
      url: `${getRouteForEnv(
        env,
        '/business-entity/{sendingPaymentsCompanyExternalId}/disbursement/{disbursementId}/general',
      )}`,
      column_width: 20,
    },
    {
      title: t('SENDING_COMPANY'),
      node: 'metadata',
      is_sortable: false,
      display_func: (item, node) => {
        return item[node]['sending_company_name'];
      },
      column_width: 10,
    },
    {
      title: t('RECEIVING_BANK_ACCOUNT'),
      node: 'receivingExternalAccountNickname',
      is_sortable: false,
      display_func: (item, node) => {
        return `${item[node]} (...${item['receivingExternalAccountLastFour']})`;
      },
      column_width: 15,
    },
  ];

  const changePage = (page: number, _pageSize: number) => {
    setCurrentPage(page);
  };

  const getReceivedPayouts = async (
    companyId: number,
    currentPage: number,
    perPage: number,
  ) => {
    alert.closeAlert();
    setIsLoading(true);
    try {
      const api: PayoutsApi = getApi(PayoutsApi);
      const result = await api.payoutControllerGetReceivedPayoutsV10(
        +companyId,
        currentPage,
        perPage,
        env,
      );
      return result.data;
    } catch (e: any) {
      alert.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (companyId) {
      if (!isInit) {
        setIsLoading(true);
      }

      const nextPage = async () => {
        const res = await getReceivedPayouts(+companyId, currentPage, perPage);

        if (res?.items) {
          setReceivedPayouts(res.items);
          setTotal(res.total);
          setIsLoading(false);
          setIsInit(true);
        }
      };
      nextPage();
    }
  }, [companyId, currentPage, perPage]);

  return (
    <Card>
      <Box padding="lg">
        {!isInit && !isLoading && (
          <EmptyState>
            <EmptyState.NoItems />
            <EmptyState.Title>{tCommon('NO_DATA')}</EmptyState.Title>
          </EmptyState>
        )}
        {isLoading && (
          <Flex justifyContent="center">
            <Spinner loading={isLoading} />
          </Flex>
        )}

        {isInit && !isLoading && (
          <TableBackendComponent
            data={receivedPayouts}
            data_total={total}
            current_page={currentPage}
            default_sort_column="createdAt"
            default_sort_direction="asc"
            headers={tableHeaders}
            paginator={changePage}
            sorter={() => {}}
            no_data_message={tCommon('NO_DATA')}
            show_no_data_message={true}
            fixed_table={true}
          />
        )}
      </Box>
    </Card>
  );
};

export default ReceivedPayoutsTab;
