import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import type { FormikProps } from 'formik';
import sanitizeHtml from 'sanitize-html';
import {
  Box,
  Title,
  H2,
  Form,
  Button,
  InfoBanner,
  Banner,
  Link,
  spacing,
} from '@procore/core-react';
import { AppContext } from 'contexts/AppContext';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { FormWrapper, FormButtonWrapper } from 'styles';
import type {
  ISearchForm,
  ILocalStorageSearch,
} from 'pages/CustomerSuccess/customer.success.interface';
import { getApi } from 'utils/api';
import {
  SearchApi,
  type GlobalDirectoryUserSearchDto,
} from '@procore/ipa-nt-api-client-ts';

const GlobalDirectorySearch = () => {
  const { t } = useTranslation('homepage');
  const { t: tCustomerSuccess } = useTranslation(['customer-success']);
  const { t: tCommon } = useTranslation(['common']);
  const { env } = useContext(AppContext);
  const alert = useContext(IpaAlertContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<
    GlobalDirectoryUserSearchDto[]
  >([]);
  const [isInit, setIsInit] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [includeInactive, setIncludeInactive] = useState<boolean>(false);
  const [removeDemoCompanies, setRemoveDemoCompanies] =
    useState<boolean>(false);
  const [exactEmail, setExactEmail] = useState<boolean>(false);

  const isSearchDisabled = (dirty: boolean, values: ISearchForm) => {
    values.search = values.search.replace(/"/g, '');
    return !dirty || values.search === '';
  };

  const doSearch = async (values: ISearchForm) => {
    const searchStr = sanitizeHtml(values.search, {
      allowedTags: [],
      allowedAttributes: {},
    });

    try {
      const temp: ILocalStorageSearch = {
        search: searchStr,
        include_inactive: values.include_inactive,
        remove_demo_companies: values.remove_demo_companies,
        exact_email: values.exact_email,
        results: [],
      };
      setIsLoading(true);

      const api = getApi(SearchApi);
      const result = await api.searchControllerDoGlobalDirectoryUserSearchV10(
        env,
        searchStr,
        values.include_inactive,
        values.remove_demo_companies,
        values.exact_email,
      );
      temp.results = result.data;
      setIsInit(true);
      localStorage.setItem('ipa-last-search', JSON.stringify(temp));
      setSearchResults(result.data);
    } catch (e: any) {
      alert.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem('ipa-last-search')) {
      const lastSearch = JSON.parse(localStorage.getItem('ipa-last-search'));
      setIsInit(true);
      setSearch(lastSearch.search);
      setIncludeInactive(lastSearch.include_inactive);
      setRemoveDemoCompanies(lastSearch.remove_demo_companies);
      setExactEmail(lastSearch.exact_email);
      setSearchResults(lastSearch.results);
    }
  }, []);

  return (
    <Box data-qa="homepage-global-directory-search">
      <Title>
        <Title.Text>
          <H2>{t('MODULES.GDS')}</H2>
        </Title.Text>
      </Title>
      <FormWrapper marginTop={`${spacing.md}px`}>
        <Form
          view="create"
          enableReinitialize={true}
          initialValues={{
            search: search ?? '',
            include_last_login: false,
            include_inactive: includeInactive ?? false,
            remove_demo_companies: removeDemoCompanies ?? false,
            exact_email: exactEmail ?? false,
          }}
          validationSchema={yup.object().shape({
            search: yup.string().nullable().required(tCommon('REQUIRED')),
          })}
          onSubmit={(values) => doSearch(values)}
        >
          {(formProps: FormikProps<ISearchForm>) => {
            const { dirty, values, handleSubmit } = formProps;
            return (
              <Form.Form
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmit();
                  }
                }}
              >
                <Form.Row>
                  <Form.Text
                    autoFocus
                    name="search"
                    type="text"
                    data-qa="gds-search-field"
                    label={tCustomerSuccess('GDS_SEARCH_FIELD_LABEL')}
                    colStart={1}
                    colWidth={10}
                  />
                  <FormButtonWrapper>
                    <Button
                      type="submit"
                      variant="primary"
                      data-qa="gds-search-btn"
                      disabled={isSearchDisabled(dirty, values)}
                      loading={isLoading}
                    >
                      {tCommon('SEARCH')}
                    </Button>
                  </FormButtonWrapper>
                </Form.Row>
                <Form.Row>
                  <Form.Checkbox
                    name="exact_email"
                    data-qa="exact_email"
                    label={tCustomerSuccess('GDS_SEARCH_EXACT_EMAIL')}
                    colStart={1}
                    colWidth={5}
                  />
                  <Form.Checkbox
                    name="include_inactive"
                    data-qa="include_inactive"
                    label={tCustomerSuccess('GDS_SEARCH_INCLUDE_INACTIVE')}
                    colStart={1}
                    colWidth={5}
                  />
                  <Form.Checkbox
                    name="remove_demo_companies"
                    data-qa="remove_demo_companies"
                    label={tCustomerSuccess('GDS_SEARCH_REMOVE_DEMO_COMPANIES')}
                    colStart={6}
                    colWidth={5}
                  />
                </Form.Row>
              </Form.Form>
            );
          }}
        </Form>
      </FormWrapper>
      {isInit && !isLoading && (
        <InfoBanner>
          <Banner.Content>
            <Banner.Body>
              {!searchResults.length ? (
                tCommon('NO_RESULTS')
              ) : (
                <Link href="/search/user">
                  {t('ENTRIES_FOUND', { num: searchResults.length })}
                </Link>
              )}
            </Banner.Body>
          </Banner.Content>
        </InfoBanner>
      )}
    </Box>
  );
};

export default GlobalDirectorySearch;
