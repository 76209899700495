import React, { useState } from 'react';
import {
  Page,
  Card,
  InfoBanner,
  Form,
  Button,
  spacing,
  Title,
  H1,
  Banner,
} from '@procore/core-react';
import { useTranslation } from 'react-i18next';
import { PageConfig } from 'pages/page-config.service';
import { FormWrapper } from 'styles';
import * as yup from 'yup';
import { DevPortalApi } from '@procore/ipa-nt-api-client-ts';
import { getApi } from 'utils/api';
import { useContext } from 'react';
import { IpaAlertContext } from 'contexts/IpaAlertContext';

interface ZoneOption {
  id: string;
  label: string;
  env: string;
  zoneName: string;
}

export const OauthCachePage: React.FC = () => {
  const { t: tCommon } = useTranslation(['common']);
  const pageConfig = PageConfig.get(tCommon, 'DevPortal.OauthCache');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const alert = useContext(IpaAlertContext);

  const zoneOptions: ZoneOption[] = [
    {
      id: 'PROD - US01',
      label: 'PROD - US01',
      env: 'PROD',
      zoneName: 'US01',
    },
    {
      id: 'PROD - US02',
      label: 'PROD - US02',
      env: 'PROD',
      zoneName: 'US02',
    },
    {
      id: 'PROD - UK',
      label: 'PROD - UK',
      env: 'PROD',
      zoneName: 'UK',
    },
    {
      id: 'PROD_MONTHLY - US01',
      label: 'PROD_MONTHLY - US01',
      env: 'PROD_MONTHLY',
      zoneName: 'US01',
    },
    {
      id: 'PROD_MONTHLY - US02',
      label: 'PROD_MONTHLY - US02',
      env: 'PROD_MONTHLY',
      zoneName: 'US02',
    },
    {
      id: 'PROD_MONTHLY - UK',
      label: 'PROD_MONTHLY - UK',
      env: 'PROD_MONTHLY',
      zoneName: 'UK',
    },
    {
      id: 'INTEGRATOR_SANDBOX - US01',
      label: 'INTEGRATOR_SANDBOX - US01',
      env: 'INTEGRATOR_SANDBOX',
      zoneName: 'US01',
    },
  ];

  const handleSubmit = async (values: {
    appId: string;
    selectedZone: ZoneOption;
  }) => {
    try {
      setIsLoading(true);
      const api = getApi(DevPortalApi);
      await api.devPortalControllerClearOauthCacheV10(
        values.appId,
        values.selectedZone.env,
        values.selectedZone.zoneName,
      );
      alert.success(pageConfig.translate('ENV_INFO.SUCCESS_MESSAGE'));
    } catch (error: any) {
      alert.error(
        pageConfig.translate('ENV_INFO.ERROR_MESSAGE', {
          error: error.message || error,
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const validationSchema = yup.object().shape({
    appId: yup
      .string()
      .required(pageConfig.translate('ENV_INFO.APP_ID_REQUIRED')),
    selectedZone: yup
      .object()
      .required(pageConfig.translate('ENV_INFO.ZONE_REQUIRED')),
  });

  return (
    <Page>
      <Page.Main>
        <Card>
          <InfoBanner>
            <Banner.Content>
              <Banner.Body data-qa="banner-status-text">
                {pageConfig.translate('INFO_MESSAGE')}
              </Banner.Body>
            </Banner.Content>
          </InfoBanner>
        </Card>
        <Card style={{ margin: spacing.md, padding: spacing.md }}>
          <Page.Header transparent={true}>
            <Page.Title>
              <Title>
                <Title.Text>
                  <H1>{pageConfig.label}</H1>
                </Title.Text>
              </Title>
            </Page.Title>
          </Page.Header>
          <Page.Body>
            <FormWrapper>
              <Form
                view="create"
                enableReinitialize={true}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                initialValues={{
                  appId: '',
                  selectedZone: zoneOptions[0],
                }}
              >
                <Form.Form>
                  <Page.Column>
                    <Form.Field
                      name="appId"
                      label={pageConfig.translate('ENV_INFO.APP_ID')}
                      tooltip={pageConfig.translate('ENV_INFO.APP_ID_TOOLTIP')}
                      required
                      data-qa="developer-app-id-field"
                      style={{ width: '30%', marginBottom: '10px' }}
                    />
                    <Form.Select
                      name="selectedZone"
                      label={pageConfig.translate('ENV_INFO.ZONE')}
                      data-qa="zone-select"
                      onSearch={false}
                      style={{
                        width: '28%',
                        marginBottom: '5px',
                      }}
                      options={zoneOptions}
                      required
                    />
                    <Button
                      type="submit"
                      variant="primary"
                      loading={isLoading}
                      data-qa="clear-cache-btn"
                      style={{
                        marginTop: '5px',
                        marginBottom: '10px',
                      }}
                    >
                      {pageConfig.translate('ENV_INFO.BUTTON_NAME')}
                    </Button>
                  </Page.Column>
                </Form.Form>
              </Form>
            </FormWrapper>
          </Page.Body>
        </Card>
      </Page.Main>
    </Page>
  );
};

export default OauthCachePage;
