import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Title,
  H2,
  Button,
  Form,
  InfoBanner,
  Banner,
  spacing,
  Link,
  FlexList,
} from '@procore/core-react';
import { Check, Clear } from '@procore/core-icons';
import * as yup from 'yup';
import sanitizeHtml from 'sanitize-html';
import { AppContext } from 'contexts/AppContext';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { FormWrapper, FormButtonWrapper } from 'styles';
import type { ISearchForm } from 'pages/CompanySearch/company.interface';
import { parsePastedString } from 'utils/form';
import { getApi } from 'utils/api';
import {
  SearchApi,
  type CompanySearchDto,
} from '@procore/ipa-nt-api-client-ts';
import { DataDisplayModalComponent } from 'components/DataDisplayModal';
import { TableComponent } from 'components/Table';
import type { TableHeader } from 'components/Table/table.interface';
import { CompanyLinksDropdown } from 'components/CompanyLinksDropdown';
import { styles } from 'components/Table/styles';

const CompanySearch = () => {
  const navigate = useNavigate();
  const { env } = useContext(AppContext);
  const alert = useContext(IpaAlertContext);
  const { t: tCommon } = useTranslation(['common']);
  const { t } = useTranslation('homepage');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<CompanySearchDto[]>([]);
  const [isInit, setIsInit] = useState<boolean>(false);
  const [showResultsModal, setShowResultsModal] = useState<boolean>(false);

  const displayCompanyId = (company: CompanySearchDto) => {
    return (
      <FlexList>
        <Link href={`/v2/company/${company.id}`}>{company.id}</Link>
        <CompanyLinksDropdown companyId={company.id} />
      </FlexList>
    );
  };

  const tableHeaders: TableHeader[] = [
    {
      title: t('COMPANY_ID', { ns: 'common' }),
      node: 'id',
      is_sortable: true,
      display_func: displayCompanyId,
    },
    {
      title: t('COMPANY_NAME', { ns: 'common' }),
      node: 'company',
      display_func: (company) => (
        <Link href={`/v2/company/${company.id}`}>{company.company.name}</Link>
      ),
      is_sortable: true,
    },
    {
      title: t('ACTIVE_COMPANY', { ns: 'common' }),
      node: 'company',
      display_func: (company) =>
        company.company.is_active ? (
          <Check size="sm" style={styles.iconSuccess} />
        ) : (
          <Clear size="sm" style={styles.iconError} />
        ),
      is_sortable: true,
    },
    {
      title: t('DEMO_COMPANY', { ns: 'common' }),
      node: 'company',
      display_func: (company) =>
        company.company.is_demo ? (
          <Check size="sm" style={styles.iconSuccess} />
        ) : (
          <Clear size="sm" style={styles.iconError} />
        ),
      is_sortable: true,
    },
    {
      title: t('ZONE', { ns: 'common' }),
      node: 'zone',
      is_sortable: true,
      is_text: true,
    },
  ];

  const doSearch = async (values: ISearchForm) => {
    const searchStr = sanitizeHtml(values.search, {
      allowedTags: [],
      allowedAttributes: {},
    });
    try {
      setIsLoading(true);
      setIsInit(false);
      setSearchResults([]);
      if (Number.isInteger(+searchStr)) {
        navigate(`/v2/company/${searchStr}`);
      } else {
        const res = await getApi(SearchApi).searchControllerDoCompanySearchV10(
          env,
          searchStr,
        );
        if (res.data.length === 1) {
          navigate(`/v2/company/${res.data[0].id}`);
        } else {
          setSearchResults(res.data);
          setIsInit(true);
        }
      }
    } catch (error: any) {
      alert.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const isSearchDisabled = (dirty: boolean, values: ISearchForm) => {
    values.search = String(values.search).replace(/"/g, '');
    return !dirty || values.search === '';
  };

  return (
    <Box data-qa="homepage-company-search" style={{ width: '100%' }}>
      <Title>
        <Title.Text>
          <H2>{t('MODULES.COMPANY_SEARCH')}</H2>
        </Title.Text>
      </Title>
      <FormWrapper marginTop={`${spacing.md}px`}>
        <Form
          view="create"
          enableReinitialize={true}
          initialValues={{
            search: '',
          }}
          validationSchema={yup.object().shape({
            search: yup.string().nullable().required(tCommon('REQUIRED')),
          })}
          onSubmit={(values) => doSearch(values)}
        >
          {(props: any) => {
            const { dirty, values, handleSubmit, setFieldValue } = props;
            return (
              <Form.Form
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmit();
                  }
                }}
                onPaste={(e) => parsePastedString(e, setFieldValue, 'search')}
              >
                <Form.Row>
                  <Form.Text
                    autoFocus
                    name="search"
                    type="text"
                    data-qa="company-search-field"
                    label={t('COMPANY_SEARCH_FIELD_LABEL', { ns: 'company' })}
                    colWidth={9}
                  />
                  <FormButtonWrapper>
                    <Button
                      type="submit"
                      variant="primary"
                      data-qa="company-search-btn"
                      disabled={isSearchDisabled(dirty, values)}
                      loading={isLoading}
                    >
                      {tCommon('SEARCH')}
                    </Button>
                  </FormButtonWrapper>
                </Form.Row>
              </Form.Form>
            );
          }}
        </Form>
      </FormWrapper>
      {isInit && !isLoading && (
        <InfoBanner>
          <Banner.Content>
            <Banner.Body>
              {!searchResults.length
                ? t('NO_RESULTS', { ns: 'common' })
                : t('COMPANIES_FOUND', { num: searchResults.length })}
            </Banner.Body>
          </Banner.Content>
          {searchResults.length > 0 && (
            <Banner.Action>
              <Button
                data-qa="view-company-search-results"
                onClick={() => setShowResultsModal(true)}
              >
                {t('VIEW', { ns: 'common' })}
              </Button>
            </Banner.Action>
          )}
        </InfoBanner>
      )}
      <DataDisplayModalComponent
        show={showResultsModal}
        modalTitle={t('COMPANY_SEARCH_RESULTS')}
        closeModal={() => setShowResultsModal(false)}
        modalContent={
          <TableComponent
            data={searchResults}
            headers={tableHeaders}
            default_sort_column="name"
            default_sort_direction="asc"
            show_no_data_message={true}
          />
        }
      />
    </Box>
  );
};

export default CompanySearch;
