import type { PageConfig } from 'pages/page-config.interfaces';

const AccessControlPage: PageConfig = {
  route: {
    page: 'access-control',
  },
  label: 'TITLE',
  i18n: {
    namespace: 'company',
    keyPrefix: 'ACCESS_CONTROL',
  },
  permissions: {
    edit: ['edit_access_control_settings'],
  },
  endpoints: {
    view: ['companyAccessControlControllerGetCompanySecuritySettings'],
  },
  cards: {
    AccessControlSettings: {
      label: 'TITLE',
      fields: {
        is_su_restricted: {
          label: 'SU_DISPLAY_NAME',
          tooltip: 'SU_TOOLTIP',
        },
        is_jit_restricted: {
          label: 'JIT_DISPLAY_NAME',
          tooltip: 'JIT_TOOLTIP',
        },
      },
      endpoints: {
        edit: ['companyAccessControlControllerUpdateSecuritySettings'],
      },
    },
    AllowedUsers: {
      label: 'DISPLAY_USER_HEADING',
      fields: {},
      endpoints: {
        edit: [
          'companyAccessControlControllerUpdateAllowedUser',
          'companyAccessControlControllerCreateAllowedUsers',
          'companyAccessControlControllerDeleteSuperUser',
          'companyAccessControlControllerUpdateSecuritySettings',
        ],
      },
    },
  },
};

export default AccessControlPage;
