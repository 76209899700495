import React, { useState, useEffect, useCallback, useContext } from 'react';
import {
  Card,
  Flex,
  Spinner,
  Box,
  H2,
  Form,
  Typography,
  Page,
} from '@procore/core-react';
import { getApi } from 'utils/api';
import { AxiosError } from 'axios';
import { ProjectsApi } from '@procore/ipa-nt-api-client-ts';
import type {
  ProjectRfiSettingsDto,
  ProjectRfisDto,
} from '@procore/ipa-nt-api-client-ts';
import TableComponent from 'components/Table';
import type { TableHeader } from 'components/Table/table.interface';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { ProjectContext } from 'contexts/ProjectContext';
import { StyledPageColumn, FieldWrapper } from 'styles';

const RfiSettingsPage = () => {
  const alert = useContext(IpaAlertContext);
  const context = useContext(ProjectContext);
  const t = context.pageConfig.translate;

  const rfiSettingsConfig = context.pageConfig.card('RfiSettings');
  const projectRfisConfig = context.pageConfig.card('ProjectRfis');
  const rfiEmailsConfig = context.pageConfig.card('RfiEmails');

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(true);
  const [rfiSettings, setRfiSettings] = useState<ProjectRfiSettingsDto>(
    {} as ProjectRfiSettingsDto,
  );
  const [projectRfis, setProjectRfis] = useState<ProjectRfisDto[]>([]);

  const displayEmailEvent = (item: any, node: string) => {
    return (
      <span>
        {t(`PROJECT_RFI_SETTINGS.RFI_EMAILS.${item[node].toUpperCase()}`)}
      </span>
    );
  };

  const showUser = (item: any, node: string) => {
    if (item[node] && item[node].name && item[node].login) {
      return item[node].name;
    }
  };

  const showUsers = (item: any, node: string) => {
    if (item[node] && item[node].length > 0) {
      const assignees: string[] = [];
      item[node].forEach((a: any) => {
        assignees.push(a.name);
      });
      return <span>{assignees.join(', ')}</span>;
    }
  };

  const rfiEmailHeaders: TableHeader[] = [
    {
      title: rfiEmailsConfig.translate('EMAIL_EVENT'),
      node: 'event',
      display_func: displayEmailEvent,
      is_sortable: true,
      column_width: 25,
    },
    {
      title: rfiEmailsConfig.translate('ENABLE_EMAIL'),
      node: 'enable_email',
      is_boolean: true,
      is_sortable: false,
      column_width: 15,
    },
    {
      title: rfiEmailsConfig.translate('CREATOR'),
      node: 'creator',
      is_boolean: true,
      is_sortable: false,
      column_width: 15,
    },
    {
      title: rfiEmailsConfig.translate('MANAGER'),
      node: 'manager',
      is_boolean: true,
      is_sortable: false,
      column_width: 15,
    },
    {
      title: rfiEmailsConfig.translate('ASSIGNEE'),
      node: 'assignee',
      is_boolean: true,
      is_sortable: false,
      column_width: 15,
    },
    {
      title: rfiEmailsConfig.translate('DISTRIBUTION_GROUP'),
      node: 'distribution_group',
      is_boolean: true,
      is_sortable: false,
      column_width: 15,
    },
  ];

  const projectRfisHeaders: TableHeader[] = [
    {
      title: '#',
      node: 'number',
      is_text: true,
      is_sortable: true,
      column_width: 5,
    },
    {
      title: projectRfisConfig.translate('SUBJECT'),
      node: 'subject',
      is_text: true,
      is_sortable: true,
      column_width: 25,
    },
    {
      title: projectRfisConfig.translate('STATUS'),
      node: 'status',
      is_text: true,
      is_sortable: true,
      column_width: 5,
    },
    {
      title: projectRfisConfig.translate('RESPONSIBLE_CONTRACTOR'),
      node: 'responsible_contractor',
      display_func: showUser,
      is_sortable: false,
      column_width: 10,
    },
    {
      title: projectRfisConfig.translate('RECEIVED_FROM'),
      node: 'received_from',
      display_func: showUser,
      is_sortable: false,
      column_width: 10,
    },
    {
      title: projectRfisConfig.translate('DATE_INITIATED'),
      node: 'initiated_at',
      is_date: true,
      is_sortable: true,
      column_width: 5,
    },
    {
      title: projectRfisConfig.translate('RFI_MANAGER'),
      node: 'rfi_manager',
      display_func: showUser,
      is_sortable: false,
      column_width: 10,
    },
    {
      title: projectRfisConfig.translate('ASSIGNEES'),
      node: 'assignees',
      display_func: showUsers,
      is_sortable: false,
      column_width: 20,
    },
    {
      title: projectRfisConfig.translate('BALL_IN_COURT'),
      node: 'ball_in_court',
      display_func: showUser,
      is_sortable: false,
      column_width: 10,
    },
  ];

  const initPage = useCallback(async () => {
    setIsInit(false);
    setLoading(true);
    const errors: AxiosError[] = [];
    const promises = await Promise.all([
      getProjectRfiSettings(),
      getProjectRfis(),
    ]);
    promises.forEach((promise) => {
      if (promise instanceof AxiosError) {
        errors.push(promise);
      }
    });
    if (errors.length > 0) {
      alert.error(errors);
    }
    setLoading(false);
    setIsInit(true);
  }, []);

  const getProjectRfiSettings = async () => {
    try {
      const api = getApi(ProjectsApi);
      const result = await api.projectControllerGetProjectRfiSettingsV10(
        context.env,
        context.companyId,
        context.projectId,
      );
      setRfiSettings(result.data);
    } catch (e: any) {
      return e;
    }
  };

  const getProjectRfis = async () => {
    try {
      const api = getApi(ProjectsApi);
      const result = await api.projectControllerGetProjectRfisV10(
        context.env,
        context.companyId,
        context.projectId,
      );
      setProjectRfis(result.data);
    } catch (e: any) {
      return e;
    }
  };

  useEffect(() => {
    initPage();
  }, [initPage]);

  return (
    <React.Fragment>
      <Box marginBottom="lg">
        <Card>
          <Box padding="lg">
            {isLoading && (
              <Flex justifyContent="center">
                <Spinner loading={isLoading} />
              </Flex>
            )}
            {!isLoading && isInit && Object.keys(rfiSettings).length > 0 && (
              <Form
                initialValues={rfiSettings}
                enableReinitialize={true}
                view="read"
              >
                <Form.Form>
                  <Page.Row>
                    <StyledPageColumn>
                      <FieldWrapper>
                        <Form.Text
                          name="default_rfi_manager_name"
                          label={
                            rfiSettingsConfig.fields.default_rfi_manager_name
                              .label
                          }
                        />{' '}
                        <Typography intent="body">
                          {!rfiSettings['default_rfi_manager_name']
                            ? rfiSettingsConfig.translate(
                                'SELECT_ADMIN_USER_RFI_MANAGER',
                              )
                            : rfiSettingsConfig.translate(
                                'RFI_MANAGER_WILL_BE',
                                {
                                  rfiManager:
                                    rfiSettings['default_rfi_manager_name'],
                                },
                              )}
                        </Typography>
                      </FieldWrapper>
                    </StyledPageColumn>
                    <StyledPageColumn>
                      <FieldWrapper>
                        <Form.Text
                          name="rfi_due_days_default"
                          label={
                            rfiSettingsConfig.fields.rfi_due_days_default.label
                          }
                        />{' '}
                        <Typography intent="body">
                          {rfiSettingsConfig.translate('DAYS_BY_DEFAULT')}
                        </Typography>
                      </FieldWrapper>
                    </StyledPageColumn>
                  </Page.Row>
                  <Page.Row>
                    <StyledPageColumn>
                      <Form.Checkbox
                        name="rfi_only_show_official_responses"
                        label={
                          rfiSettingsConfig.fields
                            .rfi_only_show_official_responses.label
                        }
                        colStart={1}
                        colWidth={6}
                      />
                    </StyledPageColumn>
                    <StyledPageColumn>
                      <Form.Checkbox
                        name="rfi_overdue_emails_enabled"
                        label={
                          rfiSettingsConfig.fields.rfi_overdue_emails_enabled
                            .label
                        }
                        colStart={7}
                      />
                    </StyledPageColumn>
                  </Page.Row>
                </Form.Form>
              </Form>
            )}
          </Box>
        </Card>
      </Box>
      <Box marginBottom="lg">
        <Card>
          <Box padding="lg">
            <H2>{projectRfisConfig.label}</H2>
            {isLoading && (
              <Flex justifyContent="center">
                <Spinner loading={isLoading} />
              </Flex>
            )}
            {!isLoading && isInit && (
              <TableComponent
                data={projectRfis}
                headers={projectRfisHeaders}
                hide_pagination={true}
                default_sort_column="number"
                default_sort_direction="asc"
                fixed_table={true}
              />
            )}
          </Box>
        </Card>
      </Box>
      <Card>
        <Box padding="lg">
          <H2>{rfiEmailsConfig.label}</H2>
          {isLoading && (
            <Flex justifyContent="center">
              <Spinner loading={isLoading} />
            </Flex>
          )}
          {!isLoading && isInit && (
            <TableComponent
              data={rfiSettings.rfi_notification_settings}
              headers={rfiEmailHeaders}
              hide_pagination={true}
              default_sort_column="event"
              default_sort_direction="asc"
              fixed_table={true}
            />
          )}
        </Box>
      </Card>
    </React.Fragment>
  );
};

export default RfiSettingsPage;
