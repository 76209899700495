import React, { useState } from 'react';
import { Flex, Spinner } from '@procore/core-react';
import PaymentsServiceChangeHistoryCard from './PaymentsServiceChangeHistoryCard';
import IpaPaymentsChangeHistoryCard from './IpaPaymentsChangeHistoryCard';

const ChangeHistoryTab = () => {
  const [isLoading, setLoading] = useState<boolean>(false);

  return isLoading ? (
    <Flex justifyContent="center">
      <Spinner loading={isLoading} />
    </Flex>
  ) : (
    <React.Fragment>
      <PaymentsServiceChangeHistoryCard id="changeHistory" />
      <IpaPaymentsChangeHistoryCard id="ipaChangeHistory" />
    </React.Fragment>
  );
};

export default ChangeHistoryTab;
