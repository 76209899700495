import React, { useCallback, useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Flex,
  Spinner,
  Box,
  EmptyState,
  Button,
} from '@procore/core-react';
import TableBackendComponent from 'components/TableBackend';
import type { TableHeader } from 'components/TableBackend/table.interface';
import { AppContext } from 'contexts/AppContext';
import { PageConfig } from 'pages/page-config.service';
import { PayoutsApi } from '@procore/ipa-nt-api-client-ts';
import type {
  FailedPayoutDto,
  PayoutControllerGetPayoutFailuresV10200Response,
} from '@procore/ipa-nt-api-client-ts';
import { getApi } from 'utils/api';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { can } from 'utils/permissions.helper';
import {
  formatCurrency,
  getPayoutStatus,
  getRouteForEnv,
} from 'pages/Payments/payments.helper';

const PayoutFailuresTab = () => {
  const { t } = useTranslation(['payments']);
  const { t: tCommon } = useTranslation(['common']);
  const { t: tStatus } = useTranslation(['payments'], { keyPrefix: 'STATUS' });
  const { userConfig, userSettings, env } = useContext(AppContext);
  const alert = useContext(IpaAlertContext);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(true);
  const [total, setTotal] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(
    (userSettings.default_page_size as number) || 25,
  );
  const pageConfig = PageConfig.getPage(t, 'Payments', 'PayoutFailures');
  const [payoutFailures, setPayoutFailures] = useState<any>([]);

  const getPayoutFailures = useCallback(async () => {
    alert.closeAlert();
    setLoading(true);
    try {
      const api = getApi(PayoutsApi);
      const res = await api.payoutControllerGetPayoutFailuresV10(
        +currentPage,
        +perPage,
        env,
      );
      if (res.data) {
        const failedPayouts: PayoutControllerGetPayoutFailuresV10200Response =
          res.data;
        const payoutsWithCompanyName = failedPayouts.items.map((item) => {
          return {
            ...item,
            receivingPaymentsCompanyName:
              item.receivingPaymentsCompanyName ??
              t('BUSINESS_ENTITIES_TAB.NO_ENTITY_NAME_FOUND', {
                companyId: item.receivingPaymentsCompanyExternalId,
              }),
          };
        });
        setPayoutFailures(payoutsWithCompanyName);
        setTotal(failedPayouts.total);
      }
    } catch (e: any) {
      alert.error(e);
    } finally {
      setLoading(false);
    }
  }, [env, currentPage, perPage]);

  const retryPayout = async (item: FailedPayoutDto) => {
    alert.closeAlert();
    setLoading(true);
    try {
      const api = getApi(PayoutsApi);
      api.payoutControllerRetryFailedPayoutV10(
        item.sendingPaymentsCompanyExternalId,
        item.disbursementId,
        item.id,
        env,
      );

      getPayoutFailures();

      alert.success(t('PAYOUT_FAILURES_TAB.RETRY_FAILED_PAYOUT_SUCCESS'));
    } catch (e: any) {
      alert.error(e);
    } finally {
      setLoading(false);
    }
  };

  const retryCell = (item: FailedPayoutDto) => {
    return (
      <span>
        {can({ user: userConfig }, pageConfig.permissions.edit) && (
          <Button
            variant="secondary"
            size="sm"
            data-qa={'retry-payout-btn'}
            onClick={() => retryPayout(item)}
          >
            {tCommon('RETRY')}
          </Button>
        )}
      </span>
    );
  };

  // Placeholder headers for SAFE-3826, will need to be updated for actual implementation
  const tableHeaders: TableHeader[] = [
    {
      title: '',
      node: '',
      display_func: retryCell,
    },
    {
      title: t('PAYOUT_FAILURES_TAB.ENTITY_NAME'),
      node: 'receivingPaymentsCompanyName',
      is_sortable: false,
      is_link: true,
      url: `${getRouteForEnv(
        env,
        '/business-entity/{sendingPaymentsCompanyExternalId}?tab=general',
      )}`,
    },
    {
      title: t('PAYOUT_FAILURES_TAB.INVOICE_ID'),
      node: 'invoiceId',
      is_text: true,
      is_sortable: false,
    },
    {
      title: t('PAYOUT_FAILURES_TAB.CHECK_NUMBER'),
      node: 'checkNumber',
      is_text: true,
      is_sortable: false,
    },
    {
      title: t('PAYOUT_FAILURES_TAB.DISBURSEMENT_ID'),
      node: 'disbursementId',
      is_sortable: false,
      is_link: true,
      url: `${getRouteForEnv(
        env,
        '/business-entity/{sendingPaymentsCompanyExternalId}/disbursement/{disbursementId}/general',
      )}`,
    },
    {
      title: t('PAYOUT_FAILURES_TAB.AMOUNT'),
      node: 'amount',
      is_sortable: false,
      display_func: (item: FailedPayoutDto, node: string) => {
        return (
          <span>{formatCurrency(item.currencyCode, item.amount / 100)}</span>
        );
      },
    },
    {
      title: t('PAYOUT_FAILURES_TAB.STATUS'),
      node: 'status',
      is_sortable: false,
      display_func: (item: FailedPayoutDto, node: string) => {
        return getPayoutStatus(item.status, tStatus);
      },
    },
    {
      title: t('PAYOUT_FAILURES_TAB.FAILURE_REASON'),
      node: 'statusMessage',
      is_text: true,
      is_sortable: false,
    },
    {
      title: t('PAYOUT_FAILURES_TAB.DATE_PAYMENT_CREATED'),
      node: 'createdAt',
      is_date: true,
      is_sortable: false,
    },
  ];

  useEffect(() => {
    getPayoutFailures();
  }, [getPayoutFailures]);

  return (
    <Card>
      <Box padding="lg">
        {!isInit && !isLoading && (
          <EmptyState>
            <EmptyState.NoItems />
            <EmptyState.Title>{tCommon('NO_DATA')}</EmptyState.Title>
          </EmptyState>
        )}
        {isLoading && (
          <Flex justifyContent="center">
            <Spinner loading={isLoading} />
          </Flex>
        )}

        {isInit && !isLoading && (
          <TableBackendComponent
            data={payoutFailures}
            data_total={total}
            current_page={currentPage}
            default_sort_column=""
            default_sort_direction="asc"
            headers={tableHeaders}
            paginator={() => {}}
            sorter={() => {}}
            no_data_message={tCommon('NO_DATA')}
            show_no_data_message={true}
          />
        )}
      </Box>
    </Card>
  );
};

export default PayoutFailuresTab;
