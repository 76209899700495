import React, { useContext } from 'react';
import { Popover, H2, EmptyState, Box } from '@procore/core-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  IconWrapper,
  SupportDocsWrapper,
  styles,
} from 'components/AppHeader/styles';
import { Star } from '@procore/core-icons';
import { AppContext } from 'contexts/AppContext';
import { TableComponent } from 'components/Table';
import type { TableHeader } from 'components/Table/table.interface';
import type { FavoriteAccountDto } from '@procore/ipa-nt-api-client-ts';

export const FavoriteAccounts = () => {
  const { t } = useTranslation(['header']);
  const { userSettings } = useContext(AppContext);

  const displayCompanyLink = (favorite: FavoriteAccountDto) => {
    return <Link to={`/v2/company/${favorite.id}`}>{favorite.name}</Link>;
  };

  const tableHeaders: TableHeader[] = [
    {
      title: 'Company',
      node: 'name',
      is_sortable: true,
      display_func: displayCompanyLink,
      column_width: 50,
    },
    {
      title: 'Last Update',
      node: 'updated_at',
      is_sortable: true,
      is_date: true,
      column_width: 50,
    },
  ];

  const overlay = (
    <Popover.Content
      style={styles.ipaFavoritesPopover}
      data-qa="favorite-acounts-overlay"
    >
      <SupportDocsWrapper>
        <H2>{t('FAVORITE_ACCOUNTS.TITLE')}</H2>
        {!userSettings?.favorite_accounts?.length && (
          <Box padding="sm">
            <EmptyState compact>
              <EmptyState.NoItemsWithoutCreatePermissions />
              <EmptyState.Description>
                {t('FAVORITE_ACCOUNTS.EMPTY')}
              </EmptyState.Description>
            </EmptyState>
          </Box>
        )}
        {userSettings.favorite_accounts &&
          userSettings.favorite_accounts.length > 0 && (
            <Box padding="sm">
              <TableComponent
                headers={tableHeaders}
                data={userSettings.favorite_accounts}
                default_sort_column="updated_at"
                default_sort_direction="desc"
                compressed={true}
                page_size={10}
                show_search={true}
                search_columns={['name']}
              />
            </Box>
          )}
      </SupportDocsWrapper>
    </Popover.Content>
  );

  return (
    <Popover aria-labelledby="" overlay={overlay} placement="bottom-left">
      <IconWrapper>
        <Star />
      </IconWrapper>
    </Popover>
  );
};

export default FavoriteAccounts;
