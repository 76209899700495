import styled from 'styled-components';
import { colors } from '@procore/core-react';

export const StyledLink = styled.span`
  a {
    text-decoration: underline;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }

    color: ${colors.blue45};
    &:hover {
      color: ${colors.blue30};
    }
  }
`;
