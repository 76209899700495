import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Select, Form, Button } from '@procore/core-react';
import type { SelectOption } from 'components/JitEscalateTearsheet/form.interface';
import { useTranslation } from 'react-i18next';
import type { Selection } from '@procore/core-react/dist/MenuImperative/MenuImperative.types';
import * as yup from 'yup';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import {
  FormButtonWrapper,
  FormWrapper,
  StyledFormCheckbox,
  StyledFormSelect,
  StyledFormText,
} from 'styles';
import { getApi } from 'utils/api';
import { AuditLogsApi } from '@procore/ipa-nt-api-client-ts';
import type { PageConfigTranslated } from '../page-config.interfaces';

type AuditLogsFormFormProps = {
  eventCategory: { id: string; label: string };
  email: string;
  companyId: string;
  hideViewEvents: boolean;
};

export type AuditLogsSearchProps = {
  dateRange: number;
  eventCategory: string;
  email: string;
  companyId: string;
  hideViewEvents: boolean;
};

export interface AuditLogsFormProps {
  pageConfig: PageConfigTranslated;
  submit: (values: AuditLogsSearchProps) => void;
}

export const AuditLogsForm = (props: AuditLogsFormProps) => {
  const { t } = useTranslation(['audit-logs']);
  const alert = useContext(IpaAlertContext);
  const [numberOfDays, setNumberOfDays] = useState<number>(7);
  const [eventCategories, setEventCategories] = useState<
    { id: string; label: string }[]
  >([]);
  const [selectedEventCategory, setSelectedEventCategory] = useState<{
    id: string;
    label: string;
  }>();
  const [email, setEmail] = useState<string>('');
  const [companyId, setCompanyId] = useState<string>('');

  const getDateRangeLabel = (id: number) => {
    return t('DATE_RANGE_LABEL', { days: id, ns: 'audit-logs' });
  };

  const dateRanges: SelectOption[] = [
    { id: '7', label: `${getDateRangeLabel(7)}` },
    { id: '30', label: `${getDateRangeLabel(30)}` },
    { id: '90', label: `${getDateRangeLabel(90)}` },
    { id: '120', label: `${getDateRangeLabel(120)}` },
  ];

  const getSelectLabel = () => {
    const selected = dateRanges.find((d) => d.id === numberOfDays.toString());
    return selected ? selected.label : dateRanges[0].label;
  };

  const changeDateRange = async (v: Selection) => {
    setNumberOfDays(+v.item.id);
  };

  const searchLogs = (values: AuditLogsFormFormProps) => {
    props.submit({
      dateRange: numberOfDays,
      eventCategory: values.eventCategory.id,
      email: values.email,
      companyId: values.companyId,
      hideViewEvents: values.hideViewEvents,
    });
    setSelectedEventCategory(values.eventCategory);
    setEmail(values.email);
    setCompanyId(values.companyId);
  };

  const getEventCategories = useCallback(async () => {
    try {
      const api = getApi(AuditLogsApi);
      const res = await api.auditLogControllerGetEventCategoriesV10();
      if (res.data) {
        const cats = res.data.map((t: string) => {
          return { label: t, id: t };
        });
        setEventCategories(cats);
        setSelectedEventCategory(cats[0]);
      }
    } catch (e: any) {
      alert.error(e);
    }
  }, []);

  useEffect(() => {
    getEventCategories();
  }, []);

  return (
    <React.Fragment>
      <Select
        label={getSelectLabel()}
        data-qa={'date-range-select'}
        onSelect={(v) => {
          changeDateRange(v);
        }}
      >
        {dateRanges.map((item) => (
          <Select.Option key={item.id} value={item}>
            {item.label}
          </Select.Option>
        ))}
      </Select>
      <FormWrapper>
        <Form
          view="create"
          enableReinitialize={true}
          initialValues={{
            eventCategory: selectedEventCategory,
            email: email,
            companyId: companyId,
            hideViewEvents: false,
          }}
          validationSchema={yup.object().shape({
            eventCategory: yup
              .object()
              .shape({ id: yup.string(), label: yup.string() })
              .nullable()
              .required(t('REQUIRED', { ns: 'common' })),
          })}
          onSubmit={(values) => searchLogs(values)}
        >
          {(props: any) => {
            const { values, handleSubmit } = props;
            return (
              <Form.Form
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && values.eventCategory?.id) {
                    handleSubmit();
                  }
                }}
              >
                <Form.Row>
                  <StyledFormSelect
                    autoFocus
                    name="eventCategory"
                    options={eventCategories}
                    data-qa="event-type"
                    label={t('EVENT_CATEGORY_LABEL')}
                    colStart={1}
                    colWidth={2}
                  />
                  <StyledFormText
                    autoFocus
                    name="companyId"
                    data-qa="company-id"
                    label={t('COMPANY_ID_LABEL')}
                    colStart={3}
                    colWidth={2}
                  />
                  <StyledFormText
                    autoFocus
                    name="email"
                    data-qa="user-email"
                    label={t('USER_LABEL')}
                    colStart={5}
                    colWidth={2}
                  />
                  <StyledFormCheckbox
                    autoFocus
                    name="hideViewEvents"
                    data-qa="hideViewEvents-checkbox"
                    label={t('HIDE_VIEW_EVENTS_LABEL')}
                    colStart={7}
                    colWidth={1}
                  />
                  <FormButtonWrapper>
                    <Button
                      type="submit"
                      variant="primary"
                      data-qa="search-btn"
                    >
                      {t('SEARCH', { ns: 'common' })}
                    </Button>
                  </FormButtonWrapper>
                </Form.Row>
              </Form.Form>
            );
          }}
        </Form>
      </FormWrapper>
    </React.Fragment>
  );
};

export default AuditLogsForm;
