import React, { useCallback, useEffect, useState, useContext } from 'react';
import {
  Page,
  Card,
  Flex,
  Spinner,
  Box,
  EmptyState,
  FlexList,
  Breadcrumbs,
  Title,
  H1,
} from '@procore/core-react';
import { Link } from 'components/Link';
import { useTranslation } from 'react-i18next';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { getApi } from 'utils/api';
import {
  CompanyInfoApi,
  CompanyContactsApi,
  ProjectsApi,
} from '@procore/ipa-nt-api-client-ts';
import type {
  CompanyAccountStatusDto,
  CompanyUserContactDto,
  ProjectInfo,
} from '@procore/ipa-nt-api-client-ts';
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import CompanyPills from 'components/CompanyPills';
import EnvironmentSwitcher from 'components/EnvironmentSwitcher';
import { generateUrl } from './urlGenerator';
import JitWidget from 'components/JitWidget';
import { AppContext } from 'contexts/AppContext';
import CompanyLink from 'components/CompanyLink';
import ProjectLink from 'components/ProjectLink';
import ContactInfoPage from './ContactInfo';
import ProjectPermissionTemplates from './ProjectPermissionTemplates';
import ProjectSettings from './ProjectSettings';
import CompanyCostCodes from './CompanyCostCodes';
import CustomForms from './CustomForms';
import AsidePanel from './AsidePanel';
import { ActionButtonsStyle } from './styles';
import { can } from 'utils/permissions.helper';
import type { PageConfigTranslated } from 'pages/page-config.interfaces';
import { PageConfig } from 'pages/page-config.service';
import { ContactContextProvider } from 'contexts/ContactContext';
import type { ContactContextProps } from 'contexts/ContactContext';
import { AxiosError } from 'axios';
import OutboundEmailsReport from './OutboundEmailsReport';
import MessageBanner from 'components/MessageBanner';
import ContextInfoPopup from 'components/ContextInfoPopup';

export const ContactPage = () => {
  const { t } = useTranslation(['contact']);
  const { t: tCommon } = useTranslation(['common']);
  const {
    company_id: companyId,
    contact_id: contactId,
    login_id: loginId,
    page: _page,
  } = useParams();
  const page = _page ?? 'contact-info';

  const navigate = useNavigate();
  const alert = useContext(IpaAlertContext);
  const { userConfig } = useContext(AppContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(false);

  const [contactInfo, setContactInfo] = useState<CompanyUserContactDto>();
  const [companyStatus, setCompanyStatus] = useState<CompanyAccountStatusDto>();
  const [projectInfo, setProjectInfo] = useState<ProjectInfo>();
  const projectId = searchParams.get('project_id') ?? null;

  const pages = [
    {
      label: t('CONTACT_INFORMATION'),
      component: ContactInfoPage,
      configKey: 'Contact.ContactInfo',
      id: 'contact-info',
    },
    {
      label: t('PROJECT_PERMISSION_TEMPLATES.TITLE'),
      component: ProjectPermissionTemplates,
      configKey: 'Contact.ProjectPermissionTemplates',
      id: 'project-permission-templates',
    },
    {
      label: t('OUTBOUND_EMAILS_REPORT.TITLE'),
      component: OutboundEmailsReport,
      configKey: 'Contact.OutboundEmailsReport',
      id: 'outbound-emails-report',
    },
    {
      label: t('PROJECT_SETTINGS.TITLE'),
      component: ProjectSettings,
      configKey: 'Contact.ProjectSettings',
      id: 'project-settings',
    },
    {
      label: t('COMPANY_COST_CODES.TITLE'),
      component: CompanyCostCodes,
      configKey: 'Contact.CompanyCostCodes',
      id: 'cost-codes',
    },
    {
      label: t('CUSTOM_FORMS.TITLE'),
      component: CustomForms,
      configKey: 'Contact.CustomForms',
      id: 'custom-forms',
    },
  ];

  const currentPage = pages.find((p: any) => {
    return p.id === page;
  });

  const pageConfig = PageConfig.get(
    t,
    pages.find((p: any) => p.id === page)?.configKey,
  ) as PageConfigTranslated;

  const context = {
    env: searchParams.get('env') ?? 'PROD',
    projectId: +searchParams.get('project_id'),
    companyId: Number(companyId),
    contactId: Number(contactId),
    loginId: Number(loginId),
    contactEmail: contactInfo?.login_email,
    dataSources: {
      contact: {
        id: 'contact',
        data: contactInfo!,
      },
      companyStatus: {
        id: 'companyStatus',
        data: companyStatus!,
      },
      projectInfo: {
        id: 'projectInfo',
        data: projectInfo!,
      },
    },
    hasPermission: (actions: string[]) => {
      return can({ user: userConfig, subject: companyStatus }, actions, {
        allowDemoAccess: true,
      });
    },
    pageConfig: pageConfig as PageConfigTranslated,
  } as ContactContextProps;

  const loadCompanyStatus = useCallback(async () => {
    alert.closeAlert();
    setCompanyStatus(undefined);
    setIsInit(false);
    try {
      const api = getApi(CompanyInfoApi);
      const res = await api.companyCompanyInfoControllerGetCompanyAccountStatusV10(
        context.env,
        context.companyId,
      );
      if (res.data) {
        setCompanyStatus(res.data);
      }
      setIsInit(true);
    } catch (e: any) {
      return e;
    }
  }, [context]);

  const loadContactInfo = useCallback(async () => {
    alert.closeAlert();
    setContactInfo(undefined);
    setIsInit(false);
    try {
      const api = getApi(CompanyContactsApi);
      const res = await api.companyContactsControllerGetContactV10(
        context.env,
        context.companyId,
        context.contactId,
      );
      if (res.data) {
        setContactInfo(res.data);
      }
      setIsInit(true);
    } catch (e: any) {
      return e;
    }
  }, [context]);

  const loadProjectInfo = useCallback(async () => {
    try {
      if (!context.projectId) {
        return false;
      }
      const res = await getApi(ProjectsApi).projectControllerGetProjectInfoV10(
        context.env,
        context.companyId,
        context.projectId,
      );
      if (res.data) {
        setProjectInfo(res.data);
      }
    } catch (e: any) {
      alert.error(e);
    }
  }, [context.companyId, context.env, context.projectId]);

  const initPage = useCallback(async () => {
    const errors: AxiosError[] = [];
    const promises = await Promise.all([
      loadCompanyStatus(),
      loadContactInfo(),
      loadProjectInfo(),
    ]);
    promises.forEach((promise: any) => {
      if (promise instanceof AxiosError) {
        errors.push(promise);
      }
    });
    if (errors.length > 0) {
      alert.error(errors);
    }
  }, [loadCompanyStatus, loadContactInfo, loadProjectInfo]);

  useEffect(() => {
    initPage();
  }, [
    context.companyId,
    context.env,
    context.contactId,
    context.loginId,
    page,
  ]);

  const changeEnvironment = (newEnv: string) => {
    navigate(
      generateUrl(page, {
        ...context,
        env: newEnv,
      }),
    );
  };

  if (!companyId || !currentPage) {
    return <Navigate replace to={'/404'} />;
  }

  return (
    <ContactContextProvider {...{ ...context, loadContactInfo }}>
      <Page>
        <Page.Main style={{ flex: 'auto' }}>
          <Page.Header>
            <Page.Breadcrumbs>
              <Breadcrumbs>
                <Link href={`/search/user`}>
                  <Breadcrumbs.Crumb>{t('USER_SEARCH')}</Breadcrumbs.Crumb>
                </Link>
                {page !== 'contact-info' && (
                  <Link
                    href={`/v2/company/${companyId}/contact/${contactId}/login/${loginId}/contact-info${
                      page === 'project-settings' ? '#projects' : ''
                    }`}
                  >
                    <Breadcrumbs.Crumb>
                      {t('CONTACT_INFORMATION')}
                    </Breadcrumbs.Crumb>
                  </Link>
                )}
                {page === 'custom-forms' && (
                  <Link
                    href={`/v2/company/${companyId}/contact/${contactId}/login/${loginId}/project-settings?project_id=${projectId}`}
                  >
                    <Breadcrumbs.Crumb>
                      {t('PROJECT_SETTINGS.TITLE')}
                    </Breadcrumbs.Crumb>
                  </Link>
                )}
                <Breadcrumbs.Crumb active>
                  {currentPage.label}
                </Breadcrumbs.Crumb>
              </Breadcrumbs>
            </Page.Breadcrumbs>
            <Title>
              <Title.Text>
                <H1>{currentPage.label} </H1>
              </Title.Text>
              <Title.Actions style={ActionButtonsStyle}>
                <FlexList size="xs">
                  <EnvironmentSwitcher
                    initialValue={context.env}
                    onChange={changeEnvironment}
                  />
                </FlexList>
              </Title.Actions>
            </Title>
          </Page.Header>
          <Page.Body>
            <Page.Row>
              <Page.Column>
                <Box paddingBottom="lg" paddingTop="lg">
                  {companyStatus && (
                    <React.Fragment>
                      {!companyStatus.is_active && (
                        <Box marginBottom="md">
                          <MessageBanner
                            banner_message={t('JIT_ACCESS_DISABLED', {
                              companyName: companyStatus.name,
                            })}
                            banner_type="error"
                            show_title={false}
                          />
                        </Box>
                      )}
                      {companyStatus.is_active &&
                        companyStatus.account_deactivated && (
                          <Box marginBottom="md">
                            <MessageBanner
                              banner_message={t('SUSPENDED_COMPANY', {
                                companyName: companyStatus.name,
                              })}
                              banner_type="error"
                              show_title={false}
                            />
                          </Box>
                        )}
                      <Flex gap="md">
                        {projectInfo && (
                          <ProjectLink
                            companyId={companyStatus.id}
                            env={context.env}
                            projectId={projectInfo.id}
                            projectName={projectInfo.name}
                          />
                        )}
                        <div data-qa="company-name-div">
                          <Flex>
                            <CompanyLink
                              companyId={companyStatus.id}
                              companyName={companyStatus.name}
                              env={context.env}
                            />
                            <ContextInfoPopup />
                          </Flex>
                          <CompanyPills
                            companyId={context.companyId}
                            env={context.env}
                          />
                        </div>

                        {contactInfo && page !== 'contact-info' && (
                          <div>
                            <strong>{`${tCommon('USER')}`}:</strong>{' '}
                            {contactInfo.first_name} {contactInfo.last_name}
                          </div>
                        )}
                        {context.hasPermission(['jit_escalate']) &&
                          companyStatus.is_active && (
                            <div style={{ marginLeft: 'auto' }}>
                              <JitWidget
                                env={context.env}
                                companyId={context.companyId}
                                companyName={companyStatus.name}
                                isPinRequired={
                                  companyStatus.is_pin_required_for_jit
                                }
                                impersonateEmail={context.contactEmail}
                              />
                            </div>
                          )}
                      </Flex>
                    </React.Fragment>
                  )}
                </Box>

                {isLoading && (
                  <Flex justifyContent="center">
                    <Spinner loading={isLoading} />
                  </Flex>
                )}
                {isInit && !isLoading && !companyStatus && (
                  <Card>
                    <Box padding="lg" marginBottom="lg">
                      <EmptyState>
                        <EmptyState.NoItems />
                        <EmptyState.Title>{t('EMPTY_STATE')}</EmptyState.Title>
                      </EmptyState>
                    </Box>
                  </Card>
                )}

                {isInit &&
                  !isLoading &&
                  companyStatus &&
                  React.createElement(currentPage.component)}
              </Page.Column>
            </Page.Row>
          </Page.Body>
        </Page.Main>
        {page === 'contact-info' && <AsidePanel />}
      </Page>
    </ContactContextProvider>
  );
};

export default ContactPage;
