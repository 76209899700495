import React, { useState, useContext, useEffect, useCallback } from 'react';
import {
  Page,
  Breadcrumbs,
  Tabs,
  Pill,
  Title,
  H1,
  FlexList,
} from '@procore/core-react';
import { Link } from 'components/Link';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { AppContext } from 'contexts/AppContext';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { PaymentsContext } from 'contexts/PaymentsContext';
import {
  PaymentsServiceBusinessEntitiesApi,
  PaymentsFundingAccountApi,
} from '@procore/ipa-nt-api-client-ts';
import type {
  SentDisbursementDto,
  ExternalAccountDto,
} from '@procore/ipa-nt-api-client-ts';
import { getApi } from 'utils/api';
import GeneralTab from './GeneralTab';
import type { Disbursement } from './interfaces';
import ChangeHistoryTab from './ChangeHistoryTab';
import { formatCurrency, getRouteForEnv } from 'pages/Payments/payments.helper';

export const PaymentsDisbursementPage = () => {
  const { t } = useTranslation(['payments'], {
    keyPrefix: 'DISBURSEMENT',
  });
  const navigate = useNavigate();
  const { id, disbursementId } = useParams();
  const [searchParams] = useSearchParams();
  const alert = useContext(IpaAlertContext);
  const { env } = useContext(AppContext);
  const { companyStatus } = useContext(PaymentsContext);
  const activeTab = searchParams.get('tab') ?? 'general';
  const [disbursement, setDisbursement] = useState<Disbursement>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(false);

  const tabs = [
    {
      id: 'general',
      label: t('GENERAL'),
      component: GeneralTab,
    },
    {
      id: 'change-history',
      label: t('CHANGE_HISTORY'),
      component: ChangeHistoryTab,
    },
  ];

  const changeTab = (newTab: string) => {
    navigate(
      getRouteForEnv(
        env,
        `/business-entity/${id}/disbursement/${disbursementId}?tab=${newTab}`,
      ),
    );
  };

  const formatBankAccount = (
    accounts: ExternalAccountDto[],
    disbursement: SentDisbursementDto,
  ) => {
    const externalAccount = accounts.find(
      (account: ExternalAccountDto) =>
        account.id === disbursement.sendingExternalAccountId,
    );
    return !externalAccount
      ? disbursement.sendingExternalAccountId
      : `${externalAccount.bankName} (...${externalAccount.lastFour})`;
  };

  const getDisbursement = useCallback(async () => {
    alert.closeAlert();
    try {
      setIsLoading(true);
      setIsInit(true);
      const api: PaymentsServiceBusinessEntitiesApi = getApi(
        PaymentsServiceBusinessEntitiesApi,
      );

      const externalAccounts = await getApi(
        PaymentsFundingAccountApi,
      ).fundingAccountControllerGetExternalAccountsV10(env, +id, 1, 10_000, ''); // There is no GET endpoint for external accounts, thus we have to get the full list and filter it

      const response = await api.businessEntityControllerGetDisbursementDetailsV10(
        env,
        +id,
        disbursementId,
      );
      setDisbursement({
        ...response.data,
        displayAmount: formatCurrency(
          response.data.currencyCode,
          response.data.amount / 100,
        ),
        sendingExternalAccountDisplay: formatBankAccount(
          externalAccounts.data.items,
          response.data,
        ),
      });
    } catch (e: any) {
      alert.error(e);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (id && disbursementId && !isInit) {
      getDisbursement();
    }
  }, [id, disbursementId]);

  return (
    <Page>
      <Page.Main style={{ flex: 'auto' }}>
        <Page.Header>
          <Page.Breadcrumbs>
            <Breadcrumbs>
              <Link href={`${getRouteForEnv(env, '')}`}>
                <Breadcrumbs.Crumb>{t('PAYMENTS_TITLE')}</Breadcrumbs.Crumb>
              </Link>
              <Breadcrumbs.Crumb active>
                <Link
                  href={`${getRouteForEnv(
                    env,
                    `/business-entity/${id}?tab=sent-disbursements`,
                  )}`}
                >
                  <Breadcrumbs.Crumb>
                    {t('COMPANY_BREADCRUMB', {
                      companyName: companyStatus?.name,
                      companyId: companyStatus?.id,
                    })}
                  </Breadcrumbs.Crumb>
                </Link>
              </Breadcrumbs.Crumb>
              <Breadcrumbs.Crumb active>
                {t('TITLE', {
                  disbursementId: disbursement?.disbursementIdentifier,
                })}
              </Breadcrumbs.Crumb>
            </Breadcrumbs>
          </Page.Breadcrumbs>
          <Title>
            <Title.Text>
              <FlexList size="xs">
                <H1>
                  {t('TITLE', {
                    disbursementId: disbursement?.disbursementIdentifier,
                  })}{' '}
                </H1>
                {env === 'INTEGRATOR_SANDBOX' && (
                  <Pill color="red">{t('INTEGRATOR_SANDBOX')}</Pill>
                )}
              </FlexList>
            </Title.Text>
          </Title>
          <Page.Tabs>
            <Tabs>
              {tabs.map((tab, i) => (
                <Tabs.Tab
                  key={i}
                  active={activeTab === tab.id}
                  onClick={() => changeTab(tab.id)}
                >
                  <Tabs.Link>{tab.label}</Tabs.Link>
                </Tabs.Tab>
              ))}
            </Tabs>
          </Page.Tabs>
        </Page.Header>
        <Page.Body>
          <Page.Row>
            <Page.Column>
              {tabs.map(
                (tab, idx) =>
                  activeTab === tab.id &&
                  React.createElement(tab.component, {
                    key: idx,
                    env: env,
                    companyId: +id,
                    disbursementId: disbursementId,
                    disbursement: disbursement,
                    isLoading: isLoading,
                  }),
              )}
            </Page.Column>
          </Page.Row>
        </Page.Body>
      </Page.Main>
    </Page>
  );
};

export default PaymentsDisbursementPage;
