import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Card, H2, Flex, Spinner } from '@procore/core-react';
import { PaymentsServiceBusinessEntitiesApi } from '@procore/ipa-nt-api-client-ts';
import type { BusinessEntityChangeHistoryDto } from '@procore/ipa-nt-api-client-ts';
import { getApi } from 'utils/api';
import TableBackendComponent from 'components/TableBackend';
import type { TableHeader } from 'components/TableBackend/table.interface';
import { AppContext } from 'contexts/AppContext';
import { IpaAlertContext } from 'contexts/IpaAlertContext';

import { PaymentsBusinessEntityContext } from 'contexts/PaymentsBusinessEntityContext';

export interface PaymentsServiceChangeHistoryCardProps {
  id: string;
}

export const PaymentsServiceChangeHistoryCard = (
  props: PaymentsServiceChangeHistoryCardProps,
) => {
  const { id: companyId } = useParams();
  const { userSettings } = useContext(AppContext);
  const alert = useContext(IpaAlertContext);
  const [paymentsServiceChangeHistory, setPaymentsServiceChangeHistory] =
    useState<BusinessEntityChangeHistoryDto[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(
    (userSettings.default_page_size as number) || 25,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(false);

  const { t: tCommon } = useTranslation(['common']);
  const { t } = useTranslation(['payments'], {
    keyPrefix:
      'BUSINESS_ENTITY.CHANGE_HISTORY_TAB.PAYMENTS_SERVICE_CHANGE_HISTORY_CARD',
  });
  const context = useContext(PaymentsBusinessEntityContext);

  const getBusinessEntityChangeHistory = async (
    companyId: number,
    currentPage: number,
    perPage: number,
  ) => {
    try {
      const businessEntityApi: PaymentsServiceBusinessEntitiesApi = getApi(
        PaymentsServiceBusinessEntitiesApi,
      );

      const result =
        await businessEntityApi.businessEntityControllerGetBusinessEntitiesChangeHistoryV10(
          context.env,
          companyId,
          currentPage,
          perPage,
          '',
        );

      if (result.data) {
        return result.data;
      }
    } catch (e: any) {
      alert.error(e);
    }
  };

  const changePage = (page: number, _pageSize: number) => {
    setCurrentPage(page);
  };

  const tableHeaders: TableHeader[] = [
    {
      title: t('DATE'),
      node: 'createdAt',
      is_datetime: true,
      is_sortable: true,
    },
    {
      title: t('ACTION_BY'),
      node: 'actionBy',
      is_sortable: true,
      is_text: true,
    },
    {
      title: t('CHANGED'),
      node: 'changed',
      is_sortable: true,
      is_text: true,
    },
    {
      title: t('FROM'),
      node: 'oldValue',
      is_sortable: true,
      is_text: true,
    },
    {
      title: t('TO'),
      node: 'newValue',
      is_sortable: true,
      is_text: true,
    },
  ];

  useEffect(() => {
    if (companyId) {
      if (!isInit) {
        setIsLoading(true);
      }

      const nextPage = async () => {
        const res = await getBusinessEntityChangeHistory(
          +companyId,
          currentPage,
          perPage,
        );

        if (res && res.items) {
          setPaymentsServiceChangeHistory(res.items);
          setTotal(res.total);
          setIsLoading(false);
          setIsInit(true);
        }
      };
      nextPage();
    }
  }, [companyId, currentPage, perPage]);

  return (
    <Box marginBottom="lg">
      <Card>
        <Box padding="lg">
          <H2>{t('TITLE')}</H2>
          {isLoading && (
            <Flex justifyContent="center">
              <Spinner loading={isLoading} />
            </Flex>
          )}
          {!isLoading && isInit && (
            <TableBackendComponent
              data={paymentsServiceChangeHistory}
              data_total={total}
              current_page={currentPage}
              page_size={perPage}
              default_sort_column="createdAt"
              default_sort_direction="asc"
              headers={tableHeaders}
              paginator={changePage}
              sorter={() => {}}
              no_data_message={tCommon('NO_DATA')}
              show_no_data_message={true}
            />
          )}
        </Box>
      </Card>
    </Box>
  );
};

export default PaymentsServiceChangeHistoryCard;
