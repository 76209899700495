import type { PageConfig } from 'pages/page-config.interfaces';

export default {
  route: {
    path: '/v2/jit-admin',
  },
  i18n: {
    namespace: 'jit-admin',
  },
  label: 'JIT_ADMIN_TITLE',
  endpoints: {
    view: ['jitControllerJitSessionReport'],
    edit: ['jitControllerTerminateSession'], // For the de-escalate button
  },
  cards: {},
} as PageConfig;
