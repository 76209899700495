import type { PageConfig } from 'pages/page-config.interfaces';

export default {
  label: 'JIT_AUDIT_LOG_TITLE',
  i18n: {
    namespace: 'jit-audit-log',
  },
  route: {
    path: '/v2/jit-audit-log',
  },
  endpoints: {
    view: [],
  },
  cards: {},
} as PageConfig;
