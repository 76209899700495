import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Card,
  Dropdown,
  Flex,
  Form,
  H2,
  Page,
  Pill,
  Spinner,
  Title,
} from '@procore/core-react';
import type { TableHeader } from 'components/TableBackend/table.interface';
import { PaymentsBusinessEntityContext } from 'contexts/PaymentsBusinessEntityContext';
import { PaymentsBusinessEntityGeneralTabContext } from '.';
import {
  type ExternalAccountDto,
  ExternalAccountDtoVerificationStatusEnum,
} from '@procore/ipa-nt-api-client-ts';
import DataEditModal from 'components/DataEditModal';
import TableComponent from 'components/Table';
import type { PillOption } from '@procore/core-react/dist/PillSelect/PillSelect.types';
import type { ISelectOption } from 'interfaces/common.interface';
import TableBackendComponent from 'components/TableBackend';

export interface ExternalAccountsCardProps {
  id: string;
  isCardLoading: boolean;
  updateExternalAccount: (
    id: string,
    values: VerifyExternalAccountsEditProps,
  ) => void;
  deactivateExternalAccount: (
    id: string,
    values: DeactivateExternalAccountsEditProps,
  ) => void;
  paginateExternalAccounts: (page?: number, perPage?: number) => void;
}

export interface VerifyExternalAccountsEditProps {
  verificationStatus: string;
  reason: string;
}

export interface DeactivateExternalAccountsEditProps {
  reason: string;
}

export interface VerifyExternalAccountsEditFromProps {
  verificationStatus: VerificationStatusOption;
  reason: string;
}

export interface DeactivateExternalAccountsEditFromProps {
  active: string;
  reason: string;
}

type VerificationStatusOption = {
  id: ExternalAccountDtoVerificationStatusEnum;
  label: string;
};

export const ExternalAccountsCard = (props: ExternalAccountsCardProps) => {
  const context = useContext(PaymentsBusinessEntityContext);
  const config = context.pageConfig.card('ExternalAccounts');
  const t = config.translate;
  const { t: tCommon } = useTranslation(['common']);
  const [showVerifyAccountModal, setShowVerifyAccountModal] = useState(false);
  const [showDeactivateAccountModal, setShowDeactivateAccountModal] =
    useState(false);
  const [selectedExternalAccount, setSelectedExternalAccount] =
    useState<ExternalAccountDto>({} as ExternalAccountDto);
  const [perPage, setPerPage] = useState<number>(25);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const {
    dataSources: { externalAccounts },
  } = useContext(PaymentsBusinessEntityGeneralTabContext);

  const accountVerificationStatusPillOptions: PillOption[] = [
    {
      id: ExternalAccountDtoVerificationStatusEnum.Verified,
      label: t('VERIFIED'),
      color: 'green',
    },
    {
      id: ExternalAccountDtoVerificationStatusEnum.Pending,
      label: t('PENDING'),
      color: 'gray',
    },
  ];

  const externalAccountStatusPillOptions: PillOption[] = [
    { id: 'active', label: t('ACTIVE_STATUS'), color: 'green' },
    { id: 'deactivated', label: t('DEACTIVATED_STATUS'), color: 'gray' },
  ];

  const verificationStatusOptions: VerificationStatusOption[] = [
    {
      id: ExternalAccountDtoVerificationStatusEnum.Verified,
      label: t('VERIFIED'),
    },
    {
      id: ExternalAccountDtoVerificationStatusEnum.Pending,
      label: t('PENDING'),
    },
  ];

  const accountStatusOptions: ISelectOption[] = [
    { id: 'active', label: t('ACTIVE_STATUS') },
    { id: 'deactivated', label: t('DEACTIVATED_STATUS') },
  ];

  const accountSettingsMap = [
    {
      display_name: config.translate('VERIFICATION_STATUS'),
      name: 'verificationStatus',
    },
    {
      display_name: config.translate('ACCOUNT_STATUS'),
      name: 'active',
    },
  ];

  const displayFunc = (item: ExternalAccountDto) => {
    if (!item.active || !context.hasPermission(config.permissions.edit ?? [])) {
      return <div />;
    }

    return (
      <React.Fragment>
        <Dropdown>
          {item.verificationStatus ===
            ExternalAccountDtoVerificationStatusEnum.Pending && (
            <Dropdown.Item>
              <Button
                size="sm"
                variant={'tertiary'}
                data-qa={`verify-account-${item.id}`}
                onClick={() => {
                  setSelectedExternalAccount(item);
                  setShowVerifyAccountModal(true);
                }}
              >
                {t('VERIFY')}
              </Button>
            </Dropdown.Item>
          )}
          <Dropdown.Item>
            <Button
              size="sm"
              variant={'tertiary'}
              data-qa={`deactivate-account-${item.id}`}
              onClick={() => {
                setSelectedExternalAccount(item);
                setShowDeactivateAccountModal(true);
              }}
            >
              {t('DEACTIVATE')}
            </Button>
          </Dropdown.Item>
        </Dropdown>
      </React.Fragment>
    );
  };

  const tableHeaders: TableHeader[] = [
    {
      title: t('NICKNAME'),
      node: '',
      column_width: 25,
      is_sortable: false,
      display_func: (item: ExternalAccountDto) => {
        return (
          <div>
            <div>{`${item.nickname} (...${item.lastFour ?? ''})`}</div>
            {item.defaultType === 'PAY_FROM' && <div>{t('DEFAULT')}</div>}
          </div>
        );
      },
    },
    {
      title: t('ACCOUNT_STATUS'),
      node: 'active',
      column_width: 15,
      is_sortable: false,
      display_func: (item: ExternalAccountDto) => {
        return item.active ? (
          <Pill
            color={externalAccountStatusPillOptions[0].color}
            data-qa={`ext-active-status-${item.id}`}
          >
            {externalAccountStatusPillOptions[0].label}
          </Pill>
        ) : (
          <Pill
            color={externalAccountStatusPillOptions[1].color}
            data-qa={`ext-active-status-${item.id}`}
          >
            {externalAccountStatusPillOptions[1].label}
          </Pill>
        );
      },
    },
    {
      title: t('VERIFICATION_STATUS'),
      node: '',
      column_width: 15,
      is_sortable: false,
      display_func: (item: ExternalAccountDto) => {
        const pillOption = accountVerificationStatusPillOptions.find(
          (option) => option.id === item.verificationStatus,
        );
        return (
          <Pill
            color={pillOption?.color}
            data-qa={`ext-verification-status-${item.id}`}
          >
            {pillOption?.label}
          </Pill>
        );
      },
    },
    {
      title: t('BANK_ID'),
      node: 'bankId',
      is_text: true,
      is_sortable: false,
      column_width: 15,
    },
    {
      title: t('NEXT_CHECK_NUMBER'),
      node: 'nextCheckNumber',
      is_text: true,
      is_sortable: false,
      column_width: 15,
    },
    {
      title: '',
      node: '',
      column_width: 10,
      is_sortable: false,
      display_func: (item: ExternalAccountDto) => displayFunc(item),
    },
  ];

  const closeEditModal = () => {
    setShowVerifyAccountModal(false);
    setShowDeactivateAccountModal(false);
    setSelectedExternalAccount(undefined);
  };

  const updateExternalAccount = (
    values: VerifyExternalAccountsEditFromProps,
  ) => {
    setShowVerifyAccountModal(false);
    props.updateExternalAccount(selectedExternalAccount?.id, {
      verificationStatus: values.verificationStatus.id,
      reason: values.reason,
    });
  };

  const deactivateExternalAccount = (
    values: DeactivateExternalAccountsEditProps,
  ) => {
    setShowVerifyAccountModal(false);
    props.deactivateExternalAccount(selectedExternalAccount?.id, {
      reason: values.reason,
    });
  };

  const changePage = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPerPage(pageSize);
  };

  useEffect(() => {
    if (currentPage && perPage) {
      props.paginateExternalAccounts(currentPage, perPage);
    }
  }, [currentPage, perPage]);

  return (
    <Box marginBottom="lg">
      <Card>
        {props.isCardLoading ? (
          <Flex justifyContent="center" padding="lg">
            <Spinner loading={props.isCardLoading} />
          </Flex>
        ) : (
          <Box padding="lg">
            {externalAccounts.data && (
              <React.Fragment>
                <Title>
                  <Title.Text>
                    <H2>{t('TITLE')}</H2>
                  </Title.Text>
                </Title>

                {/* Remove or comment out this TableComponent since we're using TableBackendComponent for pagination */}
                {/* <TableComponent
                  data={externalAccounts.data.items}
                  headers={tableHeaders}
                  no_data_message={tCommon('NO_DATA')}
                  show_no_data_message={true}
                  search_columns={['nickname', 'bankId', 'verificationStatus']}
                  show_search={true}
                  default_sort_column=""
                  default_sort_direction=""
                  page_size={10}
                  fixed_table={true}
                  data_qa={'external-accounts-table'}
                /> */}

                <TableBackendComponent
                  data={externalAccounts.data.items}
                  data_total={externalAccounts.data.total}
                  current_page={externalAccounts.data.page}
                  page_size={perPage}
                  page_size_action={(newSize) => {
                    setPerPage(newSize);
                    setCurrentPage(1); // Reset to page 1 when changing page size
                  }}
                  show_page_size_select={false}
                  default_sort_column=""
                  default_sort_direction="asc"
                  headers={tableHeaders}
                  paginator={changePage}
                  sorter={() => {}}
                  no_data_message={tCommon('NO_DATA')}
                  show_no_data_message={true}
                  fixed_table={true}
                  data_qa={'external-accounts-table'}
                />
              </React.Fragment>
            )}
          </Box>
        )}
        <DataEditModal
          data={{
            verificationStatus: verificationStatusOptions.find(
              (option: VerificationStatusOption) =>
                option.id === selectedExternalAccount?.verificationStatus,
            ),
            nickname: selectedExternalAccount?.nickname ?? '',
            lastFour: selectedExternalAccount?.lastFour ?? '',
          }}
          show={showVerifyAccountModal}
          formView={'update'}
          dataMap={accountSettingsMap}
          close={() => closeEditModal()}
          save={(values: VerifyExternalAccountsEditFromProps) => {
            updateExternalAccount(values);
          }}
          editReason={tCommon('PROVIDE_REASON')}
          changesTitle={t('VERIFY_ACCOUNT_TITLE')}
          editTitle={t('VERIFY_ACCOUNT_TITLE')}
        >
          <Page.Grid>
            <Page.Row>
              <Page.Column colWidth={{ tabletMd: 5, tabletSm: 11 }}>
                <Form.Text
                  name="nickname"
                  label={t('NICKNAME')}
                  data-qa="input-nickname"
                  disabled
                />
              </Page.Column>
              <Page.Column colWidth={{ tabletMd: 5, tabletSm: 11 }}>
                <Form.Text
                  name="lastFour"
                  label={t('LAST_FOUR')}
                  data-qa="input-last-four"
                  disabled
                />
              </Page.Column>
              <Page.Column colWidth={{ tabletSm: 11 }}>
                <Form.Select
                  onSearch={false}
                  name="verificationStatus"
                  id="verificationStatus"
                  data-qa="modal-verification-status"
                  label={t('VERIFICATION_STATUS')}
                  options={verificationStatusOptions}
                />
              </Page.Column>
            </Page.Row>
          </Page.Grid>
        </DataEditModal>

        <DataEditModal
          data={{
            active: selectedExternalAccount?.active
              ? accountStatusOptions.find((option) => option.id === 'active')
              : accountStatusOptions.find(
                  (option) => option.id === 'deactivated',
                ),
            nickname: selectedExternalAccount?.nickname ?? '',
            lastFour: selectedExternalAccount?.lastFour ?? '',
          }}
          show={showDeactivateAccountModal}
          formView={'update'}
          dataMap={accountSettingsMap}
          close={() => closeEditModal()}
          save={(values: DeactivateExternalAccountsEditFromProps) => {
            deactivateExternalAccount(values);
          }}
          editReason={tCommon('PROVIDE_REASON')}
          changesTitle={t('DEACTIVATE_ACCOUNT_TITLE')}
          editTitle={t('DEACTIVATE_ACCOUNT_TITLE')}
        >
          <Page.Grid>
            <Page.Row>
              <Page.Column colWidth={{ tabletMd: 5, tabletSm: 11 }}>
                <Form.Text
                  name="nickname"
                  label={t('NICKNAME')}
                  data-qa="input-nickname"
                  disabled
                />
              </Page.Column>
              <Page.Column colWidth={{ tabletMd: 5, tabletSm: 11 }}>
                <Form.Text
                  name="lastFour"
                  label={t('LAST_FOUR')}
                  data-qa="input-last-four"
                  disabled
                />
              </Page.Column>
              <Page.Column colWidth={{ tabletSm: 11 }}>
                <Form.Select
                  name={'active'}
                  label={t('ACCOUNT_STATUS')}
                  options={accountStatusOptions}
                  data-qa="modal-account-status"
                />
              </Page.Column>
            </Page.Row>
          </Page.Grid>
        </DataEditModal>
      </Card>
    </Box>
  );
};
