import React, { useContext, useEffect, useRef } from 'react';
import { Form, Page } from '@procore/core-react';
import { PaymentsBusinessEntityContext } from 'contexts/PaymentsBusinessEntityContext';
import { getFeePaySettingsOptions } from './FeePaySettingsConstants';

export interface FeePaySettingsFormProps {
  values: any;
  disabled?: boolean;
  setFieldValue?: (field: string, value: any) => void;
}

export const FeePaySettingsForm = (props: FeePaySettingsFormProps) => {
  const context = useContext(PaymentsBusinessEntityContext);
  const config = context.pageConfig.card('FeePaySettings');
  const t = config.translate;
  const options = getFeePaySettingsOptions(t);

  // Track the previous fee status to detect actual changes
  const previousFeeStatusRef = useRef(props.values.applyFeesOnAgreements?.id);

  // Check if SC Fees are enabled based on the current form values
  const areFeesEnabled =
    props.values.applyFeesOnAgreements?.id === 'feeEnabled';

  // Determine if Fee Collection and Invoice Blocking should be disabled
  // They should be disabled if the form is in read-only mode OR if SC Fees are disabled
  const feeSettingsDisabled = props.disabled || !areFeesEnabled;

  // Set appropriate default values when Fee Pay Status changes
  useEffect(() => {
    // Only execute when the fee status has actually changed
    const currentFeeStatus = props.values.applyFeesOnAgreements?.id;
    if (
      !props.disabled &&
      props.setFieldValue &&
      props.values.applyFeesOnAgreements &&
      currentFeeStatus !== previousFeeStatusRef.current
    ) {
      // Update the previous value ref
      previousFeeStatusRef.current = currentFeeStatus;

      if (areFeesEnabled) {
        // When toggling ON, set defaults for Fee Collection and Invoice Blocking
        props.setFieldValue(
          'payeeFeeCollectionStage',
          options.feeCollectionStageOptions.find(
            (option) => option.id === 'INVOICE_APPROVAL',
          ),
        );
        props.setFieldValue(
          'requiresPayAgreementForInvoiceSubmission',
          options.invoiceBlockingOptions.find(
            (option) => option.id === 'ENABLED',
          ),
        );
      } else {
        // When toggling OFF, set defaults for Fee Collection and Invoice Blocking
        props.setFieldValue(
          'payeeFeeCollectionStage',
          options.feeCollectionStageOptions.find(
            (option) => option.id === 'PAYOUT_COMPLETED',
          ),
        );
        props.setFieldValue(
          'requiresPayAgreementForInvoiceSubmission',
          options.invoiceBlockingOptions.find(
            (option) => option.id === 'DISABLED',
          ),
        );
      }
    }
  }, [
    props.values.applyFeesOnAgreements,
    props.disabled,
    props.setFieldValue,
    options,
    areFeesEnabled,
  ]);

  return (
    <Page.Grid>
      <Page.Row>
        <Page.Column>
          <Form.Select
            name="applyFeesOnAgreements"
            label={
              t('FEE_PAY_STATUS') || config.fields.applyFeesOnAgreements.label
            }
            options={options.feeStatusOptions}
            data-qa="input-fee-pay-status"
            disabled={props.disabled}
          />
        </Page.Column>
        <Page.Column>
          <Form.Select
            name="payorPaysFeesFeatureEnabled"
            label={
              t('FEE_PAY_TYPE') ||
              config.fields.payorPaysFeesFeatureEnabled.label
            }
            options={options.feePayTypeOptions}
            data-qa="input-fee-pay-type"
            disabled={props.disabled}
          />
        </Page.Column>
      </Page.Row>
      <Page.Row>
        <Page.Column>
          <Form.Select
            name="payeeFeeCollectionStage"
            label={
              t('FEE_COLLECTION') || config.fields.payeeFeeCollectionStage.label
            }
            options={options.feeCollectionStageOptions}
            data-qa="input-fee-collection-stage"
            disabled={feeSettingsDisabled}
          />
        </Page.Column>
        <Page.Column>
          <Form.Select
            name="requiresPayAgreementForInvoiceSubmission"
            label={
              t('INVOICE_BLOCKING') ||
              config.fields.requiresPayAgreementForInvoiceSubmission.label
            }
            options={options.invoiceBlockingOptions}
            data-qa="input-invoice-blocking"
            disabled={feeSettingsDisabled}
          />
        </Page.Column>
      </Page.Row>
    </Page.Grid>
  );
};

export default FeePaySettingsForm;
