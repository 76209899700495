import React, { useContext } from 'react';
import { Form } from '@procore/core-react';
import { EditableCard } from 'components/EditableCard';
import type { IEditableCard } from 'components/EditableCard';
import type { ProjectAdvancedSettingsProps } from '../interfaces';
import { ProjectInfoContext } from '../index';
import { ProjectContext } from 'contexts/ProjectContext';

export const ProjectSuperuserSettings = (
  props: ProjectAdvancedSettingsProps,
) => {
  const context = useContext(ProjectContext);
  const config = props.cardConfig;

  const {
    dataSources: { projectSettings },
  } = useContext(ProjectInfoContext);

  const advancedSettings = projectSettings.data?.advanced_settings;
  const canEdit = context.hasPermission(config.permissions.edit);

  const card: IEditableCard = {
    id: props.id,
    i18n: config.translate,
    label: config.label,
    canEdit: canEdit,
    save: props.save,
    fields: [
      {
        source: projectSettings.id,
        value: advancedSettings?.personal_weather_station_id,
        name: 'personal_weather_station_id',
        dataType: 'text',
        editable: false,
        element: Form.Text,
        hideOnEdit: true,
        ...config.fields.personal_weather_station_id,
      },
      {
        source: projectSettings.id,
        value: advancedSettings?.enable_change_order_associations,
        name: 'enable_change_order_associations',
        dataType: 'boolean',
        editable: false,
        element: Form.Checkbox,
        hideOnEdit: true,
        ...config.fields.enable_change_order_associations,
      },
      {
        source: projectSettings.id,
        value: advancedSettings?.enable_cobli_for_prime_contract,
        name: 'enable_cobli_for_prime_contract',
        dataType: 'boolean',
        editable: false,
        element: Form.Checkbox,
        hideOnEdit: true,
        ...config.fields.enable_cobli_for_prime_contract,
      },
      {
        source: projectSettings.id,
        value: advancedSettings?.enable_project_as_template,
        name: 'enable_project_as_template',
        dataType: 'boolean',
        element: Form.Checkbox,
        editable: canEdit,
        ...config.fields.enable_project_as_template,
        tooltip: advancedSettings?.is_demo
          ? config.translate('ENABLE_TEMPLATE_TOOLTIP')
          : '',
      },
    ],
  };

  return <EditableCard card={card} />;
};

export default ProjectSuperuserSettings;
