import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Page, ToolHeader, Card, Box, Button, Link } from '@procore/core-react';
import { useTranslation } from 'react-i18next';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { AppContext } from 'contexts/AppContext';
import TableComponent from 'components/Table';
import type { TableHeader } from 'components/Table/table.interface';
import { PageConfig } from 'pages/page-config.service';
import { getApi } from 'utils/api';
import { SuperUserApi } from '@procore/ipa-nt-api-client-ts';
import { ExternalLink } from '@procore/core-icons';
import { getBaseUrl } from 'utils/utils';
import ConfirmModal from 'components/ConfirmModal';
import SuperUserPromoteModal from './SuperUserPromoteModal';
import type { SUPromoteFormValues } from './SuperUserPromoteModal';

type SuSessionType = {
  env: string;
  email: string;
  isSuper: boolean;
  status: string;
  expires: string; // a number in minutes
};

export const SuperUserPage = () => {
  const { t } = useTranslation(['super-user']);
  const alert = useContext(IpaAlertContext);
  const { userConfig } = useContext(AppContext);
  const config = PageConfig.getPage(t, 'SuperUser', 'SuperUser');
  const [suSessions, setSuSessions] = useState<SuSessionType[]>([]);
  const [isDemoteModalOpen, setIsDemoteModalOpen] = useState<boolean>(false);
  const [isExtendModalOpen, setIsExtendModalOpen] = useState<boolean>(false);
  const [isPromoteModalOpen, setIsPromoteModalOpen] = useState<boolean>(false);
  const [selectedSession, setSelectedSession] = useState<
    SuSessionType | undefined
  >(undefined);

  const allowedZones =
    userConfig.user?.allowedZones?.filter(
      (zone) => zone !== 'INTEGRATOR_SANDBOX',
    ) ?? [];

  const promote = async (values: SUPromoteFormValues) => {
    try {
      const api: SuperUserApi = getApi(SuperUserApi);
      await api.superUserControllerPromoteV10({
        env: values.env,
        email: userConfig.user.username,
        hoursRequested: values.hoursRequested.id as number,
        category: values.category?.id,
        reason: values.reasonDetails,
        salesforceCaseNumber: values.salesforceCaseNumber ?? '',
      });

      fetchUserSessions();
      alert.success(
        config.translate('PROMOTE_SUCCESS', {
          email: userConfig.user.username,
          env: values.env,
        }),
      );
    } catch (e: any) {
      alert.error(
        config.translate('PROMOTE_ERROR', {
          email: userConfig.user.username,
          env: values.env,
          error: e.response?.data?.message,
        }),
      );
    } finally {
      setIsPromoteModalOpen(false);
    }
  };

  const demote = async () => {
    alert.closeAlert();
    try {
      const api: SuperUserApi = getApi(SuperUserApi);
      await api.superUserControllerDemoteV10({
        env: selectedSession.env,
        email: selectedSession.email,
      });

      fetchUserSessions();
      alert.success(
        config.translate('DEMOTE_SUCCESS', {
          email: selectedSession.email,
          env: selectedSession.env,
        }),
      );
    } catch (e: any) {
      alert.error(
        config.translate('DEMOTE_ERROR', {
          email: selectedSession?.email,
          env: selectedSession.env,
          error: e.response?.data?.message,
        }),
      );
    }
  };

  const extend = async () => {
    alert.closeAlert();
    try {
      const api: SuperUserApi = getApi(SuperUserApi);
      await api.superUserControllerExtendV10({
        env: selectedSession.env,
        email: selectedSession.email,
      });

      fetchUserSessions();
      alert.success(
        config.translate('EXTEND_SUCCESS', {
          email: selectedSession.email,
          env: selectedSession.env,
        }),
      );
    } catch (e: any) {
      alert.error(
        config.translate('EXTEND_ERROR', {
          email: selectedSession?.email,
          env: selectedSession.env,
          error: e.response?.data?.message,
        }),
      );
    }
  };

  const showDemoteModal = (session: SuSessionType) => {
    setSelectedSession(session);
    setIsDemoteModalOpen(true);
  };

  const showExtendModal = (session: SuSessionType) => {
    setSelectedSession(session);
    setIsExtendModalOpen(true);
  };

  const showPromoteModal = (session: SuSessionType) => {
    setSelectedSession(session);
    setIsPromoteModalOpen(true);
  };

  const displayPromoteDemoteFunc = (row: SuSessionType) => {
    return (
      <React.Fragment>
        {row.isSuper && (
          <Button
            size={'sm'}
            variant="secondary"
            onClick={() => showDemoteModal(row)}
            data-qa="su-demote-btn"
          >
            {config.translate('DEMOTE')}
          </Button>
        )}
        {!row.isSuper && (
          <Button
            size={'sm'}
            variant="primary"
            onClick={() => showPromoteModal(row)}
            data-qa="su-promote-btn"
          >
            {config.translate('PROMOTE')}
          </Button>
        )}
      </React.Fragment>
    );
  };

  const displayExtendFunc = (row: SuSessionType) => {
    return (
      <Button
        size={'sm'}
        variant="secondary"
        onClick={() => showExtendModal(row)}
        disabled={!row.isSuper}
        data-qa="su-extend-btn"
      >
        {config.translate('EXTEND')}
      </Button>
    );
  };

  const tableHeaders: TableHeader[] = [
    {
      title: config.translate('ENV'),
      node: 'env',
      is_sortable: false,
      display_func: (row: SuSessionType) => {
        return (
          <Link href={getBaseUrl(row.env, 'login')} target="_blank">
            {row.env}
            <ExternalLink size="sm" style={{ verticalAlign: 'text-bottom' }} />
          </Link>
        );
      },
      column_width: 30,
    },
    {
      title: config.translate('STATUS'),
      node: 'status',
      is_sortable: false,
      is_text: true,
      column_width: 35,
    },
    {
      title: config.translate('EXPIRES'),
      node: 'expires',
      is_sortable: false,
      is_text: true,
      column_width: 15,
    },
    {
      title: '',
      node: '',
      display_func: displayPromoteDemoteFunc,
      column_width: 10,
    },
    {
      title: '',
      node: '',
      display_func: displayExtendFunc,
      column_width: 10,
    },
  ];

  const getExpirationMinutes = (expiresAt: string): string => {
    const now = new Date();
    const expires = new Date(expiresAt);
    const diff = expires.getTime() - now.getTime();
    if (diff > 0) {
      const hours = Math.floor(diff / 3600000);
      const minutes = Math.floor((diff - hours * 3600000) / 60000);
      if (hours > 0) {
        return config.translate('HOURS_MINUTES', {
          hours: hours,
          minutes: minutes,
        });
      } else {
        return config.translate('MINUTES', { minutes: minutes });
      }
    } else {
      return config.translate('EXPIRED');
    }
  };

  const fetchUserSessions = useCallback(async () => {
    const api: SuperUserApi = getApi(SuperUserApi);
    let sessions = await api.superUserControllerGetSessionsForEmailV10(
      userConfig.user.username,
    );

    let resultSessions: { [key: string]: SuSessionType } = {};
    allowedZones.forEach((zone) => {
      resultSessions[zone] = {
        env: zone,
        email: userConfig.user.username,
        isSuper: false,
        status: config.translate('NORMAL_USER'),
        expires: '',
      };
    });

    if (sessions?.data) {
      sessions?.data.forEach((s) => {
        resultSessions[s.env] = {
          env: s.env,
          email: s.email,
          isSuper: true,
          status: config.translate('SUPER_USER'),
          expires: s.expires_at ? getExpirationMinutes(s.expires_at) : '',
        };
      });
    }

    setSuSessions(Object.values(resultSessions));
  }, []);

  useEffect(() => {
    alert.closeAlert();
    try {
      fetchUserSessions();
    } catch (e: any) {
      alert.error(e);
    }
  }, []);

  return (
    <React.Fragment>
      <Page>
        <Page.Main style={{ flex: 'auto' }}>
          <Page.Header>
            <ToolHeader>
              <ToolHeader.Title data-qa={'su-title'}>
                {config.translate('SU_HOME_TITLE')}{' '}
              </ToolHeader.Title>
            </ToolHeader>
          </Page.Header>
          <Page.Body>
            <Card>
              <Box padding="lg">
                <TableComponent
                  data={suSessions}
                  headers={tableHeaders}
                  default_sort_column={'env'}
                  default_sort_direction={'asc'}
                  show_no_data_message={true}
                  show_page_size_select={false}
                  fixed_table={true}
                />
              </Box>
            </Card>
          </Page.Body>
        </Page.Main>
      </Page>
      <ConfirmModal
        isOpen={isDemoteModalOpen && selectedSession !== undefined}
        modalTitle={config.translate('CONFIRM_TITLE')}
        modalCopy={config.translate('DEMOTE_CONFIRM', {
          email: selectedSession?.email,
          env: selectedSession?.env,
        })}
        closeModal={() => {
          setIsDemoteModalOpen(false);
          setSelectedSession(undefined);
        }}
        confirmModal={demote}
      />
      <ConfirmModal
        isOpen={isExtendModalOpen && selectedSession !== undefined}
        modalTitle={config.translate('CONFIRM_TITLE')}
        modalCopy={config.translate('EXTEND_CONFIRM', {
          email: selectedSession?.email,
          env: selectedSession?.env,
        })}
        closeModal={() => {
          setIsExtendModalOpen(false);
          setSelectedSession(undefined);
        }}
        confirmModal={extend}
      />
      <SuperUserPromoteModal
        env={selectedSession?.env}
        email={selectedSession?.email}
        config={config}
        closeModal={() => {
          setIsPromoteModalOpen(false);
          setSelectedSession(undefined);
        }}
        confirmModal={promote}
        show={isPromoteModalOpen && selectedSession !== undefined}
      />
    </React.Fragment>
  );
};

export default SuperUserPage;
