import type { PageConfig } from 'pages/page-config.interfaces';

const pageConfig: PageConfig = {
  label: 'PROJECT_INFORMATION',
  route: {
    page: 'project-info',
    tab: 'advanced',
  },
  endpoints: {
    view: [
      'projectControllerGetProjectSettings',
      'projectControllerGetProjectDrawingLogSettings',
    ],
    edit: ['projectControllerUpdateProjectSettings'],
  },
  cards: {
    Advanced: {
      i18n: {
        keyPrefix: 'INFO_TAB.ADVANCED_TAB',
      },
      label: 'TITLE',
      fields: {
        office: {
          label: 'OFFICE',
          tooltip: 'OFFICE_TOOLTIP',
        },
        departments: {
          label: 'DEPARTMENTS',
        },
        program: {
          label: 'PROGRAM',
        },
        flag: {
          label: 'FLAG',
          tooltip: 'FLAG_TOOLTIP',
        },
        region: {
          label: 'REGION',
        },
        bid_type: {
          label: 'BID_TYPE',
        },
        owner_type: {
          label: 'OWNER_TYPE',
        },
        parent_project: {
          label: 'PARENT_PROJECT',
        },
        warranty_start_date: {
          label: 'WARRANTY_START_DATE',
        },
        warranty_end_date: {
          label: 'WARRANTY_END_DATE',
        },
        quickbooks_customer: {
          label: 'QUICKBOOKS_CUSTOMER',
        },
        locale: {
          label: 'LANGUAGE_COUNTRY',
        },
        prevent_overbilling_on_this_project: {
          label: 'PREVENT_OVERBILLING',
        },
        change_event_noncommitted_enabled: {
          label: 'NON_COMMITMENT_COSTS',
          tooltip: 'NON_COMMITMENT_COSTS_TOOLTIP',
        },
        enable_sub_jobs: {
          label: 'ENABLE_SUB_JOBS',
        },
        is_demo: {
          label: 'TEST_PROJECT',
        },
        enable_change_order_associations: {
          label: 'LABOR_PRODUCTIVITY',
        },
      },
    },
    BudgetSettings: {
      i18n: {
        keyPrefix: 'INFO_TAB.ADVANCED_BUDGET',
      },
      permissions: {
        // hide if user doesn't have edit permission
        view: ['cs_edit', 'company_tab_edit_project_settings'],
      },
      label: 'TITLE',
      fields: {
        delete_budget: {
          label: 'DELETE',
        },
      },
      endpoints: {
        edit: ['projectControllerDeleteProjectBudget'],
      },
    },
    DrawingLogSettings: {
      i18n: {
        keyPrefix: 'INFO_TAB.ADVANCED_DLS',
      },
      label: 'TITLE',
      fields: {
        enable_rfi_real_time_as_builts: {
          label: 'ENABLE',
        },
      },
      endpoints: {
        edit: ['projectControllerUpdateDrawingLogSettings'],
      },
    },
    InboundPhotosEmailSettings: {
      i18n: {
        keyPrefix: 'INFO_TAB.ADVANCED_PHOTO',
      },
      label: 'TITLE',
      fields: {
        email_upload_address: {
          label: 'EMAIL',
        },
      },
      endpoints: {
        edit: ['projectControllerUpdatePhotoImportConfigs'],
      },
    },
    SpecificationSettings: {
      i18n: {
        keyPrefix: 'INFO_TAB.ADVANCED_SPECIFICATIONS',
      },
      permissions: {
        view: ['cs_edit', 'company_tab_edit_project_settings'],
      },
      label: 'TITLE',
      fields: {
        index_project_specification: {
          label: 'BUTTON',
        },
      },
      endpoints: {
        edit: ['projectControllerIndexProjectSpecification'],
      },
    },
    SuperUserSettings: {
      i18n: {
        keyPrefix: 'INFO_TAB.ADVANCED_TAB_SU',
      },
      label: 'TITLE',
      fields: {
        personal_weather_station_id: {
          label: 'WEATHER_STATION',
        },
        enable_change_order_associations: {
          label: 'CHANGE_ORDER',
        },
        enable_cobli_for_prime_contract: {
          label: 'COBLI',
        },
        enable_project_as_template: {
          label: 'ENABLE_TEMPLATE',
          // tooltip: 'ENABLE_TEMPLATE_TOOLTIP',
        },
      },
    },
  },
};

export default pageConfig;
