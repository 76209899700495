import React, { useContext } from 'react';
import { AppContext, AppContextProvider } from 'contexts/AppContext';
import { AppHeader } from 'components/AppHeader';
import { AppFooter } from 'components/AppFooter';
import { AppWrapper, PageWrapper } from './styles';
import { AxiosConfigurator } from 'components/AxiosConfigurator';
import { AppBugsnag } from 'components/AppBugsnag';
import { IpaAlertContextProvider } from 'contexts/IpaAlertContext';
import GlobalAnnouncementComponent from 'components/GlobalAnnouncement';
import { useOktaAuth } from '@okta/okta-react';
import { PageLoadReporter } from 'components/PageLoadReporter';
import { QuickHistoryWatcher } from 'components/QuickHistory/QuickHistoryWatcher';
import { OktaAuthRedirector } from 'components/OktaAuthRedirector';
import FedrampNoticeComponent from 'components/FedrampNotice';

interface AppContainerProps {
  children?: React.ReactNode;
}

export const AppContainer = (props: AppContainerProps) => {
  return (
    <AppContextProvider>
      <IpaAlertContextProvider>
        <AppBugsnag>
          <AppWrapper>
            <OktaAuthRedirector />
            <AxiosConfigurator />
            <PageLoadReporter />
            <QuickHistoryWatcher />
            <RequireAuth>
              <FedrampNoticeComponent />
              <AppHeader />
            </RequireAuth>
            <RequireAuth>
              <GlobalAnnouncementComponent />
            </RequireAuth>
            <PageWrapper>{props.children}</PageWrapper>
            <RequireAuth>
              <AppFooter build_number={'48d4fda'} />
            </RequireAuth>
          </AppWrapper>
        </AppBugsnag>
      </IpaAlertContextProvider>
    </AppContextProvider>
  );
};

export const RequireAuth = (props: AppContainerProps) => {
  const { authState } = useOktaAuth();
  const appContext = useContext(AppContext);
  const isAuthenticated =
    authState && authState.isAuthenticated && appContext.authHeaderPresent;

  if (isAuthenticated) {
    return <>{props.children}</>;
  } else {
    return null;
  }
};

export default AppContainer;
