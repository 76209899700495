import type { SectionConfig } from 'pages/page-config.interfaces';

export default {
  id: 'Email Management',
  route: {
    path: '/v2/email-management',
  },
  i18n: {
    namespace: 'email-management',
  },
  pages: {
    BrazeSearch: {
      route: {
        tab: 'braze-search',
      },
      label: 'TAB_BRAZE_SEARCH',
      endpoints: {
        view: [],
      },
      cards: {},
    },
    LogsSearch: {
      route: {
        tab: 'logs',
      },
      label: 'TAB_LOGS',
      endpoints: {
        view: [],
      },
      cards: {},
    },
  },
} as SectionConfig;
