import type { PageConfig } from 'pages/page-config.interfaces';

const page: PageConfig = {
  label: 'TITLE',
  route: {
    page: 'change-history',
  },
  i18n: {
    namespace: 'company',
    keyPrefix: 'CHANGE_HISTORY',
  },
  permissions: {
    view: ['company_tab_view', 'cs_view'],
  },
  endpoints: {
    view: ['companyChangeHistoryControllerGetCompanyChangeHistory'],
  },
  cards: {
    ChangeHistoryList: {
      label: 'TITLE',
      fields: {},
    },
  },
};

export default page;
