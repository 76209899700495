import type { SectionConfig } from 'pages/page-config.interfaces';

export default {
  id: 'WorkflowStateTool',
  label: 'WORKFLOW_STATE_TOOL',
  i18n: {
    namespace: 'workflow-state-tool',
  },
  route: {
    path: '/v2/workflow-state-tool',
  },
  permissions: {
    view: ['cs_view'],
    edit: ['cs_edit'],
  },
  pages: {
    WorkflowStateTool: {
      id: 'WorkflowStateTool',
      label: 'TITLE',
      i18n: {
        namespace: 'workflow-state-tool',
      },
      cards: {
        UrlLookup: {
          i18n: {
            keyPrefix: 'URL_LOOKUP_FORM',
          },
          label: 'TITLE',
          fields: {
            supportUrl: {
              label: 'SUPPORT_URL',
            },
          },
        },
        WorkflowOverride: {
          i18n: {
            keyPrefix: 'WORKFLOW_OVERRIDE_CARD',
          },
          permissions: {
            edit: ['cs_edit'],
          },
          label: 'TITLE',
          fields: {
            currentWorkflowState: {
              label: 'CURRENT_WORKFLOW_STATE',
            },
            isErpLinked: {
              label: 'IS_ERP_LINKED',
            },
          },
        },
        ItemInformation: {
          i18n: {
            keyPrefix: 'ITEM_INFORMATION_CARD',
          },
          label: 'TITLE',
          fields: {
            companyName: {
              label: 'COMPANY_NAME',
            },
            projectName: {
              label: 'PROJECT_NAME',
            },
            itemType: {
              label: 'ITEM_TYPE',
            },
            itemNumber: {
              label: 'ITEM_NUMBER',
            },
            itemTitle: {
              label: 'ITEM_TITLE',
            },
            workflowName: {
              label: 'WORKFLOW_NAME',
            },
            contractCompany: {
              label: 'CONTRACT_COMPANY',
            },
          },
        },
        WorkflowStates: {
          label: 'TITLE',
          fields: {},
        },
        WorkflowHistory: {
          label: 'TITLE',
          fields: {},
        },
      },
    },
  },
} as SectionConfig;
