import type { PageConfig } from 'pages/page-config.interfaces';

const pageConfig: PageConfig = {
  label: 'TITLE',
  route: {
    page: 'cost-codes',
  },
  i18n: {
    keyPrefix: 'PROJECT_COST_CODES',
  },
  endpoints: {
    view: [
      'projectControllerGetProjectCostCodes',
      'projectControllerGetProjectCostCodeDetails',
    ],
    edit: ['projectControllerImportProjectCostCodes'],
  },
  // Permissions for import?
  cards: {
    CostCodes: {
      label: 'TITLE',
      fields: {},
    },
    ImportCostCodes: {
      label: 'IMPORT_MODAL_TITLE',
      fields: {},
    },
  },
};

export default pageConfig;
