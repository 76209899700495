// ***** PARAMETERS *****
// action: string
// closeModal: function
// confirmModal: function
// show: boolean (displays the modal if set to true)
// entry: object
//

import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { Modal, Button, Form, Box } from '@procore/core-react';
import { IpaModalWrapperTextEditor, StyledFormSelect } from 'styles';
import { actions } from './GlobalAnnouncementHelpers';
import type {
  IGlobalAnnouncement,
  IGlobalAnnouncementModalProps,
} from './global.announcement.interface';
import { useTranslation } from 'react-i18next';

export const GlobalAnnouncementModal = (
  props: IGlobalAnnouncementModalProps,
) => {
  const { t } = useTranslation(['common', 'global-announcements']);
  const [showModal, setShowModal] = useState(false);
  const [action, setAction] = useState<string | null>(null);
  const [announcementEntry, setAnnouncementEntry] =
    useState<IGlobalAnnouncement>({} as IGlobalAnnouncement);
  const [allowNewActiveAnnouncement, setAllowNewActiveAnnouncement] =
    useState(false);
  const closeModal = () => {
    props.closeModal();
  };
  const confirmModal = (values: IGlobalAnnouncement) => {
    props.confirmModal(values);
  };

  const messageTypes = [
    { id: 'Info', label: `${t('INFO', { ns: 'common' })}` },
    { id: 'Warning', label: `${t('WARNING', { ns: 'common' })}` },
  ];

  useEffect(() => {
    setShowModal(props.show);
    if (Object.keys(props.entry).length) {
      setAnnouncementEntry(props.entry);
    }
    if (props.action) {
      setAction(props.action);
    }
    setAllowNewActiveAnnouncement(props.allowNewActiveAnnouncement);
  }, [props]);

  return (
    <Modal
      open={showModal}
      onClickOverlay={closeModal}
      id="edit-announcement-entry"
      onClose={closeModal}
    >
      <IpaModalWrapperTextEditor>
        <Modal.Header onClose={closeModal}>
          {props.action === actions.UPDATE
            ? t('EDIT_ANNOUNCEMENT', { ns: 'global-announcements' })
            : t('NEW_ANNOUNCEMENT', { ns: 'global-announcements' })}
        </Modal.Header>

        <Form
          view={props.action === actions.CREATE ? 'create' : 'update'}
          enableReinitialize={true}
          initialValues={announcementEntry}
          validationSchema={yup.object().shape({
            message: yup
              .string()
              .nullable()
              .required(t('REQUIRED', { ns: 'common' }))
              .max(500),
            messageType: yup.object().required(t('REQUIRED', { ns: 'common' })),
          })}
          onSubmit={(values) => confirmModal(values)}
        >
          {(props: any) => {
            const { dirty } = props;
            return (
              <Form.Form>
                <Modal.Body>
                  <Box>
                    <Form.RichText
                      name="message"
                      label={t('ANNOUNCEMENT_MESSAGE', {
                        ns: 'global-announcements',
                      })}
                      data-qa={'message-textarea'}
                    />
                  </Box>
                  <Box>
                    <StyledFormSelect
                      name="messageType"
                      label={t('MESSAGE_TYPE', { ns: 'global-announcements' })}
                      data-qa="message-type-select"
                      options={messageTypes}
                      onSearch={false}
                    />
                  </Box>
                  <Box>
                    <Form.Checkbox
                      name="isActive"
                      label={t('ACTIVE', { ns: 'global-announcements' })}
                      data-qa="active-checkbox"
                      disabled={
                        !allowNewActiveAnnouncement && action === actions.CREATE
                      }
                    />
                  </Box>
                  <p>{t('NOTE', { ns: 'global-announcements' })}</p>
                </Modal.Body>
                <Modal.Footer>
                  <Modal.FooterButtons>
                    <Button
                      type="button"
                      variant="secondary"
                      data-qa="cancel-btn"
                      onClick={closeModal}
                    >
                      {t('CANCEL', { ns: 'common' })}
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      data-qa="submit-btn"
                      onClick={props.confirmModal}
                      disabled={!dirty}
                    >
                      {t('CONFIRM', { ns: 'common' })}
                    </Button>
                  </Modal.FooterButtons>
                </Modal.Footer>
              </Form.Form>
            );
          }}
        </Form>
      </IpaModalWrapperTextEditor>
    </Modal>
  );
};

export default GlobalAnnouncementModal;
