import React, { useContext, useState, useCallback, useEffect } from 'react';
import { Page, Card, Box, Title, H1, H2, colors } from '@procore/core-react';
import { useTranslation } from 'react-i18next';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import JitAuditLog from 'components/JitAuditLog';
import { JitEnvironment } from './JitEnvironment';
import { useSearchParams } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';
import { can } from 'utils/permissions.helper';
import { AppContext } from 'contexts/AppContext';
import JitEscalateModal from 'components/JitEscalateModal';
import { getBaseUrl, getCompanyUrl } from 'utils/utils';
import { getApi } from 'utils/api';
import { CompaniesApi, JITApi } from '@procore/ipa-nt-api-client-ts';
import { AnalyticEvents } from '../analytic-events.service';
import type { JitEscalateFormValues } from 'components/JitEscalateModal';
import type {
  JitCompanySettingsDto,
  CreateJitSessionDtoAccessLevelEnum,
} from '@procore/ipa-nt-api-client-ts';
import { Link } from 'components/Link';

export const JitHomePage = () => {
  const { t } = useTranslation(['jit']);
  const alert = useContext(IpaAlertContext);
  const { userConfig } = useContext(AppContext);
  const [searchParams] = useSearchParams();
  const [lastRefresh, setLastRefresh] = useState<Date>();
  const [escalateModalOpen, setEscalateModalOpen] = useState<boolean>(false);
  const [latestEscalation, setLatestEscalation] = useState<Date>();
  const [selectedCompany, setSelectedCompany] =
    useState<JitCompanySettingsDto>();
  const [moduleEnv, setModuleEnv] = useState<string>();

  // Parse all possible URL parameters
  const env = sanitizeHtml(searchParams.get('env'));
  const companyId = sanitizeHtml(searchParams.get('companyId'));
  const accessLevel = sanitizeHtml(searchParams.get('accessLevel'));
  const reasonCategory = sanitizeHtml(searchParams.get('reason'));
  const length = sanitizeHtml(searchParams.get('duration'));
  const redirectUrl = sanitizeHtml(searchParams.get('redirectUrl') || '');
  const email = sanitizeHtml(searchParams.get('email'));
  const salesforceCaseNumber = sanitizeHtml(
    searchParams.get('salesforceCaseNumber'),
  );
  const detail = sanitizeHtml(searchParams.get('detail'));

  const hasSuAccess = can({ user: userConfig }, ['su']);

  const allowedZones =
    userConfig.user?.allowedZones?.filter(
      (zone) => zone !== 'INTEGRATOR_SANDBOX',
    ) ?? [];

  const closeEscalateModal = useCallback(() => {
    setLatestEscalation(new Date());
    setEscalateModalOpen(false);
    setModuleEnv(undefined);
  }, []);

  const openEscalateModal = (env: string) => {
    setEscalateModalOpen(true);
    setModuleEnv(env);
  };

  const escalate = useCallback(async (values: JitEscalateFormValues) => {
    try {
      const result = await getApi(JITApi).jitControllerCreateSessionV10({
        env: env || values.env,
        companyId: Number(values.company_id),
        accessLevel: values.access_level
          .id as CreateJitSessionDtoAccessLevelEnum,
        category: values.category.id,
        reason: values.detail,
        impersonateEmail: values.email,
        pin: values.pin,
        salesForceCaseNumber: values.salesforce_case_number,
        escalationLength: Number(values.escalation_length.id),
        projectId: Number(values.project_id),
        pdmSupport: values.pdm_support,
        redirectUrl: redirectUrl,
        escalationConfirmationCheck: values.sa_escalation_confirmation_check,
      });

      if (result.data) {
        setLatestEscalation(new Date());
        alert.success(
          t('ESCALATE_SUCCESS', {
            accessLevel: result.data.accessLevel,
            companyName: result.data.companyName,
          }),
        );
        if (values.open_new_window) {
          if (redirectUrl) {
            window.open(
              `${getBaseUrl(
                env,
                'procore',
                +values.company_id,
              )}/${redirectUrl}`,
              '_blank',
            );
          } else if (values.project_id !== '') {
            window.open(
              `${getBaseUrl(env, 'procore', +values.company_id)}/${
                values.project_id
              }/project/home`,
              '_blank',
            );
          } else {
            // Use the utility function to get the company URL
            const url = await getCompanyUrl(
              +values.company_id,
              env || values.env,
              alert.error,
            );
            window.open(url, '_blank');
          }
        }
      }
    } catch (error: any) {
      const msg = t('ESCALATE_FAILED', {
        companyId: values.company_id,
        reason: error.response?.data?.message || error.message,
      });
      alert.error(msg);
      return null;
    } finally {
      setEscalateModalOpen(false);
      await AnalyticEvents.postProcoreEvent({
        key: 'ipa.ipa_jit.escalate.escalated',
        eventProps: {
          user_company_id: values.company_id,
          access_level: values.access_level.id,
          user_email: values.email,
        },
        env: env || values.env,
      });
    }
  }, []);

  useEffect(() => {
    // Check if any URL parameter exists to trigger the modal
    const hasAnyUrlParam =
      companyId ||
      env ||
      accessLevel ||
      reasonCategory ||
      length ||
      redirectUrl ||
      email ||
      salesforceCaseNumber ||
      detail;

    if (hasAnyUrlParam && companyId && env) {
      // We require at least companyId and env to fetch company settings
      const api = getApi(JITApi);
      api
        .jitControllerGetCompanySettingsV10(+companyId, env)
        .then((response) => {
          setSelectedCompany(response.data);
          setEscalateModalOpen(true);
        })
        .catch((error) => {
          setSelectedCompany(undefined);
          alert.error(t('COMPANY_NOT_FOUND', { ns: 'jit' }));
        });
    } else {
      setSelectedCompany(undefined);
    }
  }, []);

  return (
    <Page>
      <Page.Main style={{ flex: 'auto' }}>
        <Page.Header>
          <Title>
            <Title.Text data-qa={'jit-home-title'}>
              <H1>{t('JIT_HOME_TITLE')}</H1>{' '}
            </Title.Text>
            <Title.Actions>
              {hasSuAccess && <Link href="/v2/super-user">{t('LINK_SU')}</Link>}
            </Title.Actions>
          </Title>
        </Page.Header>
        <Page.Body>
          <Card key={`jit-${lastRefresh}`}>
            <Box padding="lg">
              <H2>{t('JIT_HOME_SUBHEADING')}</H2>
              <span style={{ color: colors.orange50 }}>
                {t('JIT_MULTIPLE_ACCOUNTS_NOTE')}
              </span>
              {allowedZones.map((e) => (
                <JitEnvironment
                  key={e}
                  env={e}
                  openModal={openEscalateModal}
                  latestEscalation={latestEscalation}
                />
              ))}
            </Box>
          </Card>
          <Box paddingTop="lg" paddingBottom="lg">
            <Card>
              <Box padding="lg">
                <Box marginBottom="lg">
                  <H2>{t('JIT_AUDIT_LOG_TITLE', { ns: 'jit-audit-log' })}</H2>
                </Box>
                <JitAuditLog
                  dateRange={undefined}
                  jitSessions={undefined}
                  pageSize={undefined}
                  total={undefined}
                  refresh={() => {
                    setLastRefresh(new Date());
                  }}
                />
              </Box>
            </Card>
          </Box>
        </Page.Body>
      </Page.Main>
      <JitEscalateModal
        env={env || moduleEnv}
        isOpen={escalateModalOpen}
        closeModal={closeEscalateModal}
        re_escalate={false}
        customer_success={false}
        confirmModal={escalate}
        canSelectEscalationLength={true}
        selected_company={selectedCompany}
        selected_access_level={
          accessLevel
            ? {
                id: accessLevel,
                label: accessLevel,
                disabled: false,
              }
            : undefined
        }
        selected_reason={reasonCategory}
        escalation_length={length}
        selected_email={email}
        salesforce_case_number={salesforceCaseNumber}
        detail={detail}
        // Show the strategic account checkbox if redirectUrl is provided
        isStrategicAccount={redirectUrl !== undefined && redirectUrl !== ''}
      />
    </Page>
  );
};

export default JitHomePage;
