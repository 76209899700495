import type {
  WorkflowDataDto,
  WorkflowHistoryDto,
} from '@procore/ipa-nt-api-client-ts';
import type { IEditableCard } from 'components/EditableCard';

// Finds the characters after '/project/' and before the item_id
export const regexGetItemType = /(?<=project\/).+?(?=\/\d+)/g;
export const regexGetItemTypeCompany = /(?<=company\/).+?(?=\/\d+)/g;

// Finds the number between '.com/' and '/project' (or /company)
export const regexGetProjectId = /(?<=\.com\/)\d+(?=\/project)/g;
export const regexGetCompanyId = /(?<=\.com\/)\d+(?=\/company)/g;

// Finds the url domain
export const regexGetDomain =
  /^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/?\n]+)\//gi;

export const supportedWorkflowedItemTypes = [
  'commitments/purchase_order_contracts',
  'commitments/work_order_contracts',
  'prime_contracts',
  'direct_costs',
  'prime_contracts',
  'generic_tools/show',
  'show',
];

export const supportedWorkflowedSubitemTypes = [
  'change_orders/commitment_contract_change_orders',
  'change_orders/potential_change_orders',
  'change_orders/change_order_packages',
  'payment_applications',
  'requisitions',
];

export const supportedProcoreDomains = [
  {
    url: 'https://pz01-procore-ipa-staging.security.procoretech-qa.com/',
    env: 'PROD',
  },
  { url: 'https://app.procore.com/', env: 'PROD' },
  { url: 'https://us02.procore.com/', env: 'PROD' },
  { url: 'https://api.procore.com/', env: 'PROD' },
  { url: 'https://localhost:3000/', env: 'PROD' },
  {
    url: 'https://sandbox-monthly-us02.procore.com/',
    env: 'PROD_MONTHLY',
  },
  { url: 'https://api-sandbox-monthly.procore.com/', env: 'PROD_MONTHLY' },
  { url: 'https://sandbox-monthly.procore.com/', env: 'PROD_MONTHLY' },
  { url: 'https://api-monthly.procore.com/', env: 'PROD_MONTHLY' },
  {
    url: 'https://pz01-procore-6435d391.security.procoretech-qa.com/',
    env: 'PROD_MONTHLY',
  },
];

export const createRegexGetItemId = (itemType: string) => {
  return new RegExp(`(?<=/${itemType}/)\\d+(?=/)*`, 'g');
};

export const createRegexGetSubItemType = (itemType: string, itemId: string) => {
  return new RegExp(`(?<=/${itemType}/${itemId}/).+?(?=/\\d+)`, 'g');
};

export const createRegexGetSubItemId = (subItemType: string) => {
  return new RegExp(`(?<=/${subItemType}/)\\d+(?=/)*`, 'g');
};

export const convertSubItemTypeInUrl = (itemType: string) => {
  // This function is a mapping of URL item type found by the regex to the accepted values for the Endpoint
  // There should be one case statement for each value in supportedWorkflowedSubitemTypes
  let convertedSubItemType = '';
  switch (itemType) {
    case 'change_orders/commitment_contract_change_orders':
      convertedSubItemType = 'change_order';
      break;
    case 'change_orders/potential_change_orders':
      convertedSubItemType = 'potential_change_order';
      break;
    case 'change_orders/change_order_packages':
      convertedSubItemType = 'change_order';
      break;
    case 'payment_applications':
      convertedSubItemType = 'owner_invoice';
      break;
    case 'requisitions':
      convertedSubItemType = 'subcontractor_invoice';
      break;
    default:
      convertedSubItemType = 'Not Found';
  }
  return convertedSubItemType;
};

export const convertItemTypeInUrl = (itemType: string) => {
  // This function is a mapping of URL sub item type found by the regex to the accepted values for the Endpoint
  // There should be one case statement for each value in supportedWorkflowedItemTypes
  let convertedItemType = '';
  switch (itemType) {
    case 'commitments/purchase_order_contracts':
      convertedItemType = 'purchase_order_contract';
      break;
    case 'commitments/work_order_contracts':
      convertedItemType = 'work_order_contract';
      break;
    case 'prime_contracts':
      convertedItemType = 'prime_contract';
      break;
    case 'direct_costs':
      convertedItemType = 'direct_cost_item';
      break;
    case 'generic_tools/show':
      convertedItemType = 'project_custom_tool_item';
      break;
    case 'show':
      convertedItemType = 'company_custom_tool_item';
      break;
    default:
      convertedItemType = 'Not Found';
  }
  return convertedItemType;
};

export interface IEditableCardProps {
  id: string;
  workflowData?: WorkflowDataDto;
  workflowHistory?: WorkflowHistoryDto;
  save: IEditableCard['save'];
}
