import React, { useContext } from 'react';
import { EditableCard } from 'components/EditableCard';
import type { IEditableCard } from 'components/EditableCard';
import { Card, Box, Form, Spinner, Flex } from '@procore/core-react';
import { SubmittalSettingsContext } from '.';
import type { SubmittalSettingsCardProps } from './interfaces';

export const SubmittalProjectConfigurationCard = (
  props: SubmittalSettingsCardProps,
) => {
  const config = props.cardConfig;

  const {
    submittalSettingsDataSources: { submittalSettings },
  } = useContext(SubmittalSettingsContext);

  const data = submittalSettings?.data;

  const card: IEditableCard = {
    id: props.id,
    i18n: config.translate,
    label: config.label,
    canEdit: false,
    save: undefined,
    fields: [
      {
        source: submittalSettings.id,
        value: data?.reject_workflow_enabled,
        name: 'reject_workflow_enabled',
        element: Form.Checkbox,
        ...config.fields.reject_workflow_enabled,
      },
    ],
  };

  return (
    <Box marginBottom="lg">
      <Card>
        {props.isCardLoading ? (
          <Flex justifyContent="center" padding="lg">
            <Spinner loading={props.isCardLoading} />
          </Flex>
        ) : (
          <Box padding="lg">
            <EditableCard card={card} />
          </Box>
        )}
      </Card>
    </Box>
  );
};

export default SubmittalProjectConfigurationCard;
