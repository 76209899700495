import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useEffect } from 'react';

export const Logout = () => {
  const { authState } = useOktaAuth();
  const isAuthenticated = authState && authState.isAuthenticated;

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    window.location.href = '/signed-out';
  }, []);

  if (isAuthenticated) {
    return <div>Signing out..</div>;
  } else {
    return null;
  }
};
