import React, { useState, useContext } from 'react';
import {
  Page,
  Button,
  FlexList,
  Dropdown,
  spacing,
  Tabs,
  Form,
  Pill,
  Title,
  H1,
} from '@procore/core-react';
import { useTranslation } from 'react-i18next';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import * as yup from 'yup';
import { BetaBanner } from 'components/BetaBanner';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppContext } from 'contexts/AppContext';
import { Plus, Link, Trash } from '@procore/core-icons';
import PendingPaymentsAdminsTab from './PaymentsTabs/pendingPaymentsAdmins';
import { can } from 'utils/permissions.helper';
import { PageConfig } from 'pages/page-config.service';
import BusinessEntitiesTab from './PaymentsTabs/businessEntities';
import PayoutFailuresTab from './PaymentsTabs/payoutFailures';
import ConfirmModal from 'components/ConfirmModal';
import {
  CompanyDirectoryApi,
  CompanyInfoApi,
  PaymentsServiceBusinessEntitiesApi,
  PayoutsApi,
} from '@procore/ipa-nt-api-client-ts';
import type { CreateBusinessEntityRequestDto } from '@procore/ipa-nt-api-client-ts';
import { getApi } from 'utils/api';
import type { ISelectOption } from 'interfaces/common.interface';
import fileDownload from 'js-file-download';
import { getRouteForEnv } from './payments.helper';

export interface ICreateVendorConnection {
  company_id: string;
  vendor_id: string;
  connected_company_id: string;
}

export interface IRemoveVendorConnection {
  company_id: string;
  vendor_id: string;
}

export interface ICreateBusinessEntity {
  company_id: number;
  capabilities: ISelectOption;
}

export const PaymentsPage = () => {
  const { userConfig, env } = useContext(AppContext);
  const alert = useContext(IpaAlertContext);
  const { t } = useTranslation(['payments']);
  const { t: tCommon } = useTranslation(['common']);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const sectionConfig = PageConfig.getSection(t, 'Payments');
  const [showAddVendorConnectionModal, setShowAddVendorConnectionModal] =
    useState<boolean>(false);
  const [showRemoveVendorConnectionModal, setShowRemoveVendorConnectionModal] =
    useState<boolean>(false);
  const [showCreateBusinessEntityModal, setShowCreateBusinessEntityModal] =
    useState<boolean>(false);
  const [showPayoutHistoryModal, setShowPayoutHistoryModal] =
    useState<boolean>(false);

  const activeTab = searchParams.get('tab') ?? 'business-entities';
  const entityTypeOptions: ISelectOption[] = [
    { id: 'send_payments', label: t('SEND_TYPE') },
    { id: 'receive_payments', label: t('RECEIVE_TYPE') },
  ];

  const confirmAddVendorConnection = async (
    values: ICreateVendorConnection,
  ) => {
    try {
      const addVendorConnectionRequest = {
        company_id: +values.company_id,
        vendor_id: +values.vendor_id,
        create_settings: { connected_company_id: +values.connected_company_id },
      };

      await getApi(
        CompanyDirectoryApi,
      ).companyDirectoryControllerAddVendorConnectionV10(
        env,
        +values.company_id,
        +values.vendor_id,
        addVendorConnectionRequest,
      );

      alert.success(t('ADD_VENDOR_CONNECTION_SUCCESS'));
    } catch (e: any) {
      alert.error(e);
    } finally {
      setShowAddVendorConnectionModal(false);
    }
  };

  const confirmRemoveVendorConnection = async (
    values: IRemoveVendorConnection,
  ) => {
    try {
      await getApi(
        CompanyDirectoryApi,
      ).companyDirectoryControllerRemoveVendorConnectionV10(
        env,
        +values.company_id,
        +values.vendor_id,
      );

      alert.success(t('REMOVE_VENDOR_CONNECTION_SUCCESS'));
    } catch (e: any) {
      alert.error(e);
    } finally {
      setShowRemoveVendorConnectionModal(false);
    }
  };

  const confirmCreateBusinessEntity = async (values: ICreateBusinessEntity) => {
    try {
      const company = await getApi(
        CompanyInfoApi,
      ).companyCompanyInfoControllerGetCompanyAccountStatusV10(
        env,
        values.company_id,
      );

      const createBusinessEntityRequest: CreateBusinessEntityRequestDto = {
        externalId: values.company_id.toString(),
        externalSource: 'PROCORE',
        externalName: company.data.name,
        capabilities: values.capabilities.id.toString(),
        active: true,
      };

      const result = await getApi(
        PaymentsServiceBusinessEntitiesApi,
      ).businessEntityControllerCreateBusinessEntityV10(
        env,
        createBusinessEntityRequest,
      );

      if (result) {
        alert.success(t('CREATE_BUSINESS_ENTITY_SUCCESS'));
      }
    } catch (e: any) {
      if (e?.response?.status === 404) {
        alert.error(t('INVALID_COMPANY_ID', { companyId: values.company_id }));
      } else {
        alert.error(e);
      }
    } finally {
      setShowCreateBusinessEntityModal(false);
    }
  };

  const downloadDailyReport = async (startDate?: Date, endDate?: Date) => {
    try {
      const formattedStartDate =
        startDate?.toISOString().split('T')[0] ?? undefined;
      const formattedEndDate =
        endDate?.toISOString().split('T')[0] ?? undefined;

      const api = getApi(PayoutsApi);
      const res = await api.payoutControllerGetPayoutsReportV10(
        env,
        formattedEndDate,
        formattedStartDate,
      );
      let fileName = '';
      if (startDate) {
        fileName = `payouts-${formattedStartDate}-to-${formattedEndDate}.csv`;
      } else {
        const currentDate = new Date()
          .toLocaleDateString()
          .split('/')
          .join('-');
        fileName = `daily-payouts-${currentDate}.csv`;
      }
      fileDownload(res.data as Blob, fileName);
      alert.success(t('DAILY_PAYOUTS_SUCCESS'));
    } catch (e: any) {
      alert.error(e);
    }
  };

  const tabs = [
    {
      id: 'business-entities',
      label: t('BUSINESS_ENTITIES'),
      component: BusinessEntitiesTab,
    },
    {
      id: 'payout-failures',
      label: t('PAYOUT_FAILURES'),
      component: PayoutFailuresTab,
    },
    {
      id: 'pending-admins',
      label: t('PENDING_PAYMENTS_ADMINS'),
      component: PendingPaymentsAdminsTab,
    },
  ];

  const changeTab = (newTab: string) => {
    navigate(getRouteForEnv(env, `?tab=${newTab}`));
  };

  return (
    <Page>
      <Page.Main style={{ flex: 'auto' }}>
        <Page.Header>
          <Title>
            <Title.Text>
              <FlexList size="xs">
                <H1>{t('PAYMENTS_TITLE')}</H1>
                {env === 'INTEGRATOR_SANDBOX' && (
                  <Pill color="red">{t('INTEGRATOR_SANDBOX')}</Pill>
                )}
              </FlexList>
            </Title.Text>
            <Title.Actions style={{ paddingBottom: spacing.xs }}>
              <FlexList size="xs">
                {can(
                  { user: userConfig },
                  sectionConfig.permissions['edit_vendor_connections'],
                ) && (
                  <Button
                    variant="secondary"
                    data-qa="add-vendor-btn"
                    icon={<Link />}
                    onClick={() => setShowAddVendorConnectionModal(true)}
                  >
                    {t('ADD_VENDOR_CONNECTION')}
                  </Button>
                )}
                {can(
                  { user: userConfig },
                  sectionConfig.permissions['edit_vendor_connections'],
                ) && (
                  <Button
                    variant="secondary"
                    data-qa="remove-vendor-btn"
                    icon={<Trash />}
                    onClick={() => setShowRemoveVendorConnectionModal(true)}
                  >
                    {t('REMOVE_VENDOR_CONNECTION')}
                  </Button>
                )}
                <Dropdown
                  label={t('REPORTS')}
                  data-qa="reports-dropdown"
                  onSelect={({ item }) => {
                    if (item === 'daily-payout') {
                      downloadDailyReport();
                    } else if (item === 'payout-history') {
                      setShowPayoutHistoryModal(true);
                    }
                  }}
                >
                  <Dropdown.Item
                    item="daily-payout"
                    data-qa="daily-payout-select"
                  >
                    {t('DAILY_PAYOUTS')}
                  </Dropdown.Item>
                  <Dropdown.Item
                    item="payout-history"
                    data-qa="payout-history-select"
                  >
                    {t('PAYOUT_HISTORY')}
                  </Dropdown.Item>
                </Dropdown>
                {can(
                  { user: userConfig },
                  sectionConfig.permissions['create_business_entity'],
                ) && (
                  <Button
                    icon={<Plus />}
                    variant="primary"
                    data-qa="add-business-entity-btn"
                    onClick={() => setShowCreateBusinessEntityModal(true)}
                  >
                    {t('CREATE')}
                  </Button>
                )}
              </FlexList>
            </Title.Actions>
          </Title>
          <Page.Tabs data-qa="page-tabs">
            <Tabs>
              {tabs.map((tab, i) => (
                <Tabs.Tab
                  key={i}
                  data-qa={`tab-${tab.id}`}
                  active={activeTab === tab.id}
                  onClick={() => changeTab(tab.id)}
                >
                  <Tabs.Link>{tab.label}</Tabs.Link>
                </Tabs.Tab>
              ))}
            </Tabs>
          </Page.Tabs>
        </Page.Header>
        <Page.Body>
          <Page.Row>
            <Page.Column>
              {tabs.map(
                (tab, idx) =>
                  activeTab === tab.id &&
                  React.createElement(tab.component, {
                    key: idx,
                    env: env,
                  }),
              )}
            </Page.Column>
          </Page.Row>
        </Page.Body>
      </Page.Main>

      <ConfirmModal
        id="add-vendor-connection-confirm-modal"
        isOpen={showAddVendorConnectionModal}
        modalTitle={t('VENDOR_CONNECTION_MODAL_TITLE')}
        modalCopy={t('VENDOR_CONNECTION_MODAL_COPY')}
        closeModal={() => setShowAddVendorConnectionModal(false)}
        confirmModal={(values: ICreateVendorConnection) =>
          confirmAddVendorConnection(values)
        }
        validationSchema={{
          company_id: yup.number().required(tCommon('REQUIRED')),
          vendor_id: yup.number().required(tCommon('REQUIRED')),
          connected_company_id: yup.number().required(tCommon('REQUIRED')),
        }}
        includeReason={false}
      >
        <Form.Text
          name="company_id"
          label={t('COMPANY_ID', { ns: 'common' })}
          tooltip={t('COMPANY_ID_TOOLTIP')}
          data-qa="company-id-input"
          type="number"
          required
        />
        <Form.Text
          name="vendor_id"
          label={t('VENDOR_ID', { ns: 'common' })}
          data-qa="vendor-id-input"
          type="number"
          required
        />
        <Form.Text
          name="connected_company_id"
          label={t('CONNECTED_COMPANY_ID')}
          tooltip={t('CONNECTED_COMPANY_ID_TOOLTIP')}
          data-qa="connected-company-id-input"
          type="number"
          required
        />
      </ConfirmModal>

      <ConfirmModal
        id="remove-vendor-connection-confirm-modal"
        isOpen={showRemoveVendorConnectionModal}
        modalTitle={t('REMOVE_VENDOR_CONNECTION_MODAL_TITLE')}
        modalCopy={t('REMOVE_VENDOR_CONNECTION_MODAL_COPY')}
        closeModal={() => setShowRemoveVendorConnectionModal(false)}
        confirmModal={(values: IRemoveVendorConnection) =>
          confirmRemoveVendorConnection(values)
        }
        validationSchema={{
          company_id: yup.number().required(tCommon('REQUIRED')),
          vendor_id: yup.number().required(tCommon('REQUIRED')),
        }}
        includeReason={false}
      >
        <Form.Text
          name="company_id"
          label={t('COMPANY_ID', { ns: 'common' })}
          data-qa="company-id-input"
          type="number"
          required
        />
        <Form.Text
          name="vendor_id"
          label={t('VENDOR_ID', { ns: 'common' })}
          data-qa="vendor-id-input"
          type="number"
          required
        />
      </ConfirmModal>

      <ConfirmModal
        isOpen={showCreateBusinessEntityModal}
        modalTitle={t('CREATE_BUSINESS_ENTITY_MODAL_TITLE')}
        modalCopy={t('CREATE_BUSINESS_ENTITY_MODAL_COPY')}
        closeModal={() => setShowCreateBusinessEntityModal(false)}
        confirmModal={(values: ICreateBusinessEntity) =>
          confirmCreateBusinessEntity(values)
        }
        includeReason={false}
        validationSchema={{
          company_id: yup.number().required(tCommon('REQUIRED')),
          capabilities: yup.object().required(tCommon('REQUIRED')),
        }}
      >
        <Form.Text
          name="company_id"
          label={t('COMPANY_ID', { ns: 'common' })}
          data-qa="company-id-input"
          required
        />
        <Form.RadioButtons
          name="capabilities"
          label={t('BUSINESS_ENTITIES_TAB.ENTITY_TYPE')}
          data-qa="entity-type-input"
          options={entityTypeOptions}
          required
        />
      </ConfirmModal>
      <ConfirmModal
        isOpen={showPayoutHistoryModal}
        modalTitle={t('DOWNLOAD_PAYOUT_HISTORY')}
        modalCopy={t('PAYOUT_HISTORY_MODAL_COPY')}
        closeModal={() => setShowPayoutHistoryModal(false)}
        confirmModal={(values) => {
          downloadDailyReport(values.startDate, values.endDate);
        }}
        validationSchema={{
          endDate: yup.date().required(tCommon('REQUIRED')),
          startDate: yup.date().required(tCommon('REQUIRED')),
        }}
        includeReason={false}
      >
        <Form.DateSelect
          name="startDate"
          label={tCommon('START_DATE')}
          data-qa="payout-start-date-input"
          required
        />
        <Form.DateSelect
          name="endDate"
          label={tCommon('END_DATE')}
          data-qa="payout-end-date-input"
          disabledDate={(date) => date.getTime() > new Date().getTime()}
          required
        />
      </ConfirmModal>
    </Page>
  );
};

export default PaymentsPage;
