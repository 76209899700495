import type { PageConfig } from 'pages/page-config.interfaces';

const commitmentSettingsPage: PageConfig = {
  route: {
    page: 'commitment-settings',
  },
  i18n: {
    namespace: 'project',
    keyPrefix: 'COMMITMENT_SETTINGS_TAB',
  },
  label: 'TITLE',
  endpoints: {
    view: ['projectControllerGetProjectCommitmentSettings'],
  },
  cards: {
    CommitmentSettings: {
      label: 'TITLE',
      fields: {
        change_order_statuses_cascade: {
          label: 'CHANGE_ORDER_STATUSES_CASCADE',
        },
        enable_field_orders: {
          label: 'ENABLE_FIELD_ORDERS',
        },
        enable_rfq: {
          label: 'ENABLE_RFQ',
        },
        rfq_overdue_emails_enabled: {
          label: 'RFQ_OVERDUE_EMAILS_ENABLED',
        },
        rfq_due_days_default: {
          label: 'RFQ_DUE_DAYS_DEFAULT',
        },
        number_of_commitment_change_order_tiers: {
          label: 'NUMBER_OF_COMMITMENT_CHANGE_ORDER_TIERS',
        },
      },
      endpoints: {
        edit: ['projectControllerUpdateProjectCommitmentSettings'],
      },
    },
  },
};

export default commitmentSettingsPage;
