import type { PageConfig } from 'pages/page-config.interfaces';

export default {
  label: 'DASHBOARD_TITLE',
  route: {
    path: '/v2/dashboard',
  },
  endpoints: {
    view: ['adminControllerGetActiveSuperusers', 'adminControllerGetUserstats'],
  },
  cards: {
    // Active Super Users Table
  },
} as PageConfig;
