import type { PageConfig } from 'pages/page-config.interfaces';

const page: PageConfig = {
  label: 'TITLE',
  route: {
    page: 'pdf-template-config',
  },
  i18n: {
    namespace: 'company',
    keyPrefix: 'PDF_TEMPLATE_CONFIG',
  },
  permissions: {
    view: ['company_tab_view', 'cs_view'],
  },
  endpoints: {
    view: [''],
  },
  cards: {
    CompanyTemplates: {
      label: 'COMPANY_TEMPLATES.TITLE',
      fields: {},
    },
    ProjectTemplates: {
      label: 'PROJECT_TEMPLATES.TITLE',
      fields: {},
    },
  },
};

export default page;
