import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { getApi } from 'utils/api';
import { JITApi } from '@procore/ipa-nt-api-client-ts';
import type { JitSessionDto } from '@procore/ipa-nt-api-client-ts';
import ConfirmModal from 'components/ConfirmModal';

interface JitExtendModalProps {
  session: JitSessionDto | null;
  isOpen: boolean;
  closeModal(): void;
  setParentErrorMessage?: (message: string) => void;
  doneCallback?: (success: boolean, session: JitSessionDto) => void;
}

export const JitExtendModal = (props: JitExtendModalProps) => {
  const { session } = props;
  const { t } = useTranslation(['jit']);

  const extendConfirm = useCallback(async () => {
    if (!props.session) {
      return;
    }

    try {
      await getApi(JITApi).jitControllerExtendSessionV10(session.id);
      props.doneCallback && props.doneCallback(true, session);
    } catch (error: any) {
      const msg = t('EXTEND_ERROR', {
        ns: 'jit',
        reason: error.response?.data?.message || error.message,
      });
      props.setParentErrorMessage && props.setParentErrorMessage(msg);
      props.doneCallback && props.doneCallback(false, session);
      return null;
    }
  }, [session]);

  const newExpiresAt = (numberOfHours: number) => {
    const options = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    const newExpireAt = session?.expiresAt
      ? new Date(session?.expiresAt)
      : new Date();
    newExpireAt.setHours(newExpireAt.getHours() + numberOfHours);
    return new Intl.DateTimeFormat('en-US', options as any).format(newExpireAt);
  };

  const questionVars = {
    env: session?.env,
    userName: session?.userEmail,
    companyName: session?.companyName,
    accessLevel: session?.accessLevel,
    newExpiresAt: newExpiresAt(session?.hoursRequested ?? 2),
  };

  return (
    <div>
      <ConfirmModal
        isOpen={props.isOpen}
        modalTitle={t('CONFIRM_TITLE', { ns: 'jit' })}
        modalCopy={t('EXTEND_QUESTION', {
          ns: 'jit',
          ...questionVars,
        })}
        closeModal={props.closeModal}
        confirmModal={extendConfirm}
      />
    </div>
  );
};
