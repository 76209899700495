import type { PageConfig } from 'pages/page-config.interfaces';

export default {
  label: 'TITLE',
  i18n: {
    namespace: 'api-requests',
  },
  route: {
    path: '/v2/api-requests',
  },
  endpoints: {
    view: ['apiRequestsGet'],
  },
  cards: {},
} as PageConfig;
