import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useTranslation } from 'react-i18next';
import { toRelativeUrl } from '@okta/okta-auth-js';

export const OktaAuthRedirector: React.FC = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const { t } = useTranslation(['common']);

  useEffect(() => {
    if (!authState || window.location.href.includes('/oauth2/callback')) {
      return;
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin,
      );
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
  }, [oktaAuth, !!authState, authState?.isAuthenticated]);

  if (!authState || !authState?.isAuthenticated) {
    return <h4>{t('AUTHENTICATION_REDIRECT')}</h4>;
  } else {
    return <React.Fragment />;
  }
};
