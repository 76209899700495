import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Modal, Form, Button, Box, H3, Page } from '@procore/core-react';
import { getChangedData, validateFormBeforeSubmit } from 'utils/form';
import { getSettingValue } from 'utils/utils';
import ErpSettingsForm from './ErpSettingsForm';
import type {
  IErpEditSettingsModalProps,
  IErpSettingsEdit,
  IErpSettings,
} from './erp.settings.interface';
import type { FormikProps } from 'formik';
import { FormWrapper, ComparisonGridWrapper, StyledFormTextArea } from 'styles';

export const ErpEditSettingsModal = (props: IErpEditSettingsModalProps) => {
  const { t } = useTranslation('company');
  const [filteredData, setFilteredData] = useState<IErpSettings>(
    {} as IErpSettings,
  );
  const [modalStep, setModalStep] = useState<number>(1);
  const [changedData, setChangedData] = useState<any>([]);

  const dataMap = [
    {
      display_name: t('ERP_SETTINGS.SUBSCRIBE_TO_ERP_DIGEST_EMAILS'),
      name: 'subscribed_to_erp_digest_emails',
    },
    {
      display_name: t('ERP_SETTINGS.CAN_PUSH_TO_ACCOUNTING'),
      name: 'can_push_to_accounting',
    },
  ];

  const closeModal = () => {
    setModalStep(1);
    if (document.getElementById('erp-settings-modal-form')) {
      const form = document.getElementById(
        'erp-settings-modal-form',
      ) as HTMLFormElement;
      form.reset();
    }
    props.closeModal();
  };

  const changeModalView = (n: number) => {
    setModalStep(n);
  };

  const formForward = (n: number, values: any) => {
    setChangedData(getChangedData(filteredData, values));
    setModalStep(n);
  };

  const getSettingDisplayName = (name: string): string => {
    const setting = dataMap.find((s: any) => s.name === name);
    return setting ? setting.display_name : '';
  };

  useEffect(() => {
    if (props.data) {
      let temp: IErpSettings = {} as IErpSettings;
      const fieldNames = dataMap.map((d) => d.name);
      for (const key in props.data) {
        if (fieldNames.includes(key)) {
          temp[key as keyof IErpSettings] =
            props.data[key as keyof IErpSettings];
        }
      }
      setFilteredData(temp);
    }
  }, [props.data]);

  return (
    <Modal
      open={props.isOpen}
      onClickOverlay={closeModal}
      width="md"
      data-qa={'erp-setting-modal'}
      onClose={closeModal}
    >
      <Modal.Header onClose={closeModal}>
        {t('ERP_SETTINGS.EDIT_MODAL_TITLE')}
      </Modal.Header>
      <FormWrapper>
        <Form
          view="update"
          enableReinitialize={true}
          initialValues={{
            subscribed_to_erp_digest_emails:
              props.data.subscribed_to_erp_digest_emails,
            can_push_to_accounting: props.data.can_push_to_accounting,
            reason: '',
          }}
          validationSchema={yup.object().shape({
            reason: yup
              .string()
              .nullable()
              .required(t('REQUIRED', { ns: 'common' })),
          })}
          onSubmit={(values) => {
            props.edit(values);
            closeModal();
          }}
        >
          {(formProps: FormikProps<IErpSettingsEdit>) => {
            const { dirty, values, setFieldValue } = formProps;
            return (
              <Form.Form id="erp-settings-modal-form">
                {modalStep === 1 && (
                  <React.Fragment>
                    <Modal.Body>
                      <ErpSettingsForm
                        data={filteredData}
                        setFieldValue={setFieldValue}
                      />
                    </Modal.Body>
                    <Modal.Footer>
                      <Modal.FooterButtons>
                        <React.Fragment>
                          <Button variant="tertiary" onClick={props.closeModal}>
                            {t('CANCEL', { ns: 'common' })}
                          </Button>
                          <Button
                            variant="primary"
                            type="button"
                            data-qa="update-user-next-btn"
                            disabled={!dirty}
                            onClick={() =>
                              validateFormBeforeSubmit(formProps, () =>
                                formForward(2, values),
                              )
                            }
                          >
                            {t('NEXT', { ns: 'common' })}
                          </Button>
                        </React.Fragment>
                      </Modal.FooterButtons>
                    </Modal.Footer>
                  </React.Fragment>
                )}
                {modalStep === 2 && (
                  <React.Fragment>
                    <Modal.Body>
                      <Modal.Section>
                        <p>{t('CHANGES_MADE', { ns: 'common' })}</p>
                        <ComparisonGridWrapper>
                          <Page.Row>
                            <Page.Column>
                              <Box>
                                <H3>{t('CURRENT_VALUES', { ns: 'common' })}</H3>
                                <Box className="ipa-col">
                                  <ul>
                                    {changedData &&
                                      changedData.map((key: string) => (
                                        <li key={key}>{`${getSettingDisplayName(
                                          key,
                                        )}: ${getSettingValue(
                                          (filteredData as any)[key],
                                        )}`}</li>
                                      ))}
                                  </ul>
                                </Box>
                              </Box>
                            </Page.Column>
                            <Page.Column>
                              <Box>
                                <H3>{t('NEW_VALUES', { ns: 'common' })}</H3>
                                <Box className="ipa-col">
                                  <ul>
                                    {changedData &&
                                      changedData.map((key: string) => (
                                        <li key={key}>{`${getSettingDisplayName(
                                          key,
                                        )}: ${getSettingValue(
                                          (values as any)[key],
                                        )}`}</li>
                                      ))}
                                  </ul>
                                </Box>
                              </Box>
                            </Page.Column>
                          </Page.Row>
                        </ComparisonGridWrapper>
                        <Box>
                          <p>{t('PROVIDE_REASON', { ns: 'common' })}</p>
                          <StyledFormTextArea
                            name="reason"
                            data-qa="reason-textarea"
                            label={t('REASON', { ns: 'common' })}
                          />
                        </Box>
                      </Modal.Section>
                    </Modal.Body>
                    <Modal.Footer>
                      <Modal.FooterButtons>
                        <Button
                          variant="tertiary"
                          onClick={() => changeModalView(1)}
                        >
                          {t('BACK', { ns: 'common' })}
                        </Button>
                        <Button
                          variant="primary"
                          type="submit"
                          disabled={values.reason === ''}
                          data-qa="data-change-modal-save"
                        >
                          {t('SAVE', { ns: 'common' })}
                        </Button>
                      </Modal.FooterButtons>
                    </Modal.Footer>
                  </React.Fragment>
                )}
              </Form.Form>
            );
          }}
        </Form>
      </FormWrapper>
    </Modal>
  );
};
