export const validateFormBeforeSubmit = (
  formProps: any,
  nextFunction: () => any,
) => {
  formProps.validateForm().then((errors: any) => {
    // Check if the current form is valid (ignore empty reason)
    const validationErrors: string[] = Object.keys(errors);
    if (
      validationErrors.length === 0 ||
      (validationErrors.length === 1 && validationErrors[0] === 'reason')
    ) {
      formProps.setErrors({});
      nextFunction();
    }
  });
};

export const getChangedData = (settings: any, values: any) => {
  const temp: any = [];

  Object.keys(settings).forEach((key) => {
    if (!Array.isArray(settings[key])) {
      if (
        typeof settings[key] === 'object' &&
        settings[key] != null &&
        settings[key].id !== values[key].id
      ) {
        temp.push(key);
      } else if (
        (typeof settings[key] !== 'object' || settings[key] === null) &&
        values[key] !== undefined &&
        settings[key] !== values[key]
      ) {
        temp.push(key);
      }
    } else {
      // If settings[key] is an array
      if (JSON.stringify(settings[key]) !== JSON.stringify(values[key])) {
        temp.push(key);
      }
    }
  });

  return temp;
};

export const urlIdParser = (
  e: React.ClipboardEvent,
  setFieldValue: Function,
  fieldName: string,
): string => {
  e.preventDefault();
  const { clipboardData } = e;
  const pastedData = clipboardData.getData('Text');
  let parsedValue: number | string = pastedData;
  const matched = [
    /(?<id>\d+)\/(?<type>project|company)/,
    /(?<type>projects|companies)\/(?<id>\d+)/,
  ].find((regex) => {
    if (regex.test(pastedData)) {
      const matches = pastedData.match(regex);
      if (matches?.groups?.id) {
        parsedValue = +matches.groups.id;
        setFieldValue(fieldName, parsedValue);
        return true;
      }
    }
  });

  if (!matched) {
    if (Number.isInteger(+pastedData)) {
      parsedValue = +pastedData;
    } else {
      parsedValue = pastedData.replace(/us02|uk01/, '').replace(/[^0-9]+/g, '');
      parsedValue = +parsedValue;
    }
    setFieldValue(fieldName, parsedValue);
  }

  return parsedValue.toString();
};

/*
 * This function is used to parse commas / periods from a pasted string from Salesforce
 * Example: 1,000,000 -> 1000000
 */
export const punctuationIdParser = (
  e: React.ClipboardEvent,
  setFieldValue: Function,
  fieldName: string,
) => {
  e.preventDefault();
  const { clipboardData } = e;
  const pastedData = clipboardData.getData('Text');
  const regex = /^[0-9,.]+$/;
  if (regex.test(pastedData)) {
    setFieldValue(fieldName, pastedData.replace(/[^0-9]+/g, ''));
  } else {
    setFieldValue(fieldName, pastedData);
  }
};

export const parsePastedString = (
  e: React.ClipboardEvent,
  setFieldValue: Function,
  fieldName: string,
) => {
  e.preventDefault();
  const { clipboardData } = e;
  const pastedData = clipboardData.getData('Text');

  if (pastedData.includes('.com')) {
    return urlIdParser(e, setFieldValue, fieldName);
  } else {
    return punctuationIdParser(e, setFieldValue, fieldName);
  }
};
