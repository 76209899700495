import React from 'react';
import { Dropdown } from '@procore/core-react';
import { Link } from 'components/Link';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { generateUrl as projectUrlGenerator } from 'pages/Project/urlGenerator';

interface ProjectToolProps {
  // key is the last part of the url leading to the project tool page
  key: string;
  label: string;
}

interface ProjectToolsDropdownProps {
  env: string;
  companyId: number;
  projectId: number;
  contactId?: number;
  loginId?: number;
}

export const ProjectToolsDropdown = (props: ProjectToolsDropdownProps) => {
  const { t } = useTranslation(['project']);
  const { page: _page } = useParams();
  const pageKey = _page ?? '';

  const projectTools: ProjectToolProps[] = [
    {
      key: 'project-info',
      label: t('PROJECT_INFORMATION'),
    },
    {
      key: 'locations',
      label: t('LOCATIONS'),
    },
    {
      key: 'meeting-settings',
      label: t('MEETING_SETTINGS'),
    },
    {
      key: 'rfi-settings',
      label: t('RFI_SETTINGS'),
    },
    {
      key: 'schedule-settings',
      label: t('SCHEDULE_SETTINGS'),
    },
    {
      key: 'submittal-responses',
      label: t('SUBMITTAL_RESPONSES'),
    },
    {
      key: 'submittal-settings',
      label: t('SUBMITTAL_SETTINGS'),
    },
    {
      key: 'submittal-workflow-templates',
      label: t('SUBMITTAL_WORKFLOW_TEMPLATES'),
    },
    {
      key: 'task-items-settings',
      label: t('TASK_ITEMS_SETTINGS'),
    },
    {
      key: 'daily-log-settings',
      label: t('DAILY_LOG_SETTINGS'),
    },
    {
      key: 'cost-codes',
      label: t('COST_CODES'),
    },
    {
      key: 'prime-contract-settings',
      label: t('PRIME_CONTRACT_SETTINGS'),
    },
    {
      key: 'commitment-settings',
      label: t('COMMITMENT_SETTINGS'),
    },
    {
      key: 'project-config-history',
      label: t('PROJECT_CONFIG_HISTORY'),
    },
  ];

  const findCurrentTool = () => {
    const tool = projectTools.find((page: ProjectToolProps) => {
      return pageKey === page.key;
    });
    return tool;
  };

  const currentTool = findCurrentTool();

  return (
    <Dropdown data-qa="tools-dropdown">
      {projectTools
        .sort((a: ProjectToolProps, b: ProjectToolProps) =>
          a.label < b.label ? -1 : 1,
        )
        .map((page: ProjectToolProps, index: number) => (
          <Dropdown.Item
            item={page.key}
            key={page.key}
            data-qa={page.key}
            selected={currentTool && page.key === currentTool.key}
          >
            <Link href={projectUrlGenerator(page.key, props)}>
              {page.label}
            </Link>
          </Dropdown.Item>
        ))}
    </Dropdown>
  );
};

export default ProjectToolsDropdown;
