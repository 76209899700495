import type { PageConfig } from 'pages/page-config.interfaces';

const pageConfig: PageConfig = {
  label: 'PROJECT_MANAGEMENT',
  route: {
    page: '',
  },
  endpoints: {
    view: ['companyControllerSearchProjects'],
  },
  cards: {
    ProjectList: {
      i18n: {
        keyPrefix: 'PROJECTS',
      },
      label: 'TITLE',
      fields: {
        project_number: {
          label: 'PROJECT_NUMBER',
        },
        id: {
          label: 'PROJECT_ID',
        },
        project_name: {
          label: 'PROJECT_NAME',
        },
        is_active: {
          label: 'PROJECT_ACTIVE',
        },
      },
    },

    DefaultStages: {
      i18n: {
        keyPrefix: 'DEFAULT_STAGES',
      },
      label: 'TITLE',
      fields: {
        formatted_name: {
          label: 'STAGES',
        },
        projects_count: {
          label: 'PROJECTS',
        },
      },
      endpoints: {
        view: ['companyControllerGetProjectStages'],
      },
    },
  },
};

export default pageConfig;
