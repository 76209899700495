import type { PageConfig } from 'pages/page-config.interfaces';

const pageConfig: PageConfig = {
  label: 'COMPANY_SUBMITTALS',
  route: {
    page: 'submittals',
  },
  endpoints: {
    view: ['companyControllerGetCompanySubmittals'],
  },
  cards: {
    CustomSubmittalTypes: {
      i18n: {
        keyPrefix: 'CUSTOM_SUBMITTAL_TYPES',
      },
      label: 'TITLE',
      fields: {
        name: {
          label: 'NAME',
        },
        active: {
          label: 'ACTIVE',
        },
        global: {
          label: 'GLOBAL',
        },
      },
    },

    CustomSubmittalLogStatuses: {
      i18n: {
        keyPrefix: 'CUSTOM_SUBMITTAL_LOG_STATUSES',
      },
      label: 'TITLE',
      fields: {
        name: {
          label: 'NAME',
        },
        global: {
          label: 'GLOBAL',
        },
      },
    },
  },
};

export default pageConfig;
