import type { PageConfig } from 'pages/page-config.interfaces';

const pageConfig: PageConfig = {
  label: 'TITLE',
  i18n: {
    keyPrefix: 'PROJECT_SUBMITTAL_SETTINGS',
  },
  route: {
    page: 'submittal-settings',
    path: '/v2/company/:company_id/project/:project_id/submittal-settings',
  },
  endpoints: {
    view: [
      'projectControllerGetProjectSubmittalSettings',
      'projectControllerGetProjectWorkflowApproverOptions',
    ],
  },
  cards: {
    SubmittalSettings: {
      i18n: {
        keyPrefix: 'PROJECT_SUBMITTAL_SETTINGS.SETTINGS_CARD',
      },
      label: 'TITLE',
      fields: {
        default_submittal_manager: {
          label: 'DEFUALT_SUBMITTAL_MANAGER',
        },
        sort_submittal_spec_section_number_list: {
          label: 'SUBMITTAL_SORT_BY_SPEC_NUMBER',
        },
        sort_submittal_packages_section_list: {
          label: 'SUBMITTAL_SORT_BY_PACKAGE_NUMBER',
        },
        dynamic_approver_due_dates: {
          label: 'DYNAMIC_APPROVER_DUE_DATES',
        },
        new_submittals_due_in_days: {
          label: 'NEW_SUBMITTALS_DUE_IN_DAYS',
        },
        submittal_log_default_design_team_review_time: {
          label: 'SUBMITTAL_LOG_DEFAULT_DESIGN_TEAM_REVIEW_TIME',
        },
      },
      endpoints: {
        edit: ['projectControllerUpdateProjectSubmittalSettings'],
      },
    },
    SubmittalProjectConfiguration: {
      i18n: {
        keyPrefix: 'PROJECT_SUBMITTAL_SETTINGS.SUBMITTAL_PROJECT_CONFIGURATION',
      },
      label: 'TITLE',
      fields: {
        reject_workflow_enabled: {
          label: 'REJECT_WORKFLOW_ENABLED',
        },
      },
      endpoints: {
        edit: ['projectControllerUpdateSubmittalProjectConfiguration'],
      },
    },
    SubmittalEmails: {
      i18n: {
        keyPrefix: 'PROJECT_SUBMITTAL_SETTINGS.SUBMITTAL_EMAILS_CARD',
      },
      label: 'TITLE',
      fields: {
        name: {
          label: 'TABLE_HEADER.ACTION',
        },
        creator: {
          label: 'TABLE_HEADER.CREATOR',
        },
        manager: {
          label: 'TABLE_HEADER.SUBMITTAL_MANAGER',
        },
        submitter: {
          label: 'TABLE_HEADER.SUBMITTER',
        },
        approver: {
          label: 'TABLE_HEADER.APPROVER',
        },
        reviewer: {
          label: 'TABLE_HEADER.REVIEWER',
        },
        distribution_group: {
          label: 'TABLE_HEADER.DISTRIBUTION_GROUP',
        },
      },
    },
  },
};

export default pageConfig;
