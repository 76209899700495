import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Card,
  EmptyState,
  Flex,
  Spinner,
  Title,
  H2,
} from '@procore/core-react';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { getApi } from 'utils/api';
import { CompaniesApi } from '@procore/ipa-nt-api-client-ts';
import type { ProjectStageDto } from '@procore/ipa-nt-api-client-ts';
import TableComponent from 'components/Table';
import type { TableHeader } from 'components/Table/table.interface';
import { ProjectManagementContext } from '../index';

const DefaultStagesCard = () => {
  const { t } = useTranslation(['project-management'], {
    keyPrefix: 'DEFAULT_STAGES',
  });
  const { t: tCommon } = useTranslation(['common']);
  const alert = useContext(IpaAlertContext);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(true);
  const [stages, setStages] = useState<ProjectStageDto[]>([]);
  const { companyId, env } = useContext(ProjectManagementContext);

  const tableHeaders: TableHeader[] = [
    {
      title: t('STAGES'),
      node: 'formatted_name',
      is_sortable: false,
      is_text: true,
      column_width: 50,
    },
    {
      title: t('PROJECTS'),
      node: 'projects_count',
      is_sortable: false,
      is_text: true,
      column_width: 50,
    },
  ];

  const getProjectStages = useCallback(async () => {
    setIsInit(false);
    setLoading(true);

    try {
      const api = getApi(CompaniesApi);
      const result = await api.companyControllerGetProjectStagesV10(
        env,
        companyId,
      );

      setStages(result.data);
    } catch (e: any) {
      alert.error(e);
    } finally {
      setLoading(false);
      setIsInit(true);
    }
  }, [companyId, env]);

  useEffect(() => {
    setIsInit(false);
    setLoading(true);

    try {
      getProjectStages();
    } catch (e: any) {
      alert.error(e);
    } finally {
      setLoading(false);
      setIsInit(true);
    }
  }, [getProjectStages]);

  return (
    <Card>
      <Box padding="lg">
        <Title>
          <Title.Text>
            <H2>{t('TITLE')}</H2>
          </Title.Text>
        </Title>
        <div>
          {(!isInit || isLoading) && (
            <Box marginBottom="lg">
              <Card>
                <Box padding="lg">
                  {!isInit && !isLoading && (
                    <EmptyState>
                      <EmptyState.NoItems />
                      <EmptyState.Title>{tCommon('NO_DATA')}</EmptyState.Title>
                    </EmptyState>
                  )}
                  {isLoading && (
                    <Flex justifyContent="center">
                      <Spinner loading={isLoading} />
                    </Flex>
                  )}
                </Box>
              </Card>
            </Box>
          )}
          {isInit && !isLoading && (
            <TableComponent
              data_qa="pm-project-stages-table"
              data={stages ?? []}
              headers={tableHeaders}
              default_sort_column="formatted_name"
              show_no_data_message={true}
              no_data_message={tCommon('NO_DATA')}
              fixed_table={true}
              default_sort_direction={'asc'}
            />
          )}
        </div>
      </Box>
    </Card>
  );
};
export default DefaultStagesCard;
