import React, { useContext } from 'react';
import { Box, Link, FlexList, Grid, Button, Table } from '@procore/core-react';
import { FooterWrapper, styles } from './styles';
import { useTranslation } from 'react-i18next';
import { useOktaAuth } from '@okta/okta-react';
import { AppContext } from 'contexts/AppContext';

interface AppFooterProps {
  build_number: string;
}

export const AppFooter = (props: AppFooterProps) => {
  const { authState } = useOktaAuth();
  const { publicConfig, userConfig } = useContext(AppContext);
  const { t } = useTranslation('footer');

  const showCopyToken = userConfig?.user?.groups?.includes('Dev');

  const copyToken = () =>
    navigator.clipboard.writeText(authState?.accessToken?.accessToken ?? '');

  return (
    <FooterWrapper>
      <Grid>
        <Grid.Row>
          <Grid.Col colWidth={{ tabletSm: 12, tabletMd: 6, tabletLg: 6 }}>
            <Box style={styles.buildStyle}>
              {t('BUILD')} {props.build_number}
            </Box>
          </Grid.Col>
          <Grid.Col colWidth={{ tabletSm: 12, tabletMd: 6, tabletLg: 6 }}>
            <FlexList justifyContent="flex-end" size="sm">
              <Box>
                <Link
                  href="slack://channel?team=T02AS2EJA&amp;id=C02MJ40US5U"
                  style={styles.linkStyle}
                >
                  {t('NEED_HELP')}
                </Link>
              </Box>
              <Box>
                <Link
                  href="https://github.com/procore/ipa_nt"
                  style={styles.linkStyle}
                >
                  Github
                </Link>
              </Box>
              <Box>
                {showCopyToken && (
                  <Button onClick={copyToken}>{t('COPY_TOKEN')}</Button>
                )}
              </Box>
            </FlexList>
          </Grid.Col>
        </Grid.Row>
      </Grid>
      {/* This is to prevent page shrinking when a modal is activated */}
      <Table {...{ variant: 'fixed' }}></Table>
    </FooterWrapper>
  );
};

export default AppFooter;
