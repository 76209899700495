import type { PageConfig } from 'pages/page-config.interfaces';

export default {
  label: 'BUSINESS_ENTITY.GENERAL',
  endpoints: {
    view: [],
    edit: [],
  },
  cards: {
    GeneralInformation: {
      i18n: {
        keyPrefix: 'BUSINESS_ENTITY.GENERAL_TAB.GENERAL_INFORMATION_CARD',
      },
      label: 'TITLE',
      permissions: {
        edit: ['edit_payments_company'],
      },
      fields: {
        entityName: {
          label: 'COMPANY_NAME',
          disabled: true,
        },
        externalId: {
          label: 'COMPANY_ID',
        },
        capabilities: {
          label: 'ENTITY_TYPE',
        },
        active: {
          label: 'ENTITY_STATUS',
        },
        mtlEnabled: {
          label: 'MTL_ONBOARDING',
        },
      },
    },
    FeePaySettings: {
      i18n: {
        keyPrefix: 'BUSINESS_ENTITY.GENERAL_TAB.FEE_PAY_SETTINGS_CARD',
      },
      label: 'TITLE',
      permissions: {
        edit: ['edit_payments_company'],
      },
      fields: {
        applyFeesOnAgreements: {
          label: 'FEE_PAY_STATUS',
        },
        payorPaysFeesFeatureEnabled: {
          label: 'FEE_PAY_TYPE',
        },
        payeeFeeCollectionStage: {
          label: 'FEE_COLLECTION',
        },
        requiresPayAgreementForInvoiceSubmission: {
          label: 'INVOICE_BLOCKING',
        },
      },
    },
    Permissions: {
      i18n: {
        keyPrefix: 'BUSINESS_ENTITY.GENERAL_TAB.PERMISSIONS_CARD',
      },
      permissions: {
        edit: ['edit_payments_admins'],
        approve: ['approve_payments_admins'],
      },
      label: 'TITLE',
      fields: {
        admins: {
          label: 'ADMINS',
        },
        disbursers: {
          label: 'DISBURSERS',
        },
      },
    },
    DemandDepositAccounts: {
      i18n: {
        keyPrefix: 'BUSINESS_ENTITY.GENERAL_TAB.DEMAND_DEPOSIT_ACCOUNTS_CARD',
      },
      label: 'TITLE',
      permissions: {
        edit: ['edit_payments_company'],
      },
      fields: {},
    },
    ExternalAccounts: {
      i18n: {
        keyPrefix: 'BUSINESS_ENTITY.GENERAL_TAB.EXTERNAL_ACCOUNTS_CARD',
      },
      label: 'TITLE',
      permissions: {
        edit: ['edit_payments_company'],
      },
      fields: {},
    },
  },
} as PageConfig;
