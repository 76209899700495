import React, { useContext, useEffect, useState, useRef } from 'react';
import { BookInfo } from '@procore/core-icons';
import { styles, SupportDocsWrapper } from 'components/AppHeader/styles';
import { Popover } from '@procore/core-react';
import { useTranslation } from 'react-i18next';
import { CompanyContext } from 'contexts/CompanyContext';
import { ProjectManagementContext } from 'pages/ProjectManagement';
import { ContactContext } from 'contexts/ContactContext';
import { ProjectContext } from 'contexts/ProjectContext';
import type { OverlayTriggerRef } from '@procore/core-react/dist/OverlayTrigger/OverlayTrigger.types';

const contextInfo = {
  companyId: null as number,
  companyName: null as string,
  userLoginEmail: null as string,
  contactName: null as string,
  projectId: null as number,
  projectName: null as string,
  projectNumber: null as string,
};

const ContextInfoPopup = () => {
  const { t } = useTranslation('context-info-popup');
  const popoverRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<OverlayTriggerRef>(null);
  const [show, setShow] = useState<boolean>(false);

  const useFirstAvailableContext = () => {
    const companyContext = useContext(CompanyContext);
    const projectManagementContext = useContext(ProjectManagementContext);
    const contactContext = useContext(ContactContext);
    const projectContext = useContext(ProjectContext);

    if (companyContext && Object.keys(companyContext).length > 0) {
      return companyContext;
    } else if (
      projectManagementContext &&
      Object.keys(projectManagementContext).length > 0
    ) {
      return projectManagementContext;
    } else if (contactContext && Object.keys(contactContext).length > 0) {
      return contactContext;
    } else if (projectContext && Object.keys(projectContext).length > 0) {
      return projectContext;
    } else {
      return null;
    }
  };

  const context = useFirstAvailableContext();

  if (context) {
    if ('companyId' in context) {
      contextInfo.companyId = context.companyId;
    }
    if ('companyName' in context) {
      contextInfo.companyName = context.companyName;
    }
    if ('dataSources' in context && 'companyStatus' in context.dataSources) {
      contextInfo.companyName = context.dataSources.companyStatus?.data?.name;
    }
    if ('dataSources' in context && 'contact' in context.dataSources) {
      contextInfo.userLoginEmail =
        context.dataSources.contact?.data?.login_email;
      contextInfo.contactName = `${context.dataSources.contact?.data?.first_name} ${context.dataSources.contact?.data?.last_name}`;
    }
    if ('dataSources' in context && 'projectSettings' in context.dataSources) {
      contextInfo.projectId = context.dataSources.projectSettings?.data?.id;
      contextInfo.projectName = context.dataSources.projectSettings?.data?.name;
      contextInfo.projectNumber =
        context.dataSources.projectSettings?.data?.project_number;
    }
  }

  useEffect(() => {
    const onKeyDownHandler = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 'i') {
        if (!show) {
          overlayRef.current?.show();
          setShow(true);
          popoverRef.current?.scrollIntoView({ behavior: 'smooth' });
        } else {
          overlayRef.current?.hide();
          setShow(false);
        }
      }
    };

    window.addEventListener('keydown', onKeyDownHandler);

    return () => {
      window.removeEventListener('keydown', onKeyDownHandler);
    };
  }, [show]);

  const companyContextInfoOverlay = (
    <Popover.Content style={styles.ipaReleasePopover}>
      <SupportDocsWrapper data-qa="context-info-popup-content">
        <ul>
          {contextInfo.companyId && (
            <li>
              {t('COMPANY_ID')}: {contextInfo.companyId}
            </li>
          )}
          {contextInfo.companyName && (
            <li>
              {t('COMPANY_NAME')}: {contextInfo.companyName}
            </li>
          )}
          {contextInfo.userLoginEmail && (
            <li>
              {t('USER_LOGIN_EMAIL')}: {contextInfo.userLoginEmail}
            </li>
          )}
          {contextInfo.contactName && (
            <li>
              {t('CONTACT_NAME')}: {contextInfo.contactName}
            </li>
          )}
          {contextInfo.projectId && (
            <li>
              {t('PROJECT_ID')}: {contextInfo.projectId}
            </li>
          )}
          {contextInfo.projectName && (
            <li>
              {t('PROJECT_NAME')}: {contextInfo.projectName}
            </li>
          )}
          {contextInfo.projectNumber && (
            <li>
              {t('PROJECT_NUMBER')}: {contextInfo.projectNumber}
            </li>
          )}
        </ul>
      </SupportDocsWrapper>
    </Popover.Content>
  );

  return (
    <div ref={popoverRef} data-qa="context-info-popup">
      <Popover
        aria-labelledby="company-context-info"
        overlay={companyContextInfoOverlay}
        placement="bottom-left"
        overlayRef={overlayRef}
      >
        {() => <BookInfo onClick={() => console.log(true)} />}
      </Popover>
    </div>
  );
};

export default ContextInfoPopup;
