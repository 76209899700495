import React, { useEffect } from 'react';
import { Popover, H2, Typography, EmptyState, Box } from '@procore/core-react';
import { useTranslation } from 'react-i18next';
import { IconWrapper } from 'components/AppHeader/styles';
import { ListBulleted } from '@procore/core-icons';
import { QuickHistoryState, type QuickHistoryItem } from './QuickHistoryState';
import { QuickHistoryWrapper, styles, SquareIcon, Legend } from './styles';
import { Link } from 'components/Link';

const state = new QuickHistoryState();

export const QuickHistory = () => {
  const { t } = useTranslation(['header']);
  const { t: tCommon } = useTranslation(['common']);

  const [items, setItems] = React.useState(state.getItems());

  useEffect(() => {
    window.addEventListener('storage', () => {
      setItems(state.getItems());
    });
  }, []);

  const getLink = (item: QuickHistoryItem) => {
    switch (item.type) {
      case 'company':
        return `/v2/company/${item.companyId}`;
      case 'project':
        return `/v2/company/${item.companyId}/project/${item.projectId}/project-info`;
      case 'contact':
        return `/v2/company/${item.companyId}/contact/${item.contactId}/login/0/contact-info`;
      default:
        return '/';
    }
  };

  const overlay = (
    <Popover.Content style={styles.popover}>
      <QuickHistoryWrapper>
        <H2>{t('QUICK_HISTORY.TITLE')}</H2>
        {!items.length && (
          <EmptyState compact>
            <EmptyState.NoResults />
            <EmptyState.Title>{t('QUICK_HISTORY.EMPTY')}</EmptyState.Title>
          </EmptyState>
        )}
        {items.length > 0 && (
          <Box>
            <ul>
              {items.map((item) => (
                <li key={item.title}>
                  <SquareIcon className={item.type} />
                  <Link href={getLink(item)}>{item.title}</Link>
                </li>
              ))}
            </ul>
            <Legend gap="md">
              <Typography intent="small">
                <SquareIcon className="company legend" /> - {tCommon('COMPANY')}
              </Typography>
              <Typography intent="small">
                <SquareIcon className="project legend" /> - {tCommon('PROJECT')}
              </Typography>
              <Typography intent="small">
                <SquareIcon className="contact legend" /> - {tCommon('CONTACT')}
              </Typography>
            </Legend>
          </Box>
        )}
      </QuickHistoryWrapper>
    </Popover.Content>
  );

  return (
    <Popover aria-labelledby="" overlay={overlay} placement="bottom-left">
      <IconWrapper>
        <ListBulleted />
      </IconWrapper>
    </Popover>
  );
};

export default QuickHistory;
