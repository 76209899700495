import React, { useState, useContext } from 'react';
import {
  Page,
  Card,
  Button,
  Title,
  H1,
  InfoBanner,
  Banner,
  spacing,
  Form,
} from '@procore/core-react';
import { FormWrapper } from 'styles';
import { useTranslation } from 'react-i18next';
import { DevPortalApi } from '@procore/ipa-nt-api-client-ts';
import { getApi } from 'utils/api';
import { PageConfig } from 'pages/page-config.service';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import * as yup from 'yup';

export const OauthApplicationsPage = () => {
  const { t: tCommon } = useTranslation(['common']);
  const pageConfig = PageConfig.get(tCommon, 'DevPortal.OauthApplications');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const alert = useContext(IpaAlertContext);

  const handleSubmit = async (values: { appId: string; secret: string }) => {
    try {
      alert.closeAlert();
      setIsLoading(true);

      const api = getApi(DevPortalApi);
      const response: any = await api.devPortalControllerGetEnvironmentV10(
        values.appId,
        values.secret,
      );
      const data = response.data;
      const status = data.status || response.status;

      if (status === 200) {
        alert.success(
          pageConfig.translate('ENV_INFO.SUCCESS_MESSAGE', {
            environment: data.environment,
          }),
        );
      } else if (status === 401) {
        alert.error(pageConfig.translate('ENV_INFO.UNAUTHORIZED_MESSAGE'));
      } else {
        alert.error(
          pageConfig.translate('ENV_INFO.ERROR_MESSAGE', {
            error: data.error,
          }),
        );
      }
    } catch (error: any) {
      alert.error(
        pageConfig.translate('ENV_INFO.ERROR_MESSAGE', {
          error: error.message || error,
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Page>
      <Page.Main>
        <Card>
          <InfoBanner>
            <Banner.Content>
              <Banner.Body data-qa="banner-status-text">
                {pageConfig.translate('INFO_MESSAGE')}
              </Banner.Body>
            </Banner.Content>
          </InfoBanner>
        </Card>
        <Card style={{ margin: spacing.md, padding: spacing.md }}>
          <Page.Header transparent={true}>
            <Page.Title>
              <Title>
                <Title.Text>
                  <H1>{pageConfig.label}</H1>
                </Title.Text>
              </Title>
            </Page.Title>
          </Page.Header>
          <Page.Body>
            <FormWrapper>
              <Form
                view="create"
                enableReinitialize={true}
                initialValues={{
                  appId: '',
                  secret: '',
                }}
                validationSchema={yup.object().shape(
                  {
                    appId: yup.string().when('secret', {
                      is: (val: string) => val === '',
                      then: yup.string().required(tCommon('REQUIRED')),
                      otherwise: yup.string(),
                    }),
                    secret: yup.string().when('appId', {
                      is: (val: string) => val === '',
                      then: yup.string().required(tCommon('REQUIRED')),
                      otherwise: yup.string(),
                    }),
                  },
                  [['appId', 'secret']],
                )}
                onSubmit={(values) => handleSubmit(values)}
              >
                {(formProps: any) => {
                  const { values } = formProps;
                  return (
                    <Form.Form>
                      <Form.Text
                        name="appId"
                        placeholder={pageConfig.translate('ENV_INFO.APP_ID')}
                        data-qa="app-id-field"
                        disabled={!!values.secret}
                        style={{
                          width: '60%',
                          marginBottom: spacing.sm,
                        }}
                      />
                      <Form.Text
                        name="secret"
                        placeholder={pageConfig.translate('ENV_INFO.SECRET')}
                        data-qa="secret-field"
                        disabled={!!values.appId}
                        style={{
                          width: '60%',
                          marginBottom: spacing.xs,
                        }}
                      />
                      <Button
                        type="submit"
                        variant="primary"
                        data-qa="search-btn"
                        disabled={isLoading}
                      >
                        {tCommon('SEARCH')}
                      </Button>
                    </Form.Form>
                  );
                }}
              </Form>
            </FormWrapper>
          </Page.Body>
        </Card>
      </Page.Main>
    </Page>
  );
};

export default OauthApplicationsPage;
