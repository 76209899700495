import styled, { keyframes } from 'styled-components';
import {
  colors,
  spacing,
  Tooltip,
  Form,
  Page,
  Select,
  Grid,
  Table,
} from '@procore/core-react';

type Layout = 'row' | 'column';

interface IpaToastWrapperProps {
  timeout: number;
}

interface IpaAlertWrapperProps extends IpaToastWrapperProps {
  alertType: string;
  keepVisible: boolean;
}

interface IpaRadioButtonsProps {
  layout?: Layout; // "row" or "column"
}

interface IpaFormWrapperProps {
  marginTop?: string;
}

export const ipaAppStyles = {
  ipaGrid: {
    padding: '0 16px',
  },
  pmActions: {
    marginTop: '0px',
    paddingTop: '0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  titleMargin: {
    marginBottom: spacing.sm,
  },
  inlineButton: {
    marginTop: spacing.xl,
  },
  titleActions: {
    position: 'relative',
    bottom: spacing.xs,
  } as React.CSSProperties,
  backgrounds: {
    orange: {
      backgroundColor: `${colors.orange50}`,
    },
    blue: {
      backgroundColor: `${colors.blue50}`,
    },
    green: {
      backgroundColor: `${colors.green50}`,
    },
  },
  inlineTooltip: {
    marginLeft: spacing.xs,
    position: 'relative',
    top: spacing.xxs,
  } as React.CSSProperties,
  success: {
    color: `${colors.green50}`,
  },
  failure: {
    color: `${colors.red50}`,
  },
  gridRow: {
    padding: `${spacing.sm}px 0`,
  },
  ipaModalForm: {
    overflow: 'auto',
  },
  ipaCustomField: {
    border: `1px solid ${colors.gray70}`,
    padding: `${spacing.sm}px`,
    marginBottom: `${spacing.md}px`,
  },
  bottomAlignLinkIcon: {
    position: 'relative',
    top: `${spacing.xs}px`,
  } as React.CSSProperties,
};

export const FormFieldWrapper = styled.div`
  & > div {
    &:first-child {
      display: none;
    }
  }
`;

export const IpaModalWrapper = styled.div`
  form div {
    max-width: -webkit-fill-available;
  }
  input,
  textarea {
    box-sizing: border-box;
  }
  overflow: auto;
`;

export const IpaModalWrapperTextEditor = styled.div`
  form div {
    max-width: none;
  }
  input,
  textarea {
    box-sizing: border-box;
  }
  overflow: auto;

  .tox-tinymce {
    min-height: 300px;
    max-height: 400px;
  }
`;

export const EmailValueWrapper = styled.div`
  margin-bottom: 20px;
  div {
    font-weight: bold;
    letter-spacing: 0.15px;
  }
`;

export const FieldWrapper = styled.span`
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 24px;

  div {
    &:nth-child(2) {
      display: inline;
    }
    div {
      display: none;
    }

    label {
      display: inline-block;
    }
  }
`;

export const ActionButtons = styled.div`
  margin-left: auto;
  button {
    margin-right: ${spacing.sm}px;
  }
`;

export const TableCellButtons = styled.div`
  button {
    margin-left: ${spacing.sm}px;
    &:first-child {
      margin-left: 0;
    }
  }
`;

const easeInAnimation = keyframes`
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
    bottom: 48px;
  }
  15% {
    opacity: 1;
  }
  75% {
    opacity: 1;
    bottom: 48px;
  }
  100% {
    opacity: 0;
    bottom: -100px;
    animation-timing-function: ease-out;
  }
`;

const alertAnimation = keyframes`
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
    bottom: 48px;
  }
  15% {
    opacity: 1;
  }
`;

export const ToastWrapper = styled.div`
  position: fixed;
  width: 100%;
  z-index: 10;
  left: 0;
  opacity: 0;
  animation-name: ${easeInAnimation};
  animation-duration: ${(props: IpaToastWrapperProps) => props.timeout + 's'};
  & > div {
    margin: 0 auto;
  }
`;

export const IpaAlertWrapper = styled.div`
  position: fixed;
  width: 100%;
  z-index: 20000;
  left: 0;
  bottom: 48px;
  animation-name: ${(props: IpaAlertWrapperProps) =>
    props.alertType === 'error' || props.keepVisible
      ? alertAnimation
      : easeInAnimation};
  animation-duration: ${(props: IpaAlertWrapperProps) => props.timeout + 's'};
  & > div {
    margin: 0 auto;
  }
`;

export const MultiSelectWrapper = styled.div`
  *,
  ::before,
  ::after {
    box-sizing: border-box;
  }
  span {
    max-width: 180px;
  }
`;

export const FormWrapper = styled.div`
  margin-top: ${(props: IpaFormWrapperProps) => props.marginTop ?? '0px'};
  input {
    box-sizing: border-box;
  }
`;

export const FormButtonWrapper = styled.div`
  position: relative;
  button {
    position: absolute;
    top: ${spacing.xl}px;
    margin-top: ${spacing.xs}px;
  }
`;

export const ComparisonGridWrapper = styled.div`
  .ipa-col {
    background: ${colors.gray94};
    padding: ${spacing.md}px;

    ul {
      margin-top: ${spacing.md}px;
      margin-bottom: 0;
      padding-left: 0;
      list-style-type: none;
      li {
        padding-bottom: ${spacing.md}px;
        padding-left: 0;
      }
    }
  }

  .ipa-tools-wrapper {
    max-height: 250px;
    overflow: auto;
  }
`;

export const InlineTooltip = styled(Tooltip)`
  svg {
    position: relative;
    top: ${spacing.xxs}px;
    margin-left: ${spacing.xxs}px;
  }
`;

export const StyledFormText = styled(Form.Text)`
  box-sizing: border-box;
`;

export const StyledFormSelect = styled(Form.Select)`
  box-sizing: border-box;
`;

export const StyledPageBody = styled(Page.Body)`
  padding-bottom: ${spacing.xl}px;
`;

export const StyledFormCheckbox = styled(Form.Checkbox)`
  box-sizing: border-box;
`;

export const StyledFormTextArea = styled(Form.TextArea)`
  box-sizing: border-box;
`;

export const StyledFormRadioButtons = styled(Form.RadioButtons)`
  display: flex;
  flex-direction: ${(props: IpaRadioButtonsProps) => props.layout ?? 'column'};
  gap: ${spacing.lg}px;
  label {
    margin-top: ${spacing.xs}px !important;
  }
  span {
    :before {
      box-sizing: border-box;
    }
  }
`;

export const StyledSelect = styled(Select)`
  box-sizing: border-box;
`;

export const StyledPageColumn = styled(Page.Column)`
  box-sizing: border-box;
`;

export const StyledGridColumn = styled(Grid.Col)`
  box-sizing: border-box;
`;

export const StyledTable = styled(Table)`
  border: 1px solid ${colors.gray90};
`;

export const StyledPageBreadcrumbs = styled(Page.Breadcrumbs)`
  min-height: ${spacing.xxl}px;
`;
