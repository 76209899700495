import styled from 'styled-components';
import { colors, spacing } from '@procore/core-react';
import CSS from 'csstype';

interface CaretProps {
  animate: boolean;
}

interface IpaMenuProps {
  show: boolean;
}

interface AppHeaderStyles {
  homeButton: CSS.Properties;
  logoWrapper: CSS.Properties;
  headerIcons: CSS.Properties;
  ipaReleasePopover: CSS.Properties;
  ipaFavoritesPopover: CSS.Properties;
  releaseAllLink: CSS.Properties;
}

const navHeight = '52px';

export const HeaderWrapper = styled.div`
  background-color: ${colors.gray10};
  width: 100%;
  height: ${navHeight};
  * {
    box-sizing: border-box;
  }

  svg {
    vertical-align: middle;
  }
`;

export const IconWrapper = styled.div`
  width: ${spacing.xxl}px;
  height: ${spacing.xxl}px;
  margin-top: ${spacing.xs}px;
  cursor: pointer;
  line-height: 28px;
  text-align: center;
  &:hover {
    background: ${colors.gray30};
  }
`;

export const CurrentLocation = styled.div`
  border-radius: ${spacing.xs}px;
  background: ${colors.gray20};
  height: 40px;
  margin: 6px 0 6px ${spacing.xxl}px;
  cursor: pointer;
  &:hover {
    background: ${colors.gray30};
  }
  display: flex;
  flex-direction: row;
  color: ${colors.gray98};
  padding-left: ${spacing.sm}px;

  .tab {
    font-size: 12px;
    letter-spacing: 0.25px;
  }

  .page {
    font-size: 14px;
    letter-spacing: 0.15px;
    font-weight: 600;
  }
`;

export const Caret = styled.div<CaretProps>`
  transition: transform 0.3s ease 0s;
  height: 40px;
  line-height: 40px;
  padding: 0 ${spacing.sm}px;
  transform: ${(props) => (props.animate ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

export const IpaMenu = styled.div<IpaMenuProps>`
  position: fixed;
  top: ${navHeight};
  left: 0;
  width: 100%;
  background: ${colors.gray15};
  min-height: 100px;
  z-index: 100;
  display: ${(props) => (props.show ? 'block' : 'none')};

  .menu-grid {
    width: 90%;
    padding: ${spacing.xl}px ${spacing.xl}px ${spacing.xl}px ${spacing.xxl}px;
    margin: 0 !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: ${spacing.lg}px;

    > div {
      flex: 0 0 auto;
      min-width: 200px;
      margin: 0;
    }

    ul {
      padding: ${spacing.xs}px ${spacing.lg}px;
      list-style-type: none;
      margin: 0;

      li {
        padding: ${spacing.xs}px 0;
        white-space: nowrap;

        a {
          color: ${colors.gray70};
          font-size: 14px;
        }
      }
    }
  }

  .tab {
    font-size: 16px;
    color: ${colors.white};
    font-weight: 600;
    padding: ${spacing.xs}px ${spacing.lg}px;
    border-bottom: 1px solid ${colors.gray85};
    min-width: 150px;
    white-space: nowrap;
  }
`;

export const SupportDocsWrapper = styled.div`
  h2 {
    padding: ${spacing.sm}px;
    border-radius: ${spacing.xs}px ${spacing.xs}px 0 0;
  }

  > ul {
    padding: 0px ${spacing.sm}px ${spacing.xs}px ${spacing.sm}px;
    list-style-type: none;

    > li {
      padding: ${spacing.xs}px;
      border: 'none';
      margin-bottom: ${spacing.xs}px;
      border-radius: ${spacing.xs}px;
      font-size: 14px;

      span {
        color: ${colors.gray50};
        font-size: 12px;
        display: block;
        margin-top: ${spacing.sm}px;
      }

      ul {
        margin-left: ${spacing.lg}px;
        list-style: disc;
      }
    }
  }

  .release-all {
    text-align: center;
    padding-bottom: ${spacing.sm}px;
    font-size: 14px;
  }
`;

export const NavSearch = styled.div`
  padding-top: 2px;
  margin-right: 24px;

  .form__field__header {
    display: none;
  }

  .search-field {
    width: 200px;
  }
`;

export const BetaButtonWrapper = styled.div`
  padding-top: 2px;
`;

export const SearchFieldWrapper = styled.div`
  span {
    display: none;
  }

  #nav-search {
    width: 200px;
    box-sizing: border-box;
  }

  div:first-child {
    margin: 0;
  }
`;

export const styles: AppHeaderStyles = {
  homeButton: {
    height: navHeight,
    display: 'block',
    lineHeight: navHeight,
    color: colors.white,
    backgroundColor: colors.orange50,
    minWidth: '32px',
    width: '32px',
    textAlign: 'center',
  },
  logoWrapper: {
    padding: '10px 0 0 24px',
  },
  headerIcons: {
    padding: '6px 24px 0 0',
    marginLeft: 'auto',
    color: colors.white,
  },
  ipaReleasePopover: {
    width: '350px',
    maxWidth: 'none',
  },
  ipaFavoritesPopover: {
    width: '450px',
    maxWidth: 'none',
  },
  releaseAllLink: {
    textAlign: 'center',
  },
};

export const NavButton = styled.div`
  border-radius: ${spacing.xs}px;
  background: ${colors.gray20};
  height: 40px;
  cursor: pointer;
  &:hover {
    background: ${colors.gray30};
  }
  display: flex;
  flex-direction: row;
  color: ${colors.gray98};
  padding-left: ${spacing.sm}px;

  span {
    font-size: 14px;
    letter-spacing: 0.15px;
    display: inline-block;
    line-height: 40px;
  }
`;

export const Lookup = styled.div<IpaMenuProps>`
  position: absolute;
  top: 46px;
  right: 0;
  width: 200px;
  min-width: 400px;
  z-index: 100;
  display: ${(props) => (props.show ? 'block' : 'none')};

  .lookup-wrapper {
    padding: ${spacing.sm}px;
  }

  .error {
    margin-top: ${spacing.xs}px;
    color: ${colors.red50};
    padding: ${spacing.xs}px 0;
    font-size: 14px;
  }
`;

export const Info = styled.div`
  color: ${colors.black};
  font-size: 14px;
  margin-top: ${spacing.sm}px;
`;
