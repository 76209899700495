import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  createContext,
} from 'react';
import { Flex, Spinner } from '@procore/core-react';
import GeneralInformationCard from './GeneralInformationCard';
import PermissionsCard from './PermissionsCard';
import type { IEditPermissions } from './PermissionsCard';
import DemandDepositAccountsCard from './DemandDepositAccountCard';
import type { IAddFundingAccount } from './DemandDepositAccountCard';
import { PaymentsBusinessEntityContext } from 'contexts/PaymentsBusinessEntityContext';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { AxiosError } from 'axios';
import type {
  FormattedPaymentsUser,
  PaymentsBusinessEntityGeneralTabContextProps,
} from './interfaces';
import {
  PaymentsServiceBusinessEntitiesApi,
  PaymentsFundingAccountApi,
  PaymentsServiceUsersApi,
  PendingPaymentAdminsApi,
  PaymentsUserDtoUserRoleEnum,
  CompanyDirectoryApi,
  EntitlementsApi,
} from '@procore/ipa-nt-api-client-ts';
import type {
  BusinessEntityDto,
  FundingAccountControllerListFundingAccountsV10200Response,
  PaymentsUserDto,
  PendingPaymentAdminsDto,
  CompanyPersonDto,
  UpdateCompanyFeeSettingsRequestDto,
  FundingAccountControllerGetExternalAccountsV10200Response,
} from '@procore/ipa-nt-api-client-ts';
import { getApi, getErrorMessage } from 'utils/api';
import type { IEditableCard } from 'components/EditableCard';
import FeePaySettingsCard from './FeePaySettingsCard';
import type { AccountFeaturesProps } from 'pages/Company/Tools/interfaces';
import {
  type DeactivateExternalAccountsEditProps,
  ExternalAccountsCard,
  type VerifyExternalAccountsEditProps,
} from './ExternalAccountsCard';
export const PaymentsBusinessEntityGeneralTabContext = createContext(
  {} as PaymentsBusinessEntityGeneralTabContextProps,
);

const GeneralTab = () => {
  const context = useContext(PaymentsBusinessEntityContext);
  const alert = useContext(IpaAlertContext);
  const perPage = 100;
  const companyPeoplePerPage = 5000;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cardLoading, setCardLoading] = useState<{ [key: string]: boolean }>(
    {},
  );
  const [generalInformation, setGeneralInformation] =
    useState<BusinessEntityDto>();
  const [fundingAccounts, setFundingAccounts] =
    useState<FundingAccountControllerListFundingAccountsV10200Response>();
  const [externalAccounts, setExternalAccounts] =
    useState<FundingAccountControllerGetExternalAccountsV10200Response>();
  const [paymentsUsers, setPaymentsUsers] = useState<PaymentsUserDto[]>();
  const [pendingPaymentAdmins, setPendingPaymentAdmins] =
    useState<PendingPaymentAdminsDto[]>();
  const [formattedUsers, setFormattedUsers] =
    useState<FormattedPaymentsUser[]>();
  const [companyPeople, setCompanyPeople] = useState<FormattedPaymentsUser[]>();
  const [hasPayeeFeeEntitlement, setHasPayeeFeeEntitlement] =
    useState<boolean>(false);

  const dataSources: PaymentsBusinessEntityGeneralTabContextProps['dataSources'] =
    {
      generalInformation: {
        id: 'generalInformation',
        data: generalInformation,
      },
      fundingAccounts: {
        id: 'fundingAccounts',
        data: fundingAccounts,
      },
      paymentsUsers: {
        id: 'paymentsUsers',
        data: formattedUsers,
      },
      companyPeople: {
        id: 'companyPeople',
        data: companyPeople,
      },
      externalAccounts: {
        id: 'externalAccounts',
        data: externalAccounts,
      },
    };

  const getFundingAccounts = useCallback(async () => {
    try {
      const api: PaymentsFundingAccountApi = getApi(PaymentsFundingAccountApi);

      const response = await api.fundingAccountControllerListFundingAccountsV10(
        context.env,
        Number(context.companyId),
        1,
        perPage,
        '',
      );
      if (response.data) {
        setFundingAccounts(response.data);
      }
    } catch (e: any) {
      return e;
    }
  }, [context.env, context.companyId]);

  const getExternalAccounts = useCallback(
    async (page?: number, perPage?: number) => {
      try {
        const api: PaymentsFundingAccountApi = getApi(
          PaymentsFundingAccountApi,
        );

        const response = await api.fundingAccountControllerGetExternalAccountsV10(
          context.env,
          Number(context.companyId),
          page || 1,
          perPage || 25,
          '',
        );

        if (response.data) {
          setExternalAccounts(response.data);
        }
      } catch (e: any) {
        return e;
      }
    },
    [context.env, context.companyId],
  );

  const getPaymentsUsers = useCallback(async () => {
    try {
      const api: PaymentsServiceUsersApi = getApi(PaymentsServiceUsersApi);

      const response = await api.paymentsUsersControllerListPaymentsUsersV10(
        context.env,
        Number(context.companyId),
        1,
        perPage,
      );

      setPaymentsUsers(response.data.items);
    } catch (e: any) {
      return e;
    }
  }, [context.companyId, context.env]);

  const getEntitlements = useCallback(async () => {
    try {
      const api = getApi(EntitlementsApi);
      const res = await api.toolEntitlementControllerGetEntitlementsV10(
        Number(context.companyId),
        context.env,
        {
          headers: {
            'Cache-Control': 'no-cache',
          },
        },
      );

      if (res.data) {
        // Check if the company has the payee fee entitlement

        const accountFeatures = (res.data as AccountFeaturesProps).entitlements;

        setHasPayeeFeeEntitlement(
          accountFeatures.some(
            (feature) =>
              feature.feature_id ===
                'procore_company_payments_with_payee_fee' &&
              feature.is_entitled,
          ),
        );
      }
    } catch (e: any) {
      return e;
    }
  }, [context.companyId, context.env]);

  const getPendingPaymentAdmins = useCallback(async () => {
    try {
      const api = getApi(PendingPaymentAdminsApi);
      const res =
        await api.pendingPaymentAdminsControllerGetPendingPaymentsAdminsByCompanyV10(
          'pending',
          context.env,
          Number(context.companyId),
        );
      setPendingPaymentAdmins(res.data);
    } catch (e: any) {
      return e;
    }
  }, [context.companyId, context.env]);

  const getCompanyPeople = useCallback(async () => {
    try {
      const api = getApi(CompanyDirectoryApi);
      const res = await api.companyDirectoryControllerGetCompanyPeopleV10(
        context.env,
        Number(context.companyId),
        companyPeoplePerPage,
        1,
        true,
      );

      let formattedCompanyPeople: FormattedPaymentsUser[] = res.data
        .filter(
          (companyPerson: CompanyPersonDto) =>
            !!companyPerson.user_uuid && !!companyPerson.contact,
        )
        .map((companyPerson: CompanyPersonDto) => {
          return {
            id: companyPerson.user_uuid.toString(),
            role: PaymentsUserDtoUserRoleEnum.PaymentAdmin,
            label: `${companyPerson.first_name} ${companyPerson.last_name} (${companyPerson.contact.email})`,
            isPending: false,
          };
        });

      setCompanyPeople(formattedCompanyPeople);
    } catch (e: any) {
      return e;
    }
  }, [context.companyId, context.env]);

  const getPermissionsData = async () => {
    const errors: AxiosError[] = [];
    const promises = await Promise.all([
      getPaymentsUsers(),
      getPendingPaymentAdmins(),
      getCompanyPeople(),
    ]);

    promises.forEach((promise) => {
      if (promise instanceof AxiosError) {
        errors.push(promise);
      }
    });
    if (errors.length > 0) {
      alert.error(errors);
    }
  };

  const saveGeneralInformation = async (
    card: IEditableCard,
    values: any,
    reason?: string,
    changedKeys?: string[],
  ) => {
    alert.closeAlert();
    setCardLoading({ ...cardLoading, [card.id]: true });

    const operations = [];

    if (values.generalInformation.mtlEnabled) {
      operations.push(
        updateMtlEnabledStatus(values.generalInformation.mtlEnabled, reason),
      );
    }
    if (values.generalInformation.active) {
      operations.push(
        updateEntityActiveStatus(values.generalInformation.active, reason),
      );
    }

    const results = await Promise.allSettled(operations);

    setCardLoading({
      ...cardLoading,
      [card.id]: false,
    });

    const errors = results.flatMap((result, index: number): string | string[] =>
      result.status === 'rejected'
        ? getErrorMessage(result.reason) ||
          context.pageConfig.translate('EDIT_ERROR')
        : [],
    );

    if (errors.length > 0) {
      alert.error(errors.join('\n'));
    } else {
      await getGeneralInformation();
      alert.success(context.pageConfig.translate('CARD_UPDATED'));
    }
  };

  const getGeneralInformation = async () => {
    const api: PaymentsServiceBusinessEntitiesApi = getApi(
      PaymentsServiceBusinessEntitiesApi,
    );

    try {
      const response = await api.businessEntityControllerGetBusinessEntityV10(
        context.env,
        Number(context.companyId),
      );

      setGeneralInformation(response.data);
    } catch (e: any) {
      alert.error(e);
    }
  };

  const updateEntityActiveStatus = async (active: any, reason?: string) => {
    const api: PaymentsServiceBusinessEntitiesApi = getApi(
      PaymentsServiceBusinessEntitiesApi,
    );

    if (active.id === 'active') {
      return await api.businessEntityControllerActivateBusinessEntityV10(
        context.env,
        Number(context.companyId),
      );
    } else if (active.id === 'inactive') {
      return await api.businessEntityControllerDeactivateBusinessEntityV10(
        context.env,
        Number(context.companyId),
      );
    } else {
      alert.error(context.pageConfig.translate('EDIT_ERROR'));
    }
  };

  const verifyExternalAccount = async (
    id: string,
    values: VerifyExternalAccountsEditProps,
  ) => {
    alert.closeAlert();
    setCardLoading({ ...cardLoading, externalAccounts: true });
    try {
      const api: PaymentsFundingAccountApi = getApi(PaymentsFundingAccountApi);
      const res = await api.fundingAccountControllerVerifyExternalAccountV10(
        context.env,
        Number(context.companyId),
        id,
        {
          verificationStatus: values.verificationStatus,
          externalAccountId: id,
          reason: values.reason,
        },
      );

      await getExternalAccounts();
      alert.success(
        context.pageConfig.translate('UPDATE_EXTERNAL_ACCOUNT_SUCCESS'),
      );
    } catch (e: any) {
      alert.error(e);
    } finally {
      setCardLoading({ ...cardLoading, externalAccounts: false });
    }
  };

  const deactivateExternalAccount = async (
    id: string,
    values: DeactivateExternalAccountsEditProps,
  ) => {
    alert.closeAlert();
    setCardLoading({ ...cardLoading, externalAccounts: true });
    try {
      const api: PaymentsFundingAccountApi = getApi(PaymentsFundingAccountApi);
      const res = await api.fundingAccountControllerDeactivateExternalAccountV10(
        context.env,
        Number(context.companyId),
        id,
        {
          externalAccountId: id,
          reason: values.reason,
        },
      );

      await getExternalAccounts();
      alert.success(
        context.pageConfig.translate('UPDATE_EXTERNAL_ACCOUNT_SUCCESS'),
      );
    } catch (e: any) {
      alert.error(e);
    } finally {
      setCardLoading({ ...cardLoading, externalAccounts: false });
    }
  };

  const updateMtlEnabledStatus = async (values: any, reason?: string) => {
    const api: PaymentsServiceBusinessEntitiesApi = getApi(
      PaymentsServiceBusinessEntitiesApi,
    );

    if (values.id === 'enabled') {
      return await api.businessEntityControllerEnableMtlV10(
        context.env,
        Number(context.companyId),
      );
    } else if (values.id === 'disabled') {
      return await api.businessEntityControllerDisableMtlV10(
        context.env,
        Number(context.companyId),
      );
    } else {
      alert.error(context.pageConfig.translate('EDIT_ERROR'));
    }
  };

  const createAdmins = async (
    adminsToCreate: string[],
    values: IEditPermissions,
  ) => {
    const pendingAdminsApi = getApi(PendingPaymentAdminsApi);

    adminsToCreate.map(async (adminId) => {
      await pendingAdminsApi.pendingPaymentAdminsControllerCreatePendingPaymentAdminV10(
        context.env,
        {
          pendingUserUuid: adminId,
          companyId: Number(context.companyId),
          companyName: generalInformation?.entityName,
          companyAccountType: generalInformation?.capabilities,
          userName: values.paymentsUsers.admins?.find(
            (user) => user.id === adminId,
          )?.label,
        },
      );
    });
  };

  const removeAdmins = async (adminsToRemove: string[], reason: string) => {
    adminsToRemove.map(async (adminId) => {
      const isPendingAdmin = pendingPaymentAdmins?.some(
        (admin) => admin.user_id === adminId,
      );

      if (isPendingAdmin) {
        const pendingAdminsApi = getApi(PendingPaymentAdminsApi);

        await pendingAdminsApi.pendingPaymentAdminsControllerRejectPaymentAdminV10(
          context.env,
          {
            externalUUID: adminId,
            companyId: Number(context.companyId),
            reason: reason,
            userId: pendingPaymentAdmins?.find(
              (admin) => admin.user_id === adminId,
            )?.id,
            userRole: PaymentsUserDtoUserRoleEnum.PaymentAdmin,
          },
        );
      } else {
        const paymentsUsersApi = getApi(PaymentsServiceUsersApi);

        await paymentsUsersApi.paymentsUsersControllerDeletePaymentsUserV10(
          context.env,
          Number(context.companyId),
          adminId,
        );
      }
    });
  };

  const createDisbursers = async (
    disbursersToCreate: string[],
    reason: string,
  ) => {
    disbursersToCreate.map(async (disburserId) => {
      const paymentsUsersApi = getApi(PaymentsServiceUsersApi);
      await paymentsUsersApi.paymentsUsersControllerAddPaymentsUserV10(
        context.env,
        Number(context.companyId),
        {
          externalUUID: disburserId,
          userRole: PaymentsUserDtoUserRoleEnum.Disburser,
          companyId: Number(context.companyId),
          reason: reason,
        },
      );
    });
  };

  const removeDisbursers = async (disbursersToRemove: string[]) => {
    disbursersToRemove.map(async (disburserId) => {
      const paymentsUsersApi = getApi(PaymentsServiceUsersApi);
      await paymentsUsersApi.paymentsUsersControllerDeletePaymentsUserV10(
        context.env,
        Number(context.companyId),
        disburserId,
      );
    });
  };

  const savePermissions = async (
    card: IEditableCard,
    values: IEditPermissions,
    reason?: string,
    _changedKeys?: string[],
  ) => {
    alert.closeAlert();
    setCardLoading({ ...cardLoading, [card.id]: true });

    try {
      const newPaymentAdminIds =
        values.paymentsUsers.admins?.map((admin) => admin.id) ?? [];
      const newDisburserIds =
        values.paymentsUsers.disbursers?.map((disburser) => disburser.id) ?? [];

      const commonIds = newPaymentAdminIds.filter((id) =>
        newDisburserIds.includes(id),
      );
      if (commonIds.length > 0)
        throw new Error('Cannot have the same user in both roles.');

      const oldPaymentAdminIds = formattedUsers
        ?.filter(
          (user) => user.role === PaymentsUserDtoUserRoleEnum.PaymentAdmin,
        )
        ?.map((user) => user.id);

      const oldDisburserIds = formattedUsers
        ?.filter((user) => user.role === PaymentsUserDtoUserRoleEnum.Disburser)
        ?.map((user) => user.id);

      // Create new pending admins
      if (values.paymentsUsers.admins !== undefined) {
        let adminsToCreate = newPaymentAdminIds.filter(
          (adminId) => !oldPaymentAdminIds.includes(adminId),
        );

        await createAdmins(adminsToCreate, values);

        // Reject pending Admins + remove payment admins
        let adminsToRemove = oldPaymentAdminIds.filter(
          (adminId) => !newPaymentAdminIds.includes(adminId),
        );

        await removeAdmins(adminsToRemove, reason);
      }

      if (values.paymentsUsers.disbursers !== undefined) {
        // Create Disbursers
        let disbursersToCreate = newDisburserIds.filter(
          (disburserId) => !oldDisburserIds.includes(disburserId),
        );

        await createDisbursers(disbursersToCreate, reason);

        // Remove Disbursers
        let disbursersToRemove = oldDisburserIds.filter(
          (disburserId) => !newDisburserIds.includes(disburserId),
        );

        await removeDisbursers(disbursersToRemove);
      }

      alert.success(context.pageConfig.translate('SAVED_PERMISSIONS'));

      await getPermissionsData();
    } catch (e: any) {
      alert.error(e);
    } finally {
      setCardLoading({
        ...cardLoading,
        [card.id]: false,
      });
    }
  };

  const addFundingAccount = async (values: IAddFundingAccount) => {
    alert.closeAlert();
    setCardLoading({ ...cardLoading, demandDepositAccounts: true });
    try {
      const api: PaymentsFundingAccountApi = getApi(PaymentsFundingAccountApi);

      await api.fundingAccountControllerCreateFundingAccountV10(
        context.env,
        Number(context.companyId),
        values,
      );

      // Load the funding accounts again to refresh the data
      await getFundingAccounts();

      alert.success(
        context.pageConfig.translate('ADD_FUNDING_ACCOUNT_SUCCESS'),
      );
    } catch (e: any) {
      alert.error(e);
    } finally {
      setCardLoading({ ...cardLoading, demandDepositAccounts: false });
    }
  };

  const updateFundingAccount = async (
    id: string,
    values: IAddFundingAccount,
  ) => {
    alert.closeAlert();
    setCardLoading({ ...cardLoading, demandDepositAccounts: true });
    try {
      const api: PaymentsFundingAccountApi = getApi(PaymentsFundingAccountApi);

      await api.fundingAccountControllerUpdateFundingAccountV10(
        context.env,
        Number(context.companyId),
        id,
        values,
      );

      // Load the funding accounts again to refresh the data
      await getFundingAccounts();

      alert.success(
        context.pageConfig.translate('UPDATE_FUNDING_ACCOUNT_SUCCESS'),
      );
    } catch (e: any) {
      alert.error(e);
    } finally {
      setCardLoading({ ...cardLoading, demandDepositAccounts: false });
    }
  };

  const approveAdmins = async (values: any) => {
    alert.closeAlert();
    setCardLoading({ ...cardLoading, permissions: true });
    try {
      const api = getApi(PendingPaymentAdminsApi);

      for (const approvedAdmin of values.approvedAdmins) {
        let pendingAdmin = pendingPaymentAdmins?.find(
          (pendingAdmin) => pendingAdmin.user_id === approvedAdmin.id,
        );

        await api.pendingPaymentAdminsControllerApprovePaymentAdminV10(
          context.env,
          {
            companyId: Number(context.companyId),
            userId: pendingAdmin.id,
            externalUUID: pendingAdmin.user_id,
            userRole: 'PAYMENT_ADMIN',
            createdBy: pendingAdmin.created_by_okta_id,
            reason: '',
          },
        );
      }

      await initPage();
      alert.success(context.pageConfig.translate('APPROVE_ADMINS_SUCCESS'));
    } catch (e: any) {
      alert.error(e);
    } finally {
      setCardLoading({ ...cardLoading, permisisons: false });
    }
  };

  const saveFeePaySettings = async (
    card: IEditableCard,
    values: any,
    reason?: string,
    changedKeys?: string[],
  ) => {
    alert.closeAlert();
    setCardLoading({ ...cardLoading, [card.id]: true });

    const api: PaymentsServiceBusinessEntitiesApi = getApi(
      PaymentsServiceBusinessEntitiesApi,
    );

    try {
      const payload: UpdateCompanyFeeSettingsRequestDto = {
        companyId: Number(context.companyId),
        applyFeesOnAgreements: values.applyFeesOnAgreements,
        payorPaysFeesFeatureEnabled: values.payorPaysFeesFeatureEnabled,
        payeeFeeCollectionStage: values.payeeFeeCollectionStage,
        requiresPayAgreementForInvoiceSubmission:
          values.requiresPayAgreementForInvoiceSubmission,
      };

      const response =
        await api.businessEntityControllerUpdateBusinessEntityFeeSettingsV10(
          context.env,
          Number(context.companyId),
          payload,
        );

      alert.success(context.pageConfig.translate('FEE_SETTINGS_UPDATED'));

      setGeneralInformation(response.data);
    } catch (error: any) {
      alert.error(error);
    } finally {
      setCardLoading({
        ...cardLoading,
        [card.id]: false,
      });
    }
  };

  const initPage = useCallback(async () => {
    setIsLoading(true);
    const errors: AxiosError[] = [];
    const promises = await Promise.all([
      getPaymentsUsers(),
      getPendingPaymentAdmins(),
      getCompanyPeople(),
      getEntitlements(),
    ]);
    promises.forEach((promise) => {
      if (promise instanceof AxiosError) {
        errors.push(promise);
      }
    });
    if (errors.length > 0) {
      alert.error(errors);
    }
    if (context.dataSources.generalInformation.data) {
      const { capabilities } = context.dataSources.generalInformation.data;
      setGeneralInformation(context.dataSources.generalInformation.data);
      if (capabilities === 'send_payments') {
        await getFundingAccounts();
        await getExternalAccounts();
      }
    }

    setIsLoading(false);
  }, [
    getCompanyPeople,
    getPaymentsUsers,
    getPendingPaymentAdmins,
    getEntitlements,
  ]);

  const formatUsers = useCallback(() => {
    const formattedPendingUsers: FormattedPaymentsUser[] = (
      pendingPaymentAdmins ?? []
    ).map((pendingUser) => {
      return {
        id: pendingUser.user_id,
        role: PaymentsUserDtoUserRoleEnum.PaymentAdmin,
        label: pendingUser.user_name ?? '', // Ensure name is always a string
        isPending: true,
      };
    });

    const formattedUsers: FormattedPaymentsUser[] = (paymentsUsers ?? []).map(
      (user) => {
        return {
          id: user.externalUUID,
          role: user.userRole,
          label: user.userName ?? user.externalUUID,
          isPending: false,
        };
      },
    );

    // Merge the two arrays
    return [...formattedUsers, ...formattedPendingUsers];
  }, [paymentsUsers, pendingPaymentAdmins]);

  useEffect(() => {
    initPage();
  }, [context.companyId, context.env, initPage]);

  useEffect(() => {
    if (paymentsUsers && pendingPaymentAdmins) {
      const formattedUsers = formatUsers();
      setFormattedUsers(formattedUsers);
    }
  }, [formatUsers, paymentsUsers, pendingPaymentAdmins]);

  return isLoading ? (
    <Flex justifyContent="center">
      <Spinner loading={isLoading} />
    </Flex>
  ) : (
    <PaymentsBusinessEntityGeneralTabContext.Provider value={{ dataSources }}>
      <React.Fragment>
        <GeneralInformationCard
          id="generalInformation"
          isCardLoading={cardLoading['generalInformation'] ?? false}
          save={saveGeneralInformation}
        />
        {generalInformation?.capabilities === 'send_payments' &&
          hasPayeeFeeEntitlement && (
            <FeePaySettingsCard
              id="feePaySettings"
              isCardLoading={cardLoading['feePaySettings'] ?? false}
              save={saveFeePaySettings}
            />
          )}
        <PermissionsCard
          id="permissions"
          isCardLoading={cardLoading['permissions'] || false}
          approveAdmins={approveAdmins}
          save={savePermissions}
        />
        {generalInformation?.capabilities === 'send_payments' && (
          <React.Fragment>
            <DemandDepositAccountsCard
              id="demandDepositAccounts"
              isCardLoading={cardLoading['demandDepositAccounts'] ?? false}
              pageSize={perPage}
              addFundingAccount={(values: IAddFundingAccount) => {
                addFundingAccount(values);
              }}
              editFundingAccount={(id, values: IAddFundingAccount) => {
                updateFundingAccount(id, values);
              }}
            />
            <ExternalAccountsCard
              id="externalAccounts"
              isCardLoading={cardLoading['externalAccounts'] ?? false}
              updateExternalAccount={(
                id,
                values: VerifyExternalAccountsEditProps,
              ) => {
                verifyExternalAccount(id, values);
              }}
              deactivateExternalAccount={(
                id,
                values: DeactivateExternalAccountsEditProps,
              ) => {
                deactivateExternalAccount(id, values);
              }}
              paginateExternalAccounts={getExternalAccounts}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    </PaymentsBusinessEntityGeneralTabContext.Provider>
  );
};

export default GeneralTab;
