import React, { useEffect } from 'react';
import { AppContext } from 'contexts/AppContext';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { getApi } from 'utils/api';
import {
  CompanyContactsApi,
  CompanyInfoApi,
  ProjectsApi,
} from '@procore/ipa-nt-api-client-ts';
import { QuickHistoryState } from './QuickHistoryState';

const itemTypes = [
  {
    type: 'contact',
    regex: new RegExp(
      '/v2/company/(?<companyId>\\d+)/contact/(?<contactId>\\d+)/login/(?<loginId>\\d+)/.*',
    ),
    url: '/v2/company/:companyId/contact/:contactId/login/:loginId/contact-info',
  },
  {
    type: 'project',
    regex: new RegExp(
      '/v2/company/(?<companyId>\\d+)/project/(?<projectId>\\d+)/.*',
    ),
    url: '/v2/company/:companyId/project/:projectId/project-info',
  },
  {
    type: 'company',
    regex: new RegExp('/v2/company/(?<companyId>\\d+).*'),
    url: '/v2/company/:companyId/info',
  },
];

const getCompanyInfo = async (companyId: string) => {
  try {
    const api = getApi(CompanyInfoApi);
    const res = await api.companyCompanyInfoControllerGetCompanyAccountStatusV10(
      'PROD',
      Number(companyId),
    );
    if (res.data) {
      return res.data;
    }
  } catch (e: any) {
    console.error('Could not fetch company info', e);
  }
};

const getProjectInfo = async (companyId: string, projectId: string) => {
  try {
    const api = getApi(ProjectsApi);
    const res = await api.projectControllerGetProjectInfoV10(
      'PROD',
      Number(companyId),
      Number(projectId),
    );
    if (res.data) {
      return res.data;
    }
  } catch (e: any) {
    console.error('Could not fetch project info', e);
  }
};

const getContactInfo = async (companyId: string, contactId: string) => {
  try {
    const api = getApi(CompanyContactsApi);
    const res = await api.companyContactsControllerGetContactV10(
      'PROD',
      Number(companyId),
      Number(contactId),
    );
    if (res.data) {
      return res.data;
    }
  } catch (e: any) {
    console.error('Could not fetch contact info', e);
  }
};

type StateKey = {
  type: string;
  companyId: string;
  projectId?: string;
  contactId?: string;
};

const state = new QuickHistoryState();
const queue: StateKey[] = [];

export const QuickHistoryWatcher = () => {
  const location = useLocation();
  const appContext = useContext(AppContext);
  let processQueueTimer: NodeJS.Timeout;

  const recordPageLoad = async (url: string) => {
    const item = itemTypes.find((i) => i.regex.test(url));

    if (item) {
      const ids = url.match(item.regex);
      if (!ids) {
        console.log('Unable to extract ids from url');
        return;
      }
      if (ids.groups && 'companyId' in ids.groups) {
        const stateKey: StateKey = {
          type: item.type,
          companyId: ids.groups.companyId,
          projectId: ids.groups.projectId,
          contactId: ids.groups.contactId,
        };

        if (state.has(stateKey)) {
          state.bump(stateKey);
        } else {
          queue.push(stateKey);
        }
      }
    } else {
      console.log('Quick History Watcher: Page type not recognized');
    }
  };

  const getItemData = async (
    companyId: string,
    projectId?: string,
    contactId?: string,
  ) => {
    const companyInfo = await getCompanyInfo(companyId);
    return {
      company: companyInfo,
      project: projectId
        ? await getProjectInfo(companyId, projectId)
        : undefined,
      contact: contactId
        ? await getContactInfo(companyId, contactId)
        : undefined,
    };
  };

  const processQueue = async () => {
    if (!appContext.authHeaderPresent) {
      return;
    }

    while (queue.length > 0) {
      const item = queue.shift();
      if (item) {
        const data = await getItemData(
          item.companyId,
          item.projectId,
          item.contactId,
        );

        if (item.type === 'company' && data.company) {
          state.add(item, data.company.name);
        } else if (item.type === 'project' && data.project) {
          state.add(item, data.project.name);
        } else if (item.type === 'contact' && data.company && data.contact) {
          state.add(item, data.company.name + ': ' + data.contact.email);
        }
      }
    }
  };

  // Start/stop queue processing based on auth header presence
  useEffect(() => {
    if (processQueueTimer && !appContext.authHeaderPresent) {
      clearInterval(processQueueTimer);
    }

    if (!processQueueTimer && appContext.authHeaderPresent) {
      processQueueTimer = setInterval(processQueue, 500);
    }
  }, [appContext.authHeaderPresent]);

  useEffect(() => {
    recordPageLoad(location.pathname);
  }, [location]);

  return <React.Fragment />;
};
