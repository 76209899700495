import React from 'react';
import { type LinkProps } from '@procore/core-react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { StyledLink } from './styles';

export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  ({ children, ...props }, ref) => {
    return (
      <StyledLink>
        <ReactRouterLink {...props} to={props.href} ref={ref}>
          {children}
        </ReactRouterLink>
      </StyledLink>
    );
  },
);
