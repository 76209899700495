import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  RequestLoggingApi,
  type ApiRequestGetDto,
} from '@procore/ipa-nt-api-client-ts';
import { useTranslation } from 'react-i18next';

import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { getApi } from 'utils/api';
import { Flex, Spinner } from '@procore/core-react';

interface ApiRequestDetailProps {
  requestId: string;
}

export const ApiRequestDetail = (props: ApiRequestDetailProps) => {
  const { t } = useTranslation(['api-requests']);
  const alert = useContext(IpaAlertContext);
  const [request, setRequest] = useState<ApiRequestGetDto>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getRequestData = useCallback(async () => {
    setRequest(null);
    setIsLoading(true);
    alert.closeAlert();
    try {
      const api = getApi(RequestLoggingApi);
      const res = await api.requestLoggingControllerGetV10(props.requestId);

      if (res.data) {
        setRequest(res.data);
      }
    } catch (e: any) {
      alert.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [props.requestId]);

  useEffect(() => {
    getRequestData();
  }, [getRequestData]);

  return (
    <div>
      {isLoading && (
        <Flex justifyContent="center">
          <Spinner loading={isLoading} />
        </Flex>
      )}
      {!isLoading && request && (
        <div className="request-detail">
          <div className="metadata">
            <div>
              <span>
                <strong>API Class: </strong>
              </span>
              <span>
                {request.metadata.client}::{request.metadata.api_name}
              </span>
            </div>
            <div>
              <span>
                <strong>Status: </strong>
              </span>
              <span>{request.metadata.status}</span>
            </div>
            <div>
              <span>
                <strong>Request ID: </strong>
              </span>
              <span>{request.metadata.request_id}</span>
            </div>
            <div>
              <span>
                <strong>Timestamp: </strong>
              </span>
              <span>{request.metadata.started_at}</span>
            </div>
          </div>
          <div>
            <h4>Request</h4>
            <ul>
              {Object.entries(request.data.requestHeaders).map(
                ([key, value]) => (
                  <li key={key}>
                    <strong>{key}: </strong>
                    {value}
                  </li>
                ),
              )}
            </ul>
            <pre>{JSON.stringify(request.data.requestData, null, 2)}</pre>
          </div>
          <div>
            <h4>Response</h4>
            <ul>
              {Object.entries(request.data.responseHeaders).map(
                ([key, value]) => (
                  <li key={key}>
                    <strong>{key}: </strong>
                    {value}
                  </li>
                ),
              )}
            </ul>
            <pre>{JSON.stringify(request.data.responseData, null, 2)}</pre>
          </div>
        </div>
      )}
    </div>
  );
};
